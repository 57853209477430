import React, { useState, useRef } from "react";
import { Button, Input, InputRef, Space, Tag } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { ProTable, ProColumns, ActionType } from "@ant-design/pro-components";
import lang from "../../../../../../lang";
import Export from "../../../../../../common/components/Export";

interface Ticket {
  id: string;
  subject: string;
  tags: string[];
  department: string;
  service: string;
  contact: string;
  status: string;
  priority: string;
  lastReply: string;
  created: string;
}

const ticketData: Ticket[] = [];

const TicketsTable: React.FC = () => {
  const [pageSize, setPageSize] = useState<number>(6);
  const searchInput = useRef<InputRef>(null);
  const actionRef = useRef<ActionType>();
  const [allSearch, setAllSearch] = useState<string>("");
  const [searchAttributes, setSearchAttributes] = useState<
    { key: string; value: string }[]
  >([]);

  const expobj = new Export(lang.get("tickets", ["Tickets"]) + ", " + new Date().toDateString());

  const handleSearch = (field: string, value: string) => {
    setAllSearch("");
    const existingAttribute = searchAttributes.find((attr) => attr.key === field);

    if (existingAttribute) {
      setSearchAttributes(
        searchAttributes.map((attr) =>
          attr.key === field ? { ...attr, value } : attr
        )
      );
    } else {
      setSearchAttributes([...searchAttributes, { key: field, value }]);
    }
  };

  const searchCols = (field: string, label: string) => {
    return {
      filterDropdown: (props: any) => {
        const { close } = props;

        setTimeout(() => {
          if (searchInput.current) {
            searchInput.current.focus();
          }
        }, 100);

        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={lang.get("tickets", [`Search ${label}`])}
              onChange={(e) => handleSearch(field, e.target.value)}
              value={searchAttributes.find((item) => item.key === field)?.value}
              style={{ marginBottom: 8, display: "block" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  close();
                  actionRef.current?.reload();
                }
              }}
            />
            <Space>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="small"
                onClick={() => actionRef.current?.reload()}
                style={{ width: 90 }}
              >
                {lang.get("tickets", ["Search"])}
              </Button>
              <Button
                onClick={() => close()}
                size="small"
                style={{ width: 90 }}
              >
                {lang.get("tickets", ["Close"])}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
    };
  };

  const columns: ProColumns<Ticket>[] = [
    {
      title: lang.get("tickets", ["ID"]),
      dataIndex: "id",
      key: "id",
      sorter: true,
      ...searchCols("id", lang.get("tickets", ["ID"])),
    },
    {
      title: lang.get("tickets", ["Subject"]),
      dataIndex: "subject",
      key: "subject",
      sorter: true,
      ...searchCols("subject", lang.get("tickets", ["Subject"])),
    },
    {
      title: lang.get("tickets", ["Tags"]),
      dataIndex: "tags",
      key: "tags",
      render: (_, record: Ticket) => (
        <>{record.tags.map((tag) => (
          <Tag key={tag}>{tag}</Tag>
        ))}</>
      ),
    },
    {
      title: lang.get("tickets", ["Department"]),
      dataIndex: "department",
      key: "department",
      sorter: true,
      ...searchCols("department", lang.get("tickets", ["Department"])),
    },
    {
      title: lang.get("tickets", ["Service"]),
      dataIndex: "service",
      key: "service",
      sorter: true,
      ...searchCols("service", lang.get("tickets", ["Service"])),
    },
    {
      title: lang.get("tickets", ["Contact"]),
      dataIndex: "contact",
      key: "contact",
      sorter: true,
      ...searchCols("contact", lang.get("tickets", ["Contact"])),
    },
    {
      title: lang.get("tickets", ["Status"]),
      dataIndex: "status",
      key: "status",
      sorter: true,
      render: (_, record: Ticket) => (
        <Tag color={record.status === "Open" ? "green" : "red"}>
          {lang.get("tickets", [record.status])}
        </Tag>
      ),
    },
    {
      title: lang.get("tickets", ["Priority"]),
      dataIndex: "priority",
      key: "priority",
      sorter: true,
      render: (_, record: Ticket) => (
        <Tag color={record.priority === "Urgent" ? "purple" : "blue"}>
          {lang.get("tickets", [record.priority])}
        </Tag>
      ),
    },
    {
      title: lang.get("tickets", ["Last Reply"]),
      dataIndex: "lastReply",
      key: "lastReply",
      sorter: true,
      ...searchCols("lastReply", lang.get("tickets", ["Last Reply"])),
    },
    {
      title: lang.get("tickets", ["Created"]),
      dataIndex: "created",
      key: "created",
      sorter: true,
      ...searchCols("created", lang.get("tickets", ["Created"])),
    },
  ];

  return (
    <ProTable
      headerTitle={lang.get("tickets", ["Tickets"])}
      columns={columns}
      dataSource={ticketData}
      actionRef={actionRef}
      rowKey="id"
      pagination={{
        defaultCurrent: 1,
        pageSize,
        showSizeChanger: true,
        pageSizeOptions: [6, 10, 20],
        showTotal: (total, range) =>
          lang.get("tickets", [`Showing ${range[0]}-${range[1]} of ${total} items`]),
        onShowSizeChange: (_, size) => setPageSize(size),
      }}
      search={false}
      scroll={{ x: 800 }}
      toolBarRender={() => [
        <Input
          style={{ width: "auto" }}
          key="all_search"
          allowClear
          placeholder={lang.get("tickets", ["Global Search"])}
          value={allSearch}
          onChange={(e) => {
            setAllSearch(e.target.value);
            setSearchAttributes([]);
            actionRef.current?.reload();
          }}
        />, 
        <Button
          key="csv"
          onClick={() => {
            const csvData = ticketData.map((row) => ({
              [lang.get("tickets", ["ID"])]: row.id,
              [lang.get("tickets", ["Subject"])]: row.subject,
              [lang.get("tickets", ["Tags"])]: row.tags.join(", "),
              [lang.get("tickets", ["Department"])]: row.department,
              [lang.get("tickets", ["Service"])]: row.service,
              [lang.get("tickets", ["Contact"])]: row.contact,
              [lang.get("tickets", ["Status"])]: row.status,
              [lang.get("tickets", ["Priority"])]: row.priority,
              [lang.get("tickets", ["Last Reply"])]: row.lastReply,
              [lang.get("tickets", ["Created"])]: row.created,
            }));
            expobj.exportToCSV(csvData);
          }}
        >
          {lang.get("tickets", ["Export CSV"])}
        </Button>,
        <Button
          key="excel"
          onClick={() => {
            const excelData = ticketData.map((row) => ({
              [lang.get("tickets", ["ID"])]: row.id,
              [lang.get("tickets", ["Subject"])]: row.subject,
              [lang.get("tickets", ["Tags"])]: row.tags.join(", "),
              [lang.get("tickets", ["Department"])]: row.department,
              [lang.get("tickets", ["Service"])]: row.service,
              [lang.get("tickets", ["Contact"])]: row.contact,
              [lang.get("tickets", ["Status"])]: row.status,
              [lang.get("tickets", ["Priority"])]: row.priority,
              [lang.get("tickets", ["Last Reply"])]: row.lastReply,
              [lang.get("tickets", ["Created"])]: row.created,
            }));
            expobj.exportToExcel(excelData);
          }}
        >
          {lang.get("tickets", ["Export Excel"])}
        </Button>,
        <Button
          key="pdf"
          onClick={() => {
            expobj.exportToPDF({
              head: [
                [
                  lang.get("tickets", ["ID"]),
                  lang.get("tickets", ["Subject"]),
                  lang.get("tickets", ["Tags"]),
                  lang.get("tickets", ["Department"]),
                  lang.get("tickets", ["Service"]),
                  lang.get("tickets", ["Contact"]),
                  lang.get("tickets", ["Status"]),
                  lang.get("tickets", ["Priority"]),
                  lang.get("tickets", ["Last Reply"]),
                  lang.get("tickets", ["Created"]),
                ],
              ],
              body: ticketData.map((row) => [
                row.id,
                row.subject,
                row.tags.join(", "),
                row.department,
                row.service,
                row.contact,
                row.status,
                row.priority,
                row.lastReply,
                row.created,
              ]),
            });
          }}
        >
          {lang.get("tickets", ["Export PDF"])}
        </Button>,
      ]}
    />
  );
};

export default TicketsTable;
