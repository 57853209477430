import { Breadcrumb, Typography, Button, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import lang from "../../../lang";
import InternalDeliveryTable from "./InternalDeliveryTable";
import { useNavigate } from "react-router-dom";
//   import InternalDeliveryNoteTable from "./InternalDeliveryNoteTable";

const { Title } = Typography;

const InternalDeliveryNote: React.FC = () => {
  const navigate = useNavigate();

  const handleAddTransferClick = () => {
    navigate("/stock-transfer-add");
  };

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      {/* Breadcrumb */}
      <Breadcrumb style={{ marginBottom: "16px" }} separator=">">
        <Breadcrumb.Item>{lang.get("breadcrumbs", ["Home"])}</Breadcrumb.Item>
        <Breadcrumb.Item>
          {lang.get("breadcrumbs", ["Inventory"])}
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {lang.get("breadcrumbs", ["Stock Transfer"])}
        </Breadcrumb.Item>
      </Breadcrumb>

      {/* Page Title */}
      <Title level={2} style={{ marginBottom: "16px" }}>
        {lang.get("internalDeliveryNote", ["Stock Transfer"])} (7)
      </Title>

      {/* Filter and Actions */}
      <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
        <Col xs={24} sm={12} md={12} lg={12} style={{ textAlign: "left" }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => handleAddTransferClick()}
          >
            {lang.get("internalDeliveryNote", ["Add New Transfer"])}
          </Button>
        </Col>
      </Row>

      {/* Table */}
      <InternalDeliveryTable />
    </div>
  );
};

export default InternalDeliveryNote;
