import React, { useState, useRef } from "react";
import {
  Tag,
  InputRef,
  Input,
  Button,
  Space,
  Avatar,
  Tooltip,
  Badge,
} from "antd";
import {
  CheckCircleOutlined,
  PauseCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  AlertOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import Export from "../../../../common/components/Export";
import { ActionType, ProColumns, ProTable } from "@ant-design/pro-components";

import { DBProject, DBCustomer } from "../../types/types";

import apiRequest from "../../../../common/utils/apiRequest";
import coreFunctions from "../../../../common/utils/coreFunctions";
import lang from "../../../../lang";

const statusStyles: Record<string, { color: string; icon: React.ReactNode }> = {
  Finished: { color: "green", icon: <CheckCircleOutlined /> },
  "On Hold": { color: "orange", icon: <PauseCircleOutlined /> },
  "In Progress": { color: "blue", icon: <SyncOutlined spin /> },
  Cancelled: { color: "red", icon: <CloseCircleOutlined /> },
};

const priorityStyles: Record<string, { color: string; icon: React.ReactNode }> =
  {
    High: { color: "red", icon: <AlertOutlined /> },
    Medium: { color: "blue", icon: <SyncOutlined /> },
    Low: { color: "gray", icon: <PauseCircleOutlined /> },
    Urgent: { color: "orange", icon: <AlertOutlined /> },
  };

const ProjectTable = ({ client }: { client: DBCustomer | null }) => {
  const [pageSize, setPageSize] = useState(15);
  const searchInput = useRef<InputRef>(null);
  const actionRef = useRef<ActionType>();
  const [searchAttributes, setSearchAttributes] = useState<
    { key: string; value: string }[]
  >([]);
  const [allSearch, setAllSearch] = useState<string>("");
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [tableData, setTableData] = useState<DBProject[]>([]);

  const expobj = new Export(
    lang.get("projects", ["Projects"]) + ", " + new Date().toDateString()
  );

  const handleSearch = (field: string, value: string) => {
    setAllSearch("");
    const existingAttribute = searchAttributes.find(
      (attr) => attr.key === field
    );

    if (existingAttribute) {
      setSearchAttributes(
        searchAttributes.map((attr) =>
          attr.key === field ? { ...attr, value } : attr
        )
      );
    } else {
      setSearchAttributes([
        ...searchAttributes,
        {
          key: field,
          value,
        },
      ]);
    }
  };

  const searchCols = (field: string, label: string) => {
    return {
      filterDropdown: (props: any) => {
        const { close } = props;

        setTimeout(() => {
          if (searchInput.current) {
            searchInput.current.focus();
          }
        }, 100);

        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={lang.get("projects", [`Search ${label}`])}
              onChange={(e) => handleSearch(field, e.target.value)}
              value={
                searchAttributes.filter((item) => item.key === field)[0]?.value
              }
              style={{ marginBottom: 8, display: "block" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  close();
                  if (actionRef.current) {
                    actionRef.current?.reset?.();
                    actionRef.current.reload();
                  }
                }
              }}
            />
            <Space>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="small"
                onClick={() => {
                  actionRef.current?.reset?.();
                  actionRef.current?.reload();
                }}
                style={{ width: 90 }}
              >
                {lang.get("projects", ["Search"])}
              </Button>
              <Button
                onClick={() => close()}
                size="small"
                style={{ width: 90 }}
              >
                {lang.get("projects", ["Close"])}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
    };
  };

  const columns: ProColumns<DBProject>[] = [
    {
      title: lang.get("projects", ["#"]),
      sorter: false,
      width: 50,
      render: (_, record, index) => <p style={{ margin: 0 }}>{index + 1}</p>,
    },
    {
      title: lang.get("projects", ["Number"]),
      dataIndex: "number",
      key: "number",
      sorter: true,
      width: 50,
      ...searchCols("number", lang.get("projects", ["Project Number"])),
      render: (_, record) => <p style={{ margin: 0 }}>{record.number}</p>,
    },
    {
      title: lang.get("projects", ["Project Name"]),
      dataIndex: "name",
      key: "name",
      sorter: true,
      ...searchCols("name", lang.get("projects", ["Project Name"])),
      render: (_, record: DBProject) => (
        <Link
          to={`/project-details/${record.id}`}
          style={{
            color: "inherit",
            textDecoration: "none",
          }}
        >
          <p style={{ margin: 0, fontWeight: "bold" }}>{record.name}</p>
        </Link>
      ),
    },
    {
      title: lang.get("projects", ["Customer"]),
      dataIndex: "customer",
      key: "customer",
      ...searchCols("customer", lang.get("projects", ["Customer"])),
      sorter: true,
    },
    {
      title: lang.get("projects", ["Tags"]),
      dataIndex: "Tags",
      key: "Tags",
      ...searchCols("tags", lang.get("projects", ["tags"])),
      render: (_, record: DBProject) => {
        for (let i = 0; i < record.tags.length; i++) {
          return <Badge color="#2db7f5" text={record.tags[i]} />;
        }
      },
    },
    {
      title: lang.get("projects", ["Start Date"]),
      dataIndex: "start_date",
      key: "start_date",
      ...searchCols("start_date", lang.get("projects", ["Start Date"])),
      sorter: true,
    },
    {
      title: lang.get("projects", ["Deadline"]),
      dataIndex: "deadline",
      key: "deadline",
      ...searchCols("deadline", lang.get("projects", ["Deadline"])),
      sorter: (a: DBProject, b: DBProject) =>
        new Date(a.deadline).getTime() - new Date(b.deadline).getTime(),
    },
    {
      title: lang.get("projects", ["Members"]),
      dataIndex: "members",
      key: "members",
      ...searchCols("members", lang.get("projects", ["Members"])),
      render: (_, record: DBProject) =>
        record.members.map((member, index) => {
          if (index == 3) {
            return (
              <Tooltip title={lang.get("projects", ["More Members"])}>
                <Avatar size="small" key={index}>
                  +{record.members.length - 3}
                </Avatar>
              </Tooltip>
            );
          }
          if (index > 3) {
            return null;
          } else {
            return (
              <Tooltip title={member.name} key={member.id}>
                <Avatar key={member.id} src={member.profile_image}>
                  {!member.profile_image && member.name[0]}
                </Avatar>
              </Tooltip>
            );
          }
        }),
      sorter: true,
    },
    {
      title: lang.get("projects", ["Progress"]),
      dataIndex: "progress",
      key: "progress",
      ...searchCols("progress", lang.get("projects", ["progress"])),
      render: (_, record: DBProject) => `${record.progress}/${100}`,
      sorter: true,
    },
    {
      title: lang.get("projects", ["Tasks"]),
      dataIndex: "task",
      key: "task",
      ...searchCols("tasks", lang.get("projects", ["tasks"])),
    },
    {
      title: lang.get("projects", ["Status"]),
      dataIndex: "status",
      key: "status",
      ...searchCols("status", lang.get("projects", ["Status"])),
      render: (_, record: DBProject) => {
        if (!record.status) {
          return null;
        }
        
        return (
          <Tag color={record.status.type}>
            {lang.get("projects", [record.status.name])}
          </Tag>
        );
      },
      sorter: true,
    },

    {
      title: lang.get("projects", ["Priority"]),
      dataIndex: "priority",
      key: "priority",
      ...searchCols("priority", lang.get("projects", ["Priority"])),
      render: (_, record: DBProject) => {
        if (!record.priority) {
          return null;
        }
        const { color, icon } = priorityStyles[record.priority] || {
          color: "gray",
          icon: null,
        };
        return (
          <Tag color={color}>
            {icon} {lang.get("projects", [record.priority])}
          </Tag>
        );
      },
      sorter: true,
    },
  ];

  return (
    <ProTable
      headerTitle={lang.get("projects", ["Projects"])}
      columns={columns}
      actionRef={actionRef}
      dataSource={tableData}
      request={async (params, sort, filter) => {
        await new coreFunctions().waitRequest(10);

        const processedFilters = Object.entries(filter || {}).reduce(
          (acc, [key, value]) => {
            if (value && value.length > 0) {
              acc[key] = value;
            }
            return acc;
          },
          {} as Record<string, (string | number)[]>
        );

        const payload = {
          client: "",
          params: params,
          sorts: sort,
          filters: processedFilters,
          searches: searchAttributes,
          all_search: allSearch,
        };

        if (client?.id) {
          payload.client = client.id;
        }

        const API_REQUEST = new apiRequest("projects/get", true);
        const response = await API_REQUEST.send(payload);

        if (response && response.status === "success") {
          const fetchedData = response.data || [];
          setTableData(fetchedData);
          setTotalRecords(response.total);

          return {
            data: fetchedData,
            total: totalRecords,
            success: true,
          };
        }

        return {
          data: [],
          total: 0,
          success: false,
        };
      }}
      rowKey="id"
      cardBordered
      pagination={{
        defaultCurrent: 1,
        pageSize: pageSize,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50],
        showTotal: (total, range) =>
          lang.get("projects", [
            `Showing ${range[0]}-${range[1]} of ${total} items`,
          ]),
        onShowSizeChange: (current, size) => {
          setPageSize(size);
        },
      }}
      search={false}
      scroll={{ x: 800 }}
      toolBarRender={() => [
        <Input
          style={{ width: "auto" }}
          key="all_search"
          allowClear
          placeholder={lang.get("projects", ["Search"])}
          value={allSearch}
          onChange={(e) => {
            setAllSearch(e.target.value);
            setSearchAttributes([]);
            actionRef?.current?.reload();
          }}
        />,
        <Button
          key="csv"
          onClick={() => {
            const csvData = tableData.map((row) => ({
              [lang.get("projects", ["ID"])]: row.id,
              [lang.get("projects", ["Name"])]: row.name,
              [lang.get("projects", ["Customer"])]: row.customer,
              [lang.get("projects", ["Tags"])]: row.tags,
              [lang.get("projects", ["Start Date"])]: row.start_date,
              [lang.get("projects", ["Deadline"])]: row.deadline,
              [lang.get("projects", ["Members"])]: row.members,
              [lang.get("projects", ["Progress"])]: row.progress,
              [lang.get("projects", ["Status"])]: row.status,
              [lang.get("projects", ["Priority"])]: row.priority,
            }));
            expobj.exportToCSV(csvData);
          }}
        >
          {lang.get("projects", ["Export CSV"])}
        </Button>,
        <Button
          key="excel"
          onClick={() => {
            const excelData = tableData.map((row) => ({
              [lang.get("projects", ["ID"])]: row.id,
              [lang.get("projects", ["Name"])]: row.name,
              [lang.get("projects", ["Customer"])]: row.customer,
              [lang.get("projects", ["Tags"])]: row.tags,
              [lang.get("projects", ["Start Date"])]: row.start_date,
              [lang.get("projects", ["Deadline"])]: row.deadline,
              [lang.get("projects", ["Members"])]: row.members,
              [lang.get("projects", ["Progress"])]: row.progress,
              [lang.get("projects", ["Status"])]: row.status,
              [lang.get("projects", ["Priority"])]: row.priority,
            }));
            expobj.exportToExcel(excelData);
          }}
        >
          {lang.get("projects", ["Export Excel"])}
        </Button>,
        <Button
          key="pdf"
          onClick={() => {
            expobj.exportToPDF({
              head: [
                [
                  lang.get("projects", ["ID"]),
                  lang.get("projects", ["Name"]),
                  lang.get("projects", ["Customer"]),
                  lang.get("projects", ["Tags"]),
                  lang.get("projects", ["Start Date"]),
                  lang.get("projects", ["Deadline"]),
                  lang.get("projects", ["Members"]),
                  lang.get("projects", ["Progress"]),
                  lang.get("projects", ["Status"]),
                  lang.get("projects", ["Priority"]),
                ],
              ],
              body: tableData.map((row) => [
                row.id,
                row.name,
                row.customer,
                row.tags,
                row.start_date,
                row.deadline,
                row.members,
                row.progress,
                row.status,
                row.priority,
              ]),
            });
          }}
        >
          {lang.get("projects", ["Export PDF"])}
        </Button>,
      ]}
    />
  );
};

export default ProjectTable;
