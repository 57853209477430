import React, { useState } from "react";
import { Breadcrumb, Typography, Button, Row, Col, Space } from "antd";
import {
  PlusOutlined,
  SyncOutlined,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import InvoiceTable from "./InvoiceTable";
import InvoiceStatistics from "../../../project/project-list/components/tabs/sales/invoices/InvoiceStatistics";
import lang from "../../../../lang";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

const Invoices: React.FC = () => {
  const [showStatistics, setShowStatistics] = useState(true);
  const navigate = useNavigate();
  const handleToggleStatistics = () => {
    setShowStatistics(!showStatistics);
  };

  const handleCreateInvoiceClick = () => {
    navigate("/invoice-add");
  };

  const handleRecurringInvoicesClick = () => {
    console.log(lang.get("sales", ["Recurring Invoices Action"]));
  };

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      <Breadcrumb style={{ marginBottom: "16px" }} separator=">">
        <Breadcrumb.Item>{lang.get("breadcrumbs", ["Home"])}</Breadcrumb.Item>
        <Breadcrumb.Item>{lang.get("breadcrumbs", ["Sales"])}</Breadcrumb.Item>
        <Breadcrumb.Item>
          {lang.get("breadcrumbs", ["Invoices"])}
        </Breadcrumb.Item>
      </Breadcrumb>

      <Title level={2} style={{ marginBottom: "16px" }}>
        {lang.get("sales", ["Invoices"])}
      </Title>
      {/* Statistics Section */}

      <div style={{ marginBottom: "16px" }}>
        <InvoiceStatistics />
      </div>

      <Row
        gutter={[16, 16]}
        style={{ marginBottom: "16px" }}
        align="middle"
        justify="space-between"
      >
        <Col>
          <Space>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleCreateInvoiceClick}
            >
              {lang.get("sales", ["Create New Invoice"])}
            </Button>

            <Button
              icon={<SyncOutlined />}
              onClick={handleRecurringInvoicesClick}
            >
              {lang.get("sales", ["Recurring Invoices"])}
            </Button>
          </Space>
        </Col>
      </Row>

      <InvoiceTable />
    </div>
  );
};

export default Invoices;
