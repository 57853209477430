import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumb, Card, notification } from "antd";
import lang from "../../../lang";

import ProjectSummary from "./components/SummarySection";
import ProjectTabs from "./components/ProjectDetailsTab";
import { DBSingleProject } from "../types/types";

import apiRequest from "../../../common/utils/apiRequest";
import coreFunctions from "../../../common/utils/coreFunctions";

const ProjectDetails = () => {
  const { id } = useParams<{ id: string }>();
  const [project, setProject] = useState<DBSingleProject | null>(null);

  const summaryRef = useRef<HTMLDivElement>(null);
  const tabsRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  useEffect(() => {
    const loadData = async () => {
      await new coreFunctions().waitRequest(100);

      const API_REQUEST = new apiRequest("project/get", true);

      API_REQUEST.send(
        {
          project: id,
        },
        (data: { status: string; message: string; data: DBSingleProject }) => {
          if (data.status === "success") {
            setProject(data.data);
          } else {
            notification.error({
              message: lang.get("project", [data.message]),
              description: data.message,
            });

            navigate("/project-list");
          }
        }
      );
    };

    loadData();
  }, [id]);

  const handleProjectChange = (project: DBSingleProject) => {
    setProject(project);
  };

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      <div ref={summaryRef} id="projects-summary">
        <Breadcrumb
          style={{ marginBottom: "20px" }}
          items={[
            { title: lang.get("breadcrumbs", ["Home"]), href: "/" },
            { title: lang.get("breadcrumbs", ["Projects"]), href: "/projects" },
            { title: project ? project.name : "" },
          ]}
        />
        <Card
          style={{
            backgroundColor: "#f5f7fa",
          }}
        >
          <ProjectSummary project={project} setProject={handleProjectChange} />
        </Card>
      </div>
      <div ref={tabsRef} id="project-tabs" style={{ marginTop: "20px" }}>
        <ProjectTabs project={project} />
      </div>
    </div>
  );
};

export default ProjectDetails;
