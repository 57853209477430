import React, { useState } from "react";
import {
  Card,
  Descriptions,
  Button,
  Modal,
  Col,
  Row,
  message,
  Popconfirm,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import lang from "../../../../../lang";
import ItemAddBody from "../ItemAddBody";
import SalesChart from "../../common/charts/SalesChart";

import { DB_SINGLE_ITEM } from "../../../types/types";
import { Badge } from "antd/lib";

import apiRequest from "../../../../../common/utils/apiRequest";
import { useNavigate } from "react-router-dom";

const OverviewComponent = ({ item }: { item: DB_SINGLE_ITEM }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);

  const navigate = useNavigate();

  const deleteItem = () => {
    message.loading(lang.get("inventory", ["Deleting item"]), 1).then(() => {
      const API_REQUEST = new apiRequest("inventory/item/delete", true);
      API_REQUEST.send(
        {
          item: item.id,
        },
        (response: { status: string; message: string }) => {
          if (response.status === "success") {
            message.success(
              lang.get("inventory", ["Item deleted successfully."]),
              2
            );
            navigate("/items");
          } else {
            message.error(lang.get("inventory", [response.message]), 2);
          }
        }
      );
    });
  };

  return (
    <div style={{ background: "#f5f7fa" }}>
      <Card
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>{item.name}</span>
            <div style={{ display: "flex", gap: "10px" }}>
              <Button
                type="primary"
                icon={<EditOutlined />}
                onClick={() => setModalOpen(true)}
              >
                {lang.get("items", ["Edit"])}
              </Button>
              <Button type="primary" danger icon={<DeleteOutlined />}>
                <Popconfirm
                  key="delete"
                  title={lang.get("inventory", [
                    "Are you sure you want to delete?",
                  ])}
                  onConfirm={deleteItem}
                  okText="Yes"
                  cancelText="No"
                >
                  {lang.get("items", ["Delete"])}
                </Popconfirm>
              </Button>
            </div>
          </div>
        }
        bordered={false}
        style={{ marginBottom: "20px", border: "1px solid #e9554a" }}
      >
        <Descriptions title="General Information" bordered size="small">
          <Descriptions.Item label={lang.get("inventory", ["Code"])}>
            {item.code}
          </Descriptions.Item>
          <Descriptions.Item label={lang.get("inventory", ["Item Name"])}>
            {item.name}
          </Descriptions.Item>
          <Descriptions.Item label={lang.get("inventory", ["Item Group"])}>
            {item.commodity_group}
          </Descriptions.Item>
          <Descriptions.Item label={lang.get("inventory", ["Item Sub Group"])}>
            {item.commodity_sub_group}
          </Descriptions.Item>
          <Descriptions.Item label={lang.get("inventory", ["Item Barcode"])}>
            {item.barcode}
          </Descriptions.Item>
          {item.sales_taxes
            ? item.sales_taxes.map((tax, index) => (
                <Descriptions.Item
                  key={"tax" + index}
                  label={lang.get("inventory", ["Item Tax"])}
                >
                  {tax.name}
                </Descriptions.Item>
              ))
            : null}
          {item.purchase_taxes
            ? item.purchase_taxes.map((tax, index) => (
                <Descriptions.Item
                  key={"tax" + index}
                  label={lang.get("inventory", ["Item Tax"])}
                >
                  {tax.name}
                </Descriptions.Item>
              ))
            : null}
        </Descriptions>

        <Descriptions
          title="Properties"
          bordered
          size="small"
          style={{ marginTop: "20px" }}
        >
          <Descriptions.Item label={lang.get("inventory", ["Model"])}>
            {item.models
              ? item.models.map((model) => (
                  <Badge key={model.id}>{model.name}</Badge>
                ))
              : null}
          </Descriptions.Item>
          <Descriptions.Item label={lang.get("inventory", ["Colors"])}>
            {item.colors
              ? item.colors.map((color) => (
                  <Badge key={color.id}>{color.name}</Badge>
                ))
              : null}
          </Descriptions.Item>
          <Descriptions.Item label={lang.get("inventory", ["Sizes"])}>
            {item.sizes
              ? item.sizes.map((size) => (
                  <Badge key={size.id}>{size.name}</Badge>
                ))
              : null}
          </Descriptions.Item>
          <Descriptions.Item label={lang.get("inventory", ["Styles"])}>
            {item.styles}
          </Descriptions.Item>
          <Descriptions.Item label={lang.get("inventory", ["Unit"])}>
            {item.unit}
          </Descriptions.Item>
          <Descriptions.Item label={lang.get("inventory", ["Warehouse"])}>
            {item.warehouses
              ? item.warehouses.map((warehouse) => (
                  <Badge key={warehouse.id}>{warehouse.name}</Badge>
                ))
              : null}
          </Descriptions.Item>
          <Descriptions.Item label={lang.get("inventory", ["Description"])}>
            {item.description}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          title={lang.get("inventory", ["Pricing Structure"])}
          bordered
          size="small"
          style={{ marginTop: "20px" }}
        >
          <Descriptions.Item
            label={lang.get("inventory", ["Default Profit Rate %"])}
          >
            {item.default_profit_rate}
          </Descriptions.Item>
          <Descriptions.Item
            label={lang.get("inventory", ["Default Selling Price"])}
          >
            {item.selling_price}
          </Descriptions.Item>
          <Descriptions.Item label={lang.get("inventory", ["Current Stock"])}>
            -
          </Descriptions.Item>
          <Descriptions.Item label={lang.get("inventory", ["Income Account"])}>
            {item.sales_account?.name}
          </Descriptions.Item>
          <Descriptions.Item
            label={lang.get("inventory", ["Inventory Account"])}
          >
            {item.inventory_account?.name}
          </Descriptions.Item>
          <Descriptions.Item label={lang.get("inventory", ["Expense Account"])}>
            {item.purchase_account?.name}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          title={lang.get("inventory", ["Uploaded Images"])}
          bordered
          size="small"
          style={{ marginTop: "20px" }}
        />
        {item.additional_files ? (
          <Row gutter={[16, 16]}>
            {item.additional_files.map((addtional_file, index) => (
              <Col xs={24} sm={12} md={8} lg={6} xl={6} key={"image" + index}>
                <Card
                  bordered={false}
                  style={{ textAlign: "center", height: "100%" }}
                >
                  <img
                    key={"image" + index}
                    src={addtional_file.url}
                    alt={lang.get("inventory", ["Item Image"])}
                    style={{ height: "100px" }}
                  />
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <p>No images found</p>
        )}
      </Card>

      <Card
        title="Analytics Overview"
        bordered={false}
        style={{ marginBottom: "20px", border: "1px solid #e9554a" }}
      >
        <Row gutter={[16, 16]}>
          {/* First Chart */}
          <Col xs={24} lg={8}>
            <Card
              bordered={false}
              style={{ textAlign: "center", height: "100%" }}
            >
              <SalesChart />
            </Card>
          </Col>

          {/* Second Chart */}
          <Col xs={24} lg={8}>
            <Card
              bordered={false}
              style={{ textAlign: "center", height: "100%" }}
            >
              {/* Placeholder for Chart 2 */}
              {/* <WarehouseLevels /> */}
            </Card>
          </Col>

          {/* Third Chart */}
          <Col xs={24} lg={8}>
            <Card
              bordered={false}
              style={{ textAlign: "center", height: "100%" }}
            >
              <SalesChart />
            </Card>
          </Col>
        </Row>
      </Card>
      <Modal
        title={lang.get("items", ["Add Item"])}
        open={modalOpen}
        footer={null}
        onCancel={() => setModalOpen(false)}
        width={1250}
      >
        <ItemAddBody
          modelClose={() => setModalOpen(false)}
          setRefreshTable={setRefreshTable}
          refreshTable={refreshTable}
        />
      </Modal>
    </div>
  );
};

export default OverviewComponent;
