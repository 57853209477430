import React, { useState } from "react";
import {
  Button,
  Card,
  Timeline,
  Switch,
  Space,
  Select,
  Input,
  DatePicker,
  Row,
  Col,
} from "antd";
import { ExportOutlined, SearchOutlined } from "@ant-design/icons";
import lang from "../../../../../../lang";
const { RangePicker } = DatePicker;

interface ActivityItem {
  id: number;
  timestamp: string;
  user: string;
  content: string;
  visibleToCustomer: boolean;
}

const Activity = () => {
  const [activities, setActivities] = useState<ActivityItem[]>([]);

  const toggleVisibility = (id: number) => {
    setActivities((prevActivities) =>
      prevActivities.map((activity) =>
        activity.id === id
          ? { ...activity, visibleToCustomer: !activity.visibleToCustomer }
          : activity
      )
    );
  };

  return (
    <div style={{ padding: "16px", background: "#f5f7fa" }}>
      {/* Header Controls */}
      <Row gutter={[16, 16]} align="middle" justify="space-between">
        <Col>
          <Select
            defaultValue="25"
            style={{ width: 80 }}
            options={[
              { value: "25", label: lang.get("activity", ["25"]) },
              { value: "50", label: lang.get("activity", ["50"]) },
              { value: "100", label: lang.get("activity", ["100"]) },
            ]}
          />
        </Col>
        <Col>
          <Space size="middle">
            <RangePicker style={{ width: 250 }} />
            <Input
              placeholder={lang.get("activity", ["Search activities..."])}
              prefix={<SearchOutlined />}
              style={{ width: 200 }}
            />
            <Button icon={<ExportOutlined />} type="primary">
              {lang.get("activity", ["Export"])}
            </Button>
          </Space>
        </Col>
      </Row>

      {/* Activity Timeline */}
      <Card bordered={false} style={{ marginTop: "16px" }}>
        <Timeline>
          {activities.map((activity) => (
            <Timeline.Item
              key={activity.id}
              style={{ marginBottom: "24px" }} // Add spacing between items
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <div style={{ fontSize: "13px", color: "#888" }}>
                    {activity.timestamp}
                  </div>
                  <div style={{ fontWeight: "bold" }}>{activity.user}</div>
                  <div>{activity.content}</div>
                </div>
                <Switch
                  checked={activity.visibleToCustomer}
                  onChange={() => toggleVisibility(activity.id)}
                  size="small"
                />
              </div>
            </Timeline.Item>
          ))}
        </Timeline>
      </Card>
    </div>
  );
};

export default Activity;
