import { StatisticCard } from "@ant-design/pro-components";
import RcResizeObserver from "rc-resize-observer";
import { useState } from "react";
import Paid from "../../../../../../assets/img/statistics/paid.svg";
import Due from "../../../../../../assets/img/statistics/due.svg";
import Outstanding from "../../../../../../assets/img/statistics/outstanding.svg";

const { Divider } = StatisticCard;

const imgStyle = {
  display: "block",
  width: 60,
  height: 55,
};

const PerformaInvoiceStatitstic = () => {
  const [responsive, setResponsive] = useState(false);

  return (
    <RcResizeObserver
      key="resize-observer"
      onResize={(offset) => {
        setResponsive(offset.width < 596);
      }}
    >
      <StatisticCard.Group
        direction={responsive ? "row" : "row"}
        style={{
          paddingTop: "16px",
          paddingBottom: "16px",
          height: responsive ? "100px" : "auto",
          overflowX: "auto",
          overflowY: "hidden",
        }}
      >
        <StatisticCard
          statistic={{
            title: "Outstanding Invoices",
            value: 17100.0,
            icon: <img style={imgStyle} src={Outstanding} alt="icon" />,
          }}
        />
        <Divider type={responsive ? "horizontal" : "vertical"} />
        <StatisticCard
          statistic={{
            title: "Past Due Invoices",
            value: 0.0,
            icon: <img style={imgStyle} src={Due} alt="icon" />,
          }}
        />
        <Divider type={responsive ? "horizontal" : "vertical"} />
        <StatisticCard
          statistic={{
            title: "Paid Invoices",
            value: 15000.0,
            icon: <img style={imgStyle} src={Paid} alt="icon" />,
          }}
        />
      </StatisticCard.Group>
    </RcResizeObserver>
  );
};

export default PerformaInvoiceStatitstic;
