import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumb, notification, Skeleton } from "antd";
import lang from "../../../lang";

import ItemTabs from "./item-components/ItemTabs";

import { DB_SINGLE_ITEM } from "../types/types";
import apiRequest from "../../../common/utils/apiRequest";

const ItemDetails = () => {
  const { id } = useParams<{ id: string }>();
  const [item, setItem] = useState<DB_SINGLE_ITEM>({} as DB_SINGLE_ITEM);
  const [loading, setLoading] = useState(true);

  const summaryRef = useRef<HTMLDivElement>(null);
  const tabsRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      const API_REQUEST = new apiRequest("inventory/item/get", true);

      API_REQUEST.send(
        {
          item: id,
        },
        (reponse: { status: string; data: DB_SINGLE_ITEM; message: string }) => {
          if (reponse.status === "success") {
            setItem(reponse.data);
          } else {
            notification.error({
              message: lang.get("inventory", ["Error"]),
              description: reponse.message,
            });

            navigate("/items");
          }

          setLoading(false);
        }
      );
    }
  }, [id]);

  return loading ? (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      <Skeleton active />
    </div>
  ) : (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      <div ref={summaryRef} id="items-summary">
        <Breadcrumb
          style={{ marginBottom: "20px" }}
          items={[
            { title: lang.get("breadcrumbs", ["Home"]), href: "/" },
            { title: lang.get("breadcrumbs", ["Items"]), href: "/items" },
            { title: lang.get("items", [item ? item.name : "No Item"]) },
          ]}
        />
      </div>
      <div ref={tabsRef} id="item-tabs" style={{ marginTop: "20px" }}>
        <ItemTabs item={item} />
      </div>
    </div>
  );
};

export default ItemDetails;
