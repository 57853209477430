import { Card, Row, Col, Avatar, Typography, Skeleton, Space } from "antd";
import {
  LinkedinOutlined,
  FacebookOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import team15 from "../../../../assets/img/team/15.webp";
import lang from "../../../../lang";
import { useEffect, useState } from "react";
import QueueAnim from "rc-queue-anim";
import { DBCustomer } from "../../types/types";

export type Customer = {
  id: string;
  name: string;
  phone: string;
  currency: string;
  address: string;
  last_login: string;
  primary_email?: string;
};

const { Text, Title } = Typography;

const ProfileCard = (props: { customer: DBCustomer; loading: boolean }) => {
  return (
    <Card bordered={false} style={{ borderRadius: "8px", height: "100%" }}>
      <div style={{ marginBottom: "50px" }}>
        {props.loading ? (
          <Row gutter={[12, 24]} align="middle">
            <Col xs={24} md={6} style={{ textAlign: "center" }}>
              <Skeleton.Button
                active
                size={"large"}
                shape={"circle"}
                block={true}
                style={{ width: "150px", height: "150px" }}
              />
            </Col>
            <Col xs={24} md={12}>
              <Row gutter={[24, 24]} align="middle">
                <Skeleton active style={{ width: "100%" }} />
              </Row>
            </Col>
          </Row>
        ) : (
          <QueueAnim delay={100} type={"bottom"}>
            <Row key={"anim1"} gutter={[12, 24]} align="middle">
              <Col xs={24} md={6} style={{ textAlign: "center" }}>
                <Avatar size={150} src={props.customer?.profile_image}/>
              </Col>

              <Col xs={24} md={12}>
                <Title level={3} style={{ margin: 0 }}>
                  {props.customer?.company_name}
                </Title>
                <Text
                  type="secondary"
                  style={{ fontSize: "14px", fontWeight: "bold" }}
                >
                  {lang.get("customer", [" Joined 3 months ago"])}
                </Text>
                <div style={{ marginTop: "8px" }}>
                  <Row gutter={[8, 8]} justify="start" wrap>
                    <Col>
                      <a href="#linkedin">
                        <LinkedinOutlined
                          style={{ fontSize: "20px", color: "#0077b5" }}
                        />
                      </a>
                    </Col>
                    <Col>
                      <a href="#facebook">
                        <FacebookOutlined
                          style={{ fontSize: "20px", color: "#4267B2" }}
                        />
                      </a>
                    </Col>
                    <Col>
                      <a href="#twitter">
                        <TwitterOutlined
                          style={{ fontSize: "20px", color: "#1DA1F2" }}
                        />
                      </a>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col xs={24} md={6}>
                <Row gutter={[16, 16]} wrap>
                  <Col span={24}>
                    <Text strong style={{ fontSize: "16px" }}>
                      {lang.get("customer", ["Email :"])}
                    </Text>
                    <p style={{ margin: 0 }}>
                      <Text style={{ fontSize: "15px" }}>
                        {props.customer?.primary_email}
                      </Text>
                    </p>
                  </Col>

                  <Col span={24}>
                    <Text strong style={{ fontSize: "16px" }}>
                      Phone:
                    </Text>
                    <p style={{ margin: 0 }}>
                      <Text style={{ fontSize: "15px" }}>
                        {props.customer?.phone}
                      </Text>
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </QueueAnim>
        )}
      </div>

      <div
        style={{
          borderTop: "1px dashed #d9d9d9",
          paddingTop: "16px",
          textAlign: "center",
        }}
      >
        <QueueAnim delay={500} type={"right"}>
          <Row key={"anim2"} gutter={[24, 24]} justify="space-between">
            <Col xs={24} sm={8}>
              <Text strong>{lang.get("customer", ["Total Overdue: "])}</Text>
              <p style={{ margin: 0 }}>
                {props.loading ? (
                  <Skeleton.Input active style={{ height: "20px" }} />
                ) : (
                  <Text>{lang.get("customer", ["N/A"])}</Text>
                )}
              </p>
            </Col>
            <Col xs={24} sm={8}>
              <Text strong>
                {" "}
                {lang.get("customer", ["Total Transactions :"])}
              </Text>
              <p style={{ margin: 0 }}>
                {props.loading ? (
                  <Skeleton.Input active style={{ height: "20px" }} />
                ) : (
                  <Text>{lang.get("customer", ["N/A"])}</Text>
                )}
              </p>
            </Col>
            <Col xs={24} sm={8}>
              <Text strong>{lang.get("customer", ["Last Login:"])}</Text>
              <p style={{ margin: 0 }}>
                {props.loading ? (
                  <Skeleton.Input active style={{ height: "20px" }} />
                ) : (
                  <Text>{props.customer?.last_login}</Text>
                )}
              </p>
            </Col>
          </Row>
        </QueueAnim>
      </div>
    </Card>
  );
};

export default ProfileCard;
