import { Breadcrumb, Typography, Button, Space, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import lang from "../../../lang";
import PurchaseRequestTable from "./PurchaseRequestTable";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

const PurchaseRequest: React.FC = () => {
  const navigate = useNavigate();

  const handleAddPurchaseRequest = () => {
    navigate("/purchase/purchase-request/request-add");
  };

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      {/* Breadcrumb */}
      <Breadcrumb style={{ marginBottom: "16px" }} separator=">">
        <Breadcrumb.Item>{lang.get("breadcrumbs", ["Home"])}</Breadcrumb.Item>
        <Breadcrumb.Item>
          {lang.get("breadcrumbs", ["Purchases"])}
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {lang.get("breadcrumbs", ["Purchase Requests"])}
        </Breadcrumb.Item>
      </Breadcrumb>

      {/* Page Title */}
      <Title level={2} style={{ marginBottom: "16px" }}>
        {lang.get("purchases", ["Purchase Requests"])} (4)
      </Title>

      {/* Filter and Actions */}
      <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Space size="middle" wrap>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => handleAddPurchaseRequest()}
            >
              {lang.get("purchases", ["Add Purchase Request"])}
            </Button>
          </Space>
        </Col>
      </Row>

      {/* Table */}
      <PurchaseRequestTable />
    </div>
  );
};

export default PurchaseRequest;
