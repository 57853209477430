import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { ProTable } from "@ant-design/pro-components";
import { Avatar, Button, Input, InputRef, Space, Switch } from "antd";
import type { ActionType, ProColumns } from "@ant-design/pro-components";
import { ReactNode, useRef, useState } from "react";
import lang from "../../../lang";
import Export from "../../../common/components/Export";


interface User {
  id: string;
  fullName: string;
  email: string;
  role: string;
  lastLogin: string;
  active: boolean;
}

const users: User[] = [
  {
    id: "1",
    fullName: "Damith Sanjaya",
    email: "damithoffice123@gmail.com",
    role: "Employee",
    lastLogin: "Never",
    active: false,
  },
  {
    id: "2",
    fullName: "Darshana Sampath",
    email: "Info@senex.lk",
    role: "jb decorator",
    lastLogin: "6 months ago",
    active: false,
  },
  {
    id: "3",
    fullName: "Demo Version",
    email: "test@appoloy.lk",
    role: "Employee",
    lastLogin: "2 years ago",
    active: false,
  },
  {
    id: "4",
    fullName: "Demo User 2",
    email: "demo@quicksoft.lk",
    role: "Employee",
    lastLogin: "10 minutes ago",
    active: true,
  },
  {
    id: "5",
    fullName: "Demo User",
    email: "demo1@quicksoft.lk",
    role: "Employee",
    lastLogin: "one year ago",
    active: false,
  },
  {
    id: "6",
    fullName: "Demo 3",
    email: "indunika.wellnessfix@gmail.com",
    role: "jb decorator",
    lastLogin: "9 months ago",
    active: false,
  },
  {
    id: "7",
    fullName: "Hashan Weerasinghe",
    email: "hashan@securelanka.com",
    role: "Employee",
    lastLogin: "5 months ago",
    active: false,
  },
  {
    id: "8",
    fullName: "Kasun Rangajith",
    email: "krmanathunga3000@gmail.com",
    role: "jb decorator",
    lastLogin: "one year ago",
    active: false,
  },
  {
    id: "9",
    fullName: "New Salesman",
    email: "new@gmail.com",
    role: "Sales Department",
    lastLogin: "11 months ago",
    active: false,
  },
];

const UsersTable: React.FC = () => {
  const actionRef = useRef<ActionType>();
  const [allSearch, setAllSearch] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(10);
  const searchInput = useRef<InputRef>(null);
  const expobj = new Export("users, " + new Date().toDateString());
  const [searchAttributes, setSearchAttributes] = useState<
    { key: string; value: string }[]
  >([]);

  const handleSearch = (field: string, value: string) => {
    setAllSearch("");
    const existingAttribute = searchAttributes.find(
      (attr) => attr.key === field
    );

    if (existingAttribute) {
      setSearchAttributes(
        searchAttributes.map((attr) =>
          attr.key === field ? { ...attr, value } : attr
        )
      );
    } else {
      setSearchAttributes([
        ...searchAttributes,
        {
          key: field,
          value,
        },
      ]);
    }
  };

  const [userData, setUserData] = useState<User[]>(users);

  const handleToggleActive = (userId: string, checked: boolean) => {
    setUserData((prevUsers: User[]) =>
      prevUsers.map((user) =>
        user.id === userId ? { ...user, active: checked } : user
      )
    );
    console.log(`User with ID ${userId} active status changed to: ${checked}`);
  };

  const searchCols = (field: string, label: string) => {
    return {
      filterDropdown: (props: any) => {
        const { close } = props;

        setTimeout(() => {
          if (searchInput.current) {
            searchInput.current.focus();
          }
        }, 100);

        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={lang.get("userManagement", [`Search ${label}`])}
              onChange={(e) => handleSearch(field, e.target.value)}
              value={
                searchAttributes.filter((item) => item.key === field)[0]?.value
              }
              style={{ marginBottom: 8, display: "block" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  close();
                  if (actionRef.current) {
                    actionRef.current?.reset?.();
                    actionRef.current.reload();
                  }
                }
              }}
            />
            <Space>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="small"
                onClick={() => {
                  actionRef.current?.reset?.();
                  actionRef.current?.reload();
                }}
                style={{ width: 90 }}
              >
                {lang.get("userManagement", ["Search"])}
              </Button>
              <Button
                onClick={() => close()}
                size="small"
                style={{ width: 90 }}
              >
                {lang.get("userManagement", ["Close"])}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
    };
  };

  const columns: ProColumns<User>[] = [
    {
      title: "Full Name",
      dataIndex: "fullName",
      key: "fullName",
      sorter: true,
      render: (fullName: ReactNode) => (
        <Space>
          <Avatar icon={<UserOutlined />} />
          <span>{fullName}</span>
        </Space>
      ),
      align: "left",
      width: 250,
      ...searchCols("fullName", "Full Name"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: true,
      render: (email: ReactNode) => <span>{email}</span>,
      align: "left",
      width: 300,
      ...searchCols("email", "Email"),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      sorter: true,
      render: (role: ReactNode) => <span>{role}</span>,
      align: "left",
      width: 200,
      ...searchCols("role", "Role"),
    },
    {
      title: "Last Login",
      dataIndex: "lastLogin",
      key: "lastLogin",
      sorter: true,
      render: (lastLogin: ReactNode) => <span>{lastLogin}</span>,
      align: "left",
      width: 200,
      ...searchCols("lastLogin", "Last Login"),
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      render: (_, record) => (
        <Switch
          checked={record.active}
          onChange={(checked) => handleToggleActive(record.id, checked)}
        />
      ),
      align: "center",
      width: 150,
    },
    {
      title: "Options",
      key: "actions",
      width: 150,
      render: (_, record) => (
        <Space size="middle">
          <Button
            icon={<EditOutlined />}
            onClick={() => console.log(`Editing ${record.fullName}`)}
          />
          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={() => console.log(`Deleting ${record.fullName}`)}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <ProTable
        headerTitle={lang.get("userManagement", ["Users"])}
        columns={columns}
        dataSource={userData}
        actionRef={actionRef}
        rowKey="id"
        pagination={{
          defaultCurrent: 1,
          pageSize: pageSize,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50],
          showTotal: (total, range) =>
            lang.get("userManagement", [
              `Showing ${range[0]}-${range[1]} of ${total} items`,
            ]),
          onShowSizeChange: (current, size) => {
            setPageSize(size);
          },
        }}
        search={false}
        scroll={{ x: 800 }}
        cardBordered
        toolBarRender={() => [
          <Input
            style={{ width: "auto" }}
            key="all_search"
            allowClear
            placeholder={lang.get("userManagement", ["Global Search"])}
            value={allSearch}
            onChange={(e) => {
              setAllSearch(e.target.value);
              setSearchAttributes([]);
              actionRef?.current?.reload();
            }}
          />,
          <Button
            key="csv"
            onClick={() => {
              const csvData = users.map((row) => ({
                [lang.get("userManagement", ["Name"])]: row.fullName,
                [lang.get("userManagement", ["Email"])]: row.email,
                [lang.get("userManagement", ["Role"])]: row.role,
                [lang.get("userManagement", ["Last Login"])]: row.lastLogin,
                [lang.get("userManagement", ["Active"])]: row.active,
              }));
              expobj.exportToCSV(csvData);
            }}
          >
            {lang.get("userManagement", ["Export CSV"])}
          </Button>,
          <Button
            key="csv"
            onClick={() => {
              const csvData = userData.map((row) => ({
                [lang.get("userManagement", ["Name"])]: row.fullName,
                [lang.get("userManagement", ["Email"])]: row.email,
                [lang.get("userManagement", ["Role"])]: row.role,
                [lang.get("userManagement", ["Last Login"])]: row.lastLogin,
                [lang.get("userManagement", ["Active"])]: row.active,
              }));
              expobj.exportToCSV(csvData);
            }}
          >
            {lang.get("userManagement", ["Search"])}
          </Button>,
          <Button
            key="excel"
            onClick={() => {
              const excelData = userData.map((row) => ({
                [lang.get("userManagement", ["Name"])]: row.fullName,
                [lang.get("userManagement", ["Email"])]: row.email,
                [lang.get("userManagement", ["Role"])]: row.role,
                [lang.get("userManagement", ["Last Login"])]: row.lastLogin,
                [lang.get("userManagement", ["Active"])]: row.active,
              }));
              expobj.exportToExcel(excelData);
            }}
          >
            {lang.get("userManagement", ["Export Excel"])}
          </Button>,

          <Button
            key="pdf"
            onClick={() => {
              expobj.exportToPDF({
                head: [
                  [
                    lang.get("userManagement", ["Name"]),
                    lang.get("userManagement", ["Email"]),
                    lang.get("userManagement", ["Role"]),
                    lang.get("userManagement", ["Last Login"]),
                    lang.get("userManagement", ["Active"]),
                  ],
                ],
                body: users.map((row) => [
                  row.fullName,
                  row.email,
                  row.role,
                  row.lastLogin,
                  row.active,
                ]),
              });
            }}
          >
            {lang.get("userManagement", ["Export PDF"])}
          </Button>,
        ]}
      />
    </div>
  );
};

export default UsersTable;
