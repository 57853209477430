import React, { useState, useRef, useEffect } from "react";
import type {
  ProColumns,
  EditableFormInstance,
  ActionType,
} from "@ant-design/pro-components";
import { EditableProTable, ProCard } from "@ant-design/pro-components";
import { Input, Select, message, DatePicker, Button, Modal } from "antd";
import moment from "moment";
import { CodeOutlined, DeleteOutlined } from "@ant-design/icons";
import lang from "../../../lang";

import {
  SelectType,
  InventoryDeliveryVoucherRowItem,
  TaxOption,
  DeliveryVoucher,
  DeliveryVoucherSummary,
} from "../types/types";
import coreFunctions from "../../../common/utils/coreFunctions";
import Handsontable from "handsontable";
import "handsontable/dist/handsontable.full.css";

const { Option } = Select;

const defaultValues = {
  quantity: 0,
  free_quantity: 0,
  purchase_price: 0.0,
  taxes: [],
  serials: [],
  discount: {
    type: 0,
    value: 0,
  },
  selling_price: 0.0,
  batch_number: "",
  warranty: "",
  mfg_date: "",
  exp_date: "",
};

const DeliveryVoucherAddTable = ({
  setItemRows,
  postData,
  calculateTotal,
  itemTaxes,
  summary,
  setSummary,
  defaultCurrecyCode,
}: {
  setItemRows: (items: InventoryDeliveryVoucherRowItem[]) => void;
  postData: DeliveryVoucher;
  calculateTotal: (
    items: InventoryDeliveryVoucherRowItem[]
  ) => DeliveryVoucherSummary;
  itemTaxes: TaxOption[];
  summary: DeliveryVoucherSummary;
  setSummary: (data: DeliveryVoucherSummary) => void;
  defaultCurrecyCode: string;
}) => {
  const first_row_id = Date.now().toString();

  const [tableData, setTableData] = useState<InventoryDeliveryVoucherRowItem[]>(
    [{ id: first_row_id, item_id: "", ...defaultValues }]
  );

  const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([
    first_row_id,
  ]);
  const actionRef = useRef<ActionType>();
  const editableFormRef = useRef<EditableFormInstance>();
  const [items, setItems] = useState<SelectType[]>([]);
  const [warranties, setWarranties] = useState<SelectType[]>([]);
  const [activeRow, setActiveRow] = useState<InventoryDeliveryVoucherRowItem>(
    {} as InventoryDeliveryVoucherRowItem
  );
  const [serialEditModalVisible, setSerialEditModalVisible] = useState(false);

  const CORE_FUNCTIONS = new coreFunctions();
  const tableRef = useRef<HTMLDivElement | null>(null);

  const headers = ["Serial Number"];

  const [hot, setHot] = useState<Handsontable>();

  useEffect(() => {
    let data: any[] = [];
    let cols: any[] = [];

    const totalSerials =
      activeRow?.quantity + (activeRow?.free_quantity || 0) || 0;

    for (let j = 0; j < headers.length; j++) {
      cols.push({});
    }

    if (activeRow.serials && activeRow.serials.length > 0) {
      for (let i = 0; i < totalSerials; i++) {
        for (let j = 0; j < headers.length; j++) {
          data.push([activeRow.serials[i]]);
        }
      }
    } else {
      for (let i = 0; i < totalSerials; i++) {
        for (let j = 0; j < headers.length; j++) {
          data.push([]);
        }
      }
    }

    if (tableRef.current) {
      const hot = new Handsontable(tableRef.current, {
        data: data,
        colHeaders: headers,
        columns: cols,
        rowHeaders: true,
        contextMenu: true,
        stretchH: "all",
        autoWrapRow: true,
        width: "100%",
        height: "300px",
        rowHeights: 30,
        licenseKey: "non-commercial-and-evaluation",
      });

      setHot(hot);
    }
  }, [activeRow]);

  const handleItemSelect = (key: string | undefined, value: string) => {
    const id = Date.now().toString();

    let existingRow = key && tableData.find((row) => row.id === key);

    if (existingRow) {
      existingRow.item_id = value;
      setTableData(tableData);
    }

    setTableData([
      ...tableData,
      {
        id: id,
        item_id: "",
        ...defaultValues,
      },
    ]);

    setEditableRowKeys((keys) => [...keys, id]);

    setItemRows(tableData);
  };

  const deleteLastRow = () => {
    setTableData((prev) => {
      if (prev.length > 1) {
        const newData = prev.slice(0, -1);
        setEditableRowKeys((keys) =>
          keys.filter((key) => key !== prev[prev.length - 1].id)
        );
        return newData;
      } else {
        message.warning(
          lang.get("inventory", ["Cannot delete the last remaining row."])
        );
        return prev;
      }
    });
  };

  useEffect(() => {
    setSummary(calculateTotal(tableData));
  }, [postData]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        deleteLastRow();
      } else if (event.key === "+") {
        const id = Date.now().toString() + Math.random();

        setTableData([
          ...tableData,
          {
            id: id,
            item_id: "",
            ...defaultValues,
          },
        ]);

        setEditableRowKeys((keys) => [...keys, id]);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const columns: ProColumns<InventoryDeliveryVoucherRowItem>[] = [
    {
      title: lang.get("inventory", ["Item Name"]),
      dataIndex: "itemName",
      align: "center",
      renderFormItem: (_, { record }) => (
        <Select
          placeholder={lang.get("sales", ["Search and select an item"])}
          value={record?.item}
          onChange={(value) => handleItemSelect(record?.id, value)}
          showSearch
          onInputKeyDown={(e) =>
            CORE_FUNCTIONS.searchOptions(
              e.currentTarget.value,
              "inventory",
              "items",
              setItems
            )
          }
          style={{ width: "100%" }}
        >
          {items.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: lang.get("inventory", ["Quantity"]),
      dataIndex: "quantity",
      align: "center",
      valueType: "digit",
      renderFormItem: (_, { record, onChange }) => (
        <Input
          type="number"
          value={record?.quantity}
          placeholder={lang.get("inventory", ["Quantity"])}
          onChange={(e) => {
            const value = Number(e.target.value);
            onChange?.(value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id ? { ...row, quantity: value } : row
              )
            );

            setItemRows(tableData);
          }}
        />
      ),
    },
    {
      title: lang.get("inventory", ["Free Quantity"]),
      dataIndex: "freeQuantity",
      align: "center",
      renderFormItem: (_, { record, onChange }) => (
        <Input
          type="number"
          placeholder={lang.get("inventory", ["Free Quantity"])}
          value={record?.free_quantity}
          onChange={(e) => {
            const value = Number(e.target.value);
            onChange?.(value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id ? { ...row, free_quantity: value } : row
              )
            );

            setItemRows(tableData);
          }}
        />
      ),
    },
    {
      title: lang.get("inventory", ["Purchase Price"]),
      dataIndex: "purchasePrice",
      align: "center",
      valueType: "money",
      renderFormItem: (_, { record, onChange }) => (
        <Input
          type="number"
          value={record?.purchase_price}
          placeholder={lang.get("inventory", ["Purchase Price"])}
          onChange={(e) => {
            const value = Number(e.target.value);
            onChange?.(value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id ? { ...row, purchase_price: value } : row
              )
            );

            setItemRows(tableData);
          }}
        />
      ),
    },
    {
      title: lang.get("inventory", ["Tax"]),
      dataIndex: "tax",
      align: "left",
      renderFormItem: (_, { record, onChange }) => (
        <Select
          mode="multiple"
          showSearch
          onChange={(value) => {
            let taxes: TaxOption[] = [];
            for (let i = 0; i < value.length; i++) {
              for (let j = 0; j < itemTaxes.length; j++) {
                if (value[i] == itemTaxes[j].id) {
                  taxes.push(itemTaxes[j]);
                }
              }
            }
            onChange?.(value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id ? { ...row, taxes } : row
              )
            );

            setItemRows(tableData);
          }}
          placeholder={lang.get("inventory", ["Select Tax"])}
        >
          {itemTaxes.map((tax) => (
            <Option key={tax.id} value={tax.id}>
              {tax.name}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: lang.get("inventory", ["Discount"]),
      dataIndex: "discount",
      align: "center",
      renderFormItem: (_, { record }) => (
        <Input.Group compact>
          <Input
            onChange={(e) => {
              const value = Number(e.target?.value);
              setTableData((prev) =>
                prev.map((row) =>
                  row.id === record?.id
                    ? {
                        ...row,
                        discount: {
                          type: row.discount?.type ? row.discount?.type : 0,
                          value,
                        },
                      }
                    : row
                )
              );
              setItemRows(tableData);
            }}
            placeholder={lang.get("inventory", ["Enter Discount"])}
            style={{ width: "70%" }}
          />
          <Select
            onChange={(value) => {
              setTableData((prev) =>
                prev.map((row) =>
                  row.id === record?.id
                    ? {
                        ...row,
                        discount: {
                          type: value,
                          value: row?.discount?.value
                            ? row?.discount?.value
                            : 0,
                        },
                      }
                    : row
                )
              );
              setItemRows(tableData);
            }}
            style={{ width: "30%" }}
            dropdownStyle={{ width: "5%" }}
          >
            <Option value="0">%</Option>
            <Option value="1">{lang.get("inventory", ["Fixed"])}</Option>
          </Select>
        </Input.Group>
      ),
    },
    {
      title: lang.get("inventory", ["Selling Price"]),
      dataIndex: "sellingPrice",
      align: "center",
      valueType: "money",
      renderFormItem: (_, { record, onChange }) => (
        <Input
          type="number"
          placeholder={lang.get("inventory", ["Selling Price"])}
          value={record?.selling_price}
          onChange={(e) => {
            const value = Number(e.target.value);
            onChange?.(value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id ? { ...row, selling_price: value } : row
              )
            );

            setItemRows(tableData);
          }}
        />
      ),
    },
    {
      title: lang.get("inventory", ["Batch Number"]),
      dataIndex: "batchNumber",
      align: "center",
      renderFormItem: (_, { record, onChange }) => (
        <Input
          value={record?.batch_number}
          placeholder={lang.get("inventory", ["Batch Number"])}
          onChange={(e) => {
            const value = e.target.value;
            onChange?.(value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id ? { ...row, batch_number: value } : row
              )
            );

            setItemRows(tableData);
          }}
        />
      ),
    },
    {
      title: lang.get("inventory", ["Warranty"]),
      dataIndex: "warranty",
      align: "center",
      renderFormItem: (_, { record, onChange }) => (
        <Select
          value={record?.warranty}
          onChange={(value) => {
            onChange?.(value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id ? { ...row, warranty: value } : row
              )
            );

            setItemRows(tableData);
          }}
          showSearch
          onInputKeyDown={(e) =>
            CORE_FUNCTIONS.searchOptions(
              e.currentTarget.value,
              "inventory",
              "warranties",
              setWarranties
            )
          }
          placeholder={lang.get("inventory", ["Select Warranty"])}
        >
          {warranties.map((warranty) => (
            <Option key={warranty.id} value={warranty.id}>
              {warranty.name}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: lang.get("inventory", ["MFG Date"]),
      dataIndex: "mfgDate",
      align: "center",
      renderFormItem: (_, { record, onChange }) => (
        <DatePicker
          style={{ width: "100%" }}
          value={record?.mfg_date ? moment(record.mfg_date) : null}
          onChange={(date) => {
            const value = date ? date.format("YYYY-MM-DD") : "";
            onChange?.(value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id ? { ...row, mfg_date: value } : row
              )
            );

            setItemRows(tableData);
          }}
        />
      ),
    },
    {
      title: lang.get("inventory", ["EXP Date"]),
      dataIndex: "expDate",
      align: "center",
      renderFormItem: (_, { record, onChange }) => (
        <DatePicker
          style={{ width: "100%" }}
          value={record?.exp_date ? moment(record.exp_date) : null}
          onChange={(date) => {
            const value = date ? date.format("YYYY-MM-DD") : "";
            onChange?.(value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id ? { ...row, exp_date: value } : row
              )
            );

            setItemRows(tableData);
          }}
        />
      ),
    },
    {
      title: lang.get("inventory", ["Total"]),
      dataIndex: "sub_total",
      align: "center",
      valueType: "money",
      renderFormItem: (_, { record }) => (
        <p style={{ textAlign: "right" }}>{record?.sub_total}</p>
      ),
    },
    {
      title: lang.get("inventory", ["Actions"]),
      align: "center",
      valueType: "option",
    },
  ];

  return (
    <ProCard
      style={{
        border: "1px solid #d9d9d9",
        borderRadius: "8px",
        backgroundColor: "#f9f9fb",
        position: "relative",
      }}
    >
      <EditableProTable<InventoryDeliveryVoucherRowItem>
        rowKey="id"
        value={tableData}
        onChange={(value) =>
          setTableData([...value] as InventoryDeliveryVoucherRowItem[])
        }
        columns={columns}
        options={{
          fullScreen: true,
          setting: true,
          density: false,
          reload: false,
        }}
        toolbar={{
          title: lang.get("inventory", ["Add Items"]),
          tooltip: lang.get("inventory", ["Add Items"]),
        }}
        columnsState={{
          persistenceKey: "inventory-receiving-voucher-table",
          persistenceType: "localStorage",
        }}
        editable={{
          type: "multiple",
          editableKeys,
          onChange: setEditableRowKeys,
          actionRender: (row, _, defaultDoms) => [
            <button
              key="delete"
              type="button"
              style={{
                color: tableData.length === 1 ? "gray" : "red",
                border: "none",
                background: "none",
                cursor: tableData.length === 1 ? "not-allowed" : "pointer",
              }}
              onClick={() => {
                if (tableData.length > 1) {
                  setTableData((prev) =>
                    prev.filter((item) => item.id !== row.id)
                  );
                  setEditableRowKeys((prev) =>
                    prev.filter((key) => key !== row.id)
                  );
                } else {
                  message.warning(
                    lang.get("inventory", [
                      "Cannot delete the last remaining row.",
                    ])
                  );
                }
              }}
              disabled={tableData.length === 1}
            >
              <DeleteOutlined />
            </button>,
            <Button
              disabled={
                row.quantity <= 0 &&
                (row.free_quantity ? row.free_quantity : 0) <= 0
              }
              key="serials_edit"
              type="default"
              onClick={() => {
                setActiveRow(row);
                setSerialEditModalVisible(true);
              }}
            >
              <CodeOutlined />
            </Button>,
          ],
        }}
        recordCreatorProps={false}
        actionRef={actionRef}
        editableFormRef={editableFormRef}
        search={false}
        scroll={{ x: 800 }}
        toolBarRender={() => []}
        summary={() => {
          return (
            <tr style={{ backgroundColor: "#f5f7fa" }}>
              <th style={{ textAlign: "left" }}>
                {lang.get("inventory", ["Totals"])}
                {" (" + defaultCurrecyCode + ") "}
              </th>
              <td style={{ textAlign: "left" }}>{summary.quantity}</td>
              <td style={{ textAlign: "left" }}>{summary.free_quantity}</td>
              <td style={{ textAlign: "left" }}></td>
              <td style={{ textAlign: "right" }}>
                {summary.total_rows_tax.toFixed(2)}
              </td>
              <td style={{ textAlign: "right" }}>
                {summary.total_rows_discount.toFixed(2)}
              </td>
              <td style={{ textAlign: "left" }} colSpan={5}></td>
              <td style={{ textAlign: "right" }}>
                {summary.total_rows_sub_total.toFixed(2)}
              </td>
              <td></td>
            </tr>
          );
        }}
      />
      <Modal
        style={{ top: 20 }}
        open={serialEditModalVisible}
        title={lang.get("inventory", ["Edit Serial Numbers"])}
        onOk={() => {
          setSerialEditModalVisible(false);
          setActiveRow({} as InventoryDeliveryVoucherRowItem);
          const serials = hot?.getData();
          if (serials) {
            let newSerials: string[] = [];
            for (let i = 0; i < serials.length; i++) {
              const serial = serials[i];
              if (serial[0]) {
                newSerials.push(serial[0] as string);
              }
            }
            activeRow.serials = newSerials;
            setTableData((prev) => {
              const index = prev.findIndex((item) => item.id === activeRow.id);
              prev[index] = activeRow;
              return [...prev];
            });

            setItemRows([...tableData]);

            hot?.destroy();
          }
        }}
        onCancel={() => {
          setSerialEditModalVisible(false);
          hot?.destroy();
        }}
        onClose={() => hot?.destroy()}
      >
        <hr />
        <div ref={tableRef}></div>
      </Modal>
    </ProCard>
  );
};

export default DeliveryVoucherAddTable;
