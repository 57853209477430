import React, { useState } from "react";
import {
  Row,
  Col,
  Form,
  Tabs,
  Input,
  Typography,
  Select,
  Skeleton,
  message,
  Spin,
} from "antd";
import {
  UserOutlined,
  EnvironmentOutlined,
  ContactsOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { DBCustomer } from "../../../types/types";
import ContactTab from "./ContactTab";
import Map from "./Map";

import apiRequest from "../../../../../common/utils/apiRequest";
import lang from "../../../../../lang";

const { TabPane } = Tabs;
const { Text } = Typography;
const { TextArea } = Input;

interface TabLink {
  id: string;
  label: string;
  icon: JSX.Element;
  content?: JSX.Element;
}

const ProfileContent = (props: {
  client: DBCustomer;
  preload: boolean;
  setRefresh: (e: boolean) => void;
  refresh: boolean;
}) => {
  const [activeTab, setActiveTab] = useState("profile");
  const [preload] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [client, setClient] = useState<DBCustomer>(props.client);
  const [saving, setSaving] = useState(false);

  const handleSelectChange = (value: string, field: string) => {
    setClient({
      ...client,
      [field]: value,
    });

    const API_REQUEST = new apiRequest("client/update", true);

    API_REQUEST.send(
      {
        client: client.id,
        updates: { [field]: value },
      },
      (data: { status: string; message: string }) => {
        message.loading(lang.get("customer", ["updating"]), 1).then(() => {
          if (data.status === "success") {
            message.success(lang.get("customer", [data.message]), 2);
          } else {
            message.error(lang.get("customer", [data.message]), 2);
          }
          setClient({ ...client, [field]: value });
          setSaving(false);
        });
      }
    );
  };

  const updateChanges = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    prevValue: string = ""
  ) => {
    setSaving(true);
    const fieldId = e.target.id;
    const value = e.target.value;
    if (prevValue === value) {
      setSaving(false);
      return;
    }

    const API_REQUEST = new apiRequest("client/update", true);

    API_REQUEST.send(
      {
        client: client.id,
        updates: { [fieldId]: value },
      },
      (data: { status: string; message: string }) => {
        message.loading(lang.get("customer", ["updating"]), 1).then(() => {
          if (data.status === "success") {
            message.success(lang.get("customer", [data.message]), 2);
          } else {
            message.error(lang.get("customer", [data.message]), 2);
          }
          setClient({ ...client, [fieldId]: value });
          setSaving(false);
        });
      }
    );
  };

  const tabLinks: TabLink[] = [
    {
      id: "profile",
      label: "Profile",
      icon: saving ? (
        <Spin indicator={<LoadingOutlined spin />} spinning={true} />
      ) : (
        <UserOutlined />
      ),
      content: (
        <div style={{ padding: "16px" }}>
          <Form layout="vertical">
            <Row gutter={[16, 16]} justify="space-between">
              <Col xs={24} sm={12} lg={8}>
                <Form.Item label={<Text strong>Company Name</Text>}>
                  <Input
                    id="company_name"
                    placeholder="Company Name"
                    defaultValue={client.company_name}
                    onBlur={(e) => updateChanges(e, client.company_name)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={8}>
                <Form.Item label={<Text strong>VAT Number</Text>}>
                  <Input
                    id="vat_number"
                    placeholder="VAT Number"
                    defaultValue={client.vat_number}
                    onBlur={(e) => updateChanges(e, client.vat_number)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={8}>
                <Form.Item label={<Text strong>Website</Text>}>
                  <Input
                    placeholder="Website"
                    id="website"
                    defaultValue={client.website}
                    onBlur={(e) => updateChanges(e, client.website)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]} justify="space-between">
              <Col xs={24} sm={12} lg={8}>
                <Form.Item label={<Text strong>Address</Text>}>
                  <TextArea
                    placeholder="Address"
                    id="address"
                    defaultValue={client.address}
                    onBlur={(e) => updateChanges(e, client.address)}
                    rows={2}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} lg={8}>
                <Form.Item label={<Text strong>Phone</Text>}>
                  <Input
                    placeholder="Phone"
                    id="phone"
                    defaultValue={client.phone}
                    onBlur={(e) => updateChanges(e, client.phone)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={8}>
                <Form.Item label={<Text strong>City</Text>}>
                  <Input
                    placeholder="City"
                    id="city"
                    defaultValue={client.city}
                    onBlur={(e) => updateChanges(e, client.city)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]} justify="space-between">
              <Col xs={24} sm={12} lg={8}>
                <Form.Item label={<Text strong>State</Text>}>
                  <Input
                    id="state"
                    placeholder="State"
                    defaultValue={client.state}
                    onBlur={(e) => updateChanges(e, client.state)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={8}>
                <Form.Item label={<Text strong>Zip Code</Text>}>
                  <Input
                    type="number"
                    id="zip_code"
                    placeholder="Zip Code"
                    defaultValue={client.zip_code}
                    onBlur={(e) => updateChanges(e, client.zip_code)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={8}>
                <Form.Item label={<Text strong>Country</Text>} name="country">
                  <Select
                    onChange={(value) => handleSelectChange(value, "country")}
                    showSearch
                    id="country"
                    placeholder="Select a country"
                    optionFilterProp="label"
                    defaultValue={client.country}
                    filterOption={(input, option) =>
                      option?.label
                        ?.toLowerCase()
                        .includes(input.toLowerCase()) ?? false
                    }
                    options={[
                      { value: "Sri Lanka", label: "Sri Lanka" },
                      { value: "India", label: "India" },
                      { value: "USA", label: "USA" },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      ),
    },
    {
      id: "contacts",
      label: "Contacts",
      icon: <ContactsOutlined />,
      content: (
        <div style={{ padding: "16px" }}>
          <ContactTab
            client={client}
            preload={preload}
            setRefresh={setRefresh}
            refresh={refresh}
            currentKey={activeTab}
          />
        </div>
      ),
    },
    {
      id: "map",
      label: "Map",
      icon: <EnvironmentOutlined />,
      content: (
        <div style={{ padding: "16px" }}>
          <Map />
        </div>
      ),
    },
  ];

  return (
    <div style={{ background: "#fff", padding: "16px", borderRadius: "8px" }}>
      <Tabs activeKey={activeTab} onChange={(key) => setActiveTab(key)}>
        {tabLinks.map((tab) => (
          <TabPane
            tab={
              <span
                style={{
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                {tab.icon}
                {tab.label}
              </span>
            }
            key={tab.id}
          >
            {tab.content}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default ProfileContent;
