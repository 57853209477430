import apiRequest from "./apiRequest";
import { SelectType } from "../types";
import debounce from "lodash/debounce";

class coreFunctions {
  constructor() {}

  makeStrongPassword() {
    const pregs =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ@#$%^&*abcdefghijklmnopqrstuvwxyz0123456789";

    let password = "";
    for (let i = 0; i < 12; i++) {
      password += pregs.charAt(Math.floor(Math.random() * pregs.length));
    }

    return password;
  }

  formatISODateTime(
    isoString: string,
    onlydate: boolean = false,
    time: boolean = false
  ) {
    const date = new Date(isoString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    if (onlydate) {
      return `${year}-${month}-${day}`;
    }

    if (time) {
      return `${hours}:${minutes}:${seconds}`;
    }

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  getTimeDuration(start: string, end: string = new Date().toISOString()) {
    const start_dt = new Date(start);
    const end_dt = new Date(end);
    const diff = end_dt.getTime() - start_dt.getTime();
    const hours = Math.floor(diff / 1000 / 60 / 60);
    const minutes = Math.floor(diff / 1000 / 60) - hours * 60;
    const seconds = Math.floor(diff / 1000) - hours * 60 * 60 - minutes * 60;

    return `${hours}:${minutes}:${seconds}`;
  }

  waitTimePromise = async (time: number = 100) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, time);
    });
  };

  async waitRequest(time: number = 100) {
    await this.waitTimePromise(time);
  }

  async searchOptions(
    value: string,
    module: string,
    type: string,
    callback: (data: SelectType[]) => void,
    parms?: any,
    loadingCallback?: (loading: boolean) => void
  ) {
    const handleSearch = debounce(async () => {
      const SEARCH_REQUEST = new apiRequest(module + "/search", true);

      await SEARCH_REQUEST.send(
        { type: type, value: value, ...parms },
        (data: { status: string; message: string; data: SelectType[] }) => {
          if (data.status === "success") {
            callback(data.data);
          }
        }
      );
    }, 100);

    if (typeof loadingCallback === "function") loadingCallback(true);
    await handleSearch();
    if (typeof loadingCallback === "function") loadingCallback(false);
  }

  castStringToNumber(variable: any) {
    switch (typeof variable) {
      case "number":
        return variable;
      case "string":
        let coverted_number = parseFloat(variable);
        return isNaN(coverted_number) ? 0 : coverted_number;
      case undefined:
        return 0;
      default:
        return 0;
    }
  }
}

export default coreFunctions;
