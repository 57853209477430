import React, { useState } from "react";
import { ProForm, ProCard } from "@ant-design/pro-components";
import {
  Checkbox,
  Row,
  Col,
  Typography,
  Divider,
  Button,
  Space,
  Input,
  Form,
} from "antd";

const { Title } = Typography;

const modules = [
  {
    module: "Customers",
    capabilities: ["View (Own)", "View (Global)", "Create", "Edit", "Delete"],
  },
  {
    module: "Projects",
    capabilities: [
      "View (Own)",
      "View (Global)",
      "Create",
      "Edit",
      "Delete",
      "Activity Log",
    ],
  },
  {
    module: "Files",
    capabilities: ["View (Own)", "View (Global)", "Create", "Edit", "Delete"],
  },
  {
    module: "Timesheets",
    capabilities: [
      "View (Own)",
      "View (Global)",
      "Create",
      "Edit",
      "Delete",
      "Edit Timesheets (Global)",
      "Edit Own Timesheets",
      "Delete Timesheets (Global)",
      "Delete Own Timesheets",
    ],
  },
  {
    module: "Tasks",
    capabilities: ["View (Own)", "View (Global)", "Create", "Edit", "Delete"],
  },
];

const AddRole: React.FC = () => {
  const [selectedCapabilities, setSelectedCapabilities] = useState<{
    [key: string]: string[];
  }>({});

  const handleCapabilityChange = (
    module: string,
    capability: string,
    checked: boolean
  ) => {
    setSelectedCapabilities((prev) => {
      const currentCapabilities = prev[module] || [];
      if (checked) {
        return { ...prev, [module]: [...currentCapabilities, capability] };
      } else {
        return {
          ...prev,
          [module]: currentCapabilities.filter((item) => item !== capability),
        };
      }
    });
  };

  const handleSave = () => {
    console.log("Selected Capabilities:", selectedCapabilities);
  };

  return (
    <div
      style={{
        padding: "30px",
        backgroundColor: "#f0f2f5",
        minHeight: "100vh",
      }}
    >
      <Title level={3} style={{ marginBottom: "20px", color: "#595959" }}>
        Add New Role
      </Title>
      <Row justify="start">
        <Col xs={24} sm={24} md={20} lg={16}>
          <ProCard
            style={{
              borderRadius: "8px",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
            }}
          >
            <ProForm layout="vertical" submitter={false}>
              {/* Role Name */}
              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <Form.Item
                    name="roleName"
                    label={<span style={{ fontSize: "14px" }}>Role Name</span>}
                    rules={[
                      { required: true, message: "Role Name is required!" },
                    ]}
                  >
                    <Input placeholder="Enter Role Name" size="large" />
                  </Form.Item>
                </Col>
              </Row>

              <Divider style={{ margin: "20px 0", borderColor: "#e6e6e6" }} />

              {/* Modules and Capabilities */}
              <Row>
                <Col xs={24}>
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "collapse",
                      backgroundColor: "#fff",
                      borderRadius: "4px",
                      overflow: "hidden",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            backgroundColor: "#fafafa",
                            border: "1px solid #d9d9d9",
                            padding: "12px",
                            textAlign: "left",
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "#595959",
                          }}
                        >
                          Module
                        </th>
                        <th
                          style={{
                            backgroundColor: "#fafafa",
                            border: "1px solid #d9d9d9",
                            padding: "12px",
                            textAlign: "left",
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "#595959",
                          }}
                        >
                          Capabilities
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {modules.map((module, index) => (
                        <tr key={index}>
                          <td
                            style={{
                              border: "1px solid #d9d9d9",
                              padding: "12px",
                              fontSize: "14px",
                              color: "#595959",
                            }}
                          >
                            {module.module}
                          </td>
                          <td
                            style={{
                              border: "1px solid #d9d9d9",
                              padding: "12px",
                              fontSize: "14px",
                              color: "#595959",
                            }}
                          >
                            <div>
                              {module.capabilities.length > 0 ? (
                                module.capabilities.map((capability, i) => (
                                  <div key={i} style={{ marginBottom: "8px" }}>
                                    <Checkbox
                                      checked={selectedCapabilities[
                                        module.module
                                      ]?.includes(capability)}
                                      onChange={(e) =>
                                        handleCapabilityChange(
                                          module.module,
                                          capability,
                                          e.target.checked
                                        )
                                      }
                                    >
                                      {capability}
                                    </Checkbox>
                                  </div>
                                ))
                              ) : (
                                <span style={{ color: "#d9d9d9" }}>
                                  No Capabilities
                                </span>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Col>
              </Row>

              <Divider style={{ margin: "20px 0", borderColor: "#e6e6e6" }} />

              {/* Buttons */}
              <Row justify="end" gutter={[16, 16]}>
                <Space>
                  <Button onClick={() => console.log("Cancel clicked")}>
                    Cancel
                  </Button>
                  <Button color="primary" variant="solid" onClick={handleSave}>
                    Save Role
                  </Button>
                </Space>
              </Row>
            </ProForm>
          </ProCard>
        </Col>
      </Row>
    </div>
  );
};

export default AddRole;
