import { SearchOutlined } from "@ant-design/icons";
import type { ActionType, ProColumns } from "@ant-design/pro-components";
import { ProTable } from "@ant-design/pro-components";
import { Button, Input, Space, InputRef, DatePicker, Select } from "antd";
import { useRef, useState } from "react";
import Export from "../../../../../common/components/Export";
import lang from "../../../../../lang";

const { RangePicker } = DatePicker;
const { Option } = Select;

interface Expense {
  id: string;
  category: string;
  amount: string;
  name: string;
  receipt: string;
  date: string;
  project: string;
  invoice: string;
  reference: string;
  paymentMode: string;
}

const Expenses: Expense[] = [
  {
    id: "8",
    category: "Fuel",
    amount: "LKR 100.00",
    name: "gh",
    receipt: "",
    date: "2023-03-05",
    project: "",
    invoice: "001",
    reference: "001",
    paymentMode: "Main Cash",
  },
];

const ExpensesTable = () => {
  const actionRef = useRef<ActionType>();
  const [pageSize, setPageSize] = useState<number>(10);
  const searchInput = useRef<InputRef>(null);
  const [searchAttributes, setSearchAttributes] = useState<
    { key: string; value: string }[]
  >([]);
  const [allSearch, setAllSearch] = useState<string>("");

  const expobj = new Export("expenses, " + new Date().toDateString());

  const handleSearch = (field: string, value: string) => {
    setAllSearch("");
    const existingAttribute = searchAttributes.find(
      (attr) => attr.key === field
    );

    if (existingAttribute) {
      setSearchAttributes(
        searchAttributes.map((attr) =>
          attr.key === field ? { ...attr, value } : attr
        )
      );
    } else {
      setSearchAttributes([
        ...searchAttributes,
        {
          key: field,
          value,
        },
      ]);
    }
  };

  const searchCols = (field: string, label: string) => {
    return {
      filterDropdown: (props: any) => {
        const { close } = props;

        setTimeout(() => {
          if (searchInput.current) {
            searchInput.current.focus();
          }
        }, 100);

        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={lang.get("expenses", [`Search ${label}`])}
              onChange={(e) => handleSearch(field, e.target.value)}
              value={
                searchAttributes.filter((item) => item.key === field)[0]?.value
              }
              style={{ marginBottom: 8, display: "block" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  close();
                  if (actionRef.current) {
                    actionRef.current?.reset?.();
                    actionRef.current.reload();
                  }
                }
              }}
            />
            <Space>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="small"
                onClick={() => {
                  actionRef.current?.reset?.();
                  actionRef.current?.reload();
                }}
                style={{ width: 90 }}
              >
                {lang.get("expenses", ["Search"])}
              </Button>
              <Button
                onClick={() => close()}
                size="small"
                style={{ width: 90 }}
              >
                {lang.get("expenses", ["Close"])}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
    };
  };

  const columns: ProColumns<Expense>[] = [
    {
      title: lang.get("expenses", ["#"]),
      dataIndex: "id",
      sorter: true,
      ellipsis: true,
      ...searchCols("id", lang.get("expenses", ["#"])),
    },
    {
      title: lang.get("expenses", ["Category"]),
      dataIndex: "category",
      sorter: true,
      ...searchCols("category", lang.get("expenses", ["Category"])),
    },
    {
      title: lang.get("expenses", ["Amount"]),
      dataIndex: "amount",
      sorter: true,
      ...searchCols("amount", lang.get("expenses", ["Amount"])),
    },
    {
      title: lang.get("expenses", ["Name"]),
      dataIndex: "name",
      sorter: true,
      ...searchCols("name", lang.get("expenses", ["Name"])),
    },
    {
      title: lang.get("expenses", ["Receipt"]),
      dataIndex: "receipt",
      sorter: true,
      ...searchCols("receipt", lang.get("expenses", ["Receipt"])),
    },
    {
      title: lang.get("expenses", ["Date"]),
      dataIndex: "date",
      sorter: true,
      ...searchCols("date", lang.get("expenses", ["Date"])),
    },
    {
      title: lang.get("expenses", ["Project"]),
      dataIndex: "project",
      sorter: true,
      ...searchCols("project", lang.get("expenses", ["Project"])),
    },
    {
      title: lang.get("expenses", ["Invoice"]),
      dataIndex: "invoice",
      sorter: true,
      ...searchCols("invoice", lang.get("expenses", ["Invoice"])),
    },
    {
      title: lang.get("expenses", ["Reference #"]),
      dataIndex: "reference",
      sorter: true,
      ...searchCols("reference", lang.get("expenses", ["Reference #"])),
    },
    {
      title: lang.get("expenses", ["Payment Mode"]),
      dataIndex: "paymentMode",
      sorter: true,
      ...searchCols("paymentMode", lang.get("expenses", ["Payment Mode"])),
    },
  ];

  return (
    <ProTable
      headerTitle={lang.get("expenses", ["Expenses"])}
      columns={columns}
      actionRef={actionRef}
      dataSource={Expenses}
      rowKey="id"
      cardBordered
      pagination={{
        defaultCurrent: 1,
        pageSize: pageSize,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50],
        showTotal: (total, range) =>
          lang.get("expenses", [`Showing ${range[0]}-${range[1]} of ${total} items`]),
        onShowSizeChange: (current, size) => {
          setPageSize(size);
        },
      }}
      search={false}
      scroll={{ x: 800 }}
      toolBarRender={() => [
        <RangePicker key="date" />,
        <Select
          placeholder={lang.get("expenses", ["Select Currency"])}
          style={{ width: 200 }}
        >
          <Option value="USD">USD - {lang.get("expenses", ["US Dollar"])}</Option>
          <Option value="EUR">EUR - {lang.get("expenses", ["Euro"])}</Option>
          <Option value="GBP">GBP - {lang.get("expenses", ["British Pound"])}</Option>
          <Option value="LKR">LKR - {lang.get("expenses", ["Sri Lankan Rupee"])}</Option>
        </Select>,
        <Input
          style={{ width: "auto" }}
          key="all_search"
          allowClear
          placeholder={lang.get("expenses", ["Global Search"])}
          value={allSearch}
          onChange={(e) => {
            setAllSearch(e.target.value);
            setSearchAttributes([]);
            actionRef?.current?.reload();
          }}
        />,
        <Button
          key="csv"
          onClick={() => {
            const csvData = Expenses.map((row) => ({
              [lang.get("expenses", ["#"])]: row.id,
              [lang.get("expenses", ["Category"])]: row.category,
              [lang.get("expenses", ["Amount"])]: row.amount,
              [lang.get("expenses", ["Name"])]: row.name,
              [lang.get("expenses", ["Receipt"])]: row.receipt,
              [lang.get("expenses", ["Date"])]: row.date,
              [lang.get("expenses", ["Project"])]: row.project,
              [lang.get("expenses", ["Invoice"])]: row.invoice,
              [lang.get("expenses", ["Reference #"])]: row.reference,
              [lang.get("expenses", ["Payment Mode"])]: row.paymentMode,
            }));
            expobj.exportToCSV(csvData);
          }}
        >
          {lang.get("expenses", ["Export CSV"])}
        </Button>,
        <Button
          key="excel"
          onClick={() => {
            const excelData = Expenses.map((row) => ({
              [lang.get("expenses", ["#"])]: row.id,
              [lang.get("expenses", ["Category"])]: row.category,
              [lang.get("expenses", ["Amount"])]: row.amount,
              [lang.get("expenses", ["Name"])]: row.name,
              [lang.get("expenses", ["Receipt"])]: row.receipt,
              [lang.get("expenses", ["Date"])]: row.date,
              [lang.get("expenses", ["Project"])]: row.project,
              [lang.get("expenses", ["Invoice"])]: row.invoice,
              [lang.get("expenses", ["Reference #"])]: row.reference,
              [lang.get("expenses", ["Payment Mode"])]: row.paymentMode,
            }));
            expobj.exportToExcel(excelData);
          }}
        >
          {lang.get("expenses", ["Export Excel"])}
        </Button>,
        <Button
          key="pdf"
          onClick={() => {
            expobj.exportToPDF({
              head: [
                [
                  lang.get("expenses", ["#"]),
                  lang.get("expenses", ["Category"]),
                  lang.get("expenses", ["Amount"]),
                  lang.get("expenses", ["Name"]),
                  lang.get("expenses", ["Receipt"]),
                  lang.get("expenses", ["Date"]),
                  lang.get("expenses", ["Project"]),
                  lang.get("expenses", ["Invoice"]),
                  lang.get("expenses", ["Reference #"]),
                  lang.get("expenses", ["Payment Mode"]),
                ],
              ],
              body: Expenses.map((row) => [
                row.id,
                row.category,
                row.amount,
                row.name,
                row.receipt,
                row.date,
                row.project,
                row.invoice,
                row.reference,
                row.paymentMode,
              ]),
            });
          }}
        >
          {lang.get("expenses", ["Export PDF"])}
        </Button>,
      ]}
    />
  );
};

export default ExpensesTable;
