import React, { useState, useRef } from "react";
import { Tag, InputRef, Input, Button, Space, Select } from "antd";
import {
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { ActionType, ProColumns, ProTable } from "@ant-design/pro-components";
import Export from "../../../../common/components/Export";
import lang from "../../../../lang";
import Item1 from "../../../../assets/img/icons/item/pc.svg";
import { Link } from "react-router-dom";

import apiRequest from "../../../../common/utils/apiRequest";
import coreFunctions from "../../../../common/utils/coreFunctions";
import { Item } from "../../types/types";

const Option = Select;
const statusStyles: Record<string, { color: string; icon: React.ReactNode }> = {
  Available: { color: "green", icon: <CheckCircleOutlined /> },
  "Out of Stock": { color: "red", icon: <CloseCircleOutlined /> },
  Pending: { color: "blue", icon: <SyncOutlined spin /> },
};

const ItemListTable = (props: {
  setRefreshTable: (refreshTable: boolean) => void;
  refreshTable: boolean;
}) => {
  const [pageSize, setPageSize] = useState(15);
  const searchInput = useRef<InputRef>(null);
  const actionRef = useRef<ActionType>();
  const [searchAttributes, setSearchAttributes] = useState<
    { key: string; value: string }[]
  >([]);
  const [allSearch, setAllSearch] = useState<string>("");
  const [tableData, setTableData] = useState<Item[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const expobj = new Export(
    lang.get("inventory", ["items"]) + ", " + new Date().toDateString()
  );

  const handleSearch = (field: string, value: string) => {
    setAllSearch("");
    const existingAttribute = searchAttributes.find(
      (attr) => attr.key === field
    );

    if (existingAttribute) {
      setSearchAttributes(
        searchAttributes.map((attr) =>
          attr.key === field ? { ...attr, value } : attr
        )
      );
    } else {
      setSearchAttributes([
        ...searchAttributes,
        {
          key: field,
          value,
        },
      ]);
    }
  };

  const searchCols = (field: string, label: string) => {
    return {
      filterDropdown: (props: any) => {
        const { close } = props;

        setTimeout(() => {
          if (searchInput.current) {
            searchInput.current.focus();
          }
        }, 100);

        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={lang.get("projects", [`Search ${label}`])}
              onChange={(e) => handleSearch(field, e.target.value)}
              value={
                searchAttributes.filter((item) => item.key === field)[0]?.value
              }
              style={{ marginBottom: 8, display: "block" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  close();
                  if (actionRef.current) {
                    actionRef.current?.reset?.();
                    actionRef.current.reload();
                  }
                }
              }}
            />
            <Space>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="small"
                onClick={() => {
                  actionRef.current?.reset?.();
                  actionRef.current?.reload();
                }}
                style={{ width: 90 }}
              >
                {lang.get("projects", ["Search"])}
              </Button>
              <Button
                onClick={() => close()}
                size="small"
                style={{ width: 90 }}
              >
                {lang.get("projects", ["Close"])}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
    };
  };

  const columns: ProColumns<Item>[] = [
    {
      title: lang.get("items", ["Image"]),
      dataIndex: "image",
      key: "image",
      align: "left",
      width: 100,
      render: (_, record: Item) => (
        <div style={{ width: 50, height: 50, background: "#f0f0f0" }}>
          <img
            src={record.image ? record.image : Item1}
            alt={lang.get("items", ["Item Image"])}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      ),
    },
    {
      title: lang.get("items", ["Item Code"]),
      dataIndex: "code",
      key: "code",
      sorter: true,
      ...searchCols("code", lang.get("items", ["Item Code"])),
      align: "left",
      width: 100,
    },
    {
      title: lang.get("items", ["Item Name"]),
      dataIndex: "name",
      key: "name",
      sorter: true,
      ...searchCols("name", lang.get("items", ["Item Name"])),
      align: "left",
      width: 200,
      render: (_, record: Item) => (
        <Link
          to={`/item-details/${record.id}`}
          style={{
            color: "inherit",
            textDecoration: "none",
          }}
        >
          <p style={{ margin: 0, fontWeight: "bold" }}>{record.name}</p>
        </Link>
      ),
    },
    {
      title: lang.get("items", ["Barcode"]),
      dataIndex: "barcode",
      key: "barcode",
      sorter: true,
      ...searchCols("barcode", lang.get("items", ["Barcode"])),
      align: "left",
      width: 200,
    },
    {
      title: lang.get("items", ["Type"]),
      dataIndex: "type",
      key: "type",
      sorter: true,
      ...searchCols("type", lang.get("items", ["Type"])),
      align: "center",
      width: 100,
    },
    {
      title: lang.get("items", ["Warehouse Name"]),
      dataIndex: "warehouse",
      key: "warehouse",
      sorter: true,
      ...searchCols("warehouse", lang.get("items", ["Warehouse Name"])),
      align: "center",
      width: 200,
      render: (_, record: Item) => {
        return record.warehouses_with_stock
          ? record.warehouses_with_stock.map((warehouse_with_stock) => (
              <Tag color="blue" key={warehouse_with_stock}>
                {warehouse_with_stock}
              </Tag>
            ))
          : null;
      },
    },
    {
      title: lang.get("items", ["Current Stock"]),
      dataIndex: "current_stock",
      key: "current_stock",
      sorter: true,
      ...searchCols("current_stock", lang.get("items", ["Current Stock"])),
      align: "center",
      width: 150,
    },
    {
      title: lang.get("items", ["Purchase Price"]),
      dataIndex: "purchase_price",
      key: "purchase_price",
      sorter: true,
      ...searchCols("purchase_price", lang.get("items", ["Purchase Price"])),
      align: "center",
    },
    {
      title: lang.get("items", ["Selling Price"]),
      dataIndex: "default_price",
      key: "default_price",
      sorter: true,
      ...searchCols("default_price", lang.get("items", ["Selling Price"])),
      align: "center",
    },
    {
      title: lang.get("items", ["Tax"]),
      dataIndex: "taxes",
      key: "taxes",
      sorter: true,
      ...searchCols("tax", lang.get("items", ["Tax"])),
      align: "center",
      width: 100,
      render: (_, record: Item) => {
        return record.taxes.map((tax) => (
          <Tag color="blue" key={tax}>
            {tax}
          </Tag>
        ));
      },
    },
    {
      title: lang.get("items", ["Status"]),
      dataIndex: "status",
      key: "status",
      ...searchCols("status", lang.get("items", ["Status"])),
      render: (_, record: Item) => {
        const { color, icon } = statusStyles[record.status] || {
          color: "gray",
          icon: null,
        };
        return <Tag color={color}>{icon}</Tag>;
      },
      sorter: true,
      align: "left",
    },
    {
      title: lang.get("items", ["Category"]),
      dataIndex: "commodity_group",
      key: "commodity_group",
      sorter: true,
      ...searchCols("commodity_group", lang.get("items", ["Category"])),
      align: "left",
    },
    {
      title: lang.get("items", ["Brand"]),
      dataIndex: "brands",
      key: "brands",
      sorter: true,
      ...searchCols("brand", lang.get("items", ["Brand"])),
      align: "left",
      render: (_, record: Item) => {
        return record.brands
          ? record.brands.map((brand) => (
              <Tag color="purple" key={brand}>
                {brand}
              </Tag>
            ))
          : null;
      },
    },
    {
      title: lang.get("items", ["Minimum Stock"]),
      dataIndex: "minimum_stock",
      key: "minimum_stock",
      sorter: true,
      ...searchCols("minimum_stock", lang.get("items", ["Minimum Stock"])),
      align: "center",
    },
    {
      title: lang.get("items", ["Maximum Stock"]),
      dataIndex: "maximum_stock",
      key: "maximum_stock",
      sorter: true,
      ...searchCols("maximum_stock", lang.get("items", ["Maximum Stock"])),
      align: "center",
    },
  ];

  return (
    <ProTable
      columns={columns}
      actionRef={actionRef}
      dataSource={tableData}
      request={async (params, sort, filter) => {
        await new coreFunctions().waitRequest(10);

        const processedFilters = Object.entries(filter || {}).reduce(
          (acc, [key, value]) => {
            if (value && value.length > 0) {
              acc[key] = value;
            }
            return acc;
          },
          {} as Record<string, (string | number)[]>
        );

        const payload = {
          params: params,
          sorts: sort,
          filters: processedFilters,
          searches: searchAttributes,
          all_search: allSearch,
        };

        const API_REQUEST = new apiRequest("inventory/items/get", true);
        const response = await API_REQUEST.send(payload);

        if (response && response.status === "success") {
          const fetchedData = response.data || [];
          setTableData(fetchedData);
          setTotalRecords(response.total);

          return {
            data: fetchedData,
            total: totalRecords,
            success: true,
          };
        }

        return {
          data: [],
          total: 0,
          success: false,
        };
      }}
      rowKey="key"
      pagination={{
        defaultCurrent: 1,
        pageSize: pageSize,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50],
        onShowSizeChange: (current, size) => setPageSize(size),
      }}
      search={false}
      scroll={{ x: "max-content" }}
      style={{ width: "100%" }}
      toolBarRender={() => [
        <Space size="middle" wrap key="filters">
          <Select
            placeholder={lang.get("items", ["Select Warehouse"])}
            style={{ width: 150 }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
              const optionText = option?.children as unknown as string;
              return optionText?.toLowerCase().includes(input.toLowerCase());
            }}
          >
            <Option value="main">{lang.get("warehouses", ["Main"])}</Option>
            <Option value="warehouse-receive-return-order">
              {lang.get("warehouses", ["Warehouse Receive Return Order"])}
            </Option>
          </Select>
          <Select
            placeholder={lang.get("items", ["Commodity"])}
            style={{ width: 200 }}
            showSearch
            mode="multiple"
            allowClear
            optionFilterProp="children"
            filterOption={(input, option) => {
              const optionText = option?.children as unknown as string;
              return optionText?.toLowerCase().includes(input.toLowerCase());
            }}
          >
            <Option value="commodity1">(6,500.00) blous 1 (10)</Option>
            <Option value="commodity2">(8,500.00) Samsung J 7 (102)</Option>
            <Option value="commodity3">(0.00) Truecam Battery (10)</Option>
            <Option value="commodity4">(6,500.00) uykikk (94)</Option>
            <Option value="commodity5">fsdbgdsgf</Option>
          </Select>

          <Select
            placeholder={lang.get("items", ["Tags"])}
            style={{ width: 200 }}
            showSearch
            mode="tags"
            allowClear
            optionFilterProp="children"
            filterOption={(input, option) => {
              const optionText = option?.children as unknown as string;
              return optionText?.toLowerCase().includes(input.toLowerCase());
            }}
          >
            <Option value="tag1">Electronics</Option>
            <Option value="tag2">Clothing</Option>
            <Option value="tag3">Batteries</Option>
            <Option value="tag4">Miscellaneous</Option>
          </Select>

          <Select
            placeholder={lang.get("items", ["Alert"])}
            style={{ width: 150 }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
              const optionText = option?.children as unknown as string;
              return optionText?.toLowerCase().includes(input.toLowerCase());
            }}
          >
            <Option value="minimum-stock">
              {lang.get("alerts", ["Minimum stock"])}
            </Option>
            <Option value="maximum-stock">
              {lang.get("alerts", ["Maximum stock"])}
            </Option>
            <Option value="out-of-stock">
              {lang.get("alerts", ["Out of stock"])}
            </Option>
            <Option value="expiration">
              {lang.get("alerts", ["1 Month Before expiration and expired"])}
            </Option>
          </Select>

          <Select
            placeholder={lang.get("items", ["Can be Inventory"])}
            style={{ width: 300 }}
            mode="multiple"
            showSearch
            allowClear
            optionFilterProp="children"
            filterOption={(input, option) => {
              const optionText = option?.children as unknown as string;
              return optionText?.toLowerCase().includes(input.toLowerCase());
            }}
          >
            <Option value="can-be-sold">
              {lang.get("inventory", ["Can be sold"])}
            </Option>
            <Option value="can-be-purchased">
              {lang.get("inventory", ["Can be purchased"])}
            </Option>
            <Option value="can-be-manufacturing">
              {lang.get("inventory", ["Can be manufacturing"])}
            </Option>
            <Option value="can-be-inventory">
              {lang.get("inventory", ["Can be Inventory"])}
            </Option>
          </Select>
        </Space>,
        <Input
          key="global_search"
          placeholder="Global Search"
          value={allSearch}
          onChange={(e) => {
            setAllSearch(e.target.value);
            setSearchAttributes([]);
            actionRef?.current?.reload();
          }}
          style={{ width: 200 }}
        />,

        <Button
          key="csv"
          onClick={() => {
            const csvData = tableData.map((row) => ({
              [lang.get("items", ["ID"])]: row.id,
              [lang.get("items", ["Image"])]: row.image,
              [lang.get("items", ["Item Code"])]: row.code,
              [lang.get("items", ["Item Name"])]: row.name,
              [lang.get("items", ["Barcode"])]: row.barcode,
              [lang.get("items", ["Type"])]: row.type,
              [lang.get("items", ["Warehouse Name"])]:
                row.warehouses_with_stock.map((warehouse) => warehouse),
              [lang.get("items", ["Current Stock"])]: 0,
              [lang.get("items", ["Purchase Price"])]: row.purchase_price,
              [lang.get("items", ["Selling Price"])]: row.selling_price,
              [lang.get("items", ["Tax"])]: 0,
              [lang.get("items", ["Status"])]: row.status,
              [lang.get("items", ["Category"])]: row.commodity_group,
              [lang.get("items", ["Brand"])]: row.brands
                .map((brand) => brand)
                .join(", "),
              [lang.get("items", ["Minimum Stock"])]: row.minimum_stock,
              [lang.get("items", ["Maximum Stock"])]: row.maximum_stock,
            }));
            expobj.exportToCSV(csvData);
          }}
        >
          {lang.get("items", ["Export CSV"])}
        </Button>,
        <Button
          key="excel"
          onClick={() => {
            const excelData = tableData.map((row) => ({
              [lang.get("items", ["ID"])]: row.id,
              [lang.get("items", ["Image"])]: row.image,
              [lang.get("items", ["Item Code"])]: row.code,
              [lang.get("items", ["Item Name"])]: row.name,
              [lang.get("items", ["Barcode"])]: row.barcode,
              [lang.get("items", ["Type"])]: row.type,
              [lang.get("items", ["Warehouse Name"])]:
                row.warehouses_with_stock.map((warehouse) => warehouse),
              [lang.get("items", ["Current Stock"])]: 0,
              [lang.get("items", ["Purchase Price"])]: row.purchase_price,
              [lang.get("items", ["Selling Price"])]: row.selling_price,
              [lang.get("items", ["Tax"])]: 0,
              [lang.get("items", ["Status"])]: row.status,
              [lang.get("items", ["Category"])]: row.commodity_group,
              [lang.get("items", ["Brand"])]: row.brands
                .map((brand) => brand)
                .join(", "),
              [lang.get("items", ["Minimum Stock"])]: row.minimum_stock,
              [lang.get("items", ["Maximum Stock"])]: row.maximum_stock,
            }));
            expobj.exportToExcel(excelData);
          }}
        >
          {lang.get("items", ["Export Excel"])}
        </Button>,
        <Button
          key="pdf"
          onClick={() => {
            expobj.exportToPDF({
              head: [
                [
                  lang.get("items", ["ID"]),
                  lang.get("items", ["Image"]),
                  lang.get("items", ["Item Code"]),
                  lang.get("items", ["Item Name"]),
                  lang.get("items", ["Barcode"]),
                  lang.get("items", ["Type"]),
                  lang.get("items", ["Warehouse Name"]),
                  lang.get("items", ["Current Stock"]),
                  lang.get("items", ["Purchase Price"]),
                  lang.get("items", ["Selling Price"]),
                  lang.get("items", ["Tax"]),
                  lang.get("items", ["Status"]),
                  lang.get("items", ["Category"]),
                  lang.get("items", ["Brand"]),
                  lang.get("items", ["Minimum Stock"]),
                  lang.get("items", ["Maximum Stock"]),
                ],
              ],
              body: tableData.map((row) => [
                row.id,
                row.image,
                row.code,
                row.name,
                row.barcode,
                row.type,
                row.warehouses_with_stock.map((warehouse) => warehouse),
                0,
                row.purchase_price,
                row.selling_price,
                0,
                row.status,
                row.commodity_group,
                row.brands.map((brand) => brand).join(", "),
                row.minimum_stock,
                row.maximum_stock,
              ]),
            });
          }}
        >
          {lang.get("items", ["Export PDF"])}
        </Button>,
      ]}
    />
  );
};

export default ItemListTable;
