import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Dropdown,
  Avatar,
  Button,
  Space,
  Tag,
  Typography,
  MenuProps,
  Modal,
  Skeleton,
  Tooltip,
} from "antd";
import {
  PlusOutlined,
  SettingOutlined,
  FileTextOutlined,
  DownOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
  PauseCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import lang from "../../../../lang";

import AddTask from "./add-task/AddTask";

import { DBProject, DBSingleProject } from "../../types/types";

import apiRequest from "../../../../common/utils/apiRequest";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

// Define styles for each status
const statusStyles: Record<string, { color: string; icon: React.ReactNode }> = {
  Finished: { color: "green", icon: <CheckCircleOutlined /> },
  "In Progress": { color: "blue", icon: <LoadingOutlined /> },
  "On Hold": { color: "gold", icon: <PauseCircleOutlined /> },
  Cancelled: { color: "red", icon: <CloseCircleOutlined /> },
};

const ProjectSummary = ({
  project,
  setProject,
}: {
  project: DBSingleProject | null;
  setProject: (e: DBSingleProject) => void;
}) => {
  const [projects, setProjects] = useState<DBSingleProject[]>([]);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const projectMenuItems: MenuProps["items"] = projects.map((proj) => ({
    key: proj.id,
    label: proj.name,
    onClick: () => {
      navigate(`/project-details/${proj.id}`);
    },
  }));

  const renderStatusTag = (status: string) => {
    if (typeof status === "string") {
      return <Tag>{lang.get("projects", [status])}</Tag>;
    }
  };

  useEffect(() => {
    const API_REQUEST = new apiRequest("projects/get", true);

    API_REQUEST.send(
      {},
      (data: { status: string; message: string; data: DBSingleProject[] }) => {
        if (data.status === "success") {
          setProjects(data.data);
          setLoading(false);
        }
      }
    );
  }, [project?.id]);

  return (
    <>
      {loading ? (
        <Skeleton active />
      ) : (
        <Row justify="space-between" align="middle" gutter={[24, 0]}>
          {/* Project Summary Section */}
          <Col xs={24} md={12}>
            <Space size="large" align="center">
              {/* Project Dropdown */}
              <Dropdown menu={{ items: projectMenuItems }} trigger={["click"]}>
                <Button type="text">
                  <Title level={5} style={{ margin: 0 }}>
                    {project?.name ||
                      lang.get("projects", ["Select a project"])}
                  </Title>
                  <DownOutlined />
                </Button>
              </Dropdown>

              {/* Avatar Group */}
              {project && (
                <Avatar.Group>
                  {project.members.map((member, index) => {
                    if (index == 3) {
                      return (
                        <Tooltip title={lang.get("projects", ["More Members"])}>
                          <Avatar size="small" key={index}>
                            +{project.members.length - 3}
                          </Avatar>
                        </Tooltip>
                      );
                    }
                    if (index > 3) {
                      return null;
                    } else {
                      return (
                        <Tooltip title={member.name} key={member.id}>
                          <Avatar key={member.id} src={member.profile_image}>
                            {!member.profile_image && member.name[0]}
                          </Avatar>
                        </Tooltip>
                      );
                    }
                  })}
                </Avatar.Group>
              )}

              {/* Status Tag */}
              {project && renderStatusTag(project.status.name)}
            </Space>
          </Col>

          {/* Action Buttons Section */}
          <Col xs={24} md={12}>
            <Row gutter={[16, 16]} justify="end">
              <Col>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => setShowTaskModal(true)}
                >
                  {lang.get("buttons", ["New Task"])}
                </Button>

                <Modal
                  title={lang.get(
                    "modals",
                    ["Add New Task to Project"] + " | " + project?.name
                  )}
                  destroyOnClose
                  open={showTaskModal}
                  onCancel={() => setShowTaskModal(false)}
                  footer={null}
                  width={800}
                >
                  <AddTask
                    project={project}
                    setShowTaskModal={setShowTaskModal}
                  />
                </Modal>
              </Col>
              <Col>
                <Button icon={<FileTextOutlined />}>
                  {lang.get("buttons", ["Invoice Project"])}
                </Button>
              </Col>
              <Col>
                <Button icon={<SettingOutlined />}>
                  {lang.get("buttons", ["Customize"])}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ProjectSummary;
