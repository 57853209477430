import React, { useState, useEffect } from "react";
import { Modal } from "antd";

const WhatsNew: React.FC = () => {
  const [hasNewVersion, setHasNewVersion] = useState(false);

  const handleOk = () => {
    setHasNewVersion(false);
    localStorage.removeItem("hasNewVersion");
  };

  useEffect(() => {
    setTimeout(() => {
      if (localStorage.getItem("hasNewVersion")) {
        setHasNewVersion(true);
      }
    }, 2000);
  }, []);

  return (
    <div>
      {hasNewVersion && (
        <Modal
          title="Whats New 1.0v"
          animation={true}
          centered
          open={hasNewVersion}
          cancelText={"Okay"}
          okButtonProps={{ style: { display: "none" } }}
          okType="primary"
          onOk={handleOk}
          onCancel={() => {
            localStorage.removeItem("hasNewVersion");
            setHasNewVersion(false);
          }}
        >
          <hr />
          <iframe
            width="100%"
            height="500px"
            title="Whats New"
            scrolling="no"
            src="http://clients.globalcloudmedia.lk/"
          />
        </Modal>
      )}
    </div>
  );
};

export default WhatsNew;
