import React, { useState, useRef } from "react";
import { Button, Input, InputRef, Space, Tag } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { ProTable, ProColumns, ActionType } from "@ant-design/pro-components";
import lang from "../../../../../../../lang";
import Export from "../../../../../../../common/components/Export";

interface Subscription {
  id: string;
  subscriptionName: string;
  customer: string;
  project: string;
  status: string;
  nextBillingCycle: string;
  dateSubscribed: string;
}

const subscriptionData: Subscription[] = [];

const SubscriptionTable: React.FC = () => {
  const [pageSize, setPageSize] = useState<number>(6);
  const searchInput = useRef<InputRef>(null);
  const actionRef = useRef<ActionType>();
  const [allSearch, setAllSearch] = useState<string>("");
  const [searchAttributes, setSearchAttributes] = useState<
    { key: string; value: string }[]
  >([]);

  const expobj = new Export(
    lang.get("subscriptions", ["Subscriptions"]) + ", " + new Date().toDateString()
  );

  const handleSearch = (field: string, value: string) => {
    setAllSearch("");
    const existingAttribute = searchAttributes.find(
      (attr) => attr.key === field
    );

    if (existingAttribute) {
      setSearchAttributes(
        searchAttributes.map((attr) =>
          attr.key === field ? { ...attr, value } : attr
        )
      );
    } else {
      setSearchAttributes([...searchAttributes, { key: field, value }]);
    }
  };

  const searchCols = (field: string, label: string) => {
    return {
      filterDropdown: (props: any) => {
        const { close } = props;

        setTimeout(() => {
          if (searchInput.current) {
            searchInput.current.focus();
          }
        }, 100);

        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={lang.get("subscriptions", [`Search ${label}`])}
              onChange={(e) => handleSearch(field, e.target.value)}
              value={searchAttributes.find((item) => item.key === field)?.value}
              style={{ marginBottom: 8, display: "block" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  close();
                  actionRef.current?.reload();
                }
              }}
            />
            <Space>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="small"
                onClick={() => actionRef.current?.reload()}
                style={{ width: 90 }}
              >
                {lang.get("subscriptions", ["Search"])}
              </Button>
              <Button
                onClick={() => close()}
                size="small"
                style={{ width: 90 }}
              >
                {lang.get("subscriptions", ["Close"])}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
    };
  };

  const columns: ProColumns<Subscription>[] = [
    {
      title: lang.get("subscriptions", ["ID"]),
      dataIndex: "id",
      key: "id",
      sorter: true,
      ...searchCols("id", lang.get("subscriptions", ["ID"]))
    },
    {
      title: lang.get("subscriptions", ["Subscription Name"]),
      dataIndex: "subscriptionName",
      key: "subscriptionName",
      sorter: true,
      ...searchCols("subscriptionName", lang.get("subscriptions", ["Subscription Name"]))
    },
    {
      title: lang.get("subscriptions", ["Customer"]),
      dataIndex: "customer",
      key: "customer",
      sorter: true,
      ...searchCols("customer", lang.get("subscriptions", ["Customer"]))
    },
    {
      title: lang.get("subscriptions", ["Project"]),
      dataIndex: "project",
      key: "project",
      sorter: true,
      ...searchCols("project", lang.get("subscriptions", ["Project"]))
    },
    {
      title: lang.get("subscriptions", ["Status"]),
      dataIndex: "status",
      key: "status",
      sorter: true,
      render: (_, record: Subscription) => (
        <Tag color={record.status === "Active" ? "green" : "red"}>
          {lang.get("subscriptions", [record.status])}
        </Tag>
      )
    },
    {
      title: lang.get("subscriptions", ["Next Billing Cycle"]),
      dataIndex: "nextBillingCycle",
      key: "nextBillingCycle",
      sorter: true,
      ...searchCols("nextBillingCycle", lang.get("subscriptions", ["Next Billing Cycle"]))
    },
    {
      title: lang.get("subscriptions", ["Date Subscribed"]),
      dataIndex: "dateSubscribed",
      key: "dateSubscribed",
      sorter: true,
      ...searchCols("dateSubscribed", lang.get("subscriptions", ["Date Subscribed"]))
    },
  ];

  return (
    <ProTable
      headerTitle={lang.get("subscriptions", ["Subscriptions"])}
      columns={columns}
      dataSource={subscriptionData}
      actionRef={actionRef}
      rowKey="id"
      pagination={{
        defaultCurrent: 1,
        pageSize,
        showSizeChanger: true,
        pageSizeOptions: [6, 10, 20],
        showTotal: (total, range) =>
          lang.get("subscriptions", [
            `Showing ${range[0]}-${range[1]} of ${total} items`,
          ]),
        onShowSizeChange: (_, size) => setPageSize(size),
      }}
      search={false}
      scroll={{ x: 800 }}
      toolBarRender={() => [
        <Input
          style={{ width: "auto" }}
          key="all_search"
          allowClear
          placeholder={lang.get("subscriptions", ["Global Search"])}
          value={allSearch}
          onChange={(e) => {
            setAllSearch(e.target.value);
            setSearchAttributes([]);
            actionRef.current?.reload();
          }}
        />, 
        <Button
          key="csv"
          onClick={() => {
            const csvData = subscriptionData.map((row) => ({
              [lang.get("subscriptions", ["ID"])]: row.id,
              [lang.get("subscriptions", ["Subscription Name"])]: row.subscriptionName,
              [lang.get("subscriptions", ["Customer"])]: row.customer,
              [lang.get("subscriptions", ["Project"])]: row.project,
              [lang.get("subscriptions", ["Status"])]: row.status,
              [lang.get("subscriptions", ["Next Billing Cycle"])]: row.nextBillingCycle,
              [lang.get("subscriptions", ["Date Subscribed"])]: row.dateSubscribed,
            }));
            expobj.exportToCSV(csvData);
          }}
        >
          {lang.get("subscriptions", ["Export CSV"])}
        </Button>,
        <Button
          key="excel"
          onClick={() => {
            const excelData = subscriptionData.map((row) => ({
              [lang.get("subscriptions", ["ID"])]: row.id,
              [lang.get("subscriptions", ["Subscription Name"])]: row.subscriptionName,
              [lang.get("subscriptions", ["Customer"])]: row.customer,
              [lang.get("subscriptions", ["Project"])]: row.project,
              [lang.get("subscriptions", ["Status"])]: row.status,
              [lang.get("subscriptions", ["Next Billing Cycle"])]: row.nextBillingCycle,
              [lang.get("subscriptions", ["Date Subscribed"])]: row.dateSubscribed,
            }));
            expobj.exportToExcel(excelData);
          }}
        >
          {lang.get("subscriptions", ["Export Excel"])}
        </Button>,
        <Button
          key="pdf"
          onClick={() => {
            expobj.exportToPDF({
              head: [
                [
                  lang.get("subscriptions", ["ID"]),
                  lang.get("subscriptions", ["Subscription Name"]),
                  lang.get("subscriptions", ["Customer"]),
                  lang.get("subscriptions", ["Project"]),
                  lang.get("subscriptions", ["Status"]),
                  lang.get("subscriptions", ["Next Billing Cycle"]),
                  lang.get("subscriptions", ["Date Subscribed"]),
                ],
              ],
              body: subscriptionData.map((row) => [
                row.id,
                row.subscriptionName,
                row.customer,
                row.project,
                row.status,
                row.nextBillingCycle,
                row.dateSubscribed,
              ]),
            });
          }}
        >
          {lang.get("subscriptions", ["Export PDF"])}
        </Button>,
      ]}
    />
  );
};

export default SubscriptionTable;
