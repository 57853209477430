import React, { useState, useRef } from "react";
import { Tag, Input, Button, Space } from "antd";
import {
  SearchOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { ActionType, ProColumns, ProTable } from "@ant-design/pro-components";
import lang from "../../../lang";
import Export from "../../../common/components/Export";

const VendorTable = () => {
  const [pageSize, setPageSize] = useState(15);
  const [searchAttributes, setSearchAttributes] = useState<
    { key: string; value: string }[]
  >([]);
  const [allSearch, setAllSearch] = useState<string>("");
  const actionRef = useRef<ActionType>();
  const expobj = new Export(
    lang.get("vendors", ["Vendors"]) + ", " + new Date().toDateString()
  );

  const handleSearch = (field: string, value: string) => {
    setAllSearch("");
    const existingAttribute = searchAttributes.find(
      (attr) => attr.key === field
    );

    if (existingAttribute) {
      setSearchAttributes(
        searchAttributes.map((attr) =>
          attr.key === field ? { ...attr, value } : attr
        )
      );
    } else {
      setSearchAttributes([
        ...searchAttributes,
        {
          key: field,
          value,
        },
      ]);
    }
  };

  const searchCols = (field: string, label: string) => {
    return {
      filterDropdown: (props: any) => {
        const { close } = props;

        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              placeholder={lang.get("vendors", [`Search ${label}`])}
              onChange={(e) => handleSearch(field, e.target.value)}
              value={searchAttributes.find((item) => item.key === field)?.value}
              style={{ marginBottom: 8, display: "block" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  close();
                  actionRef.current?.reload();
                }
              }}
            />
            <Space>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="small"
                onClick={() => actionRef.current?.reload()}
                style={{ width: 90 }}
              >
                {lang.get("vendors", ["Search"])}
              </Button>
              <Button
                onClick={() => close()}
                size="small"
                style={{ width: 90 }}
              >
                {lang.get("vendors", ["Close"])}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
    };
  };

  interface Vendor {
    id: string;
    company: string;
    primaryContact: string;
    primaryEmail: string;
    phone: string;
    vendorCategory: string;
    active: boolean;
    dateCreated: string;
  }

  const columns: ProColumns<Vendor>[] = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: 50,
      align: "center",
    },
    {
      title: lang.get("vendors", ["Company"]),
      dataIndex: "company",
      key: "company",
      sorter: true,
      ...searchCols("company", lang.get("vendors", ["Company"])),
    },
    {
      title: lang.get("vendors", ["Primary Contact"]),
      dataIndex: "primaryContact",
      key: "primaryContact",
      sorter: true,
      ...searchCols("primaryContact", lang.get("vendors", ["Primary Contact"])),
    },
    {
      title: lang.get("vendors", ["Primary Email"]),
      dataIndex: "primaryEmail",
      key: "primaryEmail",
      sorter: true,
      ...searchCols("primaryEmail", lang.get("vendors", ["Primary Email"])),
    },
    {
      title: lang.get("vendors", ["Phone"]),
      dataIndex: "phone",
      key: "phone",
      sorter: true,
      ...searchCols("phone", lang.get("vendors", ["Phone"])),
    },
    {
      title: lang.get("vendors", ["Vendor Category"]),
      dataIndex: "vendorCategory",
      key: "vendorCategory",
      sorter: true,
      ...searchCols("vendorCategory", lang.get("vendors", ["Vendor Category"])),
    },
    {
      title: lang.get("vendors", ["Active"]),
      dataIndex: "active",
      key: "active",
      sorter: true,
      render: (_, record: Vendor) => (
        <Tag color={record.active ? "green" : "red"}>
          {record.active ? (
            <>
              <CheckCircleOutlined /> {lang.get("vendors", ["Active"])}
            </>
          ) : (
            <>
              <CloseCircleOutlined /> {lang.get("vendors", ["Inactive"])}
            </>
          )}
        </Tag>
      ),
    },
    {
      title: lang.get("vendors", ["Date Created"]),
      dataIndex: "dateCreated",
      key: "dateCreated",
      sorter: true,
    },
  ];

  const data: Vendor[] = [
    {
      id: "1",
      company: "Acme Corp",
      primaryContact: "John Doe",
      primaryEmail: "john.doe@acme.com",
      phone: "123-456-7890",
      vendorCategory: "Electronics",
      active: true,
      dateCreated: "2025-01-01",
    },
    {
      id: "2",
      company: "Tech Solutions",
      primaryContact: "Jane Smith",
      primaryEmail: "jane.smith@techsolutions.com",
      phone: "987-654-3210",
      vendorCategory: "Software",
      active: false,
      dateCreated: "2025-01-03",
    },
    // Add more vendor data here as needed
  ];

  return (
    <ProTable
      columns={columns}
      actionRef={actionRef}
      dataSource={data}
      rowKey="id"
      pagination={{
        defaultCurrent: 1,
        pageSize: pageSize,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50],
        onShowSizeChange: (_, size) => setPageSize(size),
      }}
      search={false}
      scroll={{ x: "max-content" }}
      toolBarRender={() => [
        <Input
          key="global_search"
          placeholder={lang.get("vendors", ["Global Search"])}
          value={allSearch}
          onChange={(e) => {
            setAllSearch(e.target.value);
            setSearchAttributes([]);
            actionRef?.current?.reload();
          }}
          style={{ width: 200 }}
        />,
        <Button
          key="csv"
          onClick={() => {
            const csvData = data.map((row) => ({
              [lang.get("vendors", ["ID"])]: row.id,
              [lang.get("vendors", ["Company"])]: row.company,
              [lang.get("vendors", ["Primary Contact"])]: row.primaryContact,
              [lang.get("vendors", ["Primary Email"])]: row.primaryEmail,
              [lang.get("vendors", ["Phone"])]: row.phone,
              [lang.get("vendors", ["Vendor Category"])]: row.vendorCategory,
              [lang.get("vendors", ["Active"])]: row.active ? "Yes" : "No",
              [lang.get("vendors", ["Date Created"])]: row.dateCreated,
            }));
            expobj.exportToCSV(csvData);
          }}
        >
          {lang.get("vendors", ["Export CSV"])}
        </Button>,
      ]}
    />
  );
};

export default VendorTable;
