import React, { useState } from "react";
import { Button, Badge, Space } from "antd";
import {
  BellOutlined,
  AppstoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import ThemeToggler from "../common/base/ThemeToggleButton";
import ProfileDropdownMenu from "./ProfileDropdownMenu";

import StartTimerComponent from "../modules/project/project-list/components/tabs/timesheet/Timer";
import TimesheetModal from "../modules/project/project-list/components/tabs/timesheet/TimesheetModal";

import lang from "../lang";

interface NavbarActionsProps {
  theme: "light" | "dark";
  toggleTheme: () => void;
}

const NavbarActions: React.FC<NavbarActionsProps> = ({
  theme,
  toggleTheme,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <Space size="middle" className="navbar-actions">
      {/* <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => setModalVisible(true)}
      >
        {lang.get("project", ["Timesheet"])}
      </Button> */}
      {/* <TimesheetModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        project={null}
      />
      <StartTimerComponent /> */}
      {/* Theme Toggle Button */}
      <ThemeToggler />

      <Badge>
        <Button shape="circle" icon={<BellOutlined />} />
      </Badge>

      {/* Nine Dot Menu */}
      <Button shape="circle" icon={<AppstoreOutlined />} />

      {/* User Avatar */}
      <ProfileDropdownMenu />
    </Space>
  );
};

export default NavbarActions;
