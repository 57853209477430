// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken } from "firebase/messaging";

import apiRequest from "./common/utils/apiRequest";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD5Q2Wq3pt7SGkuU2hVdkRKazjEUVHJQr8",
  authDomain: "erp-notifications-7111d.firebaseapp.com",
  projectId: "erp-notifications-7111d",
  storageBucket: "erp-notifications-7111d.firebasestorage.app",
  messagingSenderId: "175281245564",
  appId: "1:175281245564:web:84abdda815d190508f28f9",
  measurementId: "G-3BXJ05NDSE",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const messaging = getMessaging(app);

const getFCMToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        "BFiUglQDe9nu_gdOEBag3wl2Fz_qBnJVsY1cyvMzUF8FNtGPhRW45ZEE0RAco6KKCHcXXc1CCU-CGW93SDohVQs", // VAPID key from Firebase Console
    });

    if (currentToken) {
      return currentToken;
    }
  } catch (error) {}
};

const requestPermission = async () => {
  try {
    await Notification.requestPermission();

    const token = await getFCMToken();
    const API_REQUEST = new apiRequest("save-fcm-token", true);
    API_REQUEST.send(
      {
        device_token: token,
      },
      (data: { status: string }) => {
        console.log(data);
      }
    );
  } catch (error) {
    console.error("Permission denied", error);
  }
};

export default requestPermission;
