import React, { useState, ChangeEvent, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Tabs,
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Typography,
  message,
} from "antd";
import { InfoCircleOutlined, SettingOutlined } from "@ant-design/icons";

import { Editor } from "@tinymce/tinymce-react";
import ProjectSettingBody from "./ProjectSettingBody";
import { ProFormUploadDragger } from "@ant-design/pro-components";
import lang from "../../../lang";

import { DBProject, SelectType } from "../types/types";
import apiRequest from "../../../common/utils/apiRequest";
import UploadFile from "../../../common/utils/upload";
import coreFunctions from "../../../common/utils/coreFunctions";
import { useNavigate } from "react-router-dom";

const { TabPane } = Tabs;
const { Title } = Typography;

const ProjectAddBody: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("projectInfo");

  const [payloadData, setPayloadData] = useState<DBProject>({
    name: "",
    customer: "",
    billing_type: "Fixed",
    status: {
      name: "",
      id: "",
      type: "",
      icon: "",
    },
    total_rate: "",
    estimated_hours: "",
    members: [],
    start_date: "",
    deadline: "",
    tags: [],
    description: "",
    priority: "",
    managers: [],
    department: [],
    notifiers: [],
    attachments: [],
    progress: 0,

    number: "",
    id: "",
  });

  const [attachments, setAttachments] = useState<any[]>([]);
  const [saving, setSaving] = useState(false);
  const [customerList, setCustomerList] = useState<SelectType[]>([]);
  const [billingTypeList, setBillingTypeList] = useState<SelectType[]>([]);
  const [statusList, setStatusList] = useState<SelectType[]>([]);
  const [priorityList, setPriorityList] = useState<SelectType[]>([]);
  const [tagList, setTagList] = useState<SelectType[]>([]);
  const [departmentList, setDepartmentList] = useState<SelectType[]>([]);
  const [projectManagerList, setProjectManagerList] = useState<SelectType[]>(
    []
  );
  const [membersList, setMembersList] = useState<SelectType[]>([]);

  const CORE_FUNCTIONS = new coreFunctions();

  const navigate = useNavigate();

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPayloadData({
      ...payloadData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSelectChange = (value: string | string[], field: string) => {
    setPayloadData({
      ...payloadData,
      [field]: value,
    });
  };

  const handleSave = async () => {
    setSaving(true);
    if (attachments.length > 0 && payloadData.attachments.length < 1) {
      const FILE_INSTANCE = new UploadFile();

      for (let i = 0; i < attachments.length; i++) {
        await FILE_INSTANCE.initUpload(attachments[i]);

        const file_name = FILE_INSTANCE.getFileName();
        if (file_name !== null) {
          payloadData.attachments.push(file_name);
        }
      }

      setPayloadData(payloadData);
    }

    message.loading(lang.get("project", ["Adding project"]), 1).then(() => {
      const API_REQUEST = new apiRequest("project/add", true, true);
      API_REQUEST.send(
        payloadData,
        (data: { status: string; message: string }) => {
          if (data.status === "success") {
            message.success(
              lang.get("project", ["Project added successfully."]),
              2
            );

            navigate("/project-list");
          } else {
            message.error(lang.get("project", [data.message]), 2);
          }
        }
      );
    });
  };

  useEffect(() => {
    const BILLING_TYPE_REQUEST = new apiRequest(
      "projects/billing_types/get/all",
      true
    );
    BILLING_TYPE_REQUEST.send(
      {},
      (data: { data: SelectType[]; status: string }) => {
        if (data.status === "success") {
          setBillingTypeList(data.data);
        }
      }
    );

    const PROJECT_STATUS_REQUEST = new apiRequest(
      "projects/status/get/all",
      true
    );
    PROJECT_STATUS_REQUEST.send(
      {},
      (data: { data: SelectType[]; status: string }) => {
        if (data.status === "success") {
          setStatusList(data.data);
        }
      }
    );

    const PRIORITY_REQUEST = new apiRequest(
      "projects/priorities/get/all",
      true
    );
    PRIORITY_REQUEST.send(
      {},
      (data: { data: SelectType[]; status: string }) => {
        if (data.status === "success") {
          setPriorityList(data.data);
        }
      }
    );

    const DEPARTMENT_REQUEST = new apiRequest("departments/get/all", true);
    DEPARTMENT_REQUEST.send(
      {},
      (data: { data: SelectType[]; status: string }) => {
        if (data.status === "success") {
          setDepartmentList(data.data);
        }
      }
    );
  }, []);

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      {/* <PageBreadcrumb items={defaultBreadcrumbItems} /> */}
      <Title level={2} className="mb-4">
        {lang.get("project", ["Add Project"])}
      </Title>
      <Card>
        <Tabs
          activeKey={activeTab}
          onChange={(key) => setActiveTab(key)}
          tabBarStyle={{ marginBottom: 24, backgroundColor: "#f5f7fa" }}
        >
          {/* Project Info Tab */}
          <TabPane
            tab={
              <div style={{ paddingLeft: "10px" }}>
                <InfoCircleOutlined /> {lang.get("project", ["Project Info"])}
              </div>
            }
            key="projectInfo"
          >
            <Form layout="vertical">
              <Row gutter={[16, 0]}>
                {/* Project Name */}
                <Col xs={24} sm={8}>
                  <Form.Item label="Project Name" name="Project_name">
                    <Input
                      id="name"
                      placeholder="Enter Project Name"
                      onChange={handleInputChange}
                      size="large"
                    />
                  </Form.Item>
                </Col>

                {/* Customer */}
                <Col xs={24} sm={8}>
                  <Form.Item label="Customer" name="customer">
                    <Select
                      placeholder={lang.get("project", ["Type Customer Name"])}
                      showSearch
                      onChange={(value) =>
                        handleSelectChange(value, "customer")
                      }
                      onInputKeyDown={(e) =>
                        CORE_FUNCTIONS.searchOptions(
                          e.currentTarget.value,
                          "clients",
                          "clients",
                          setCustomerList
                        )
                      }
                      size="large"
                    >
                      {customerList.map((customer) => (
                        <Select.Option key={customer.id} value={customer.id}>
                          {customer.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                {/* Billing Type */}
                <Col xs={24} sm={8}>
                  <Form.Item label="Billing Type" name="BillingType">
                    <Select
                      placeholder="Select Billing Type"
                      showSearch
                      value={payloadData.billing_type}
                      onChange={(value) =>
                        handleSelectChange(value, "billing_type")
                      }
                      size="large"
                    >
                      {billingTypeList.map((billingType) => (
                        <Select.Option
                          key={billingType.id}
                          value={billingType.id}
                        >
                          {billingType.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                {/* Status */}
                <Col xs={24} sm={8}>
                  <Form.Item label="Status" name="status">
                    <Select
                      placeholder="Select Status"
                      showSearch
                      onChange={(value) => handleSelectChange(value, "status")}
                      size="large"
                    >
                      {statusList.map((status) => (
                        <Select.Option key={status.id} value={status.id}>
                          {status.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                {/* Priority */}
                <Col xs={24} sm={8}>
                  <Form.Item label="Priority" name="priority">
                    <Select
                      placeholder="Select Priority"
                      showSearch
                      onChange={(value) =>
                        handleSelectChange(value, "priority")
                      }
                      size="large"
                    >
                      {priorityList.map((priority) => (
                        <Select.Option key={priority.id} value={priority.id}>
                          {priority.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                {/* Project Manager */}
                <Col xs={24} sm={8}>
                  <Form.Item label="Project Manager" name="managers">
                    <Select
                      mode="multiple"
                      showSearch
                      placeholder="Select Project Managers"
                      onChange={(value) =>
                        handleSelectChange(value, "managers")
                      }
                      onInputKeyDown={(e) =>
                        CORE_FUNCTIONS.searchOptions(
                          e.currentTarget.value,
                          "hr",
                          "records",
                          setProjectManagerList
                        )
                      }
                      size="large"
                    >
                      {projectManagerList.map((manager) => (
                        <Select.Option key={manager.id} value={manager.id}>
                          {manager.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                {/* Department */}
                <Col xs={24} sm={8}>
                  <Form.Item label="Department" name="department">
                    <Select
                      mode="multiple"
                      placeholder="Select Department"
                      onChange={(value) =>
                        handleSelectChange(value, "department")
                      }
                      size="large"
                      showSearch
                    >
                      {departmentList.map((department) => (
                        <Select.Option
                          key={department.id}
                          value={department.id}
                        >
                          {department.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                {/* Total Rate */}
                <Col xs={24} sm={8}>
                  <Form.Item label="Total Rate" name="total_rate">
                    <Input
                      placeholder="Enter Total Rate"
                      id="total_rate"
                      onChange={handleInputChange}
                      size="large"
                    />
                  </Form.Item>
                </Col>

                {/* Estimated Hours */}
                <Col xs={24} sm={8}>
                  <Form.Item label="Estimated Hours" name="estimated_hours">
                    <Input
                      placeholder="Enter Estimated Hours"
                      id="estimated_hours"
                      onChange={handleInputChange}
                      size="large"
                    />
                  </Form.Item>
                </Col>

                {/* Start Date */}
                <Col xs={24} sm={8}>
                  <Form.Item label="Start Date" name="start_date">
                    <DatePicker
                      onChange={(date) =>
                        handleSelectChange(date?.toString() || "", "start_date")
                      }
                      style={{ width: "100%" }}
                      size="large"
                    />
                  </Form.Item>
                </Col>

                {/* Deadline */}
                <Col xs={24} sm={8}>
                  <Form.Item label="Deadline" name="deadline">
                    <DatePicker
                      onChange={(date) =>
                        handleSelectChange(date?.toString() || "", "deadline")
                      }
                      style={{ width: "100%" }}
                      size="large"
                    />
                  </Form.Item>
                </Col>

                {/* Members */}
                <Col xs={24} sm={8}>
                  <Form.Item label="Team Members" name="members">
                    <Select
                      mode="multiple"
                      placeholder="Select Members"
                      onChange={(value) => handleSelectChange(value, "members")}
                      size="large"
                      showSearch
                      onInputKeyDown={(e) =>
                        CORE_FUNCTIONS.searchOptions(
                          e.currentTarget.value,
                          "hr",
                          "records",
                          setMembersList,
                          { departments: payloadData.department }
                        )
                      }
                    >
                      {membersList.map((member) => (
                        <Select.Option key={member.id} value={member.id}>
                          {member.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                {/* Tags */}
                <Col xs={24}>
                  <Form.Item label="Tags" name="tags">
                    <Select
                      showSearch
                      mode="multiple"
                      placeholder="Add Tags"
                      onChange={(value) => handleSelectChange(value, "tags")}
                      size="large"
                      onInputKeyDown={(e) =>
                        CORE_FUNCTIONS.searchOptions(
                          e.currentTarget.value,
                          "global",
                          "tags",
                          setTagList
                        )
                      }
                    >
                      {tagList.map((tag) => (
                        <Select.Option key={tag.id} value={tag.id}>
                          {tag.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                {/* Description */}
                <Col xs={24}>
                  <Form.Item label="Description" name="description">
                    <Editor />
                  </Form.Item>
                </Col>

                {/* Attachments */}
                <Col xs={24}>
                  <ProFormUploadDragger
                    onChange={(info) => {
                      const newFile = info.file.originFileObj;

                      if (
                        !attachments.some((file) => file.name === newFile?.name)
                      ) {
                        setAttachments([...attachments, newFile]);
                      }
                    }}
                    title={lang.get("project", [
                      "Click or drag files into this area to upload",
                    ])}
                    description={lang.get("project", [
                      "Support single or batch upload",
                    ])}
                    accept="image/*"
                    name="drag-pic"
                  />
                </Col>
              </Row>
            </Form>
          </TabPane>

          {/* Project Settings Tab */}
          <TabPane
            tab={
              <>
                <SettingOutlined /> {lang.get("project", ["Project Settings"])}
              </>
            }
            key="projectSettings"
          >
            <ProjectSettingBody />
          </TabPane>
        </Tabs>

        <Row justify="end" gutter={16} className="mt-4">
          <Col>
            <Button type="primary" loading={saving} onClick={handleSave}>
              {lang.get("project", ["Create Project"])}
            </Button>
          </Col>
          <Col>
            <Button type="default" loading={saving} onClick={handleSave}>
              {lang.get("project", ["Save and Create Another"])}
            </Button>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ProjectAddBody;
