import React, { useState } from "react";
import {
  DashboardOutlined,
  CalendarOutlined,
  ProjectOutlined,
  FileOutlined,
  UserOutlined,
  TableOutlined,
  DollarOutlined,
  ShoppingCartOutlined,
  AppstoreOutlined,
  PartitionOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Menu, Button, Layout, Drawer } from "antd";
import type { MenuProps } from "antd";
import "./css/NavbarVertical.css";
import { useNavigate } from "react-router-dom";
const { Sider } = Layout;

interface NavbarVerticalProps {
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

const NavbarVertical: React.FC<NavbarVerticalProps> = ({
  collapsed,
  setCollapsed,
}) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false); // Drawer visibility

  const toggleCollapse = () => {
    setCollapsed((prev) => !prev);
  };
  const navigate = useNavigate();

  const onClick: MenuProps["onClick"] = (e) => {
    if (e.key === "settings") {
      setIsDrawerVisible(true);
    } else {
      navigate(`/${e.key}`);
    }
  };

  const closeDrawer = () => {
    setIsDrawerVisible(false);
  };

  const items: MenuProps["items"] = [
    {
      key: "main",
      type: "group",
      label: "MAIN",
      children: [
        {
          key: "dashboard",
          icon: <DashboardOutlined />,
          label: "Dashboard",
        },
        // {
        //   key: "calendar",
        //   icon: <CalendarOutlined />,
        //   label: "Calendar",
        // },
      ],
    },

    {
      type: "divider",
    },
    {
      key: "sections",
      type: "group",
      label: "SECTIONS",
      children: [
        {
          key: "projects",
          icon: <ProjectOutlined />,
          label: "Projects",
          children: [
            // { key: "1", label: "Dashboard" },
            { key: "project-list", label: "Project List" },
          ],
        },
        // {
        //   key: "files",
        //   icon: <FileOutlined />,
        //   label: "Files",
        // },
        {
          key: "customers",
          icon: <UserOutlined />,
          label: "Customers",
          children: [
            // { key: "1", label: "Dashboard" },
            { key: "customer-list", label: "Customer List" },
          ],
        },
        {
          key: "sales",
          icon: <TableOutlined />,
          label: "Sales",
          children: [
            // { key: "1", label: "Dashboard" },
            { key: "proposals", label: "Proposals" },
            { key: "estimates", label: "Estimates" },
            { key: "invoices", label: "Invoices" },
            { key: "performa-invoices", label: "Proforma Invoices" },
            { key: "credit-notes", label: "Credit Notes" },
            { key: "payments", label: "Payments" },
          ],
        },
        // {
        //   key: "accounting",
        //   icon: <DollarOutlined />,
        //   label: "Accounting",
        // },
        // {
        //   key: "expenses",
        //   icon: <DollarOutlined />,
        //   label: "Expenses",
        // },
        {
          key: "purchase",
          icon: <ShoppingCartOutlined />,
          label: "Purchase",
          children: [
            { key: "purchase/vendor", label: "Vendors" },
            { key: "purchase/purchase-request", label: "Purchase Request" },
            { key: "purchase/purchase-order", label: "Purchase Order" },
            { key: "purchase/order-returns", label: "Order Returns" },
          ],
        },
        {
          key: "inventory",
          icon: <AppstoreOutlined />,
          label: "Inventory",
          children: [
            // { key: "dashboard", label: "Dashboard" },
            { key: "items", label: "Items" },
            {
              key: "inventory-receiving-voucher",
              label: "Inventory Receiving Voucher",
            },
            {
              key: "inventory-delivery-voucher",
              label: "Inventory Delivery Voucher",
            },
            { key: "packing-list", label: "Shipment" },
            { key: "return-orders", label: "Return Orders" },
            { key: "internal-delivery-note", label: "Stock Transfer" },
            { key: "stock-adjustment", label: "Stock Adjustment" },
            { key: "warehouse-management", label: "Warehouse Management" },
            { key: "reports", label: "Reports" },
          ],
        },
        // {
        //   key: "project_management",
        //   icon: <PartitionOutlined />,
        //   label: "Project management",
        // },
        {
          key: "tasks",
          icon: <ProjectOutlined />,
          label: "Tasks",
        },
        // {
        //   key: "support",
        //   icon: <QuestionCircleOutlined />,
        //   label: "Support",
        // },
        // {
        //   key: "agreements",
        //   icon: <SettingOutlined />,
        //   label: "Agreements",
        // },
        // {
        //   key: "settings",
        //   icon: <SettingOutlined />,
        //   label: "Settings",
        // },
      ],
    },
  ];

  const drawerMenuItems: MenuProps["items"] = [
    { key: "staff", label: "Staff" },
    {
      key: "user-management",
      label: "User Management",
      icon: <UserOutlined />,
      children: [
        { key: "users", label: "Users" },
        { key: "roles", label: "Roles" },
        { key: "departments", label: "Departments" },
      ],
    },

    { key: "customers", label: "Customers" },
    { key: "support", label: "Support" },
    { key: "leads", label: "Leads" },
    { key: "finance", label: "Finance" },
    { key: "contracts", label: "Contracts" },
    {
      key: "estimate-request",
      label: "Estimate Request",
    },
    { key: "modules", label: "Modules" },
    { key: "email-templates", label: "Email Templates" },
    { key: "custom-fields", label: "Custom Fields" },
    { key: "gdpr", label: "GDPR" },
    { key: "roles", label: "Roles" },
    { key: "menu-setup", label: "Menu Setup" },
    { key: "settings", label: "Settings" },
    { key: "help", label: "Help" },
  ];

  return (
    <>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={toggleCollapse}
        trigger={null}
        width={250}
        className="navbar-sider"
      >
        <Menu
          onClick={onClick}
          defaultSelectedKeys={["dashboard"]}
          defaultOpenKeys={["main"]}
          mode="inline"
          inlineCollapsed={collapsed}
          items={items}
          className="navbar-menu"
        />
        <div className="navbar-footer">
          <Button
            type="text"
            onClick={toggleCollapse}
            className="navbar-toggle"
          >
            {collapsed ? <MenuUnfoldOutlined /> : <MenuUnfoldOutlined />}
          </Button>
        </div>
      </Sider>

      <Drawer
        title="Setup"
        placement="left"
        onClose={closeDrawer}
        open={isDrawerVisible}
        width={250}
        closable
        className="navbar-sider"
        height={"100vh"}
      >
        <Menu
          mode="inline"
          items={drawerMenuItems}
          className="navbar-menu"
          onClick={(e) => {
            navigate(`/${e.key}`);
            closeDrawer();
          }}
          style={{ borderInlineEnd: "none" }}
        />
      </Drawer>
    </>
  );
};

export default NavbarVertical;
