import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { ProTable } from "@ant-design/pro-components";
import { Button, Input, InputRef, Space, Tag } from "antd";
import type { ActionType, ProColumns } from "@ant-design/pro-components";
import { ReactNode, useRef, useState } from "react";
import lang from "../../../lang";
import Export from "../../../common/components/Export";

interface Role {
  id: string;
  name: string;
  totalUsers: number;
}

const roles: Role[] = [
  { id: "1", name: "Employee", totalUsers: 7 },
  { id: "2", name: "jb decorator", totalUsers: 7 },
  { id: "3", name: "Sales Department", totalUsers: 1 },
];

const RoleTable: React.FC = () => {
  const actionRef = useRef<ActionType>();
  const [allSearch, setAllSearch] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(10);
  const searchInput = useRef<InputRef>(null);
  const expobj = new Export("roles, " + new Date().toDateString());

  const [searchAttributes, setSearchAttributes] = useState<
    { key: string; value: string }[]
  >([]);

  const handleSearch = (field: string, value: string) => {
    setAllSearch("");
    const existingAttribute = searchAttributes.find(
      (attr) => attr.key === field
    );

    if (existingAttribute) {
      setSearchAttributes(
        searchAttributes.map((attr) =>
          attr.key === field ? { ...attr, value } : attr
        )
      );
    } else {
      setSearchAttributes([
        ...searchAttributes,
        {
          key: field,
          value,
        },
      ]);
    }
  };

  const searchCols = (field: string, label: string) => {
    return {
      filterDropdown: (props: any) => {
        const { close } = props;

        setTimeout(() => {
          if (searchInput.current) {
            searchInput.current.focus();
          }
        }, 100);

        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={lang.get("sales", [`Search ${label}`])}
              onChange={(e) => handleSearch(field, e.target.value)}
              value={
                searchAttributes.filter((item) => item.key === field)[0]?.value
              }
              style={{ marginBottom: 8, display: "block" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  close();
                  if (actionRef.current) {
                    actionRef.current?.reset?.();
                    actionRef.current.reload();
                  }
                }
              }}
            />
            <Space>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="small"
                onClick={() => {
                  actionRef.current?.reset?.();
                  actionRef.current?.reload();
                }}
                style={{ width: 90 }}
              >
                {lang.get("sales", ["Search"])}
              </Button>
              <Button
                onClick={() => close()}
                size="small"
                style={{ width: 90 }}
              >
                {lang.get("sales", ["Close"])}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
    };
  };

  const columns: ProColumns<Role>[] = [
    {
      title: "Role Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (name: ReactNode) => <span>{name}</span>,
      align: "left",
      width: 250,
      ...searchCols("name", "Role Name"),
    },
    {
      title: "Total Users",
      dataIndex: "totalUsers",
      key: "totalUsers",
      sorter: true,
      render: (totalUsers: ReactNode) => <Tag color="blue">{totalUsers}</Tag>,
      width: 250,
      ...searchCols("totalUsers", "Total Users"),
    },
    {
      title: "Options",
      key: "actions",
      width: 250,
      render: (_, record) => (
        <Space size="middle">
          <Button
            icon={<EditOutlined />}
            onClick={() => console.log(`Editing ${record.name}`)}
          />
          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={() => console.log(`Deleting ${record.name}`)}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <ProTable
        headerTitle={lang.get("userManagement", ["Roles"])}
        columns={columns}
        dataSource={roles}
        actionRef={actionRef}
        rowKey="id"
        pagination={{
          defaultCurrent: 1,
          pageSize: pageSize,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50],
          showTotal: (total, range) =>
            lang.get("sales", [
              `Showing ${range[0]}-${range[1]} of ${total} items`,
            ]),
          onShowSizeChange: (current, size) => {
            setPageSize(size);
          },
        }}
        search={false}
        scroll={{ x: 800 }}
        cardBordered
        toolBarRender={() => [
          <Input
            style={{ width: "auto" }}
            key="all_search"
            allowClear
            placeholder={lang.get("userManagement", ["Global Search"])}
            value={allSearch}
            onChange={(e) => {
              setAllSearch(e.target.value);
              setSearchAttributes([]);
              actionRef?.current?.reload();
            }}
          />,
          <Button
            key="csv"
            onClick={() => {
              const csvData = roles.map((row) => ({
                [lang.get("userManagement", ["Role Name"])]: row.name,
                [lang.get("userManagement", ["Total Users"])]: row.totalUsers,
              }));
              expobj.exportToCSV(csvData);
            }}
          >
            {lang.get("userManagement", ["Export CSV"])}
          </Button>,
          <Button
            key="excel"
            onClick={() => {
              const excelData = roles.map((row) => ({
                [lang.get("userManagement", ["Role Name"])]: row.name,
                [lang.get("userManagement", ["Total Users"])]: row.totalUsers,
              }));
              expobj.exportToExcel(excelData);
            }}
          >
            {lang.get("sales", ["Export Excel"])}
          </Button>,
          <Button
            key="pdf"
            onClick={() => {
              expobj.exportToPDF({
                head: [
                  [
                    lang.get("userManagement", ["Role Name"]),
                    lang.get("userManagement", ["Total Users"]),
                  ],
                ],
                body: roles.map((row) => [
                  row.name,
                  row.totalUsers,
                ]),
              });
            }}
          >
            {lang.get("userManagement", ["Export PDF"])}
          </Button>,
        ]}
      />
    </div>
  );
};

export default RoleTable;
