import { StatisticCard } from "@ant-design/pro-components";
import RcResizeObserver from "rc-resize-observer";
import { useEffect, useState } from "react";
import { DBCustomer, StatusByCount } from "../../types/types";
import apiRequest from "../../../../common/utils/apiRequest";
import { Skeleton } from "antd";

const ProjectStatistic = ({ client }: { client: DBCustomer | null }) => {
  const [responsive, setResponsive] = useState(false);
  const [countByStatus, setCountByStatus] = useState<StatusByCount[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      const API_REQUEST = new apiRequest(
        "projects/analytics/count/status",
        true
      );

      await API_REQUEST.send(
        {
          client: client?.id,
        },
        (data: { data: StatusByCount[]; status: string }) => {
          if (data.status === "success") {
            setCountByStatus(data.data);
          }
        }
      );

      setLoading(false);
    };

    loadData();
  }, []);

  return loading ? (
    <Skeleton active />
  ) : (
    <RcResizeObserver
      key="resize-observer"
      onResize={(offset) => {
        setResponsive(offset.width < 596);
      }}
    >
      <StatisticCard.Group
        direction={responsive ? "column" : "row"}
        style={{
          paddingTop: "16px",
          paddingBottom: "16px",
          overflowX: responsive ? "auto" : "hidden",
          overflowY: "hidden",
          boxShadow: "4px  4px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        {countByStatus.map((status) => (
          <StatisticCard
            statistic={{
              title: status.name,
              value: status.count,
              valueStyle: { color: status.color },
            }}
            chart={
              <img
                src="https://gw.alipayobjects.com/zos/alicdn/6YR18tCxJ/huanlv.svg"
                alt="Billable"
                width="80%"
              />
            }
            chartPlacement="left"
          />
        ))}
      </StatisticCard.Group>
    </RcResizeObserver>
  );
};

export default ProjectStatistic;
