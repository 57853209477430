import React, { useState } from "react";
import { ProForm, ProCard } from "@ant-design/pro-components";
import {
  Row,
  Col,
  Typography,
  Divider,
  Button,
  Space,
  Input,
  Form,
  Select,
  Upload,
} from "antd";
import { SyncOutlined, UploadOutlined } from "@ant-design/icons";

const { Title } = Typography;
const { Option } = Select;

const AddUser: React.FC = () => {
  const [passwordVisible,setPasswordVisible] = useState(false);

  const handleRegeneratePassword = () => {
    const newPassword = Math.random().toString(36).slice(-8);
    console.log("New Password Generated:", newPassword);
  };

  const handleSave = (values: any) => {
    console.log("Form Values:", values);
  };

  return (
    <div
      style={{
        padding: "30px",
        backgroundColor: "#f0f2f5",
        minHeight: "100vh",
      }}
    >
      <Title level={3} style={{ marginBottom: "20px", color: "#595959" }}>
        Add New User
      </Title>
      <Row justify="start">
        <Col xs={24} sm={24} md={20} lg={16}>
          <ProCard
            style={{
              borderRadius: "8px",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
            }}
          >
            <ProForm layout="vertical" onFinish={handleSave} submitter={false}>
              {/* Profile Image */}
              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <Form.Item
                    name="profileImage"
                    label={
                      <span style={{ fontSize: "14px" }}>Profile Image</span>
                    }
                  >
                    <Upload
                      maxCount={1}
                      listType="picture"
                      beforeUpload={() => false}
                    >
                      <Button icon={<UploadOutlined />}>
                        Upload Profile Image
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>

              {/* First Name and Last Name */}
              <Row gutter={[16, 16]}>
                <Col xs={24} md={12} lg={12}>
                  <Form.Item
                    name="firstName"
                    label={<span style={{ fontSize: "14px" }}>First Name</span>}
                    rules={[
                      { required: true, message: "First Name is required!" },
                    ]}
                  >
                    <Input placeholder="Enter First Name" size="large" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <Form.Item
                    name="lastName"
                    label={<span style={{ fontSize: "14px" }}>Last Name</span>}
                    rules={[
                      { required: true, message: "Last Name is required!" },
                    ]}
                  >
                    <Input placeholder="Enter Last Name" size="large" />
                  </Form.Item>
                </Col>
              </Row>

              {/* Email */}
              <Row gutter={[16, 16]}>
                <Col xs={24} md={12} lg={12}>
                  <Form.Item
                    name="email"
                    label={<span style={{ fontSize: "14px" }}>Email</span>}
                    rules={[
                      { required: true, message: "Email is required!" },
                      { type: "email", message: "Enter a valid email!" },
                    ]}
                  >
                    <Input placeholder="Enter Email" size="large" />
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={12}>
                  <Form.Item
                    name="department"
                    label={<span style={{ fontSize: "14px" }}>Department</span>}
                    rules={[
                      { required: true, message: "Department is required!" },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select Departments"
                      size="large"
                    >
                      <Option value="HR">HR</Option>
                      <Option value="Engineering">Engineering</Option>
                      <Option value="Sales">Sales</Option>
                      <Option value="Marketing">Marketing</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              {/* Department */}
              <Row gutter={[16, 16]}></Row>

              {/* Password */}
              <Row gutter={[16, 16]}>
                <Col xs={24} md={12} lg={12}>
                  <Form.Item
                    name="password"
                    label={<span style={{ fontSize: "14px" }}>Password</span>}
                    rules={[
                      { required: true, message: "Password is required!" },
                    ]}
                  >
                    <Input.Password
                      placeholder="Enter Password"
                      size="large"
                      visibilityToggle={{ visible: passwordVisible, onVisibleChange: setPasswordVisible }}
                      addonAfter={
                        <Space>
                          <Button
                            size="small"
                            onClick={handleRegeneratePassword}
                          >
                            <SyncOutlined />
                          </Button>
                        </Space>
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Divider style={{ margin: "20px 0", borderColor: "#e6e6e6" }} />

              {/* Buttons */}
              <Row justify="end" gutter={[16, 16]}>
                <Space>
                  <Button onClick={() => console.log("Cancel clicked")}>
                    Cancel
                  </Button>
                  <Button color="primary" variant="solid">
                    Save User
                  </Button>
                </Space>
              </Row>
            </ProForm>
          </ProCard>
        </Col>
      </Row>
    </div>
  );
};

export default AddUser;
