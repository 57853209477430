import { Breadcrumb, Typography, Button, Space, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import lang from "../../../lang";
import VendorTable from "./VendorTable";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

const Vendors: React.FC = () => {
  const navigate = useNavigate();
  const handleAddVendorClick = () => {
    navigate("/purchase/vendor/vendor-add");
  };

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      {/* Breadcrumb */}
      <Breadcrumb style={{ marginBottom: "16px" }} separator=">">
        <Breadcrumb.Item>{lang.get("breadcrumbs", ["Home"])}</Breadcrumb.Item>
        <Breadcrumb.Item>
          {lang.get("breadcrumbs", ["Vendors"])}
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {lang.get("breadcrumbs", ["Vendor List"])}
        </Breadcrumb.Item>
      </Breadcrumb>

      {/* Page Title */}
      <Title level={2} style={{ marginBottom: "16px" }}>
        {lang.get("vendors", ["Vendor List"])} (4)
      </Title>

      {/* Filter and Actions */}
      <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Space size="middle" wrap>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                handleAddVendorClick();
              }}
            >
              {lang.get("vendors", ["Add Vendor"])}
            </Button>
          </Space>
        </Col>
      </Row>

      {/* Table */}
      <VendorTable />
    </div>
  );
};

export default Vendors;
