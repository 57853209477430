import { Editor } from "@tinymce/tinymce-react";

const TinyMCEEditor = ({
  apiKey = "your-tinymce-api-key",
  value = "",
  onEditorChange = () => {},
  height = 200,
  menubar = false,
  plugins = ["lists link code"],
  toolbar = "undo redo | formatselect | bold italic underline | bullist numlist | link code",
}) => {
  return (
    <Editor
      apiKey={apiKey}
      value={value}
      onEditorChange={onEditorChange}
      init={{
        height,
        menubar,
        plugins,
        toolbar,
      }}
    />
  );
};

export default TinyMCEEditor;
