import { useState, ChangeEvent, useEffect } from "react";
import {
  Tabs,
  Card,
  Row,
  Col,
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  Space,
} from "antd";
import { Editor } from "@tinymce/tinymce-react";
import { ProFormUploadDragger } from "@ant-design/pro-components";
import {
  FileTextOutlined,
  BellOutlined,
  FileAddOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import Doc from "./Doc";
import Reminder from "./Reminder";
import Whiteboard from "./Whiteboard";
import lang from "../../../../../lang";

import { ProjectTask, DBSingleProject, SelectType } from "../../../types/types";
import apiRequest from "../../../../../common/utils/apiRequest";
import coreFunctions from "../../../../../common/utils/coreFunctions";
import UploadFile from "../../../../../common/utils/upload";

import { message } from "antd";

const AddTask = ({
  project,
  setShowTaskModal,
}: {
  project: DBSingleProject | null;
  setShowTaskModal: (e: boolean) => void;
}) => {
  const [activeTab, setActiveTab] = useState<string>("task");
  const [milestoneList, setMilestoneList] = useState<SelectType[]>([]);
  const [priorityList, setPriorityList] = useState<SelectType[]>([]);
  const [taskRepeatList, setTaskRepeatList] = useState<SelectType[]>([]);
  const [assigneesList, setAssigneesList] = useState<SelectType[]>([]);
  const [followersList, setFollowersList] = useState<SelectType[]>([]);
  const [statusList, setStatusList] = useState<SelectType[]>([]);
  const [tagList, setTagList] = useState<SelectType[]>([]);
  const [attachments, setAttachments] = useState<any[]>([]);
  const [saving, setSaving] = useState(false);

  const initialState = {
    id: "",
    project: project ? project.id : "",
    subject: "",
    milestone: "",
    start_date: "",
    due_date: "",
    estimate_hour: 0,
    priority: "",
    status: "",
    tags: [],
    assigners: [],
    followers: [],
    attachments: [],
    description: "",
    repeat: "",
  };

  const [taskData, setTaskData] = useState<ProjectTask>(initialState);

  const CORE_FUNCTIONS = new coreFunctions();

  const handleChange = (value: string | boolean | string[], field: string) => {
    setTaskData({
      ...taskData,
      [field]: value,
    });
  };

  const handleSubmit = async (create_new: boolean = false) => {
    setSaving(true);
    if (attachments.length > 0 && taskData.attachments.length < 1) {
      const FILE_INSTANCE = new UploadFile();

      for (let i = 0; i < attachments.length; i++) {
        await FILE_INSTANCE.initUpload(attachments[i]);

        const file_name = FILE_INSTANCE.getFileName();
        if (file_name !== null) {
          taskData.attachments.push(file_name);
        }
      }

      setTaskData(taskData);
    }

    message
      .loading(lang.get("project", ["Adding project task"]), 1)
      .then(() => {
        const API_REQUEST = new apiRequest("project/task/add", true);

        API_REQUEST.send(
          taskData,
          (response: { status: string; message: string }) => {
            if (response.status == "success") {
              message.success(response.message);
              setTaskData(initialState);
              if (!create_new) {
                setShowTaskModal(false);
              }

              setAttachments([]);
            } else {
              message.error(response.message);
            }
            setSaving(false);
          }
        );
      });
  };

  return (
    <Card style={{ padding: "16px", borderRadius: "8px" }}>
      <Tabs activeKey={activeTab} onChange={setActiveTab}>
        <Tabs.TabPane
          key="task"
          tab={
            <Space>
              <FileAddOutlined />
              Task
            </Space>
          }
        >
          <Row gutter={[16, 8]} style={{ marginTop: "16px" }}>
            <Col span={12} md={24} sm={24} xs={24}>
              <Form.Item>
                <Input
                  placeholder="Enter subject"
                  id="subject"
                  size="large"
                  onChange={(e) => {
                    handleChange(e.target.value, "subject");
                  }}
                  value={taskData.subject}
                />
              </Form.Item>
            </Col>
            <Col span={12} md={12} sm={12} xs={24}>
              <Form.Item>
                <DatePicker
                  placeholder="Select start date"
                  onChange={(value) => {
                    handleChange(
                      CORE_FUNCTIONS.formatISODateTime(value.toISOString()),
                      "start_date"
                    );
                  }}
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12} md={12} sm={12} xs={24}>
              <Form.Item>
                <DatePicker
                  placeholder="Select end date"
                  onChange={(value) => {
                    handleChange(
                      CORE_FUNCTIONS.formatISODateTime(value.toISOString()),
                      "due_date"
                    );
                  }}
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12} md={12} sm={12} xs={24}>
              <Form.Item>
                <Select
                  size="large"
                  showSearch
                  placeholder="Select milestone"
                  onChange={(value) => handleChange(value, "milestone")}
                  onInputKeyDown={(e) =>
                    CORE_FUNCTIONS.searchOptions(
                      e.currentTarget.value,
                      "projects",
                      "milestones",
                      setMilestoneList,
                      { project: project?.id }
                    )
                  }
                >
                  {milestoneList.map((milestone) => (
                    <Select.Option key={milestone.id} value={milestone.id}>
                      {milestone.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12} md={12} sm={12} xs={24}>
              <Form.Item>
                <Select
                  size="large"
                  showSearch
                  placeholder="Select priority"
                  onInputKeyDown={(e) =>
                    CORE_FUNCTIONS.searchOptions(
                      e.currentTarget.value,
                      "task",
                      "priorities",
                      setPriorityList
                    )
                  }
                  onChange={(value) => handleChange(value, "priority")}
                >
                  {priorityList.map((milestone) => (
                    <Select.Option key={milestone.id} value={milestone.id}>
                      {milestone.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12} md={12} sm={12} xs={24}>
              <Form.Item>
                <Select
                  size="large"
                  showSearch
                  placeholder="Repeat every"
                  onInputKeyDown={(e) =>
                    CORE_FUNCTIONS.searchOptions(
                      e.currentTarget.value,
                      "task",
                      "repeating_periods",
                      setTaskRepeatList
                    )
                  }
                  onChange={(value) => handleChange(value, "repeat")}
                >
                  {taskRepeatList.map((milestone) => (
                    <Select.Option key={milestone.id} value={milestone.id}>
                      {milestone.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12} md={12} sm={12} xs={24}>
              <Form.Item>
                <Select
                  size="large"
                  showSearch
                  placeholder="Select status"
                  onInputKeyDown={(e) =>
                    CORE_FUNCTIONS.searchOptions(
                      e.currentTarget.value,
                      "task",
                      "statuses",
                      setStatusList
                    )
                  }
                  onChange={(value) => handleChange(value, "status")}
                >
                  {statusList.map((milestone) => (
                    <Select.Option key={milestone.id} value={milestone.id}>
                      {milestone.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 8]}>
            <Col span={12} md={12} sm={12} xs={24}>
              <Form.Item>
                <Select
                  size="large"
                  showSearch
                  mode="multiple"
                  placeholder="Select assignees"
                  onChange={(value) => handleChange(value, "assignees")}
                  onInputKeyDown={(e) =>
                    CORE_FUNCTIONS.searchOptions(
                      e.currentTarget.value,
                      "hr",
                      "records",
                      setAssigneesList
                    )
                  }
                >
                  {assigneesList.map((assignee) => (
                    <Select.Option key={assignee.id} value={assignee.id}>
                      {assignee.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12} md={12} sm={12} xs={24}>
              <Form.Item>
                <Select
                  size="large"
                  showSearch
                  mode="multiple"
                  placeholder="Select followers"
                  onChange={(value) => handleChange(value, "followers")}
                  onInputKeyDown={(e) =>
                    CORE_FUNCTIONS.searchOptions(
                      e.currentTarget.value,
                      "hr",
                      "records",
                      setFollowersList
                    )
                  }
                >
                  {followersList.map((follower) => (
                    <Select.Option key={follower.id} value={follower.id}>
                      {follower.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 8]}>
            <Col span={12} md={12} sm={24} xs={24}>
              <Form.Item>
                <Input
                  type="number"
                  placeholder="Estimate hour"
                  id="estimate_hour"
                  size="large"
                  onChange={(e) => {
                    handleChange(e.target.value, "estimate_hour");
                  }}
                  step={0.1}
                  min={0}
                />
              </Form.Item>
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Form.Item>
                <Select
                  showSearch
                  mode="multiple"
                  placeholder="Add Tags"
                  onChange={(value) => handleChange(value, "tags")}
                  size="large"
                  onInputKeyDown={(e) =>
                    CORE_FUNCTIONS.searchOptions(
                      e.currentTarget.value,
                      "global",
                      "tags",
                      setTagList
                    )
                  }
                >
                  {tagList.map((tag) => (
                    <Select.Option key={tag.id} value={tag.id}>
                      {tag.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 8]}>
            <Col span={24}>
              <Form.Item>
                <Editor
                  value={taskData.description}
                  init={{
                    height: 200,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | bold italic backcolor | " +
                      "alignleft aligncenter alignright alignjustify | " +
                      "bullist numlist outdent indent | removeformat | help",
                  }}
                  onEditorChange={(e) => {
                    handleChange(e, "description");
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <ProFormUploadDragger
                onChange={(info) => {
                  const newFile = info.file.originFileObj;

                  if (
                    !attachments.some((file) => file.name === newFile?.name)
                  ) {
                    setAttachments([...attachments, newFile]);
                  }
                }}
                title={lang.get("project", [
                  "Click or drag files into this area to upload",
                ])}
                description={lang.get("project", [
                  "Support single or batch upload",
                ])}
                accept="image/*"
                name="drag-pic"
              />
            </Col>
          </Row>
        </Tabs.TabPane>

        <Tabs.TabPane
          key="doc"
          tab={
            <Space>
              <FileTextOutlined />
              Doc
            </Space>
          }
        >
          <Doc />
        </Tabs.TabPane>

        <Tabs.TabPane
          key="reminder"
          tab={
            <Space>
              <BellOutlined />
              Reminder
            </Space>
          }
        >
          <Reminder />
        </Tabs.TabPane>

        <Tabs.TabPane
          key="whiteboard"
          tab={
            <Space>
              <CalendarOutlined />
              Whiteboard
            </Space>
          }
        >
          <Whiteboard />
        </Tabs.TabPane>
      </Tabs>

      <Space
        style={{ marginTop: "16px", justifyContent: "flex-end", width: "100%" }}
      >
        <Button type="primary" onClick={() => handleSubmit()} loading={saving}>
          Save
        </Button>
        <Button
          type="default"
          onClick={() => handleSubmit(true)}
          loading={saving}
        >
          Save & Create New
        </Button>
      </Space>
    </Card>
  );
};

export default AddTask;
