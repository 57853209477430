import React, { useState, useEffect, ChangeEvent } from "react";
import { Button, Modal, Input, Form, Tooltip, message, Select } from "antd";
import { ProFormUploadDragger } from "@ant-design/pro-components";
import { ClockCircleOutlined, FolderAddOutlined } from "@ant-design/icons";
import lang from "../../../../../../lang";

import { SelectType } from "../../../../types/types";
import coreFunctions from "../../../../../../common/utils/coreFunctions";

import apiRequest from "../../../../../../common/utils/apiRequest";
import UploadFile from "../../../../../../common/utils/upload";

const StartTimerComponent = () => {
  const [showStartModal, setShowStartModal] = useState(false);
  const [showStopModal, setShowStopModal] = useState(false);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [taskDetails, setTaskDetails] = useState({
    start_time: "",
    end_time: "",
    note: "",
    task: "",
    customer: "",
    project: "",
    attachment: "",
  });

  const [customerList, setCustomerList] = useState<SelectType[]>([]);
  const [projectList, setProjectList] = useState<SelectType[]>([]);
  const [attachment, setAttachment] = useState<File>();
  const [saving, setSaving] = useState(false);

  const CORE_FUNCTIONS = new coreFunctions();

  useEffect(() => {
    let timerInterval: ReturnType<typeof setInterval> | undefined;

    if (isTimerRunning && startTime) {
      timerInterval = setInterval(() => {
        const currentTime = new Date();
        const timeDifference = Math.floor(
          (currentTime.getTime() - startTime.getTime()) / 1000
        );
        setElapsedTime(timeDifference);
      }, 1000);
    } else if (timerInterval) {
      clearInterval(timerInterval);
    }

    return () => {
      if (timerInterval) {
        clearInterval(timerInterval);
      }
    };
  }, [isTimerRunning, startTime]);

  useEffect(() => {
    if (localStorage.getItem("timesheet_timer")) {
      const timerData = JSON.parse(
        localStorage.getItem("timesheet_timer") as string
      );
      setStartTime(new Date(timerData.startTime));
      setIsTimerRunning(true);
    }
  }, []);

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(remainingSeconds).padStart(2, "0")}`;
  };

  const handleStartShow = () => setShowStartModal(true);
  const handleStartClose = () => setShowStartModal(false);
  const handleStopShow = () => setShowStopModal(true);
  const handleStopClose = () => setShowStopModal(false);

  const startTimer = () => {
    setStartTime(new Date());
    setIsTimerRunning(true);
    setElapsedTime(0);
    setShowStartModal(false);

    localStorage.setItem(
      "timesheet_timer",
      JSON.stringify({
        startTime: new Date().toISOString(),
      })
    );

    setTaskDetails({
      start_time: "",
      end_time: "",
      note: "",
      task: "",
      customer: "",
      project: "",
      attachment: "",
    });
  };

  const stopTimer = async () => {
    setSaving(true);
    setIsTimerRunning(false);
    setStartTime(null);

    if (localStorage.getItem("timesheet_timer")) {
      const timerData = JSON.parse(
        localStorage.getItem("timesheet_timer") as string
      );

      taskDetails.end_time = CORE_FUNCTIONS.formatISODateTime(
        new Date().toISOString()
      );

      taskDetails.start_time = CORE_FUNCTIONS.formatISODateTime(
        timerData.startTime
      );

      setTaskDetails({
        ...taskDetails,
      });
    }

    localStorage.removeItem("timesheet_timer");

    if (attachment) {
      const FILE_INSTANCE = new UploadFile();

      await FILE_INSTANCE.initUpload(attachment);

      const file_name = FILE_INSTANCE.getFileName();
      if (file_name !== null) {
        taskDetails.attachment = file_name;

        setTaskDetails({
          ...taskDetails,
        });
      }
    }

    message.loading(lang.get("projects", ["Adding"]), 1).then(() => {
      const API_REQUEST = new apiRequest("project/timesheet/stop", true);
      API_REQUEST.send(
        taskDetails,
        (response: { status: string; message: string }) => {
          if (response.status == "success") {
            setShowStopModal(false);
            setSaving(false);
            message.success(response.message);
          } else {
            message.error(response.message);
          }
        }
      );
    });
  };

  return (
    <div>
      <Button
        color="default"
        variant="filled"
        onClick={isTimerRunning ? handleStopShow : handleStartShow}
        icon={
          <ClockCircleOutlined
            spin={isTimerRunning}
            style={{
              animation: isTimerRunning ? "spin 1s linear infinite" : "none",
            }}
          />
        }
      >
        {isTimerRunning
          ? lang.get("timer", ["Stop Timer"])
          : lang.get("timer", ["Start Timer"])}
      </Button>

      {isTimerRunning && (
        <Tooltip title={lang.get("timer", ["Elapsed Time"])}>
          <span
            style={{
              marginLeft: "16px",
              fontSize: "16px",
              color: "red",
            }}
          >
            {formatTime(elapsedTime)}
          </span>
        </Tooltip>
      )}

      {/* Start Timer Modal */}
      <Modal
        title={lang.get("timer", ["Start Timer"])}
        open={showStartModal}
        onCancel={handleStartClose}
        footer={null}
        centered
      >
        <div style={{ textAlign: "center" }}>
          <FolderAddOutlined style={{ fontSize: "48px", color: "#6c757d" }} />
          <h3>{lang.get("timer", ["No started timers found"])} </h3>
          <Button color="default" variant="outlined" onClick={startTimer}>
            {lang.get("timer", ["Start Timer"])}
          </Button>
          <p>
            <button
              type="button"
              onClick={() =>
                alert(lang.get("timer", ["Navigating to timesheets"]))
              }
              style={{
                background: "none",
                border: "none",
                padding: 0,
                color: "#007bff",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {lang.get("timer", ["View all timesheets"])}
            </button>
          </p>
        </div>
      </Modal>

      {/* Stop Timer Modal */}
      <Modal
        title={lang.get("timer", ["Stop Timer"])}
        open={showStopModal}
        onCancel={handleStopClose}
        destroyOnClose
        footer={null}
        centered
      >
        <hr />
        <Form layout="vertical">
          <Form.Item label="Customer" name="customer">
            <Select
              placeholder={lang.get("project", ["Type Customer Name"])}
              showSearch
              onChange={(value) => {
                setTaskDetails({
                  ...taskDetails,
                  customer: value,
                });
              }}
              onSearch={(e) =>
                CORE_FUNCTIONS.searchOptions(
                  e,
                  "clients",
                  "clients",
                  setCustomerList
                )
              }
              onClick={(e) =>
                CORE_FUNCTIONS.searchOptions(
                  "",
                  "clients",
                  "clients",
                  setCustomerList
                )
              }
              size="large"
              options={customerList.map((option) => ({
                label: option.name,
                value: option.id,
              }))}
            ></Select>
          </Form.Item>
          <Form.Item label="Project" name="project">
            <Select
              placeholder={lang.get("project", ["Type Project Name"])}
              showSearch
              onChange={(value) => {
                setTaskDetails({
                  ...taskDetails,
                  project: value,
                });
              }}
              onInputKeyDown={(e) =>
                CORE_FUNCTIONS.searchOptions(
                  e.currentTarget.value,
                  "projects",
                  "projects",
                  setProjectList,
                  {
                    customer: taskDetails.customer,
                  }
                )
              }
              onClick={() => {
                CORE_FUNCTIONS.searchOptions(
                  "",
                  "projects",
                  "projects",
                  setProjectList,
                  {
                    customer: taskDetails.customer,
                  }
                );
              }}
              size="large"
            >
              {projectList.map((customer) => (
                <Select.Option key={customer.id} value={customer.id}>
                  {customer.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={lang.get("timer", ["Task"])} name="task">
            <Input
              size="large"
              placeholder={lang.get("timer", ["Enter Task"])}
              value={taskDetails.task}
              onChange={(e) => {
                setTaskDetails({
                  ...taskDetails,
                  task: e.target.value,
                });
              }}
              id="subject"
            />
          </Form.Item>
          <Form.Item label={lang.get("timer", ["Note"])} name="note">
            <Input.TextArea
              rows={3}
              placeholder={lang.get("timer", ["Add a note"])}
              id="note"
              onChange={(e) => {
                setTaskDetails({
                  ...taskDetails,
                  note: e.target.value,
                });
              }}
            />
          </Form.Item>
          <ProFormUploadDragger
            onChange={(info) => {
              const newFile = info.file.originFileObj;
              setAttachment(newFile);
            }}
            max={1}
            title={lang.get("project", [
              "Click or drag files into this area to upload",
            ])}
            description={lang.get("project", ["Support single upload"])}
            accept="image/*"
            name="drag-pic"
          />
          <Button type="primary" block onClick={stopTimer} loading={saving}>
            {lang.get("timer", ["Confirm"])}
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default StartTimerComponent;
