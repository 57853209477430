// ProjectTable.tsx
import React, { useState, useRef } from "react";
import {
  Tag,
  InputRef,
  Input,
  Button,
  Space,
  DatePicker,
  Dropdown,
  Avatar,
  Tooltip,
} from "antd";

import {
  CheckCircleOutlined,
  PauseCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  AlertOutlined,
  SearchOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { ActionType, ProColumns, ProTable } from "@ant-design/pro-components";
import lang from "../../../../../../lang";
import Export from "../../../../../../common/components/Export";

import { DBSingleProject, ProjectTask } from "../../../../types/types";

import apiRequest from "../../../../../../common/utils/apiRequest";
import coreFunctions from "../../../../../../common/utils/coreFunctions";

const { RangePicker } = DatePicker;

const statusStyles: Record<string, { color: string; icon: React.ReactNode }> = {
  Finished: { color: "green", icon: <CheckCircleOutlined /> },
  "On Hold": { color: "orange", icon: <PauseCircleOutlined /> },
  "In Progress": { color: "blue", icon: <SyncOutlined spin /> },
  Cancelled: { color: "red", icon: <CloseCircleOutlined /> },
};

const priorityStyles: Record<string, { color: string; icon: React.ReactNode }> =
  {
    High: { color: "red", icon: <AlertOutlined /> },
    Medium: { color: "blue", icon: <SyncOutlined /> },
    Low: { color: "gray", icon: <PauseCircleOutlined /> },
    Urgent: { color: "orange", icon: <AlertOutlined /> },
  };

const filterMenus = [
  {
    label: "Status",
    key: "status",
    children: [
      { label: "Not Started", key: "not_started" },
      { label: "In Progress", key: "in_progress" },
      { label: "On Hold", key: "on_hold" },
      { label: "Cancelled", key: "cancelled" },
      { label: "Finished", key: "finished" },
    ],
  },
  {
    label: "Tags",
    key: "tags",
    children: [
      { label: "Marketing", key: "marketing" },
      { label: "Design", key: "design" },
      { label: "Development", key: "development" },
    ],
  },
  {
    label: "Priority",
    key: "priority",
    children: [
      { label: "High", key: "high" },
      { label: "Medium", key: "medium" },
      { label: "Low", key: "low" },
      { label: "Urgent", key: "urgent" },
    ],
  },
];

const TasksTable = ({ project }: { project: DBSingleProject | null }) => {
  const [pageSize, setPageSize] = useState(15);
  const searchInput = useRef<InputRef>(null);
  const actionRef = useRef<ActionType>();
  const [searchAttributes, setSearchAttributes] = useState<
    { key: string; value: string }[]
  >([]);
  const [allSearch, setAllSearch] = useState<string>("");
  const [tableData, setTableData] = useState<ProjectTask[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const expobj = new Export(
    lang.get("Task", ["Tasks"]) + ", " + new Date().toDateString()
  );

  const handleSearch = (field: string, value: string) => {
    setAllSearch("");
    const existingAttribute = searchAttributes.find(
      (attr) => attr.key === field
    );

    if (existingAttribute) {
      setSearchAttributes(
        searchAttributes.map((attr) =>
          attr.key === field ? { ...attr, value } : attr
        )
      );
    } else {
      setSearchAttributes([
        ...searchAttributes,
        {
          key: field,
          value,
        },
      ]);
    }
  };

  const searchCols = (field: string, label: string) => {
    return {
      filterDropdown: (props: any) => {
        const { close } = props;

        setTimeout(() => {
          if (searchInput.current) {
            searchInput.current.focus();
          }
        }, 100);

        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={lang.get("Task", [`Search ${label}`])}
              onChange={(e) => handleSearch(field, e.target.value)}
              value={
                searchAttributes.filter((item) => item.key === field)[0]?.value
              }
              style={{ marginBottom: 8, display: "block" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  close();
                  if (actionRef.current) {
                    actionRef.current?.reset?.();
                    actionRef.current.reload();
                  }
                }
              }}
            />
            <Space>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="small"
                onClick={() => {
                  actionRef.current?.reset?.();
                  actionRef.current?.reload();
                }}
                style={{ width: 90 }}
              >
                {lang.get("Task", ["Search"])}
              </Button>
              <Button
                onClick={() => close()}
                size="small"
                style={{ width: 90 }}
              >
                {lang.get("projects", ["Close"])}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
    };
  };

  const columns: ProColumns<ProjectTask>[] = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      sorter: true,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Task Name",
      dataIndex: "subject",
      key: "subject",
      sorter: true,
      ...searchCols("subject", lang.get("Task", ["Task Subject"])),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record: ProjectTask) => {
        const { color, icon } = statusStyles[record.status] || {
          color: "gray",
          icon: null,
        };
        return (
          <Tag color={color}>
            {icon} {lang.get("Task", [record.status])}
          </Tag>
        );
      },
      sorter: true,
      ...searchCols("status", lang.get("Task", ["Status"])),
    },

    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      ...searchCols("start_date", lang.get("Task", ["Start Date"])),
      sorter: true,
    },
    {
      title: "Deadline",
      dataIndex: "due_date",
      key: "due_date",
      ...searchCols("due_date", lang.get("Task", ["Deadline"])),
      sorter: true,
    },

    {
      title: "Assigned To",
      dataIndex: "assigners",
      key: "assigners",
      sorter: true,
      ...searchCols("assigners", lang.get("Task", ["Assigned To"])),
      render: (_, record: ProjectTask) => {
        return record.assigners
          ? record.assigners.map((member, index) => {
              if (index == 3) {
                return (
                  <Tooltip title={lang.get("projects", ["More Members"])}>
                    <Avatar size="small" key={index}>
                      +{record.assigners.length - 3}
                    </Avatar>
                  </Tooltip>
                );
              }
              if (index > 3) {
                return null;
              } else {
                return (
                  <Tooltip title={member.name} key={member.id}>
                    <Avatar
                      alt={member.name}
                      key={member.id}
                      src={member.profile_image}
                    >
                      {!member.profile_image && member.name[0]}
                    </Avatar>
                  </Tooltip>
                );
              }
            })
          : "-";
      },
    },

    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (_, record: ProjectTask) => {
        const { color, icon } = priorityStyles[record.priority] || {
          color: "gray",
          icon: null,
        };
        return (
          <Tag color={color}>
            {icon} {lang.get("projects", [record.priority])}
          </Tag>
        );
      },
      sorter: true,
      ...searchCols("priority", lang.get("Task", ["Priority"])),
    },
  ];

  return (
    <ProTable
      headerTitle={lang.get("projects", ["Tasks"])}
      columns={columns}
      actionRef={actionRef}
      dataSource={tableData}
      request={async (params, sort, filter) => {
        await new coreFunctions().waitRequest(10);

        const processedFilters = Object.entries(filter || {}).reduce(
          (acc, [key, value]) => {
            if (value && value.length > 0) {
              acc[key] = value;
            }
            return acc;
          },
          {} as Record<string, (string | number)[]>
        );

        const payload = {
          project: project?.id,
          params: params,
          sorts: sort,
          filters: processedFilters,
          searches: searchAttributes,
          all_search: allSearch,
        };

        const API_REQUEST = new apiRequest("project/tasks/get", true);
        const response = await API_REQUEST.send(payload);

        if (response && response.status === "success") {
          const fetchedData = response.data || [];
          setTableData(fetchedData);
          setTotalRecords(response.total);

          return {
            data: fetchedData,
            total: totalRecords,
            success: true,
          };
        }

        return {
          data: [],
          total: 0,
          success: false,
        };
      }}
      rowKey="id"
      cardBordered
      pagination={{
        defaultCurrent: 1,
        pageSize: pageSize,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50],
        showTotal: (total, range) =>
          lang.get("projects", [
            `Showing ${range[0]}-${range[1]} of ${total} items`,
          ]),
        onShowSizeChange: (current, size) => {
          setPageSize(size);
        },
      }}
      search={false}
      scroll={{ x: 800 }}
      toolBarRender={() => [
        <Dropdown menu={{ items: filterMenus }}>
          <Button icon={<FilterOutlined />}>Filter</Button>
        </Dropdown>,

        <RangePicker />,
        <Input
          style={{ width: "auto" }}
          key="all_search"
          allowClear
          placeholder={lang.get("Task", ["Search"])}
          value={allSearch}
          onChange={(e) => {
            setAllSearch(e.target.value);
            setSearchAttributes([]);
            actionRef?.current?.reload();
          }}
        />,
        <Button
          key="csv"
          onClick={() => {
            const csvData = tableData.map((row) => ({
              [lang.get("Task", ["Task Name"])]: row.subject,
              [lang.get("Task", ["Status"])]: row.status,
              [lang.get("Task", ["Start Date"])]: row.start_date,
              [lang.get("Task", ["Deadline"])]: row.due_date,
              [lang.get("Task", ["Assigned To"])]: row.assigners.map(
                (assignee) => assignee
              ),
              [lang.get("Task", ["Priority"])]: row.priority,
            }));
            expobj.exportToCSV(csvData);
          }}
        >
          {lang.get("Task", ["Export CSV"])}
        </Button>,
        <Button
          key="excel"
          onClick={() => {
            const excelData = tableData.map((row) => ({
              [lang.get("Task", ["Task Name"])]: row.subject,
              [lang.get("Task", ["Status"])]: row.status,
              [lang.get("Task", ["Start Date"])]: row.start_date,
              [lang.get("Task", ["Deadline"])]: row.due_date,
              [lang.get("Task", ["Assigned To"])]: row.assigners.map(
                (assignee) => assignee
              ),
              [lang.get("Task", ["Priority"])]: row.priority,
            }));
            expobj.exportToExcel(excelData);
          }}
        >
          {lang.get("Task", ["Export Excel"])}
        </Button>,
        <Button
          key="pdf"
          onClick={() => {
            expobj.exportToPDF({
              head: [
                [
                  lang.get("Task", ["Task Name"]),
                  lang.get("Task", ["Status"]),
                  lang.get("Task", ["Start Date"]),
                  lang.get("Task", ["Deadline"]),
                  lang.get("Task", ["Assigned To"]),
                  lang.get("Task", ["Priority"]),
                ],
              ],
              body: tableData.map((row) => [
                row.subject,
                row.start_date,
                row.due_date,
                row.assigners.map((assignee) => assignee),
                row.status,
                row.priority,
              ]),
            });
          }}
        >
          {lang.get("Task", ["Export PDF"])}
        </Button>,
      ]}
    />
  );
};

export default TasksTable;
