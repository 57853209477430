import React from "react";
import { Card, Timeline, Tag } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

const ActivityLogs: React.FC = () => {
  const activityData = [
    {
      key: "1",
      time: "2024-08-01 10:00 AM",
      activity: "User logged in",
      status: "Success",
    },
    {
      key: "2",
      time: "2024-08-01 10:30 AM",
      activity: "Inventory updated",
      status: "Success",
    },
    {
      key: "3",
      time: "2024-08-01 11:00 AM",
      activity: "Failed login attempt",
      status: "Failed",
    },
    {
      key: "4",
      time: "2024-08-01 11:30 AM",
      activity: "New order created",
      status: "Success",
    },
    {
      key: "5",
      time: "2024-08-01 12:00 PM",
      activity: "Order canceled",
      status: "Failed",
    },
  ];

  const getStatusTag = (status: string) => {
    if (status === "Success") {
      return (
        <Tag icon={<CheckCircleOutlined />} color="green">
          {status}
        </Tag>
      );
    }
    if (status === "Failed") {
      return (
        <Tag icon={<CloseCircleOutlined />} color="red">
          {status}
        </Tag>
      );
    }
    return <Tag>{status}</Tag>;
  };

  return (
    <div style={{ background: "#f5f7fa", padding: "20px" }}>
      <Card
        title="Activity Logs"
        bordered={false}
        style={{ width: "100%", marginBottom: "20px" }}
      >
        <Timeline mode="left">
          {activityData.map((item) => (
            <Timeline.Item
              key={item.key}
              color={item.status === "Success" ? "green" : "red"}
              dot={
                item.status === "Success" ? (
                  <CheckCircleOutlined style={{ fontSize: "16px" }} />
                ) : (
                  <CloseCircleOutlined style={{ fontSize: "16px" }} />
                )
              }
            >
              <div style={{ marginBottom: "5px", fontWeight: "bold" }}>
                {item.time}
              </div>
              <div>{item.activity}</div>
              {getStatusTag(item.status)}
            </Timeline.Item>
          ))}
        </Timeline>
      </Card>
    </div>
  );
};

export default ActivityLogs;
