import { Breadcrumb, Typography, Button, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import lang from "../../../lang";

import StockTable from "./StockTable";
import { useNavigate } from "react-router-dom";
//   import StockAdjustmentTable from "./StockAdjustmentTable";

const { Title } = Typography;

const StockAdjustment: React.FC = () => {
  const navigate = useNavigate();

  const handleAddStockAdjustmentClick = () => {
    navigate("/stock-adjustment-add");
  };

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      {/* Breadcrumb */}
      <Breadcrumb style={{ marginBottom: "16px" }} separator=">">
        <Breadcrumb.Item>{lang.get("breadcrumbs", ["Home"])}</Breadcrumb.Item>
        <Breadcrumb.Item>
          {lang.get("breadcrumbs", ["Inventory"])}
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {lang.get("breadcrumbs", ["Stock Adjustment"])}
        </Breadcrumb.Item>
      </Breadcrumb>

      {/* Page Title */}
      <Title level={2} style={{ marginBottom: "16px" }}>
        {lang.get("stockAdjustment", ["Stock Adjustment"])} (7)
      </Title>

      {/* Filter and Actions */}
      <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
        <Col xs={24} sm={12} md={12} lg={12} style={{ textAlign: "left" }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              handleAddStockAdjustmentClick();
            }}
          >
            {lang.get("stockAdjustment", ["Add Stock Adjustment"])}
          </Button>
        </Col>
      </Row>

      {/* Table */}
      <StockTable />
    </div>
  );
};

export default StockAdjustment;
