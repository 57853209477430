import React, { useState } from "react";
import { Breadcrumb, Typography, Button, Space, Modal, Row, Col } from "antd";
import { PlusOutlined, ExportOutlined } from "@ant-design/icons";
import CustomerTable from "./components/CustomerTable";
import CustomerAddBody from "../customer-add/CustomerAddBody";
import CustomerListStatitstic from "./components/CustomerListStatitstic";
import lang from "../../../lang";

const { Title } = Typography;

const CustomerList: React.FC = () => {
  const [modelOpen, setModelOpen] = useState(false);
  const [updateTable, setUpdateTable] = useState(false);

  const [modelCurrentStep, setModelCurrentStep] = useState(0);

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      {/* Breadcrumb */}
      <Breadcrumb style={{ marginBottom: "16px" }} separator=">">
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Customers</Breadcrumb.Item>
        <Breadcrumb.Item>Customer List</Breadcrumb.Item>
      </Breadcrumb>

      <div>
        {/* Page Title */}
        <Title level={2} style={{ marginBottom: "16px" }}>
          {lang.get("customer", ["Customers"])}
        </Title>
        <div style={{ marginBottom: "15px" }}>
          <CustomerListStatitstic updateTable={updateTable} />
        </div>
        <Row
          gutter={[16, 16]}
          style={{ marginBottom: "16px" }}
          align="middle"
          justify="space-between"
        >
          {/* Left Section: Buttons */}
          <Col xs={24} sm={24} md={12} lg={12}>
            <Space size="middle" wrap>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setModelOpen(true)}
              >
                {lang.get("customer", ["Add Customer"])}
              </Button>
              <Button
                variant="solid"
                color="default"
                icon={<ExportOutlined />}
                onClick={() => console.log("Import action")}
              >
                {lang.get("customer", ["Import"])}
              </Button>
            </Space>
          </Col>
        </Row>

        <CustomerTable updateTable={updateTable} />

        <Modal
          title={lang.get("customer", ["Add Customers"])}
          open={modelOpen}
          onCancel={() => {
            setModelOpen(false);
            setModelCurrentStep(0);
          }}
          maskClosable={false}
          destroyOnClose
          footer={null}
          centered
          width={700}
        >
          <CustomerAddBody
            modelClose={() => {
              setModelOpen(false);
              setModelCurrentStep(0);
            }}
            updateTable={updateTable}
            setUpdateTable={setUpdateTable}
            setCurrentStep={setModelCurrentStep}
            currentStep={modelCurrentStep}
          />
        </Modal>
      </div>
    </div>
  );
};

export default CustomerList;
