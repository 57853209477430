import React, { useState, useRef } from "react";
import { Tag, Input, Button, Space, Select, DatePicker } from "antd";
import {
  SearchOutlined,
  MailOutlined,
  ShareAltOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { ActionType, ProColumns, ProTable } from "@ant-design/pro-components";
import lang from "../../../lang";
import Export from "../../../common/components/Export";

const { Option } = Select;
const { RangePicker } = DatePicker;

const PurchaseRequestTable = () => {
  const [pageSize, setPageSize] = useState(15);
  const [searchAttributes, setSearchAttributes] = useState<
    { key: string; value: string }[]
  >([]);
  const [allSearch, setAllSearch] = useState<string>("");
  const [, setSelectedDepartment] = useState<string | null>(
    null
  );
  const [, setDateRange] = useState<[string, string] | null>(null);
  const actionRef = useRef<ActionType>();
  const expobj = new Export(
    lang.get("purchaseRequests", ["Purchase Requests"]) +
      ", " +
      new Date().toDateString()
  );

  const handleSearch = (field: string, value: string) => {
    setAllSearch("");
    const existingAttribute = searchAttributes.find(
      (attr) => attr.key === field
    );

    if (existingAttribute) {
      setSearchAttributes(
        searchAttributes.map((attr) =>
          attr.key === field ? { ...attr, value } : attr
        )
      );
    } else {
      setSearchAttributes([...searchAttributes, { key: field, value }]);
    }
  };

  const handleDepartmentFilter = (value: string | null) => {
    setSelectedDepartment(value);
    actionRef.current?.reload();
  };

  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    setDateRange(dateStrings);
    actionRef.current?.reload();
  };

  const searchCols = (field: string, label: string) => ({
    filterDropdown: (props: any) => {
      const { close } = props;

      return (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            placeholder={lang.get("purchaseRequests", [`Search ${label}`])}
            onChange={(e) => handleSearch(field, e.target.value)}
            value={searchAttributes.find((item) => item.key === field)?.value}
            style={{ marginBottom: 8, display: "block" }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                close();
                actionRef.current?.reload();
              }
            }}
          />
          <Space>
            <Button
              type="primary"
              icon={<SearchOutlined />}
              size="small"
              onClick={() => actionRef.current?.reload()}
              style={{ width: 90 }}
            >
              {lang.get("purchaseRequests", ["Search"])}
            </Button>
            <Button onClick={() => close()} size="small" style={{ width: 90 }}>
              {lang.get("purchaseRequests", ["Close"])}
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
  });

  interface PurchaseRequest {
    id: string;
    code: string;
    name: string;
    requester: string;
    department: string;
    requestDate: string;
    status: string;
    poNumber: string;
  }

  const columns: ProColumns<PurchaseRequest>[] = [
    {
      title: lang.get("purchaseRequests", ["Purchase Request Code"]),
      dataIndex: "code",
      key: "code",
      sorter: true,
      ...searchCols(
        "code",
        lang.get("purchaseRequests", ["Purchase Request Code"])
      ),
      render: (code) => <>{code}</>
    },
    {
      title: lang.get("purchaseRequests", ["Request Date"]),
      dataIndex: "requestDate",
      key: "requestDate",
      sorter: true,
    },
    {
      title: lang.get("purchaseRequests", ["Purchase Request Name"]),
      dataIndex: "name",
      key: "name",
      sorter: true,
      ...searchCols(
        "name",
        lang.get("purchaseRequests", ["Purchase Request Name"])
      ),
    },
    {
      title: lang.get("purchaseRequests", ["Requester"]),
      dataIndex: "requester",
      key: "requester",
      sorter: true,
      ...searchCols("requester", lang.get("purchaseRequests", ["Requester"])),
    },
    {
      title: lang.get("purchaseRequests", ["Department"]),
      dataIndex: "department",
      key: "department",
      sorter: true,
      filters: [
        { text: "IT", value: "IT" },
        { text: "Finance", value: "Finance" },
        { text: "HR", value: "HR" },
      ],
      onFilter: (value, record) => record.department.includes(value as string),
    },

    {
      title: lang.get("purchaseRequests", ["Status"]),
      dataIndex: "status",
      key: "status",
      sorter: true,
      render: (status) => (
        <Tag color={status === "Approved" ? "green" : "red"}>{status}</Tag>
      ),
    },
    {
      title: lang.get("purchaseRequests", ["PO No."]),
      dataIndex: "poNumber",
      key: "poNumber",
      sorter: true,
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      render: (poNumber) => <a>{poNumber}</a>,
    },
    {
      title: lang.get("purchaseRequests", ["Options"]),
      dataIndex: "options",
      key: "options",
      render: () => (
        <Space>
          <Button icon={<MailOutlined />} />
          <Button icon={<ShareAltOutlined />} />
        </Space>
      ),
    },
  ];

  const data: PurchaseRequest[] = [
    {
      id: "1",
      code: "#PR-00001-2023-TESR",
      name: "J7 Mobile",
      requester: "demo user 2",
      department: "IT",
      requestDate: "2023-05-17 12:37:58",
      status: "Approved",
      poNumber: "#PO-00004-May-2023-1234",
    },
    {
      id: "2",
      code: "#PR-00002-2023-TESR",
      name: "Test Request",
      requester: "demo user 2",
      department: "Finance",
      requestDate: "2023-08-03 11:51:07",
      status: "Approved",
      poNumber: "#PO-00005-Aug-2023-5678",
    },
  ];

  return (
    <ProTable
      columns={columns}
      actionRef={actionRef}
      dataSource={data}
      rowKey="id"
      pagination={{
        defaultCurrent: 1,
        pageSize: pageSize,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50],
        onShowSizeChange: (_, size) => setPageSize(size),
      }}
      search={false}
      scroll={{ x: "max-content" }}
      toolBarRender={() => [
        <Space size="middle" wrap>
          <Select
            placeholder={lang.get("purchaseRequests", ["Filter by Department"])}
            style={{ width: 200 }}
            allowClear
            onChange={handleDepartmentFilter}
          >
            <Option value="IT">IT</Option>
            <Option value="Finance">Finance</Option>
            <Option value="HR">HR</Option>
          </Select>
          <RangePicker
            onChange={handleDateRangeChange}
            style={{ width: 300 }}
          />
        </Space>,
        <Input
          key="global_search"
          placeholder={lang.get("purchaseRequests", ["Global Search"])}
          value={allSearch}
          onChange={(e) => {
            setAllSearch(e.target.value);
            setSearchAttributes([]);
            actionRef?.current?.reload();
          }}
          style={{ width: 200 }}
        />,
        <Button
          key="csv"
          icon={<FileTextOutlined />}
          onClick={() => {
            const csvData = data.map((row) => ({
              [lang.get("purchaseRequests", ["Code"])]: row.code,
              [lang.get("purchaseRequests", ["Name"])]: row.name,
              [lang.get("purchaseRequests", ["Requester"])]: row.requester,
              [lang.get("purchaseRequests", ["Department"])]: row.department,
              [lang.get("purchaseRequests", ["Request Date"])]: row.requestDate,
              [lang.get("purchaseRequests", ["Status"])]: row.status,
              [lang.get("purchaseRequests", ["PO No."])]: row.poNumber,
            }));
            expobj.exportToCSV(csvData);
          }}
        >
          {lang.get("purchaseRequests", ["Export CSV"])}
        </Button>,

        <Button
          key="excel"
          icon={<FileExcelOutlined />}
          onClick={() => {
            const excelData = data.map((row) => ({
              [lang.get("purchaseRequests", ["Code"])]: row.code,
              [lang.get("purchaseRequests", ["Name"])]: row.name,
              [lang.get("purchaseRequests", ["Requester"])]: row.requester,
              [lang.get("purchaseRequests", ["Department"])]: row.department,
              [lang.get("purchaseRequests", ["Request Date"])]: row.requestDate,
              [lang.get("purchaseRequests", ["Status"])]: row.status,
              [lang.get("purchaseRequests", ["PO No."])]: row.poNumber,
            }));
            expobj.exportToExcel(excelData);
          }}
        >
          {lang.get("purchaseRequests", ["Export Excel"])}
        </Button>,
        <Button
          key="pdf"
          icon={<FilePdfOutlined />}
          onClick={() => {
            expobj.exportToPDF({
              head: [
                [
                  lang.get("purchaseRequests", ["Code"]),
                  lang.get("purchaseRequests", ["Name"]),
                  lang.get("purchaseRequests", ["Requester"]),
                  lang.get("purchaseRequests", ["Department"]),
                  lang.get("purchaseRequests", ["Request Date"]),
                  lang.get("purchaseRequests", ["Status"]),
                  lang.get("purchaseRequests", ["PO No."]),
                ],
              ],
              body: data.map((row) => [
                row.code,
                row.name,
                row.requester,
                row.department,
                row.requestDate,
                row.status,
                row.poNumber,
              ]),
            });
          }}
        >
          {lang.get("purchaseRequests", ["Export PDF"])}
        </Button>,
      ]}
    />
  );
};

export default PurchaseRequestTable;
