import {
  Breadcrumb,
  Typography,
  Button,
  Row,
  Col,
  Modal,
  Form,
  Input,
  Select,
} from "antd";

import lang from "../../../lang";
import { useState } from "react";
import WarehouseTable from "./WarehouseTable";
import { PlusOutlined } from "@ant-design/icons";

const { Title } = Typography;

const Warehouse: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      {/* Breadcrumb */}
      <Breadcrumb style={{ marginBottom: "16px" }} separator=">">
        <Breadcrumb.Item>{lang.get("breadcrumbs", ["Home"])}</Breadcrumb.Item>
        <Breadcrumb.Item>
          {lang.get("breadcrumbs", ["Inventory"])}
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {lang.get("breadcrumbs", ["Warehouse"])}
        </Breadcrumb.Item>
      </Breadcrumb>

      {/* Page Title */}
      <Title level={2} style={{ marginBottom: "16px" }}>
        {lang.get("warehouse", ["Warehouse"])} (7)
      </Title>

      {/* Filter and Actions */}
      <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
        <Col style={{ textAlign: "left" }}>
          <Button
            type="primary"
            onClick={() => setModalOpen(true)}
            style={{ backgroundColor: "#1890ff", color: "#fff" }}
            icon={<PlusOutlined />}
          >
            {lang.get("warehouse", ["New Warehouse"])}
          </Button>
        </Col>
      </Row>

      {/* Table */}
      <WarehouseTable />
      {/* Add Warehouse Modal */}
      <Modal
        title={lang.get("warehouse", ["Add Warehouse"])}
        open={modalOpen}
        footer={null}
        onCancel={() => setModalOpen(false)}
        width={800}
      >
        <Form
          layout="vertical"
          onFinish={(values) => {
            console.log("Form Submitted:", values);
            setModalOpen(false);
          }}
        >
          <Row gutter={[16, 16]}>
            {/* Warehouse Number */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("warehouse", ["Warehouse Number"])}
                  </span>
                }
                name="warehouseNumber"
                rules={[
                  {
                    required: true,
                    message: lang.get("warehouse", [
                      "Warehouse Number is required",
                    ]),
                  },
                ]}
              >
                <Input
                  placeholder={lang.get("warehouse", [
                    "Enter Warehouse Number",
                  ])}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Warehouse Name */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("warehouse", ["Warehouse Name"])}
                  </span>
                }
                name="warehouseName"
                rules={[
                  {
                    required: true,
                    message: lang.get("warehouse", [
                      "Warehouse Name is required",
                    ]),
                  },
                ]}
              >
                <Input
                  placeholder={lang.get("warehouse", ["Enter Warehouse Name"])}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Warehouse Location */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("warehouse", ["Warehouse Location"])}
                  </span>
                }
                name="warehouseLocation"
                rules={[
                  {
                    required: true,
                    message: lang.get("warehouse", [
                      "Warehouse Location is required",
                    ]),
                  },
                ]}
              >
                <Input
                  placeholder={lang.get("warehouse", [
                    "Enter Warehouse Location",
                  ])}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Assign to Staff */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("warehouse", ["Assign to Staff"])}
                  </span>
                }
                name="assignToStaff"
                rules={[
                  {
                    required: true,
                    message: lang.get("warehouse", ["Please select staff"]),
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  placeholder={lang.get("warehouse", ["Select Staff"])}
                  size="large"
                  options={[
                    { value: "John Doe", label: "John Doe" },
                    { value: "Jane Smith", label: "Jane Smith" },
                    { value: "Mike Ross", label: "Mike Ross" },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="end">
            <Button
              onClick={() => setModalOpen(false)}
              style={{
                marginRight: "8px",
                backgroundColor: "white",
                borderColor: "#d9d9d9",
                color: "#000",
              }}
            >
              {lang.get("warehouse", ["Cancel"])}
            </Button>
            <Button type="primary" htmlType="submit">
              {lang.get("warehouse", ["Save"])}
            </Button>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default Warehouse;
