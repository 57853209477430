import { useState, ChangeEvent } from "react";
import { Row, Col, Form, Input, Button, Card, Typography } from "antd";
import lang from "../../../../../lang";

const { Title } = Typography;

const Map = () => {
  const [locationData, setLocationData] = useState({
    Latitude: "7.8731",
    Longitude: "80.7718",
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLocationData({
      ...locationData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSave = () => {
    console.log(lang.get("map", ["Location saved:"]), locationData);
  };

  return (
    <div>
      <Card style={{ padding: "20px" }}>
        <Title level={4} style={{ marginBottom: "20px" }}>
          {lang.get("map", ["Location Details"])}
        </Title>

        <Row gutter={[16, 16]}>
          {/* Latitude Field */}
          <Col xs={24} lg={12}>
            <Form.Item
              label={lang.get("map", ["Set Location (Google Maps)"])}
              labelCol={{ span: 24 }}
            >
              <Input.Group compact>
                <Input
                  id="Latitude"
                  placeholder={lang.get("map", ["Search Company.."])}
                  onChange={handleChange}
                  style={{ width: "calc(100% - 100px)" }}
                />
                <Button type="primary" onClick={handleSave}>
                  {lang.get("map", ["Locate"])}
                </Button>
              </Input.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ marginTop: "20px" }}>
          <Col span={24}>
            <div style={{ height: "400px", width: "100%" }}>
              <iframe
                title={lang.get("map", ["Google Map"])}
                src={`https://www.google.com/maps?q=${locationData.Latitude},${locationData.Longitude}&z=7&output=embed`}
                width="100%"
                height="100%"
                style={{ border: 0 }}
                loading="lazy"
              ></iframe>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default Map;
