import React, { useState, useRef } from "react";
import { Input, Button, Space, DatePicker, Tag, Select, Modal } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { ActionType, ProColumns, ProTable } from "@ant-design/pro-components";
import lang from "../../../lang";
import Export from "../../../common/components/Export";

const { RangePicker } = DatePicker;

interface DeliveryVoucher {
  code: string;
  voucherDate: string;
  customerName: string;
  invoice: string;
  address: string;
  stockOutWarehouse: string;
  totalAmount: number;
  status: string;
  deliveryStatus: string;
  createUser: string;
}

const DeliverTable: React.FC = () => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [searchText, setSearchText] = useState<string>("");
  const [selectedDates, setSelectedDates] = useState<[string, string] | null>(
    null
  );
  const [editingRow, setEditingRow] = React.useState<string | null>(null);
  const actionRef = useRef<ActionType>();
  const expobj = new Export(
    lang.get("deliverVoucher", ["Delivery Voucher"]) +
      ", " +
      new Date().toDateString()
  );

  const handleDateChange = (dates: any, dateStrings: [string, string]) => {
    setSelectedDates(dateStrings);
  };

  const [data, setData] = useState<DeliveryVoucher[]>([]);

  const handleStatusChange = (key: string, newStatus: string) => {
    setData((prevData) =>
      prevData.map((row) =>
        row.code === key ? { ...row, deliveryStatus: newStatus } : row
      )
    );
  };

  const columns: ProColumns<DeliveryVoucher>[] = [
    {
      title: lang.get("deliverVoucher", ["Delivery Voucher Code"]),
      dataIndex: "code",
      key: "code",
      sorter: true,
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: lang.get("deliverVoucher", ["Date"]),
      dataIndex: "voucherDate",
      key: "voucherDate",
      sorter: true,
    },
    {
      title: lang.get("deliverVoucher", ["Customer Name"]),
      dataIndex: "customerName",
      key: "customerName",
      sorter: true,
    },
    {
      title: lang.get("deliverVoucher", ["Invoice"]),
      dataIndex: "invoice",
      key: "invoice",
      render: (text) => text,
      sorter: true,
    },
    {
      title: lang.get("deliverVoucher", ["Address"]),
      dataIndex: "address",
      key: "address",
      sorter: true,
    },
    {
      title: lang.get("deliverVoucher", ["Stock Out Warehouse"]),
      dataIndex: "stockOutWarehouse",
      key: "stockOutWarehouse",
      sorter: true,
    },
    {
      title: lang.get("deliverVoucher", ["Total Amount"]),
      dataIndex: "totalAmount",
      key: "totalAmount",
      sorter: true,
      render: (amount) => `$${amount}`,
    },
    {
      title: lang.get("deliverVoucher", ["Status"]),
      dataIndex: "status",
      key: "status",
      sorter: true,
      render: (status) => (
        <Tag color={status === "Approved" ? "green" : "blue"}>{status}</Tag>
      ),
    },
    {
      title: lang.get("deliverVoucher", ["Delivery Status"]),
      dataIndex: "deliveryStatus",
      key: "deliveryStatus",
      sorter: true,
      render: (deliveryStatus: React.ReactNode, record) => {
        const currentStatus =
          typeof deliveryStatus === "string" ? deliveryStatus : "";

        return editingRow === record.code ? (
          <Select
            defaultValue={currentStatus}
            options={[
              { value: "Ready To Deliver", label: "Mark as Ready To Deliver" },
              {
                value: "Delivery In Progress",
                label: "Mark as Delivery In Progress",
              },
              { value: "Delivered", label: "Mark as Delivered" },
              { value: "Received", label: "Mark as Received" },
              { value: "Returned", label: "Mark as Returned" },
              { value: "Not Delivered", label: "Mark as Not Delivered" },
            ]}
            style={{ width: 200 }}
            dropdownStyle={{ width: 250 }}
            onBlur={() => setEditingRow(null)}
            onChange={(value) => {
              Modal.confirm({
                title: lang.get("deliverVoucher", ["Confirm Status Change"]),
                width: 300,
                content: lang.get("deliverVoucher", [
                  `Are you sure you want to change the status to "${value}"?`,
                ]),
                okText: lang.get("deliverVoucher", ["Yes"]),
                cancelText: lang.get("deliverVoucher", ["No"]),
                onOk: () => {
                  handleStatusChange(record.code, value);
                  setEditingRow(null);
                },
                onCancel: () => {
                  setEditingRow(null);
                },
              });
            }}
          />
        ) : (
          <Tag
            color={
              {
                "Ready To Deliver": "blue",
                "Delivery In Progress": "orange",
                Delivered: "green",
                Received: "purple",
                Returned: "red",
                "Not Delivered": "gray",
              }[currentStatus] || "default"
            }
            style={{ cursor: "pointer" }}
            onClick={() => setEditingRow(record.code)}
          >
            {currentStatus}
          </Tag>
        );
      },
    },

    {
      title: lang.get("deliverVoucher", ["Created User"]),
      key: "createUser",
      dataIndex: "createUser",
      sorter: true,
      render: (text) => text,
    },
  ];

  return (
    <ProTable
      columns={columns}
      actionRef={actionRef}
      dataSource={data}
      rowKey="code"
      pagination={{
        defaultCurrent: 1,
        pageSize: pageSize,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50],
        onShowSizeChange: (_, size) => setPageSize(size),
      }}
      search={false}
      scroll={{ x: "max-content" }}
      toolBarRender={() => [
        <Space size="middle" wrap key="toolbar">
          <RangePicker
            key="datePicker"
            format="YYYY-MM-DD"
            onChange={handleDateChange}
            placeholder={[
              lang.get("deliverVoucher", ["Start Date"]),
              lang.get("deliverVoucher", ["End Date"]),
            ]}
          />
          <Input
            key="searchInput"
            placeholder={lang.get("deliverVoucher", ["Search"])}
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            style={{ width: 250 }}
          />
        </Space>,
        <Button
          key="csv"
          onClick={() => {
            const csvData = data.map((row) => ({
              [lang.get("deliverVoucher", ["Delivery Voucher Code"])]: row.code,
              [lang.get("deliverVoucher", ["Customer Name"])]: row.customerName,
              [lang.get("deliverVoucher", ["Voucher Date"])]: row.voucherDate,
              [lang.get("deliverVoucher", ["Invoice"])]: row.invoice,
              [lang.get("deliverVoucher", ["Address"])]: row.address,
              [lang.get("deliverVoucher", ["Stock Out Warehouse"])]:
                row.stockOutWarehouse,
              [lang.get("deliverVoucher", ["Total Amount"])]: row.totalAmount,
              [lang.get("deliverVoucher", ["Status"])]: row.status,
              [lang.get("deliverVoucher", ["Delivery Status"])]:
                row.deliveryStatus,
              [lang.get("deliverVoucher", ["Create User"])]: row.createUser,
            }));
            expobj.exportToCSV(csvData);
          }}
        >
          {lang.get("deliverVoucher", ["Export CSV"])}
        </Button>,
        <Button
          key="excel"
          onClick={() => {
            const excelData = data.map((row) => ({
              [lang.get("deliverVoucher", ["Delivery Voucher Code"])]: row.code,
              [lang.get("deliverVoucher", ["Date"])]: row.voucherDate,
              [lang.get("deliverVoucher", ["Customer Name"])]: row.customerName,
              [lang.get("deliverVoucher", ["Invoice"])]: row.invoice,
              [lang.get("deliverVoucher", ["Address"])]: row.address,
              [lang.get("deliverVoucher", ["Stock Out Warehouse"])]:
                row.stockOutWarehouse,
              [lang.get("deliverVoucher", ["Total Amount"])]: row.totalAmount,
              [lang.get("deliverVoucher", ["Status"])]: row.status,
              [lang.get("deliverVoucher", ["Delivery Status"])]:
                row.deliveryStatus,
              [lang.get("deliverVoucher", ["Create User"])]: row.createUser,
            }));
            expobj.exportToExcel(excelData);
          }}
        >
          {lang.get("deliverVoucher", ["Export Excel"])}
        </Button>,
      ]}
    />
  );
};

export default DeliverTable;
