import { Breadcrumb, Typography, Button, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import lang from "../../../lang";
import ReceivingVoucherTable from "./ReceivingVoucherTable";
import { useNavigate } from "react-router-dom";
//   import ReceivingVoucherAddBody from "./receiving-components/ReceivingVoucherAddBody";

const { Title } = Typography;



const ReceivingVoucher: React.FC = () => {
  const navigate = useNavigate();
  const handleCreateVoucherClick = () => {  
    navigate("/inventory-receiving-voucher-add");
  };
    
  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      {/* Breadcrumb */}
      <Breadcrumb style={{ marginBottom: "16px" }} separator=">">
        <Breadcrumb.Item>{lang.get("Inventory", ["Home"])}</Breadcrumb.Item>
        <Breadcrumb.Item>
          {lang.get("Inventory", ["Inventory"])}
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {lang.get("Inventory", ["Receiving Voucher"])}
        </Breadcrumb.Item>
      </Breadcrumb>

      {/* Page Title */}
      <Title level={2} style={{ marginBottom: "16px" }}>
        {lang.get("Inventory", ["Receiving Voucher"])} (7)
      </Title>

      {/* Filter and Actions */}
      <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
        <Col xs={24} sm={12} md={12} lg={12} style={{ textAlign: "left" }}>
          <Button type="primary" icon={<PlusOutlined />} onClick= {handleCreateVoucherClick}>
            {lang.get("Inventory", ["Inventory Receiving Code"])}
          </Button>
        </Col>
      </Row>

      {/* Table */}
      <ReceivingVoucherTable />
    </div>
  );
};

export default ReceivingVoucher;
