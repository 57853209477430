import React, { useState, useRef } from "react";
import { Input, Space, DatePicker, Tag, Select, Modal } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { ActionType, ProColumns, ProTable } from "@ant-design/pro-components";
import lang from "../../../lang";

const { RangePicker } = DatePicker;

interface InventoryShipmentData {
  shipmentNumber: string;
  customerName: string;
  invoiceNumber: string;
  deliveryVoucherNumber: string;
  fromWarehouse: string;
  shippingCarrier: string;
  trackingNumber: string;
  deliveryPerson: string;
  approvalStatus: string;
  shippingStatus: string;
}

const PackageTable: React.FC = () => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [searchText, setSearchText] = useState<string>("");
  const [selectedDates, setSelectedDates] = useState<[string, string] | null>(
    null
  );
  const actionRef = useRef<ActionType>();
  const [editingRow, setEditingRow] = React.useState<string | null>(null);

  const handleDateChange = (dates: any, dateStrings: [string, string]) => {
    setSelectedDates(dateStrings);
  };

  const handleStatusChange = (key: string, newStatus: string) => {
    setData((prevData) =>
      prevData.map((row) =>
        row.shipmentNumber === key ? { ...row, shippingStatus: newStatus } : row
      )
    );
  };


  const columns: ProColumns<InventoryShipmentData>[] = [
    {
      title: lang.get("inventory", ["Shipment Number"]),
      dataIndex: "shipmentNumber",
      key: "shipmentNumber",
      sorter: true,
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: lang.get("inventory", ["Customer Name"]),
      dataIndex: "customerName",
      key: "customerName",
      sorter: true,
    },
    {
      title: lang.get("inventory", ["Invoice Number"]),
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      sorter: true,
    },
    {
      title: lang.get("inventory", ["Delivery Voucher Number"]),
      dataIndex: "deliveryVoucherNumber",
      key: "deliveryVoucherNumber",
      sorter: true,
    },
    {
      title: lang.get("inventory", ["From Warehouse"]),
      dataIndex: "fromWarehouse",
      key: "fromWarehouse",
      sorter: true,
    },
    {
      title: lang.get("inventory", ["Shipping Carrier"]),
      dataIndex: "shippingCarrier",
      key: "shippingCarrier",
      sorter: true,
    },
    {
      title: lang.get("inventory", ["Tracking Number"]),
      dataIndex: "trackingNumber",
      key: "trackingNumber",
      sorter: true,
    },
    {
      title: lang.get("inventory", ["Delivery Person"]),
      dataIndex: "deliveryPerson",
      key: "deliveryPerson",
      sorter: true,
    },
    {
      title: lang.get("inventory", ["Approval Status"]),
      dataIndex: "approvalStatus",
      key: "approvalStatus",
      sorter: true,
      render: (status) => (
        <Tag
          color={status === "Approved" ? "green" : "red"}
          icon={
            status === "Approved" ? (
              <CheckCircleOutlined />
            ) : (
              <CloseCircleOutlined />
            )
          }
        >
          {status === "Approved"
            ? lang.get("inventory", ["Approved"])
            : lang.get("inventory", ["Rejected"])}
        </Tag>
      ),
    },
    {
      title: lang.get("inventory", ["Shipping Status"]),
      dataIndex: "shippingStatus",
      key: "shippingStatus",
      sorter: true,
      render: (shippingStatus: React.ReactNode, record) => {
       
        const currentStatus = typeof shippingStatus === "string" ? shippingStatus : "";
    
        return editingRow === record.shipmentNumber ? (
          <Select
            defaultValue={currentStatus}
            options={[
              { value: "Ready To Deliver", label: "Mark as Ready To Deliver" },
              {
                value: "Delivery In Progress",
                label: "Mark as Delivery In Progress",
              },
              { value: "Delivered", label: "Mark as Delivered" },
              { value: "Received", label: "Mark as Received" },
              { value: "Returned", label: "Mark as Returned" },
              { value: "Not Delivered", label: "Mark as Not Delivered" },
            ]}
            style={{ width: 200 }}
            dropdownStyle={{ width: 250 }}
            onBlur={() => setEditingRow(null)}
            onChange={(value) => {
                Modal.confirm({
                title: lang.get("deliverVoucher", ["Confirm Status Change"]),
               width: 300,
                content: lang.get("deliverVoucher", [
                  `Are you sure you want to change the status to "${value}"?`,
                ]),
                okText: lang.get("deliverVoucher", ["Yes"]),
                cancelText: lang.get("deliverVoucher", ["No"]),
                onOk: () => {
                  handleStatusChange(record.shipmentNumber, value);
                  setEditingRow(null);
                },
                onCancel: () => {
                  setEditingRow(null);
                },
                });
              }}
              />
            ) : (
          <Tag
            color={
              {
                "Ready To Deliver": "blue",
                "Delivery In Progress": "orange",
                Delivered: "green",
                Received: "purple",
                Returned: "red",
                "Not Delivered": "gray",
              }[currentStatus] || "default"
            }
            style={{ cursor: "pointer" }}
            onClick={() => setEditingRow(record.shipmentNumber)}
          >
            {currentStatus}
          </Tag>
        );
      },
    },
  ];

  const [data, setData] = useState<InventoryShipmentData[]>([
    {
      shipmentNumber: "S001",
      customerName: "ABC Pvt Ltd",
      invoiceNumber: "INV001",
      deliveryVoucherNumber: "DV001",
      fromWarehouse: "Warehouse 1",
      shippingCarrier: "Carrier 1",
      trackingNumber: "TRK001",
      deliveryPerson: "Person 1",
      approvalStatus: "Approved",
      shippingStatus: "Delivered",
    },
    {
      shipmentNumber: "S002",
      customerName: "XYZ Pvt Ltd",
      invoiceNumber: "INV002",
      deliveryVoucherNumber: "DV002",
      fromWarehouse: "Warehouse 2",
      shippingCarrier: "Carrier 2",
      trackingNumber: "TRK002",
      deliveryPerson: "Person 2",
      approvalStatus: "Rejected",
      shippingStatus: "Pending",
    },
    {
      shipmentNumber: "S003",
      customerName: "PQR Pvt Ltd",
      invoiceNumber: "INV003",
      deliveryVoucherNumber: "DV003",
      fromWarehouse: "Warehouse 3",
      shippingCarrier: "Carrier 3",
      trackingNumber: "TRK003",
      deliveryPerson: "Person 3",
      approvalStatus: "Approved",
      shippingStatus: "In Transit",
    },
  ]);

  return (
    <ProTable
      columns={columns}
      actionRef={actionRef}
      dataSource={data}
      rowKey="code"
      pagination={{
        defaultCurrent: 1,
        pageSize: pageSize,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50],
        onShowSizeChange: (_, size) => setPageSize(size),
      }}
      search={false}
      scroll={{ x: "max-content" }}
      toolBarRender={() => [
        <Space size="middle" wrap key="toolbar">
          {/* Date Range Picker */}
          <RangePicker
            key="datePicker"
            format="YYYY-MM-DD"
            onChange={handleDateChange}
            placeholder={[
              lang.get("inventory", ["From Date"]),
              lang.get("inventory", ["To Date"]),
            ]}
          />

          {/* Staff Selector */}
          <Select
            key="staffSelector"
            placeholder={lang.get("inventory", ["Staff"])}
            style={{ width: 200 }}
            options={[
              { value: "staff1", label: "Staff 1" },
              { value: "staff2", label: "Staff 2" },
              { value: "staff3", label: "Staff 3" },
            ]}
            showSearch
            filterOption={(input, option) =>
              option
                ? option.label.toLowerCase().includes(input.toLowerCase())
                : false
            }
          />

          {/* Inventory Delivery Voucher Selector */}
          <Select
            key="inventoryDeliveryVoucher"
            placeholder={lang.get("inventory", ["Inventory Delivery Voucher"])}
            style={{ width: 250 }}
            showSearch
            options={[
              { value: "voucher1", label: "Voucher 1" },
              { value: "voucher2", label: "Voucher 2" },
              { value: "voucher3", label: "Voucher 3" },
            ]}
            filterOption={(input, option) =>
              option
                ? option.label.toLowerCase().includes(input.toLowerCase())
                : false
            }
          />

          {/* Status Selector */}
          <Select
            key="statusSelector"
            placeholder={lang.get("inventory", ["Status"])}
            mode="multiple"
            allowClear
            style={{ width: 300 }}
            options={[
              { value: "approved", label: lang.get("inventory", ["Approved"]) },
              {
                value: "notApproved",
                label: lang.get("inventory", ["Not yet approved"]),
              },
              { value: "pending", label: lang.get("inventory", ["Pending"]) },
            ]}
          />

          {/* Search Input */}
          <Input
            key="searchInput"
            placeholder={lang.get("inventory", ["Search"])}
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            style={{ width: 250 }}
          />
        </Space>,
      ]}
    />
  );
};

export default PackageTable;
