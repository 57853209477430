import { useState } from "react";
import {
  Select,
  Button,
  DatePicker,
  Input,
  Modal,
  Form,
  message,
  Spin,
} from "antd";

import {
  DBSingleProject,
  SelectType,
  ProjectTimesheet,
} from "../../../../types/types";

import coreFunctions from "../../../../../../common/utils/coreFunctions";
import apiRequest from "../../../../../../common/utils/apiRequest";
import lang from "../../../../../../lang";

const TimesheetModal = ({
  project,
  setModalVisible,
  modalVisible,
}: {
  project: DBSingleProject | null;
  setModalVisible: (modalVisible: boolean) => void;
  modalVisible: boolean;
}) => {
  const initial = {
    id: "",
    project: project ? project.id : "",
    tags: [],
    start_time: "",
    end_time: "",
    task: "",
    member: "",
    note: "",
  };

  const [saving, setSaving] = useState(false);
  const [payloadData, setPayloadData] = useState<ProjectTimesheet>(initial);
  const [tagList, setTagList] = useState<SelectType[]>([]);
  const [taskList, setTaskList] = useState<SelectType[]>([]);
  const [membersList, setMembersList] = useState<SelectType[]>([]);
  const [projectList, setProjectList] = useState<SelectType[]>([]);
  const [projectListLoading, setProjectListLoading] = useState(false);
  const [customersList, setCustomersList] = useState<SelectType[]>([]);
  const [customer, setCustomer] = useState<string>("");

  const CORE_FUNCTIONS = new coreFunctions();

  const handleModalOk = () => {
    setSaving(true);

    const API_REQUEST = new apiRequest("project/timesheet/add", true);
    message
      .loading(lang.get("project", ["Adding project timesheet"]), 1)
      .then(() => {
        API_REQUEST.send(
          payloadData,
          (response: { status: string; message: string }) => {
            if (response.status == "success") {
              message.success(response.message);
              setModalVisible(false);
              setPayloadData(initial);
            } else {
              message.error(response.message);
            }
            setSaving(false);
          }
        );
      });
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const handleChange = (value: string | string[], field: string) => {
    setPayloadData({
      ...payloadData,
      [field]: value,
    });
  };

  return (
    <Modal
      title="Add Timesheet"
      destroyOnClose={true}
      maskClosable={false}
      open={modalVisible}
      onOk={handleModalOk}
      onCancel={handleModalCancel}
      centered
      footer={[
        <Button key="cancel" onClick={handleModalCancel}>
          Cancel
        </Button>,
        <Button
          key="save"
          type="primary"
          loading={saving}
          onClick={handleModalOk}
        >
          Save
        </Button>,
      ]}
    >
      <hr />
      <Form layout="vertical">
        <Form.Item label="Tags" name="tags">
          <Select
            showSearch
            mode="multiple"
            placeholder="Add Tags"
            onChange={(value) => handleChange(value, "tags")}
            size="large"
            filterOption={false}
            onInputKeyDown={(e) =>
              CORE_FUNCTIONS.searchOptions(
                e.currentTarget.value,
                "global",
                "tags",
                setTagList
              )
            }
            onClick={() => {
              CORE_FUNCTIONS.searchOptions("", "global", "tags", setTagList);
            }}
          >
            {tagList.map((tag) => (
              <Select.Option key={tag.id} value={tag.id}>
                {tag.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Start Time" name="start_time">
          <DatePicker
            showTime
            onChange={(date) =>
              handleChange(
                CORE_FUNCTIONS.formatISODateTime(date.toISOString()),
                "start_time"
              )
            }
            size="large"
            format="YYYY-MM-DD HH:mm"
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item label="End Time" name="end_time">
          <DatePicker
            showTime
            onChange={(date) =>
              handleChange(
                CORE_FUNCTIONS.formatISODateTime(date.toISOString()),
                "end_time"
              )
            }
            size="large"
            format="YYYY-MM-DD HH:mm"
            style={{ width: "100%" }}
          />
        </Form.Item>
        {!project && (
          <>
            <Form.Item
              label={<span style={{ fontSize: "14px" }}>Customer</span>}
              name="customer"
            >
              <Select
                showSearch
                onChange={(e) => setCustomer(e)}
                placeholder="Select Customer"
                size="large"
                filterOption={false}
                onSearch={(e) => {
                  CORE_FUNCTIONS.searchOptions(
                    e,
                    "clients",
                    "clients",
                    setCustomersList
                  );
                }}
                onClick={() => {
                  CORE_FUNCTIONS.searchOptions(
                    "",
                    "clients",
                    "clients",
                    setCustomersList
                  );
                }}
                options={customersList.map((option) => ({
                  label: option.name,
                  value: option.id,
                }))}
              />
            </Form.Item>
            <Form.Item label="Project" name="project">
              <Select
                showSearch
                placeholder="Add Project"
                onChange={(value) => handleChange(value, "project")}
                loading={projectListLoading}
                size="large"
                filterOption={false}
                onSearch={(e) => {
                  CORE_FUNCTIONS.searchOptions(
                    e,
                    "projects",
                    "projects",
                    setProjectList,
                    { customer: customer }
                  );
                }}
                onClick={() => {
                  CORE_FUNCTIONS.searchOptions(
                    "",
                    "projects",
                    "projects",
                    setProjectList,
                    { customer: customer }
                  );
                }}
              >
                {projectList.map((project) => (
                  <Select.Option key={project.id} value={project.id}>
                    {project.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </>
        )}

        <Form.Item label="Task" name="task">
          <Select
            showSearch
            placeholder="Add Task"
            onChange={(value) => handleChange(value, "task")}
            size="large"
            onInputKeyDown={(e) =>
              CORE_FUNCTIONS.searchOptions(
                e.currentTarget.value,
                "projects",
                "tasks",
                setTaskList,
                { project: payloadData?.project }
              )
            }
          >
            {taskList.map((task) => (
              <Select.Option key={task.id} value={task.id}>
                {task.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Member" name="member">
          <Select
            showSearch
            placeholder="Select Members="
            onChange={(value) => handleChange(value, "member")}
            size="large"
            onInputKeyDown={(e) =>
              CORE_FUNCTIONS.searchOptions(
                e.currentTarget.value,
                "hr",
                "records",
                setMembersList
              )
            }
            onClick={() => {
              CORE_FUNCTIONS.searchOptions("", "hr", "records", setMembersList);
            }}
          >
            {membersList.map((member) => (
              <Select.Option key={member.id} value={member.id}>
                {member.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Note" name="note">
          <Input.TextArea
            placeholder="Add a note"
            onChange={(e) => handleChange(e.target.value, "note")}
            rows={4}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TimesheetModal;
