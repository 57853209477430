import { useState, useEffect, useRef } from "react";
import { Menu, Button, Skeleton, Card } from "antd";
import {
  DollarOutlined,
  FileOutlined,
  BarChartOutlined,
  FolderOutlined,
  ProjectOutlined,
  FileSearchOutlined,
  ClockCircleOutlined,
  FlagOutlined,
  UserOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";

import ProfileContent from "./tabs/ProfileTab";
import SaleContent from "./tabs/SalesTab";
import Expenses from "./expenses/Expenses";
import Contract from "./contracts/Contract";
import Projects from "./project/Projects";
import Reminder from "./reminder/Reminder";

import { DBCustomer } from "../../types/types";

const ProfileDetailsTab = (props: {
  customer: DBCustomer;
  preload: boolean;
  setRefresh: (e: boolean) => void;
  refresh: boolean;
}) => {
  const [activeKey, setActiveKey] = useState("profile");
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1920);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollTabs = (direction: "left" | "right") => {
    if (scrollContainerRef.current) {
      const scrollAmount = direction === "right" ? 150 : -150;
      scrollContainerRef.current.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const topTabs = [
    { id: "profile", label: "Profile", icon: <UserOutlined /> },
    { id: "sales", label: "Sales", icon: <BarChartOutlined /> },
    // { id: "subscriptions", label: "Subscriptions", icon: <BellOutlined /> },
    { id: "expenses", label: "Expenses", icon: <DollarOutlined /> },
    {
      id: "contracts",
      label: "Service Agreements",
      icon: <FileSearchOutlined />,
    },
    { id: "projects", label: "Projects", icon: <ProjectOutlined /> },
    { id: "tasks", label: "Tasks", icon: <FileOutlined /> },
    { id: "tickets", label: "Tickets", icon: <FileOutlined /> },
    { id: "files", label: "Files", icon: <FolderOutlined /> },
    { id: "reminders", label: "Reminders", icon: <ClockCircleOutlined /> },
    { id: "other", label: "Other", icon: <FlagOutlined /> },
  ];

  const tabContent: Record<string, JSX.Element> = {
    profile: (
      <ProfileContent
        client={props.customer}
        preload={props.preload}
        setRefresh={props.setRefresh}
        refresh={props.refresh}
      />
    ),
    sales: (
      <SaleContent
        client={props.customer}
        preload={props.preload}
        setRefresh={props.setRefresh}
        refresh={props.refresh}
      />
    ),
    subscriptions: <div>Subscriptions Content</div>,
    expenses: (
      <Expenses
        client={props.customer}
        preload={props.preload}
        setRefresh={props.setRefresh}
        refresh={props.refresh}
      />
    ),
    contracts: (
      <Contract
        client={props.customer}
        preload={props.preload}
        setRefresh={props.setRefresh}
        refresh={props.refresh}
      />
    ),
    projects: (
      <Projects
        client={props.customer}
        preload={props.preload}
        setRefresh={props.setRefresh}
        refresh={props.refresh}
      />
    ),
    tasks: <div>Tasks Content</div>,
    tickets: <div>Tickets Content</div>,
    files: <div>Files Content</div>,
    vault: <div>Vault Content</div>,
    reminders: (
      <Reminder
        client={props.customer}
        preload={props.preload}
        setRefresh={props.setRefresh}
        refresh={props.refresh}
      />
    ),
    other: <div>Other Content</div>,
  };

  return (
    <>
      <div
        style={{
          background: "#fff",
          borderRadius: 5,
          margin: "15px 5px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {isSmallScreen && (
            <Button
              icon={<LeftOutlined />}
              type="text"
              onClick={() => scrollTabs("left")}
            />
          )}
          <div
            ref={scrollContainerRef}
            style={{
              display: "flex",
              overflowX: "auto",
              scrollbarWidth: "none",
              gap: "10px",
              // padding: "0 10px",
              width: "100%",
            }}
          >
            <Menu
              onClick={({ key }) => setActiveKey(key)}
              selectedKeys={[activeKey]}
              mode="horizontal"
              style={{
                display: "flex",
                justifyContent: "start",
                columnGap: "30px",
                fontSize: "14px",
              }}
              items={topTabs.map((tab) => ({
                key: tab.id,
                label: tab.label,
                icon: tab.icon,
              }))}
            />
          </div>
          {isSmallScreen && (
            <Button
              icon={<RightOutlined />}
              type="text"
              onClick={() => scrollTabs("right")}
            />
          )}
        </div>
      </div>
      {props.preload ? (
        <Card bordered={false}>
          <Skeleton active />
        </Card>
      ) : (
        <div style={{ marginTop: "16px" }}>{tabContent[activeKey]}</div>
      )}
    </>
  );
};

export default ProfileDetailsTab;
