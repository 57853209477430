import React, { useState, useRef } from "react";
import { Tag, Input, Button, Space, Select, DatePicker } from "antd";
import { ActionType, ProColumns, ProTable } from "@ant-design/pro-components";
import { SearchOutlined } from "@ant-design/icons";
import lang from "../../../../../lang";
import Export from "../../../../../common/components/Export";

const { Option } = Select;
const { RangePicker } = DatePicker;

const TransactionHistoryTable: React.FC = () => {
  const [pageSize, setPageSize] = useState(15);
  const searchInput = useRef(null);
  const actionRef = useRef<ActionType>();
  const [searchAttributes, setSearchAttributes] = useState<
    { key: string; value: string }[]
  >([]);
  const [allSearch, setAllSearch] = useState<string>("");
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const expobj = new Export(
    lang.get("transactions", ["Transaction History"]) +
      ", " +
      new Date().toDateString()
  );
  const [dateRange, setDateRange] = useState<[string | null, string | null]>([
    null,
    null,
  ]);

  const handleSearch = (field: string, value: string) => {
    setAllSearch("");
    const existingAttribute = searchAttributes.find(
      (attr) => attr.key === field
    );

    if (existingAttribute) {
      setSearchAttributes(
        searchAttributes.map((attr) =>
          attr.key === field ? { ...attr, value } : attr
        )
      );
    } else {
      setSearchAttributes([
        ...searchAttributes,
        {
          key: field,
          value,
        },
      ]);
    }
  };

  const handleFilterChange = (value: string[]) => {
    setSelectedFilters(value);
    console.log("Selected Filters:", value); 
  };

  const handleDateChange = (dates: [any, any] | null, dateStrings: [string, string]) => {
    if (dates) {
      setDateRange([dateStrings[0], dateStrings[1]]);
      console.log("Selected Date Range:", dateStrings); 
    } else {
      setDateRange([null, null]);
    }
  };

  const searchCols = (field: string, label: string) => {
    return {
      filterDropdown: (props: any) => {
        const { close } = props;

        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={lang.get("transactions", [`Search ${label}`])}
              onChange={(e) => handleSearch(field, e.target.value)}
              value={
                searchAttributes.filter((item) => item.key === field)[0]?.value
              }
              style={{ marginBottom: 8, display: "block" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  close();
                  if (actionRef.current) {
                    actionRef.current?.reset?.();
                    actionRef.current.reload();
                  }
                }
              }}
            />
            <Space>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="small"
                onClick={() => {
                  actionRef.current?.reset?.();
                  actionRef.current?.reload();
                }}
                style={{ width: 90 }}
              >
                {lang.get("transactions", ["Search"])}
              </Button>
              <Button
                onClick={() => close()}
                size="small"
                style={{ width: 90 }}
              >
                {lang.get("transactions", ["Close"])}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
    };
  };

  const columns: ProColumns[] = [
    {
      title: "Form Code",
      dataIndex: "formCode",
      key: "formCode",
      sorter: true,
      ...searchCols("formCode", "Form Code"),
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Commodity Code",
      dataIndex: "commodityCode",
      key: "commodityCode",
      sorter: true,
      ...searchCols("commodityCode", "Commodity Code"),
    },
    {
      title: "Warehouse Code",
      dataIndex: "warehouseCode",
      key: "warehouseCode",
      sorter: true,
      ...searchCols("warehouseCode", "Warehouse Code"),
    },
    {
      title: "Warehouse Name",
      dataIndex: "warehouseName",
      key: "warehouseName",
      sorter: true,
      render: (text) => <Tag color="blue">{text}</Tag>,
    },
    {
      title: "Voucher Date",
      dataIndex: "voucherDate",
      key: "voucherDate",
      sorter: true,
      ...searchCols("voucherDate", "Voucher Date"),
    },
    {
      title: "Opening Stock",
      dataIndex: "openingStock",
      key: "openingStock",
      sorter: true,
    },
    {
      title: "Closing Stock",
      dataIndex: "closingStock",
      key: "closingStock",
      sorter: true,
    },
    {
      title: "Lot Number/Quantity",
      dataIndex: "lotQuantity",
      key: "lotQuantity",
      sorter: true,
    },
  ];

  const dataSource = [
    {
      key: "1",
      formCode: "01_Truecam Battery",
      commodityCode: "001",
      warehouseCode: "001",
      warehouseName: "Main",
      voucherDate: "2024-08-02",
      openingStock: 0,
      closingStock: 10,
      lotQuantity: 10,
    },
  ];

  return (
    <div style={{ background: "#f5f7fa" }}>
      <ProTable
        headerTitle="Transaction History"
        columns={columns}
        actionRef={actionRef}
        dataSource={dataSource}
        rowKey="key"
        cardBordered
        pagination={{
          defaultCurrent: 1,
          pageSize: pageSize,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50],
          showTotal: (total, range) =>
            `Showing ${range[0]}-${range[1]} of ${total} items`,
          onShowSizeChange: (current, size) => {
            setPageSize(size);
          },
        }}
        search={false}
        scroll={{ x: 800 }}
        toolBarRender={() => [
          <Input
            style={{ width: "auto" }}
            key="all_search"
            allowClear
            placeholder={lang.get("transactions", ["Global Search"])}
            value={allSearch}
            onChange={(e) => {
              setAllSearch(e.target.value);
              setSearchAttributes([]);
              actionRef?.current?.reload();
            }}
          />,
          <Select
          mode="multiple"
          allowClear
          style={{ width: "300px" }}
          placeholder="Filter by Transaction Type"
          onChange={handleFilterChange}
        >
          <Option value="Inventory Receiving Note">Inventory Receiving Note</Option>
          <Option value="Inventory Delivery Note">Inventory Delivery Note</Option>
          <Option value="Packaging List">Packaging List</Option>
          <Option value="Return Orders">Return Orders</Option>
          <Option value="Internal Delivery Note">Internal Delivery Note</Option>
          <Option value="Stock Adjustment">Stock Adjustment</Option>
        </Select>,
          <RangePicker
          onChange={handleDateChange}
          style={{ width: "300px" }}
          placeholder={["Start Date", "End Date"]}
        />,
          <Button
            key="csv"
            onClick={() => {
              const csvData = dataSource.map((row) => ({
                "Form Code": row.formCode,
                "Commodity Code": row.commodityCode,
                "Warehouse Code": row.warehouseCode,
                "Warehouse Name": row.warehouseName,
                "Voucher Date": row.voucherDate,
                "Opening Stock": row.openingStock,
                "Closing Stock": row.closingStock,
                "Lot Number/Quantity": row.lotQuantity,
              }));
              expobj.exportToCSV(csvData);
            }}
          >
            Export CSV
          </Button>,
          <Button
            key="excel"
            onClick={() => {
              const excelData = dataSource.map((row) => ({
                "Form Code": row.formCode,
                "Commodity Code": row.commodityCode,
                "Warehouse Code": row.warehouseCode,
                "Warehouse Name": row.warehouseName,
                "Voucher Date": row.voucherDate,
                "Opening Stock": row.openingStock,
                "Closing Stock": row.closingStock,
                "Lot Number/Quantity": row.lotQuantity,
              }));
              expobj.exportToExcel(excelData);
            }}
          >
            Export Excel
          </Button>,
          <Button
            key="pdf"
            onClick={() => {
              expobj.exportToPDF({
                head: [
                  [
                    "Form Code",
                    "Commodity Code",
                    "Warehouse Code",
                    "Warehouse Name",
                    "Voucher Date",
                    "Opening Stock",
                    "Closing Stock",
                    "Lot Number/Quantity",
                  ],
                ],
                body: dataSource.map((row) => [
                  row.formCode,
                  row.commodityCode,
                  row.warehouseCode,
                  row.warehouseName,
                  row.voucherDate,
                  row.openingStock,
                  row.closingStock,
                  row.lotQuantity,
                ]),
              });
            }}
          >
            Export PDF
          </Button>,
        ]}
      />
    </div>
  );
};

export default TransactionHistoryTable;
