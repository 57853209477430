import React, { useState, useRef } from "react";
import { Tag, Input, Button, Space } from "antd";
import { ActionType, ProColumns, ProTable } from "@ant-design/pro-components";
import { SearchOutlined } from "@ant-design/icons";
import lang from "../../../../../lang";
import Export from "../../../../../common/components/Export";

const StockInventoryTable: React.FC = () => {
  const [pageSize, setPageSize] = useState(15);
  const searchInput = useRef(null);
  const actionRef = useRef<ActionType>();
  const [searchAttributes, setSearchAttributes] = useState<
    { key: string; value: string }[]
  >([]);
  const [allSearch, setAllSearch] = useState<string>("");

  const handleSearch = (field: string, value: string) => {
    setAllSearch("");
    const existingAttribute = searchAttributes.find(
      (attr) => attr.key === field
    );

    if (existingAttribute) {
      setSearchAttributes(
        searchAttributes.map((attr) =>
          attr.key === field ? { ...attr, value } : attr
        )
      );
    } else {
      setSearchAttributes([
        ...searchAttributes,
        {
          key: field,
          value,
        },
      ]);
    }
  };

  const expobj = new Export(
    lang.get("inventory", ["Stock Inventory"]) +
      ", " +
      new Date().toDateString()
  );

  const searchCols = (field: string, label: string) => {
    return {
      filterDropdown: (props: any) => {
        const { close } = props;

        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={lang.get("inventory", [`Search ${label}`])}
              onChange={(e) => handleSearch(field, e.target.value)}
              value={
                searchAttributes.filter((item) => item.key === field)[0]?.value
              }
              style={{ marginBottom: 8, display: "block" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  close();
                  if (actionRef.current) {
                    actionRef.current?.reset?.();
                    actionRef.current.reload();
                  }
                }
              }}
            />
            <Space>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="small"
                onClick={() => {
                  actionRef.current?.reset?.();
                  actionRef.current?.reload();
                }}
                style={{ width: 90 }}
              >
                {lang.get("inventory", ["Search"])}
              </Button>
              <Button
                onClick={() => close()}
                size="small"
                style={{ width: 90 }}
              >
                {lang.get("inventory", ["Close"])}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
    };
  };

  const columns: ProColumns[] = [
    {
      title: "Commodity Name",
      dataIndex: "commodityName",
      key: "commodityName",
      sorter: true,
      ...searchCols("commodityName", "Commodity Name"),
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Expiry Date",
      dataIndex: "expiryDate",
      key: "expiryDate",
      sorter: true,
      ...searchCols("expiryDate", "Expiry Date"),
    },
    {
      title: "Lot Number",
      dataIndex: "lotNumber",
      key: "lotNumber",
      sorter: true,
      ...searchCols("lotNumber", "Lot Number"),
    },
    {
      title: "Warehouse Name",
      dataIndex: "warehouseName",
      key: "warehouseName",
      sorter: true,
      render: (text) => <Tag color="blue">{text}</Tag>,
    },
    {
      title: "Inventory",
      dataIndex: "inventory",
      key: "inventory",
      sorter: true,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Sale Price",
      dataIndex: "salePrice",
      key: "salePrice",
      sorter: true,
    },
    {
      title: "Purchase Price",
      dataIndex: "purchasePrice",
      key: "purchasePrice",
      sorter: true,
    },
    {
      title: "Tax",
      dataIndex: "tax",
      key: "tax",
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
    },
  ];

  const dataSource = [
    {
      key: "1",
      commodityName: "01_Truecam Battery",
      expiryDate: "-",
      lotNumber: "-",
      warehouseName: "Main",
      inventory: 10,
      name: "Nos",
      salePrice: "0.00",
      purchasePrice: "0.00",
      tax: "0.00",
      status: "Available",
    },

    {
      key: "2",
      commodityName: "Truecam Battery",
      expiryDate: "-",
      lotNumber: "-",
      warehouseName: "Main",
      inventory: 10,
      name: "Nos",
      salePrice: 0.0,
      purchasePrice: 0.0,
      tax: 0,
      status: "Available",
    },
    {
      key: "3",
      commodityName: "Truecam Battery",
      expiryDate: "-",
      lotNumber: "-",
      warehouseName: "Main",
      inventory: 10,
      name: "Nos",
      salePrice: 0.0,
      purchasePrice: 0.0,
      tax: 0,
      status: "Available",
    },
    {
      key: "4",
      commodityName: "Truecam Battery",
      expiryDate: "-",
      lotNumber: "-",
      warehouseName: "Main",
      inventory: 10,
      name: "Nos",
      salePrice: 0.0,
      purchasePrice: 0.0,
      tax: 0,
      status: "Available",
    },
    {
      key: "5",
      commodityName: "Truecam Battery",
      expiryDate: "-",
      lotNumber: "-",
      warehouseName: "Main",
      inventory: 10,
      name: "Nos",
      salePrice: 0.0,
      purchasePrice: 0.0,
      tax: 0,
      status: "Available",
    },
  ];

  return (
    <div style={{ background: "#f5f7fa" }}>
      <ProTable
        headerTitle="Stock Inventory"
        columns={columns}
        actionRef={actionRef}
        dataSource={dataSource}
        rowKey="key"
        cardBordered
        pagination={{
          defaultCurrent: 1,
          pageSize: pageSize,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50],
          showTotal: (total, range) =>
            `Showing ${range[0]}-${range[1]} of ${total} items`,
          onShowSizeChange: (current, size) => {
            setPageSize(size);
          },
        }}
        search={false}
        scroll={{ x: 800 }}
        toolBarRender={() => [
          <Input
            style={{ width: "auto" }}
            key="all_search"
            allowClear
            placeholder={lang.get("inventory", ["Global Search"])}
            value={allSearch}
            onChange={(e) => {
              setAllSearch(e.target.value);
              setSearchAttributes([]);
              actionRef?.current?.reload();
            }}
          />,

          <Button
            key="csv"
            onClick={() => {
              const csvData = dataSource.map((row) => ({
                "Commodity Name": row.commodityName,
                "Expiry Date": row.expiryDate,
                "Lot Number": row.lotNumber,
                "Warehouse Name": row.warehouseName,
                Inventory: row.inventory,
                Name: row.name,
                "Sale Price": row.salePrice,
                "Purchase Price": row.purchasePrice,
                Tax: row.tax,
                Status: row.status,
              }));
              expobj.exportToCSV(csvData);
            }}
          >
            Export CSV
          </Button>,
          <Button
            key="excel"
            onClick={() => {
              const excelData = dataSource.map((row) => ({
                "Commodity Name": row.commodityName,
                "Expiry Date": row.expiryDate,
                "Lot Number": row.lotNumber,
                "Warehouse Name": row.warehouseName,
                Inventory: row.inventory,
                Name: row.name,
                "Sale Price": row.salePrice,
                "Purchase Price": row.purchasePrice,
                Tax: row.tax,
                Status: row.status,
              }));
              expobj.exportToExcel(excelData);
            }}
          >
            Export Excel
          </Button>,
          <Button
            key="pdf"
            onClick={() => {
              expobj.exportToPDF({
                head: [
                  [
                    "Commodity Name",
                    "Expiry Date",
                    "Lot Number",
                    "Warehouse Name",
                    "Inventory",
                    "Name",
                    "Sale Price",
                    "Purchase Price",
                    "Tax",
                    "Status",
                  ],
                ],
                body: dataSource.map((row) => [
                  row.commodityName,
                  row.expiryDate,
                  row.lotNumber,
                  row.warehouseName,
                  row.inventory,
                  row.name,
                  row.salePrice,
                  row.purchasePrice,
                  row.tax,
                  row.status,
                ]),
              });
            }}
          >
            Export PDF
          </Button>,
        ]}
      />
    </div>
  );
};

export default StockInventoryTable;
