import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

class Export {
  private static filename = new Date().toDateString();

  constructor(export_as: string) {
    Export.filename = export_as;
  }

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  exportToCSV = (data: any) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Customers");

    const csvFile = XLSX.write(workbook, {
      bookType: "csv",
      type: "array",
    });

    saveAs(new Blob([csvFile], { type: "text/csv" }), Export.filename + ".csv");
  };

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  exportToExcel = (data: any) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Customers");

    XLSX.writeFile(workbook, Export.filename + ".xlsx");
  };

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  exportToPDF = (data: any) => {
    const doc = new jsPDF();

    if (data.header) {
      for (let i = 0; i < data.header.length; i++) {
        let header = data.header[i];
        if (header.type == "image" && header.image) {
          doc.addImage(
            header.image,
            header.x,
            header.y,
            header.width,
            header.height
          );
        } else if (header.type == "text" && header.text) {
          if (header.fontStyle) {
            doc.setFont(header.fontStyle, header.fontVariant);
          }
          if (header.fontSize) {
            doc.setFontSize(header.fontSize);
          }

          doc.text(header.text, header.x, header.y);
        }
      }
    }

    autoTable(doc, {
      head: data.head,
      body: data.body,
    });

    doc.save(Export.filename + ".pdf");
  };
}

export default Export;
