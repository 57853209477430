import { Card, Col, Row, Tooltip, Breadcrumb } from "antd";
import { Link } from "react-router-dom";

import ProjectStatistic from "../../modules/project/project-list/components/ProjectStatistic";
import CustomerListStatitstic from "../../modules/customer/customer-list/components/CustomerListStatitstic";
import { NodeCollapseOutlined, UserOutlined } from "@ant-design/icons";

import WhatsNew from "../../WhatsNew";

import lang from "../../lang";

function Home() {
  return (
    <>
      <WhatsNew />
      <div className="layout-content p-5">
        <Row className="rowgap-vbox border-bottom" gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <h3>Dashboard</h3>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="text-end">
            <h4>
              <Tooltip placement="bottom" title="Project List">
                <Link to="/project-list" className="mx-2">
                  <NodeCollapseOutlined />
                </Link>
              </Tooltip>
              <Tooltip placement="bottom" title="Customer List">
                <Link to="/customer-list" className="mx-2">
                  <UserOutlined />
                </Link>
              </Tooltip>
            </h4>
          </Col>
        </Row>

        <Row className="rowgap-vbox mt-5" gutter={[24, 1]}>
          <h5>
            <UserOutlined /> Customer Overview
          </h5>
          <CustomerListStatitstic updateTable={false} />
        </Row>

        <Row className="rowgap-vbox mt-3" gutter={[24, 1]}>
          <h5>
            <NodeCollapseOutlined /> Project Overview
          </h5>
          <ProjectStatistic client={null} />
        </Row>
      </div>
    </>
  );
}

export default Home;
