import React, { useState, useRef } from "react";
import { Tag, Input, Button, Space, Select, DatePicker } from "antd";
import {
  SearchOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { ActionType, ProColumns, ProTable } from "@ant-design/pro-components";
import lang from "../../../lang";
import Export from "../../../common/components/Export";

const { Option } = Select;
const { RangePicker } = DatePicker;

const OrderReturnsTable = () => {
  const [pageSize, setPageSize] = useState(15);
  const [selectedFilters, setSelectedFilters] = useState({
    vendors: null,
    status: null,
    dateRange: null,
  });
  const actionRef = useRef<ActionType>();
  const expobj = new Export(
    lang.get("orderReturns", ["Order Returns"]) + ", " + new Date().toDateString()
  );

  const handleFilterChange = (key: string, value: any) => {
    setSelectedFilters({ ...selectedFilters, [key]: value });
    actionRef.current?.reload();
  };

  const columns: ProColumns<any>[] = [
    {
      title: lang.get("orderReturns", ["Order Return Number"]),
      dataIndex: "orderReturnNumber",
      key: "orderReturnNumber",
      sorter: true,
      render: (text) => <a>{text}</a>,
    },
    {
      title: lang.get("orderReturns", ["Vendor"]),
      dataIndex: "vendor",
      key: "vendor",
      sorter: true,
    },
    {
      title: lang.get("orderReturns", ["Total Amount"]),
      dataIndex: "totalAmount",
      key: "totalAmount",
      sorter: true,
    },
    {
      title: lang.get("orderReturns", ["Discount Total"]),
      dataIndex: "discountTotal",
      key: "discountTotal",
      sorter: true,
    },
    {
      title: lang.get("orderReturns", ["Total After Discount"]),
      dataIndex: "totalAfterDiscount",
      key: "totalAfterDiscount",
      sorter: true,
    },
    {
      title: lang.get("orderReturns", ["Date Created"]),
      dataIndex: "dateCreated",
      key: "dateCreated",
      sorter: true,
    },
    {
      title: lang.get("orderReturns", ["Status"]),
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color={status === "Completed" ? "green" : "red"}>{status}</Tag>
      ),
    },
  ];

  const data = [
    {
      id: "1",
      orderReturnNumber: "#OR-0001",
      vendor: "Vendor A",
      totalAmount: "LKR 1,000,000.00",
      discountTotal: "LKR 50,000.00",
      totalAfterDiscount: "LKR 950,000.00",
      dateCreated: "2023-01-15",
      status: "Completed",
    },
    {
      id: "2",
      orderReturnNumber: "#OR-0002",
      vendor: "Vendor B",
      totalAmount: "LKR 500,000.00",
      discountTotal: "LKR 25,000.00",
      totalAfterDiscount: "LKR 475,000.00",
      dateCreated: "2023-02-10",
      status: "Pending",
    },
  ];

  return (
    <ProTable
      columns={columns}
      actionRef={actionRef}
      dataSource={data}
      rowKey="id"
      pagination={{
        defaultCurrent: 1,
        pageSize: pageSize,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50],
        onShowSizeChange: (_, size) => setPageSize(size),
      }}
      search={false}
      scroll={{ x: "max-content" }}
      toolBarRender={() => [
        <Space size="middle" wrap>
          <RangePicker
            onChange={(dates, dateStrings) => handleFilterChange("dateRange", dateStrings)}
          />
          <Select
            placeholder={lang.get("orderReturns", ["Filter by Status"])}
            style={{ width: 200 }}
            allowClear
            onChange={(value) => handleFilterChange("status", value)}
          >
            <Option value="Completed">Completed</Option>
            <Option value="Pending">Pending</Option>
            <Option value="Rejected">Rejected</Option>
          </Select>
          <Select
            placeholder={lang.get("orderReturns", ["Filter by Vendor"])}
            style={{ width: 200 }}
            allowClear
            onChange={(value) => handleFilterChange("vendors", value)}
          >
            <Option value="Vendor A">Vendor A</Option>
            <Option value="Vendor B">Vendor B</Option>
          </Select>
        </Space>,
        <Button
          key="csv"
          icon={<FileTextOutlined />}
          onClick={() => {
            const csvData = data.map((row) => ({
              [lang.get("orderReturns", ["Order Return Number"])]: row.orderReturnNumber,
              [lang.get("orderReturns", ["Vendor"])]: row.vendor,
              [lang.get("orderReturns", ["Total Amount"])]: row.totalAmount,
              [lang.get("orderReturns", ["Discount Total"])]: row.discountTotal,
              [lang.get("orderReturns", ["Total After Discount"])]: row.totalAfterDiscount,
              [lang.get("orderReturns", ["Date Created"])]: row.dateCreated,
              [lang.get("orderReturns", ["Status"])]: row.status,
            }));
            expobj.exportToCSV(csvData);
          }}
        >
          {lang.get("orderReturns", ["Export CSV"])}
        </Button>,
        <Button
          key="excel"
          icon={<FileExcelOutlined />}
          onClick={() => {
            const excelData = data.map((row) => ({
              [lang.get("orderReturns", ["Order Return Number"])]: row.orderReturnNumber,
              [lang.get("orderReturns", ["Vendor"])]: row.vendor,
              [lang.get("orderReturns", ["Total Amount"])]: row.totalAmount,
              [lang.get("orderReturns", ["Discount Total"])]: row.discountTotal,
              [lang.get("orderReturns", ["Total After Discount"])]: row.totalAfterDiscount,
              [lang.get("orderReturns", ["Date Created"])]: row.dateCreated,
              [lang.get("orderReturns", ["Status"])]: row.status,
            }));
            expobj.exportToExcel(excelData);
          }}
        >
          {lang.get("orderReturns", ["Export Excel"])}
        </Button>,
        <Button
          key="pdf"
          icon={<FilePdfOutlined />}
          onClick={() => {
            expobj.exportToPDF({
              head: [
                [
                  lang.get("orderReturns", ["Order Return Number"]),
                  lang.get("orderReturns", ["Vendor"]),
                  lang.get("orderReturns", ["Total Amount"]),
                  lang.get("orderReturns", ["Discount Total"]),
                  lang.get("orderReturns", ["Total After Discount"]),
                  lang.get("orderReturns", ["Date Created"]),
                  lang.get("orderReturns", ["Status"]),
                ],
              ],
              body: data.map((row) => [
                row.orderReturnNumber,
                row.vendor,
                row.totalAmount,
                row.discountTotal,
                row.totalAfterDiscount,
                row.dateCreated,
                row.status,
              ]),
            });
          }}
        >
          {lang.get("orderReturns", ["Export PDF"])}
        </Button>,
      ]}
    />
  );
};

export default OrderReturnsTable;
