import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Col,
  Row,
  Button,
  Modal,
  Input,
  message,
  Breadcrumb,
  Form,
  Typography,
  Checkbox,
} from "antd";
import { MailOutlined, DeleteOutlined, KeyOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import ProfileCard from "./components/ProfileCard";
import CustomerAddressCard from "./components/AddressCard";
import ProfileDetailsTab from "./components/ProfileDetailsTab";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  ReloadOutlined,
} from "@ant-design/icons";
import lang from "../../../lang";

import apiRequest from "../../../common/utils/apiRequest";
import { DBCustomer } from "../types/types";

const { Title } = Typography;

export type Customer = {
  id: string;
  name: string;
  vat_number: string;
  website: string;
  zip_code: string;
  city: string;
  state: string;
  country: string;
  phone: string;
  currency: string;
  address: string;
  last_login: string;
  default_language: string;
  last_order?: string;
};

const Profile = () => {
  const navigate = useNavigate();
  const [preload, setPreload] = useState(true);
  const [customer, setCustomer] = useState<DBCustomer>({} as DBCustomer);
  const [mailSubject, setMailSubject] = useState("");
  const [mailBody, setMailBody] = useState("");
  const [openMailModal, setOpenMailModal] = useState(false);
  const [openPassModal, setopenPassModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [password, setPassword] = useState("");

  const { id } = useParams();

  const deleteCustomer = () => {
    Modal.confirm({
      title: lang.get("customer", ["Are you sure?"]),
      content: lang.get("customer", ["You won't be able to revert this!"]),
      okText: lang.get("customer", ["Yes, delete it!"]),
      okType: "danger",
      cancelText: lang.get("customer", ["Cancel"]),
      onOk: () => {
        message.loading(lang.get("customer", ["Deleting..."]), 1).then(() => {
          const API_REQUEST = new apiRequest("client/delete", true);

          API_REQUEST.send({
            client: id,
          }).then(
            (response: {
              status: string;
              data: DBCustomer;
              message: string;
            }) => {
              if (response) {
                if (response.status === "success") {
                  message.success(
                    lang.get("customer", ["Customer deleted successfully."]),
                    2
                  );
                  navigate("/customer-list", { replace: true });
                } else {
                  message.error(lang.get("customer", [response.message]), 2);
                }
              }
            }
          );
        });
      },
    });
  };

  const generateRandomPassword = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+";
    let password = "";
    for (let i = 0; i < 12; i++) {
      password += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return password;
  };

  const sendMail = () => {
    if (mailSubject && mailBody) {
      message.loading(lang.get("customer", ["Sending mail..."]), 1).then(() => {
        message.success(lang.get("customer", ["Email sent successfully."]), 2);
        setMailSubject("");
        setMailBody("");
        setOpenMailModal(false);
      });
    } else {
      message.error(
        lang.get("customer", ["Please fill in the subject and body."])
      );
    }
  };

  useEffect(() => {
    const API_REQUEST = new apiRequest("client/get", true);

    API_REQUEST.send({
      client: id,
    }).then((response: { status: string; data: DBCustomer }) => {
      if (response && response.status === "success") {
        setPreload(false);
        setCustomer(response.data);
      }
    });
  }, [id]);

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      {/* Breadcrumb Section */}
      <Breadcrumb style={{ marginBottom: "16px" }} separator=">">
        <Breadcrumb.Item>{lang.get("customer", ["home"])}</Breadcrumb.Item>
        <Breadcrumb.Item>{lang.get("customer", ["customers"])}</Breadcrumb.Item>
        <Breadcrumb.Item>{lang.get("customer", ["Profile"])}</Breadcrumb.Item>
      </Breadcrumb>

      {/* Header Section */}
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: "24px" }}
      >
        <Col>
          <h2 style={{ margin: 0 }}>{lang.get("customer", ["Profile"])}</h2>
        </Col>
        <Col>
          <Row gutter={[16, 16]} wrap>
            <Col>
              <Button
                type="primary"
                icon={<MailOutlined />}
                onClick={() => setOpenMailModal(true)}
              >
                {lang.get("customer", ["Send Email"])}
              </Button>
            </Col>
            <Col>
              <Button danger icon={<DeleteOutlined />} onClick={deleteCustomer}>
                {lang.get("customer", ["Delete Customer"])}
              </Button>
            </Col>
            <Col>
              <Button
                icon={<KeyOutlined />}
                onClick={() => setopenPassModal(true)}
              >
                {lang.get("customer", ["Reset Password"])}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* Profile and Address Cards */}
      <Row gutter={[16, 16]} style={{ marginBottom: "24px" }}>
        <Col xs={24} lg={16}>
          <ProfileCard customer={customer} loading={preload} />
        </Col>
        <Col xs={24} lg={8}>
          <CustomerAddressCard />
        </Col>
      </Row>

      {/* Profile Details Tab */}
      <ProfileDetailsTab
        customer={customer}
        preload={preload}
        setRefresh={setRefresh}
        refresh={refresh}
      />

      {/* Email Modal */}
      <Modal
        title={<Title level={4}>{lang.get("customer", ["Send Email"])}</Title>}
        open={openMailModal}
        onCancel={() => setOpenMailModal(false)}
        onOk={sendMail}
        okText={lang.get("customer", ["Send Email"])}
        cancelText={lang.get("customer", ["Cancel"])}
      >
        <Form layout="vertical">
          {/* Subject Field */}
          <Form.Item label={lang.get("customer", ["Subject"])}>
            <Input
              value={mailSubject}
              onChange={(e) => setMailSubject(e.target.value)}
              placeholder={lang.get("customer", ["enter email subject"])}
            />
          </Form.Item>

          {/* Message Field */}
          <Form.Item label={lang.get("customer", ["Message"])}>
            <Editor
              value={mailBody}
              onEditorChange={(content) => setMailBody(content)}
              init={{
                height: 300,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  // eslint-disable-next-line no-multi-str
                  "undo redo | formatselect | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist outdent indent | removeformat | help",
              }}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={
          <Title level={4}>{lang.get("customer", ["Reset Password"])}</Title>
        }
        open={openPassModal}
        onCancel={() => setopenPassModal(false)}
        okText={lang.get("customer", ["Reset"])}
        cancelText={lang.get("customer", ["Cancel"])}
      >
        <Form layout="vertical">
          <Form.Item
            label={lang.get("customer", ["New Password"])}
            name="password"
            rules={[
              {
                required: true,
                message: lang.get("customer", [
                  "Please input the new password!",
                ]),
              },
            ]}
          >
            <Input.Password
              placeholder={lang.get("customer", ["Enter new password"])}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              addonAfter={
                <ReloadOutlined
                  onClick={() => setPassword(generateRandomPassword())}
                />
              }
            />
          </Form.Item>

          {/* Checkboxes */}
          <Form.Item>
            <Row gutter={[0, 8]}>
              <Col span={24}>
                <Checkbox id="send-mail">
                  {lang.get("customer", ["Send password to email"])}
                </Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox id="require-change">
                  {lang.get("customer", ["Send password to SMS"])}
                </Checkbox>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Profile;
