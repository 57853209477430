import { StatisticCard } from "@ant-design/pro-components";
import RcResizeObserver from "rc-resize-observer";
import {  useState } from "react";
import Draft from "../../../../../../assets/img/statistics/draft.svg";
import Sent from "../../../../../../assets/img/statistics/sent.svg";
import Accept from "../../../../../../assets/img/statistics/accept.svg";
import Expired from "../../../../../../assets/img/statistics/expired.svg"
import Declined from "../../../../../../assets/img/statistics/declined.svg"
const { Divider } = StatisticCard;

const imgStyle = {
  display: "block",
  width: 60,
  height: 55,
};

const EstimateStatitstic = () => {
  const [responsive, setResponsive] = useState(false);

  return (
    <RcResizeObserver
      key="resize-observer"
      onResize={(offset) => {
        setResponsive(offset.width < 596);
      }}
    >
      <StatisticCard.Group
        direction={responsive ? "row" : "row"}
        style={{
          paddingTop: "16px",
          paddingBottom: "16px",
          height: responsive ? "100px" : "auto",
          overflowX: "auto",
          overflowY: "hidden",
        }}
      >
        <StatisticCard
          statistic={{
            title: "Draft",
            value: 23500.0,
            icon: <img style={imgStyle} src={Draft} alt="icon" />,
          }}
        />
        <Divider type={responsive ? "horizontal" : "vertical"} />
        <StatisticCard
          statistic={{
            title: "Sent",
            value: 6500.0,
            icon: <img style={imgStyle} src={Sent} alt="icon" />,
          }}
        />
        <Divider type={responsive ? "horizontal" : "vertical"} />
        <StatisticCard
          statistic={{
            title: "Expired",
            value: 0.0,
            icon: <img style={imgStyle} src={Expired} alt="icon" />,
          }}
        />
        <Divider type={responsive ? "horizontal" : "vertical"} />
        <StatisticCard
          statistic={{
            title: "Declined",
            value: 0.0,
            icon: <img style={imgStyle} src={Declined} alt="icon" />,
          }}
        />
        <Divider type={responsive ? "horizontal" : "vertical"} />
        <StatisticCard
          statistic={{
            title: "Accepted",
            value: 17010.0,
            icon: <img style={imgStyle} src={Accept} alt="icon" />,
          }}
        />
      </StatisticCard.Group>
    </RcResizeObserver>
  );
};

export default EstimateStatitstic;
