import React, { useRef, useState } from "react";
import {
  Button,
  InputRef,
  Input,
  Space,
  DatePicker,
  Divider,
  message,
  Popconfirm,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import { SearchOutlined } from "@ant-design/icons";
import { ActionType, ProTable } from "@ant-design/pro-components";
import lang from "../../../../../../lang";
import Export from "../../../../../../common/components/Export";

import { DBSingleProject, ProjectTimesheet } from "../../../../types/types";

import apiRequest from "../../../../../../common/utils/apiRequest";
import coreFunctions from "../../../../../../common/utils/coreFunctions";

import { TableAdvancedFilter } from "../../../../../../common/types";

const { RangePicker } = DatePicker;

const TimesheetTable = ({ project }: { project: DBSingleProject | null }) => {
  const [pageSize, setPageSize] = useState(15);
  const searchInput = useRef<InputRef>(null);
  const actionRef = useRef<ActionType>();
  const [allSearch, setAllSearch] = useState<string>("");
  const [searchAttributes, setSearchAttributes] = useState<
    { key: string; value: string }[]
  >([]);
  const [advancedFilters, setAdvancedFilters] = useState<TableAdvancedFilter[]>(
    []
  );
  const [tableData, setTableData] = useState<ProjectTimesheet[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);

  const expobj = new Export(
    lang.get("project", ["Timesheets"]) + ", " + new Date().toDateString()
  );

  const CORE_FUNCTION = new coreFunctions();

  const handleSearch = (field: string, value: string) => {
    setAllSearch("");
    const existingAttribute = searchAttributes.find(
      (attr) => attr.key === field
    );

    if (existingAttribute) {
      setSearchAttributes(
        searchAttributes.map((attr) =>
          attr.key === field ? { ...attr, value } : attr
        )
      );
    } else {
      setSearchAttributes([...searchAttributes, { key: field, value }]);
    }
  };

  const handleAdvancedFilter = (
    key: string,
    type: string,
    value: string | string[] | number
  ) => {
    const existingAttribute = advancedFilters.find((attr) => attr.key === key);

    if (existingAttribute) {
      setAdvancedFilters(
        advancedFilters.map((attr) =>
          attr.key === key ? { ...attr, value } : attr
        )
      );
    } else {
      setAdvancedFilters([...advancedFilters, { key: key, type, value }]);
    }
  };

  const deleteRecord = (id: string) => {
    message.loading(lang.get("project", ["Deleting"]), 1).then(() => {
      const API_REQUEST = new apiRequest("project/timesheet/delete", true);
      API_REQUEST.send(
        {
          timesheet: id,
        },
        (response: { status: string; message: string }) => {
          if (response && response.status === "success") {
            actionRef.current?.reload();
            message.success(response.message, 2);
          } else {
            message.error(response.message, 2);
          }
        }
      );
    });
  };

  const searchCols = (field: string, label: string) => {
    return {
      filterDropdown: (props: any) => {
        const { close } = props;

        setTimeout(() => {
          if (searchInput.current) {
            searchInput.current.focus();
          }
        }, 100);

        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={lang.get("project", [`Search ${label}`])}
              onChange={(e) => handleSearch(field, e.target.value)}
              value={searchAttributes.find((item) => item.key === field)?.value}
              style={{ marginBottom: 8, display: "block" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  close();
                  actionRef.current?.reload();
                }
              }}
            />
            <Space>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="small"
                onClick={() => actionRef.current?.reload()}
                style={{ width: 90 }}
              >
                {lang.get("project", ["Search"])}
              </Button>
              <Button
                onClick={() => close()}
                size="small"
                style={{ width: 90 }}
              >
                {lang.get("project", ["Close"])}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
    };
  };

  const columns = [
    {
      title: "Member",
      dataIndex: "member",
      key: "member",
      ...searchCols("member", lang.get("project", ["Member"])),
      sorter: true,
    },
    {
      title: "Task",
      dataIndex: "task",
      key: "task",
      ...searchCols("task", lang.get("project", ["Task"])),
      sorter: true,
    },

    {
      title: "Start Time",
      dataIndex: "start_time",
      key: "start_time",
      ...searchCols("start_time", lang.get("project", ["Start Time"])),
      sorter: true,
    },
    {
      title: "End Time",
      dataIndex: "end_time",
      key: "end_time",
      ...searchCols("end_time", lang.get("project", ["End Time"])),
      sorter: true,
    },

    {
      title: "Note",
      dataIndex: "note",
      key: "note",
      ...searchCols("note", lang.get("project", ["Note"])),
      sorter: true,
    },

    {
      title: "Time (H)",
      dataIndex: "timeH",
      key: "timeH",
      render: (_: any, record: ProjectTimesheet) => {
        return (
          <>
            {CORE_FUNCTION.getTimeDuration(record.start_time, record.end_time)}
          </>
        );
      },
    },
    {
      title: "Options",
      dataIndex: "options",
      key: "options",
      render: (_: any, record: ProjectTimesheet) => {
        return (
          <>
            <a
              onClick={() => {
                console.log(record);
              }}
            >
              <EditOutlined />
            </a>
            <Divider type="vertical" />
            <Popconfirm
              key="delete"
              title={lang.get("project", ["Are you sure you want to delete?"])}
              onConfirm={() => deleteRecord(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined />
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <ProTable
      headerTitle={lang.get("project", ["Timesheets"])}
      columns={columns}
      dataSource={tableData}
      request={async (params, sort, filter) => {
        await new coreFunctions().waitRequest(10);

        const processedFilters = Object.entries(filter || {}).reduce(
          (acc, [key, value]) => {
            if (value && value.length > 0) {
              acc[key] = value;
            }
            return acc;
          },
          {} as Record<string, (string | number)[]>
        );

        const payload = {
          project: project?.id,
          params: params,
          sorts: sort,
          filters: processedFilters,
          searches: searchAttributes,
          all_search: allSearch,
          advanced_filters: advancedFilters,
        };

        const API_REQUEST = new apiRequest("project/timesheets/get", true);
        const response = await API_REQUEST.send(payload);

        if (response && response.status === "success") {
          const fetchedData = response.data || [];
          setTableData(fetchedData);
          setTotalRecords(response.total);

          return {
            data: fetchedData,
            total: totalRecords,
            success: true,
          };
        }

        return {
          data: [],
          total: 0,
          success: false,
        };
      }}
      actionRef={actionRef}
      rowKey="id"
      pagination={{
        defaultCurrent: 1,
        pageSize,
        showSizeChanger: true,
        pageSizeOptions: [6, 10, 20],
        showTotal: (total, range) =>
          lang.get("project", [
            `Showing ${range[0]}-${range[1]} of ${total} items`,
          ]),
        onShowSizeChange: (_, size) => setPageSize(size),
      }}
      search={false}
      scroll={{ x: 800 }}
      toolBarRender={() => [
        <RangePicker
          onChange={(date, dateStrings) => {
            if (date) {
              handleAdvancedFilter("start_time|end_time", "between", [
                CORE_FUNCTION.formatISODateTime(dateStrings[0], true),
                CORE_FUNCTION.formatISODateTime(dateStrings[1], true),
              ]);
            } else {
              setAdvancedFilters([]);
            }
            actionRef.current?.reload();
          }}
        />,
        <Input
          style={{ width: "auto" }}
          key="all_search"
          allowClear
          placeholder={lang.get("project", ["Global Search"])}
          value={allSearch}
          onChange={(e) => {
            setAllSearch(e.target.value);
            setSearchAttributes([]);
            actionRef.current?.reload();
          }}
        />,
        <Button
          key="csv"
          onClick={() => {
            const csvData = tableData.map((row) => ({
              [lang.get("project", ["Member"])]: row.member,
              [lang.get("project", ["Task"])]: row.task,
              [lang.get("project", ["Start Time"])]: row.start_time,
              [lang.get("project", ["End Time"])]: row.end_time,
              [lang.get("project", ["Note"])]: row.note,
              [lang.get("project", ["Time(H)"])]: CORE_FUNCTION.getTimeDuration(
                row.start_time,
                row.end_time
              ),
            }));
            expobj.exportToCSV(csvData);
          }}
        >
          {lang.get("project", ["Export CSV"])}
        </Button>,
        <Button
          key="excel"
          onClick={() => {
            const excelData = tableData.map((row) => ({
              [lang.get("project", ["Member"])]: row.member,
              [lang.get("project", ["Task"])]: row.task,
              [lang.get("project", ["Start Time"])]: row.start_time,
              [lang.get("project", ["End Time"])]: row.end_time,
              [lang.get("project", ["Note"])]: row.note,
              [lang.get("project", ["Time(H)"])]: CORE_FUNCTION.getTimeDuration(
                row.start_time,
                row.end_time
              ),
            }));
            expobj.exportToExcel(excelData);
          }}
        >
          {lang.get("project", ["Export Excel"])}
        </Button>,
        <Button
          key="pdf"
          onClick={() => {
            expobj.exportToPDF({
              head: [
                [
                  lang.get("project", ["Member"]),
                  lang.get("project", ["Task"]),
                  lang.get("project", ["Start Time"]),
                  lang.get("project", ["End Time"]),
                  lang.get("project", ["Note"]),
                  lang.get("project", ["Time(H)"]),
                  lang.get("project", ["Time(Decimal)"]),
                ],
              ],
              body: tableData.map((row) => [
                row.member,
                row.task,
                row.start_time,
                row.end_time,
                row.note,
                CORE_FUNCTION.getTimeDuration(row.start_time, row.end_time),
              ]),
            });
          }}
        >
          {lang.get("project", ["Export PDF"])}
        </Button>,
      ]}
    />
  );
};

export default TimesheetTable;
