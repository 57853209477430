import {
  Breadcrumb,
  Typography,
  Button,
  Row,
  Col,
  Modal,
  Form,
  Input,
  Checkbox,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import lang from "../../../lang";
import DepartmentTable from "./DepartmentTable";
import { useState } from "react";

const { Title } = Typography;

const Department: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleSave = () => {
    form.validateFields().then((values) => {
      console.log("New Department Details:", values);
      setIsModalVisible(false);
      form.resetFields();
    });
  };

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      {/* Breadcrumb */}
      <Breadcrumb style={{ marginBottom: "16px" }} separator=">">
        <Breadcrumb.Item>
          {lang.get("userManagement", ["Home"])}
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {lang.get("userManagement", ["User Management"])}
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {lang.get("userManagement", ["Departments"])}
        </Breadcrumb.Item>
      </Breadcrumb>

      {/* Page Title */}
      <Title level={2} style={{ marginBottom: "16px" }}>
        {lang.get("userManagement", ["Departments"])} (7)
      </Title>

      {/* Filter and Actions */}
      <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
        <Col xs={24} sm={12} md={12} lg={12} style={{ textAlign: "left" }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleOpenModal}
          >
            {lang.get("userManagement", ["New Department"])}
          </Button>
        </Col>
      </Row>

      {/* Table */}
      <DepartmentTable />

      <Modal
        title={lang.get("userManagement", ["New Department"])}
        open={isModalVisible}
        onCancel={handleCancel}
        onOk={handleSave}
        okText={lang.get("userManagement", ["Save"])}
        cancelText={lang.get("userManagement", ["Cancel"])}
      >
        <Form form={form} layout="vertical">
          {/* Department Name */}
          <Form.Item
            name="departmentName"
            label={<span style={{ fontSize: "14px" }}>Department Name</span>}
            rules={[
              { required: true, message: "Department Name is required!" },
            ]}
          >
            <Input placeholder="Enter Department Name" size="large" />
          </Form.Item>

          {/* Hide from Client */}
          <Form.Item name="hideFromClient" valuePropName="checked">
            <Checkbox>
              <span style={{ fontSize: "14px" }}>Hide from Client?</span>
            </Checkbox>
          </Form.Item>

          {/* Department Email */}
          <Form.Item
            name="departmentEmail"
            label={<span style={{ fontSize: "14px" }}>Department Email</span>}
            rules={[
              {
                type: "email",
                message: "Please enter a valid email address!",
              },
              {
                required: true,
                message: "Department Email is required!",
              },
            ]}
          >
            <Input placeholder="Enter Department Email" size="large" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Department;
