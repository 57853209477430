import { useState, ChangeEvent, useEffect } from "react";
import {
  Card,
  Steps,
  Row,
  Col,
  Input,
  Select,
  Form,
  Button,
  message,
  Alert,
} from "antd";
import ClientBillingAndShipping from "./CustomerBilling";
import ClientContactBody from "./CustomerContact";
import apiRequest from "../../../common/utils/apiRequest";
import coreFunctions from "../../../common/utils/coreFunctions";

import {
  ClientBillingAddress,
  ClientShippingAddress,
  DBCustomerContact,
  DBCustomer,
  SelectType,
} from "../types/types";

import lang from "../../../lang";

import UploadFile from "../../../common/utils/upload";

const { Option } = Select;
const { Step } = Steps;
const { TextArea } = Input;

const CustomerAddBody = (props: {
  modelClose: () => void;
  setUpdateTable: (updateTable: boolean) => void;
  updateTable: boolean;
  currentStep: number;
  setCurrentStep: (currentStep: number) => void;
}) => {
  const [customerData, setCustomerData] = useState<DBCustomer>(
    {} as DBCustomer
  );
  const [customerContactData, setCustomerContactData] =
    useState<DBCustomerContact>({} as DBCustomerContact);
  const [clientBillingAddress, setClientBillingAddress] = useState<
    ClientBillingAddress[]
  >([]);
  const [clientShippingAddress, setClientShippingAddress] = useState<
    ClientShippingAddress[]
  >([]);

  const [currencyList, setCurrencyList] = useState<SelectType[]>([]);
  const [languageList, setLanguageList] = useState<SelectType[]>([]);
  const [countryList, setCountryList] = useState<SelectType[]>([]);
  const [customerGroupList, setCustomerGroupList] = useState<SelectType[]>([]);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      await new coreFunctions().waitRequest();

      const LNG_REQUEST = new apiRequest("languages/get", true);
      LNG_REQUEST.send(
        {},
        (data: { status: string; message: string; data: SelectType[] }) => {
          setLanguageList(data.data);
        }
      );

      const CURRENCY_REQUEST = new apiRequest("currencies/get", true);
      CURRENCY_REQUEST.send(
        {},
        (data: { status: string; message: string; data: SelectType[] }) => {
          setCurrencyList(data.data);
        }
      );

      const COUNTRY_REQUEST = new apiRequest("countries/get", true);
      COUNTRY_REQUEST.send(
        {},
        (data: { status: string; message: string; data: SelectType[] }) => {
          setCountryList(data.data);
        }
      );

      const CUSTOMER_GROUP_REQUEST = new apiRequest("clients/groups/get", true);
      CUSTOMER_GROUP_REQUEST.send(
        {},
        (data: { status: string; message: string; data: SelectType[] }) => {
          setCustomerGroupList(data.data);
        }
      );
    };

    loadData();
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCustomerData({
      ...customerData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSelectChange = (value: string, field: string) => {
    setCustomerData({
      ...customerData,
      [field]: value,
    });
  };

  async function submitData() {
    setSaving(true);
    if (customerContactData.profile_image) {
      const FILE_INSTANCE = new UploadFile();

      await FILE_INSTANCE.initUpload(customerContactData.profile_image);

      customerContactData.profile_image = FILE_INSTANCE.getFileName();

      setCustomerContactData(customerContactData);
    }

    message.loading(lang.get("customer", ["Adding customer"]), 1).then(() => {
      const API_REQUEST = new apiRequest("client/add", true);
      API_REQUEST.send(
        {
          customer: customerData,
          contact: customerContactData,
          billing: clientBillingAddress,
          shipping: clientShippingAddress,
        },
        (response: { status: string; message: string }) => {
          if (response.status === "success") {
            message.success(
              lang.get("customer", ["Customer added successfully."]),
              2
            );

            setCustomerData({
              id: "",
              company_name: "",
              group: [],
              primary_contact: "",
              primary_email: "",
              phone: "",
              currency: "",
              created_at: "",
              status: 0,
            });

            setCustomerContactData({
              id: "",
              title: "",
              first_name: "",
              last_name: "",
              email: "",
              position: "",
              phone: "",
              password: "",
              is_primary: false,
              do_not_send_welcome_email: false,
              send_set_password: false,
              profile_image: "",
              permissions: [],
              email_notifications: [],
            });

            setClientBillingAddress([]);
            setClientShippingAddress([]);
            props.setCurrentStep(0);

            props.modelClose();
            props.setUpdateTable(!props.updateTable);
          } else {
            message.error(lang.get("customer", [response.message]), 2);
          }
          setSaving(false);
        }
      );
    });
  }

  const steps = [
    {
      title: lang.get("customer", ["Company"]),
      content: (
        <>
          <Row gutter={[16, 4]}>
            <Col xs={24} lg={12}>
              <Form.Item
                required
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("customer", "company name")}
                  </span>
                }
                name="company_name"
              >
                <Input
                  id="company_name"
                  placeholder={lang.get("customer", ["Company Name"])}
                  onChange={handleChange}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("customer", ["VAT Number"])}
                  </span>
                }
                name="vat_number"
              >
                <Input
                  id="vat_number"
                  placeholder={lang.get("customer", ["VAT Number"])}
                  onChange={handleChange}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("customer", ["Web site"])}
                  </span>
                }
                name="website"
              >
                <Input
                  id="website"
                  placeholder={lang.get("customer", ["Website"])}
                  onChange={handleChange}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                required
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("customer", ["Phone"])}
                  </span>
                }
                name="phone"
              >
                <Input
                  id="phone"
                  placeholder={lang.get("customer", ["Phone"])}
                  onChange={handleChange}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("customer", ["Groups"])}
                  </span>
                }
                name="groups"
              >
                <Select
                  showSearch
                  mode="multiple"
                  placeholder={lang.get("customer", ["Select groups"])}
                  onChange={(value) => handleSelectChange(value, "groups")}
                  value={
                    customerGroupList.find((item) => item.default === true)?.id
                  }
                  size="large"
                >
                  {customerGroupList.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("customer", ["Fax"])}
                  </span>
                }
                name="fax"
              >
                <Input
                  id="fax"
                  placeholder={lang.get("customer", ["Fax"])}
                  onChange={handleChange}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                required
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("customer", ["Currency"])}
                  </span>
                }
                name="currency"
              >
                <Select
                  showSearch
                  onChange={(value) => handleSelectChange(value, "currency")}
                  value={
                    currencyList.find((item) => item.default === true)?.id ||
                    "lkr"
                  }
                  size="large"
                >
                  {currencyList.map((currency) => (
                    <Option key={currency.id} value={currency.id}>
                      {currency.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                required
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("customer", ["Default Language"])}
                  </span>
                }
                name="default_language"
              >
                <Select
                  showSearch
                  onChange={(value) =>
                    handleSelectChange(value, "default_language")
                  }
                  value={
                    languageList.find((item) => item.default === true)?.id ||
                    "en"
                  }
                  size="large"
                >
                  {languageList.map((language) => (
                    <Option key={language.id} value={language.id}>
                      {language.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("customer", ["Address"])}
                  </span>
                }
                name="address"
              >
                <TextArea
                  id="address"
                  placeholder={lang.get("customer", ["Address"])}
                  onChange={(e) => {
                    handleChange({
                      target: {
                        id: "address",
                        value: e.target.value,
                      },
                    } as ChangeEvent<HTMLInputElement>);
                  }}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("customer", ["City"])}
                  </span>
                }
                name="city"
              >
                <Input
                  id="city"
                  placeholder={lang.get("customer", ["City"])}
                  onChange={handleChange}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("customer", ["State"])}
                  </span>
                }
                name="state"
              >
                <Input
                  id="state"
                  placeholder={lang.get("customer", ["State"])}
                  onChange={handleChange}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("customer", ["Zip Code"])}
                  </span>
                }
                name="zip_code"
              >
                <Input
                  id="zip_code"
                  placeholder={lang.get("customer", ["Zip Code"])}
                  onChange={handleChange}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                required
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("customer", ["Country"])}
                  </span>
                }
                name="country"
              >
                <Select
                  showSearch
                  onChange={(value) => handleSelectChange(value, "country")}
                  value={countryList.find((item) => item.default === true)?.id}
                  size="large"
                >
                  {countryList.map((country) => (
                    <Option key={country.id} value={country.id}>
                      {country.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 24]}>
            <Col xs={24} lg={24} sm={24} md={24}>
              <Alert
                style={{ marginBottom: "10px", width: "100%" }}
                showIcon
                type="info"
                message={lang.get("customer", ["* marked fields are required"])}
              />
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: lang.get("customer", ["Billing & Shipping"]),
      content: (
        <ClientBillingAndShipping
          setClientBillingAddress={setClientBillingAddress}
          setClientShippingAddress={setClientShippingAddress}
          clientBillingAddress={clientBillingAddress}
          clientShippingAddress={clientShippingAddress}
          countryList={countryList}
        />
      ),
    },

    {
      title: lang.get("customer", ["Contact Information"]),
      content: (
        <ClientContactBody
          contactData={customerContactData}
          setContactData={setCustomerContactData}
          modelClose={props.modelClose}
          setUpdateTable={props.setUpdateTable}
        />
      ),
    },
  ];

  return (
    <>
      <hr />
      <Card>
        <Steps
          current={props.currentStep}
          onChange={(step) => props.setCurrentStep(step)}
          className="mb-4"
        >
          {steps.map((step, index) => (
            <Step key={index} title={step.title} />
          ))}
        </Steps>
        <Form layout="vertical" style={{ fontSize: "13px" }}>
          {steps[props.currentStep].content}{" "}
        </Form>
        <div className="steps-action mt-4">
          <Row justify="end" gutter={[8, 0]}>
            {props.currentStep > 0 && (
              <Col>
                <Button
                  onClick={() => props.setCurrentStep(props.currentStep - 1)}
                >
                  {lang.get("customer", ["Previous"])}
                </Button>
              </Col>
            )}
            {props.currentStep < steps.length - 1 && (
              <Col>
                <Button
                  type="primary"
                  onClick={() => props.setCurrentStep(props.currentStep + 1)}
                >
                  {lang.get("customer", ["Next"])}
                </Button>
              </Col>
            )}
            {props.currentStep === steps.length - 1 && (
              <Col>
                <Button
                  type="primary"
                  loading={saving}
                  onClick={() => {
                    submitData();
                  }}
                >
                  {lang.get("customer", ["Save Client"])}
                </Button>
              </Col>
            )}
          </Row>
        </div>
      </Card>
    </>
  );
};

export default CustomerAddBody;
