import apiRequest from "./apiRequest";

import { S3UploadResponse, S3EtagResponses } from "../types";

export default class UploadFile {
  private filePassword?: string | null;
  private static fileHash: string | null;

  constructor(
    file?: File,
    progressSetFunc?: (progress: number) => void,
    filePassword?: string | null
  ) {
    this.filePassword = filePassword ? filePassword : null;
    if (file) {
      this.initUpload(file, progressSetFunc);
    }
  }

  private async sendUploadComplete(
    hash: string,
    etagResponses?: S3EtagResponses[],
    progressSetFunc?: (progress: number) => void
  ) {
    return new apiRequest("verifyUpload", true, true).send(
      {
        hash: hash,
        filePassword: this.filePassword,
        etag_response: etagResponses,
      },
      (data: { status: string; message: string }) => {
        if (data.status === "success") {
          UploadFile.fileHash = hash;
          if (progressSetFunc) {
            progressSetFunc(100);
          }
        } else {
          if (progressSetFunc) {
            progressSetFunc(-1);
          }
        }
      }
    );
  }

  private async oneTimeUpload(
    url: string,
    file: File,
    hash: string,
    progressSetFunc?: (progress: number) => void
  ) {
    if (progressSetFunc) {
      progressSetFunc(0);
    }

    const response = await fetch(url, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.ok) {
      if (progressSetFunc) {
        progressSetFunc(99);
      }

      return this.sendUploadComplete(hash, undefined, progressSetFunc);
    } else {
      if (progressSetFunc) {
        progressSetFunc(-1);
      }
    }
  }

  private async largeFileUpload(
    file: File,
    segments: S3UploadResponse[],
    chunkSize: number,
    hash: string,
    progressSetFunc?: (progress: number) => void
  ) {
    if (progressSetFunc) {
      progressSetFunc(0);
    }

    const totalParts = Math.ceil(file.size / chunkSize);

    const uploadPromises = segments.map((segment, index) => {
      const start = index * chunkSize;
      const end = Math.min(start + chunkSize, file.size);
      const fileChunk = file.slice(start, end);

      if (progressSetFunc) {
        const progress = ((index + 1) / totalParts) * 100;
        progressSetFunc(progress >= 100 ? 99 : progress);
      }

      return fetch(segment.url, {
        method: "PUT",
        body: fileChunk,
        headers: {
          "Content-Type": "application/octet-stream",
        },
      });
    });

    const responses = await Promise.all(uploadPromises);

    if (responses.every((response) => response.ok)) {
      const etagResponses = segments.map((urlObj, i) => ({
        ETag: responses[i].headers.get("ETag"),
        PartNumber: urlObj.partNumber,
      }));

      return this.sendUploadComplete(hash, etagResponses, progressSetFunc);
    } else {
      if (progressSetFunc) {
        progressSetFunc(-1);
      }
    }
  }

  public async initUpload(
    file: File,
    progressSetFunc?: (progress: number) => void
  ) {
    return new apiRequest("getUrl", true, true).send(
      {
        file_size: file.size,
        file_type: file.type,
      },
      (data: {
        status: string;
        hash: string;
        data: S3UploadResponse[];
        file_max_size: number;
      }) => {
        if (data.status === "success") {
          if (data.data.length > 1) {
            return this.largeFileUpload(
              file,
              data.data,
              data.file_max_size,
              data.hash,
              progressSetFunc
            );
          } else {
            return this.oneTimeUpload(
              data.data[0].url,
              file,
              data.hash,
              progressSetFunc
            );
          }
        }
      }
    );
  }

  public getFileName() {
    return UploadFile.fileHash;
  }
}
