import { Card, Row, Col, Skeleton } from "antd";
import { StatisticCard } from "@ant-design/pro-components";
import { BarChartOutlined } from "@ant-design/icons";

import type { StatisticProps } from "antd";
import CountUp from "react-countup";

import lang from "../../../../lang";
import QueueAnim from "rc-queue-anim";

import { useEffect, useState } from "react";
import { Divider } from "antd/lib";

const formatter: StatisticProps["formatter"] = (value) => {
  return <CountUp end={value as number} separator="," />;
};

export type Customer = {
  id: string;
  name: string;
  vat_number: string;
  phone: string;
  currency: string;
  address: string;
  last_login: string;
  default_language: string;
  primary_email?: string;
  last_order?: string;
};

const CustomerAddressCard = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  const data = [
    { amount: 4000, label: "Total Income" },
    { amount: 5565, label: "Total Expenses" },
    { amount: 4000, label: "Net Profit" },
    { amount: 5565, label: "Gross Revenue" },
  ];

  return (
    <Card bordered={false} style={{ borderRadius: "8px", height: "100%" }}>
      {loading ? (
        <Row gutter={[12, 24]} align="middle">
          <Col xs={24} md={6}>
            <Skeleton.Node active style={{ width: "100%" }} />
          </Col>
          <Col xs={24} md={6}>
            <Skeleton.Node active style={{ width: "100%" }} />
          </Col>
          <Col xs={24} md={6}>
            <Skeleton.Node active style={{ width: "100%" }} />
          </Col>
          <Col xs={24} md={6}>
            <Skeleton.Node active style={{ width: "100%" }} />
          </Col>
        </Row>
      ) : (
        <>
          <QueueAnim delay={100} type={"left"}>
            <Row key={"anim1"} justify="center" gutter={[16, 16]} style={{ marginTop: "5%" }}>
              {data.map((item, index) => (
                <Col xs={12} key={index} className="text-center">
                  <StatisticCard
                    style={{ width: "100%" }}
                    loading={loading}
                    statistic={{
                      formatter,
                      value: item.amount,
                      title: <h6>{item.label}</h6>,
                      description: <Divider type="vertical" />,
                    }}
                    chart={<BarChartOutlined style={{ fontSize: "60px" }} />}
                    chartPlacement="left"
                  />
                </Col>
              ))}
            </Row>
          </QueueAnim>
        </>
      )}
    </Card>
  );
};

export default CustomerAddressCard;
