import { SearchOutlined } from "@ant-design/icons";
import type { ActionType, ProColumns } from "@ant-design/pro-components";
import { ProTable } from "@ant-design/pro-components";
import { Button, Input, Space, InputRef, Tag, DatePicker, Select } from "antd";
import { ReactNode, useRef, useState } from "react";
import Export from "../../../../../../../common/components/Export";
import lang from "../../../../../../../lang";

const { Option } = Select;
const { RangePicker } = DatePicker;

interface Estimate {
  id: string;
  amount: string;
  totalTax: string;
  customer: string;
  project: string;
  tags: string[];
  date: string;
  expiryDate: string;
  reference: string;
  status: string;
}

const Estimates: Estimate[] = [];

const EstimateTable = () => {
  const actionRef = useRef<ActionType>();
  const [pageSize, setPageSize] = useState<number>(10);
  const searchInput = useRef<InputRef>(null);
  const [searchAttributes, setSearchAttributes] = useState<
    { key: string; value: string }[]
  >([]);
  const [allSearch, setAllSearch] = useState<string>("");

  const expobj = new Export("estimates, " + new Date().toDateString());

  const handleSearch = (field: string, value: string) => {
    setAllSearch("");
    const existingAttribute = searchAttributes.find(
      (attr) => attr.key === field
    );

    if (existingAttribute) {
      setSearchAttributes(
        searchAttributes.map((attr) =>
          attr.key === field ? { ...attr, value } : attr
        )
      );
    } else {
      setSearchAttributes([
        ...searchAttributes,
        {
          key: field,
          value,
        },
      ]);
    }
  };

  const searchCols = (field: string, label: string) => {
    return {
      filterDropdown: (props: any) => {
        const { close } = props;

        setTimeout(() => {
          if (searchInput.current) {
            searchInput.current.focus();
          }
        }, 100);

        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={lang.get("estimate", [`Search ${label}`])}
              onChange={(e) => handleSearch(field, e.target.value)}
              value={
                searchAttributes.filter((item) => item.key === field)[0]?.value
              }
              style={{ marginBottom: 8, display: "block" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  close();
                  if (actionRef.current) {
                    actionRef.current?.reset?.();
                    actionRef.current.reload();
                  }
                }
              }}
            />
            <Space>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="small"
                onClick={() => {
                  actionRef.current?.reset?.();
                  actionRef.current?.reload();
                }}
                style={{ width: 90 }}
              >
                {lang.get("estimate", ["Search"])}
              </Button>
              <Button
                onClick={() => close()}
                size="small"
                style={{ width: 90 }}
              >
                {lang.get("estimate", ["Close"])}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
    };
  };

  const columns: ProColumns<Estimate>[] = [
    {
      title: lang.get("estimate", ["Estimate #"]),
      dataIndex: "id",
      sorter: true,
      ellipsis: true,
      ...searchCols("id", lang.get("estimate", ["Estimate #"])),
      responsive: ["xs", "sm", "md", "lg"],
    },
    {
      title: lang.get("estimate", ["Amount"]),
      dataIndex: "amount",
      sorter: true,
      ...searchCols("amount", lang.get("estimate", ["Amount"])),
      responsive: ["sm", "md", "lg"],
    },
    {
      title: lang.get("estimate", ["Total Tax"]),
      dataIndex: "totalTax",
      sorter: true,
      ...searchCols("totalTax", lang.get("estimate", ["Total Tax"])),
      responsive: ["md", "lg"],
    },
    {
      title: lang.get("estimate", ["Project"]),
      dataIndex: "project",
      sorter: true,
      ...searchCols("project", lang.get("estimate", ["Project"])),
      responsive: ["lg"],
    },
    {
      title: lang.get("estimate", ["Customer"]),
      dataIndex: "customer",
      sorter: true,
      ...searchCols("project", lang.get("estimate", ["Customer"])),
      responsive: ["lg"],
    },
    {
      title: lang.get("estimate", ["Tags"]),
      dataIndex: "tags",
      render: (tags: ReactNode) => {
        const tagArray = tags as string[] | undefined;
        return tagArray && tagArray.length > 0 ? (
          tagArray.map((tag) => (
            <Tag color="blue" key={tag}>
              {tag}
            </Tag>
          ))
        ) : (
          <span style={{ color: "#ccc" }}>-</span>
        );
      },
    },
    {
      title: lang.get("estimate", ["Date"]),
      dataIndex: "date",
      sorter: true,
      ...searchCols("date", lang.get("estimate", ["Date"])),
    },
    {
      title: lang.get("estimate", ["Expiry Date"]),
      dataIndex: "expiryDate",
      sorter: true,
      ...searchCols("expiryDate", lang.get("estimate", ["Expiry Date"])),
    },
    {
      title: lang.get("estimate", ["Reference #"]),
      dataIndex: "reference",
      sorter: true,
      ...searchCols("reference", lang.get("estimate", ["Reference #"])),
    },
    {
      title: lang.get("estimate", ["Status"]),
      dataIndex: "status",
      sorter: true,
      filters: [
        { text: lang.get("estimate", ["Sent"]), value: "Sent" },
        { text: lang.get("estimate", ["Accepted"]), value: "Accepted" },
        { text: lang.get("estimate", ["Draft"]), value: "Draft" },
      ],
      render: (_, record) =>
        record.status === "Sent" ? (
          <Tag color="blue">{lang.get("estimate", ["Sent"])}</Tag>
        ) : record.status === "Accepted" ? (
          <Tag color="green">{lang.get("estimate", ["Accepted"])}</Tag>
        ) : (
          <Tag color="orange">{lang.get("estimate", ["Draft"])}</Tag>
        ),
    },
  ];

  return (
    <ProTable
      headerTitle={lang.get("estimate", ["Estimates"])}
      columns={columns}
      actionRef={actionRef}
      dataSource={Estimates}
      rowKey="id"
      cardBordered
      pagination={{
        defaultCurrent: 1,
        pageSize: pageSize,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50],
        showTotal: (total, range) =>
          lang.get("estimate", [
            `Showing ${range[0]}-${range[1]} of ${total} items`,
          ]),
        onShowSizeChange: (current, size) => {
          setPageSize(size);
        },
      }}
      search={false}
      scroll={{ x: 800 }}
      toolBarRender={() => [
        <RangePicker key="date" />,
        <Select
          placeholder={lang.get("expenses", ["Select Currency"])}
          style={{ width: 200 }}
        >
          <Option value="USD">
            USD - {lang.get("expenses", ["US Dollar"])}
          </Option>
          <Option value="EUR">EUR - {lang.get("expenses", ["Euro"])}</Option>
          <Option value="GBP">
            GBP - {lang.get("expenses", ["British Pound"])}
          </Option>
          <Option value="LKR">
            LKR - {lang.get("expenses", ["Sri Lankan Rupee"])}
          </Option>
        </Select>,
        <Input
          style={{ width: "auto" }}
          key="all_search"
          allowClear
          placeholder={lang.get("estimate", ["Global Search"])}
          value={allSearch}
          onChange={(e) => {
            setAllSearch(e.target.value);
            setSearchAttributes([]);
            actionRef?.current?.reload();
          }}
        />,
        <Button
          key="csv"
          onClick={() => {
            const csvData = Estimates.map((row) => ({
              [lang.get("estimate", ["Estimate #"])]: row.id,
              [lang.get("estimate", ["Amount"])]: row.amount,
              [lang.get("estimate", ["Total Tax"])]: row.totalTax,
              [lang.get("estimate", ["Project"])]: row.project,
              [lang.get("estimate", ["Customer"])]: row.customer,
              [lang.get("estimate", ["Tags"])]: row.tags.join(", "),
              [lang.get("estimate", ["Date"])]: row.date,
              [lang.get("estimate", ["Expiry Date"])]: row.expiryDate,
              [lang.get("estimate", ["Reference #"])]: row.reference,
              [lang.get("estimate", ["Status"])]: row.status,
            }));
            expobj.exportToCSV(csvData);
          }}
        >
          {lang.get("estimate", ["Export CSV"])}
        </Button>,
        <Button
          key="excel"
          onClick={() => {
            const excelData = Estimates.map((row) => ({
              [lang.get("estimate", ["Estimate #"])]: row.id,
              [lang.get("estimate", ["Amount"])]: row.amount,
              [lang.get("estimate", ["Total Tax"])]: row.totalTax,
              [lang.get("estimate", ["Project"])]: row.project,
              [lang.get("estimate", ["Customer"])]: row.customer,
              [lang.get("estimate", ["Tags"])]: row.tags.join(", "),
              [lang.get("estimate", ["Date"])]: row.date,
              [lang.get("estimate", ["Expiry Date"])]: row.expiryDate,
              [lang.get("estimate", ["Reference #"])]: row.reference,
              [lang.get("estimate", ["Status"])]: row.status,
            }));
            expobj.exportToExcel(excelData);
          }}
        >
          {lang.get("estimate", ["Export Excel"])}
        </Button>,
        <Button
          key="pdf"
          onClick={() => {
            expobj.exportToPDF({
              head: [
                [
                  lang.get("estimate", ["Estimate #"]),
                  lang.get("estimate", ["Amount"]),
                  lang.get("estimate", ["Total Tax"]),
                  lang.get("estimate", ["Project"]),
                  lang.get("estimate", ["Customer"]),
                  lang.get("estimate", ["Tags"]),
                  lang.get("estimate", ["Date"]),
                  lang.get("estimate", ["Expiry Date"]),
                  lang.get("estimate", ["Reference #"]),
                  lang.get("estimate", ["Status"]),
                ],
              ],
              body: Estimates.map((row) => [
                row.id,
                row.amount,
                row.totalTax,
                row.project,
                row.customer,
                row.tags.join(", "),
                row.date,
                row.expiryDate,
                row.reference,
                row.status,
              ]),
            });
          }}
        >
          {lang.get("estimate", ["Export PDF"])}
        </Button>,
      ]}
    />
  );
};

export default EstimateTable;
