import { useEffect, useState, useRef } from "react";
import { Button, Input, InputRef, Space, Switch, Tag } from "antd";
import { ProTable, ProColumns, ActionType } from "@ant-design/pro-components";
import { SearchOutlined } from "@ant-design/icons";
import lang from "../../../../../../lang";
import Export from "../../../../../../common/components/Export";

export const discussionData = [];

const DiscussionTable = () => {
  const [loading, setLoading] = useState(true);
  const [allSearch, setAllSearch] = useState<string>("");
  const searchInput = useRef<InputRef>(null);
  const actionRef = useRef<ActionType>();
  const [searchAttributes, setSearchAttributes] = useState<
    { key: string; value: string }[]
  >([]);

  const expobj = new Export(
    lang.get("discussions", ["Discussions"]) + ", " + new Date().toDateString()
  );

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 1000);
    return () => clearTimeout(timer);
  }, []);

  const handleSearch = (field: string, value: string) => {
    setAllSearch("");
    const existingAttribute = searchAttributes.find(
      (attr) => attr.key === field
    );

    if (existingAttribute) {
      setSearchAttributes(
        searchAttributes.map((attr) =>
          attr.key === field ? { ...attr, value } : attr
        )
      );
    } else {
      setSearchAttributes([
        ...searchAttributes,
        {
          key: field,
          value,
        },
      ]);
    }
    actionRef.current?.reload();
  };

  const searchCols = (field: string, label: string) => {
    return {
      filterDropdown: (props: any) => {
        const { close } = props;

        setTimeout(() => {
          if (searchInput.current) {
            searchInput.current.focus();
          }
        }, 100);

        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={lang.get("discussions", [`Search ${label}`])}
              onChange={(e) => handleSearch(field, e.target.value)}
              value={
                searchAttributes.filter((item) => item.key === field)[0]?.value
              }
              style={{ marginBottom: 8, display: "block" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  close();
                  actionRef.current?.reload();
                }
              }}
            />
            <Space>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="small"
                onClick={() => {
                  actionRef.current?.reload();
                  close();
                }}
                style={{ width: 90 }}
              >
                {lang.get("discussions", ["Search"])}
              </Button>
              <Button
                onClick={() => close()}
                size="small"
                style={{ width: 90 }}
              >
                {lang.get("discussions", ["Close"])}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
    };
  };

  const columns: ProColumns[] = [
    {
      title: lang.get("discussions", ["Subject"]),
      dataIndex: "subject",
      key: "subject",
      sorter: true,
      ...searchCols("subject", lang.get("discussions", ["Subject"])),
    },
    {
      title: lang.get("discussions", ["Last Activity"]),
      dataIndex: "lastActivity",
      key: "lastActivity",
      sorter: true,
      ...searchCols("lastActivity", lang.get("discussions", ["Last Activity"])),
    },
    {
      title: lang.get("discussions", ["Total Comments"]),
      dataIndex: "totalComments",
      key: "totalComments",
      sorter: true,
      ...searchCols(
        "lastActivity",
        lang.get("totalComments", ["Total Comments"])
      ),
      render: (_, record) => (
        <Tag color={record.totalComments > 0 ? "blue" : "gray"}>
          {record.totalComments}
        </Tag>
      ),
    },
    {
      title: lang.get("discussions", ["Visible to Customer"]),
      dataIndex: "visibleToCustomer",
      sorter: true,
      key: "visibleToCustomer",
      ...searchCols(
        "lastActivity",
        lang.get("visibleToCustomer", ["Visible to Customer"])
      ),
      render: (_, record) => <Switch checked={record.visibleToCustomer} />,
    },
  ];

  return (
    <ProTable
      headerTitle={lang.get("discussions", ["Discussions"])}
      columns={columns}
      dataSource={discussionData}
      loading={loading}
      actionRef={actionRef}
      rowKey="subject"
      pagination={{
        pageSize: 6,
        showTotal: (total, range) =>
          lang.get("discussions", [
            `Showing ${range[0]}-${range[1]} of ${total}`,
          ]),
      }}
      search={false}
      toolBarRender={() => [
        <Input
          style={{ width: "auto" }}
          placeholder={lang.get("discussions", ["Global Search"])}
          allowClear
          value={allSearch}
          onChange={(e) => {
            setAllSearch(e.target.value);
            setSearchAttributes([]);
            actionRef?.current?.reload();
          }}
          prefix={<SearchOutlined />}
        />,
        <Button key="csv">{lang.get("discussions", ["Export csv"])}</Button>,
        <Button key="excel">
          {lang.get("discussions", ["Export excel"])}
        </Button>,
        <Button key="pdf">{lang.get("discussions", ["Export pdf"])}</Button>,
      ]}
    />
  );
};

export default DiscussionTable;
