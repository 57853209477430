import { useState, ChangeEvent, useEffect } from "react";
import { Row, Col, Form, Input, Switch, Select } from "antd";

import { ProFormUploadDragger } from "@ant-design/pro-components";

import { DBCustomerContact, SelectType } from "../types/types";
import coreFunctions from "../../../common/utils/coreFunctions";
import apiRequest from "../../../common/utils/apiRequest";

import lang from "../../../lang";

const ClientContactBody = (props: {
  contactData: DBCustomerContact;
  setContactData: (data: DBCustomerContact) => void;
  setUpdateTable: (updateTable: boolean) => void;
  modelClose: () => void;
  selectedStep?: number;
}) => {
  const [permissionsList, setPermissionsList] = useState<SelectType[]>([]);
  const [emailTemplateList, setEmailTemplateList] = useState<SelectType[]>([]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.setContactData({
      ...props.contactData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSwitchChange = (checked: boolean, field: string) => {
    props.setContactData({
      ...props.contactData,
      [field]: checked,
    });
  };

  useEffect(() => {
    const loadData = async () => {
      await new coreFunctions().waitRequest();

      const PERMISSIONS_REQUEST = new apiRequest("permissions/get", true);
      PERMISSIONS_REQUEST.send(
        {},
        (data: { status: string; message: string; data: SelectType[] }) => {
          setPermissionsList(data.data);
        }
      );

      const EMAIL_NOTIFICATION_REQUEST = new apiRequest(
        "notifications/email_templates/get",
        true
      );
      EMAIL_NOTIFICATION_REQUEST.send(
        {},
        (data: { status: string; message: string; data: SelectType[] }) => {
          setEmailTemplateList(data.data);
        }
      );
    };

    loadData();
  }, []);

  return (
    <Form layout="vertical">
      <Row gutter={[16, 3]}>
        <Col xs={24} lg={12}>
          <Form.Item
            label={
              <span style={{ fontSize: "14px" }}>
                {lang.get("contact", ["First Name"])}{" "}
              </span>
            }
          >
            <Input
              id="firstname"
              placeholder={lang.get("contact", ["First Name"])}
              onChange={handleChange}
              value={props.contactData.first_name}
              size="large"
            />
          </Form.Item>
        </Col>

        <Col xs={24} lg={12}>
          <Form.Item
            label={
              <span style={{ fontSize: "14px" }}>
                {lang.get("contact", ["Last Name"])}{" "}
              </span>
            }
          >
            <Input
              id="lastname"
              placeholder={lang.get("contact", ["Last Name"])}
              onChange={handleChange}
              value={props.contactData.last_name}
              size="large"
            />
          </Form.Item>
        </Col>

        <Col xs={24} lg={12}>
          <Form.Item
            label={
              <span style={{ fontSize: "14px" }}>
                {lang.get("contact", ["Position"])}{" "}
              </span>
            }
          >
            <Input
              id="title"
              placeholder={lang.get("contact", ["Position"])}
              onChange={handleChange}
              value={props.contactData.position}
              size="large"
            />
          </Form.Item>
        </Col>

        <Col xs={24} lg={12}>
          <Form.Item
            label={
              <span style={{ fontSize: "14px" }}>
                {lang.get("contact", ["Phone"])}{" "}
              </span>
            }
          >
            <Input
              id="phonenumber"
              placeholder={lang.get("contact", ["Phone"])}
              onChange={handleChange}
              value={props.contactData.phone}
              size="large"
            />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item
            label={
              <span style={{ fontSize: "14px" }}>
                {lang.get("contact", ["Email"])}{" "}
              </span>
            }
          >
            <Input
              id="email"
              placeholder={lang.get("contact", ["Email"])}
              onChange={handleChange}
              value={props.contactData.email}
              size="large"
            />
          </Form.Item>
        </Col>

        <Col xs={24} lg={24}>
          <Form.Item
            label={
              <span style={{ fontSize: "14px" }}>
                {lang.get("contact", ["Password"])}{" "}
              </span>
            }
          >
            <Input.Password
              id="password"
              placeholder={lang.get("contact", ["Password"])}
              onChange={handleChange}
              size="large"
            />
          </Form.Item>
        </Col>

        <Col xs={24} lg={8} md={8}>
          <Form.Item valuePropName="checked">
            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <Switch
                id="send_set_password_email"
                onChange={(checked) =>
                  handleSwitchChange(checked, "send_set_password_email")
                }
                checked={props.contactData.send_set_password}
              />
              <span style={{ fontSize: "14px" }}>
                {lang.get("contact", ["Send SET password email"])}
              </span>
            </div>
          </Form.Item>
        </Col>

        <Col xs={24} lg={8} md={8}>
          <Form.Item valuePropName="checked">
            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <Switch
                id="donot_send_welcome_email"
                onChange={(checked) =>
                  handleSwitchChange(checked, "donot_send_welcome_email")
                }
                checked={props.contactData.do_not_send_welcome_email}
                defaultValue={false}
              />
              <span style={{ fontSize: "14px" }}>
                {lang.get("contact", ["Welcome email"])}
              </span>
            </div>
          </Form.Item>
        </Col>

        <Col xs={24} lg={8} md={8}>
          <Form.Item valuePropName="checked">
            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <Switch
                id="is_primary"
                onChange={(checked) =>
                  handleSwitchChange(checked, "is_primary")
                }
                checked={props.contactData.is_primary}
              />
              <span style={{ fontSize: "14px" }}>
                {lang.get("contact", ["Primary Contact"])}
              </span>
            </div>
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item
            label={
              <span style={{ fontSize: "14px" }}>
                {lang.get("contact", ["Permissions"])}{" "}
              </span>
            }
          >
            <Select
              mode="multiple"
              placeholder={lang.get("contact", ["Select Permissions"])}
              onChange={(value) => {
                props.setContactData({
                  ...props.contactData,
                  permissions: value,
                });
              }}
              size="large"
              options={permissionsList.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
            ></Select>
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item
            label={
              <span style={{ fontSize: "14px" }}>
                {lang.get("contact", ["Email Notifications"])}{" "}
              </span>
            }
          >
            <Select
              mode="multiple"
              placeholder={lang.get("contact", ["Select Notifications"])}
              onChange={(value) =>
                props.setContactData({
                  ...props.contactData,
                  email_notifications: value,
                })
              }
              size="large"
              options={emailTemplateList.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
            ></Select>
          </Form.Item>
        </Col>
        <Col xs={24} lg={24}>
          <Form.Item
            label={
              <span style={{ fontSize: "14px" }}>
                {lang.get("contact", ["Profile Image"])}{" "}
              </span>
            }
          >
            <ProFormUploadDragger
              max={1}
              onChange={(info) => {
                if (info.file.originFileObj) {
                  props.setContactData({
                    ...props.contactData,
                    profile_image: info.file.originFileObj,
                  });
                }
              }}
              title={lang.get("contact", [
                "Click or drag files into this area to upload",
              ])}
              description={lang.get("contact", [
                "Support single file type: jpeg, jpg, png, gif",
              ])}
              accept="image/*"
              name="drag-pic"
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ClientContactBody;
