import React, { useState, useRef, useEffect } from "react";

import type {
  ProColumns,
  EditableFormInstance,
  ActionType,
} from "@ant-design/pro-components";
import { EditableProTable, ProCard } from "@ant-design/pro-components";
import { Input, Select, message, Button } from "antd";

import { DeleteOutlined } from "@ant-design/icons";

import lang from "../../../../../lang";
import apiRequest from "../../../../../common/utils/apiRequest";

import {
  SelectType,
  CreditNoteRowItem,
  TaxOption,
  CreditNote,
  CreditNoteRowItemSection,
  CreditNoteSingleLot,
} from "../../../types/types";

import coreFunctions from "../../../../../common/utils/coreFunctions";

const { Option } = Select;

const defaultValues = {
  quantity: 0,
  free_quantity: 0,
  sale_price: 0.0,
  taxes: [],
  serials: [],
  discount: {
    type: 0,
    value: 0,
  },
  selling_price: 0.0,
  batch_number: "",
  description: "",
  lot_number: "",
};

const MainItemsTable = ({
  setPostData,
  postData,
  activeKey,
  getWarehouse,
}: {
  setPostData: (data: CreditNote) => void;
  postData: CreditNote;
  activeKey: string;
  getWarehouse: () => string;
}) => {
  const first_row_id = Date.now().toString();
  const [tableData, setTableData] = useState<CreditNoteRowItem[]>([
    { id: first_row_id, item_id: "", ...defaultValues },
  ]);
  const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([
    first_row_id,
  ]);
  const actionRef = useRef<ActionType>();
  const editableFormRef = useRef<EditableFormInstance>();
  const [items, setItems] = useState<SelectType[]>([]);
  const [itemTaxes, setItemTaxes] = useState<TaxOption[]>([]);
  const [lotNumbers, setLotNumbers] = useState<CreditNoteSingleLot[]>([]);

  const [dummy, setDummy] = useState(1);

  const CORE_FUNCTIONS = new coreFunctions();

  const handleItemSelect = (key: string | undefined, value: string) => {
    const id = Date.now().toString();

    let existingRow = key && tableData.find((row) => row.id === key);

    if (existingRow) {
      existingRow.item_id = value;
      setTableData(tableData);
    }

    setTableData([
      ...tableData,
      {
        id: id,
        item_id: "",
        ...defaultValues,
      },
    ]);

    setEditableRowKeys((keys) => [...keys, id]);

    setPostData({ ...postData });
  };

  const calculateSingleRow = (item: CreditNoteRowItem | null) => {
    if (!item) {
      return 0;
    }

    let total_per_row: number =
      (item.sale_price ? item.sale_price : 0) * item.quantity;

    const orderedTaxes = item.taxes.sort((a, b) => a.order - b.order);

    orderedTaxes.forEach((tax) => {
      let taxRate = tax.rate ? tax.rate : 0;
      let tax_per_row = (total_per_row * taxRate) / 100;
      total_per_row = total_per_row + tax_per_row;
    });

    if (
      item.discount &&
      item.discount.type !== undefined &&
      item.discount.value
    ) {
      let discount_per_row = 0;
      if (item.discount.type == 1) {
        discount_per_row = item.discount.value;
      } else if (item.discount.type == 0) {
        discount_per_row = (total_per_row * item.discount.value) / 100;
      }

      total_per_row = total_per_row - discount_per_row;
    }

    return total_per_row.toFixed(2);
  };

  useEffect(() => {
    const sections: CreditNoteRowItemSection[] = postData.items;
    sections.map((section) => {
      if (section.id === activeKey) {
        section.items = tableData;
      }
    });

    postData.items = sections;
    setPostData({ ...postData });
  }, [tableData]);

  useEffect(() => {
    setTableData([]);
    setTimeout(() => {
      const sections: CreditNoteRowItemSection[] = postData.items;
      sections.map((section) => {
        if (section.id === activeKey) {
          section.items = tableData;
        }
      });

      postData.items = sections;
      setPostData({ ...postData });
      setTableData(tableData);
    }, 1);
  }, [dummy]);

  useEffect(() => {
    const loadData = async () => {
      const TAXES = new apiRequest("taxes/get", true);
      await TAXES.send(
        {},
        (response: { data: TaxOption[]; status: string }) => {
          if (response.status == "success") {
            setItemTaxes(response.data);
          }
        }
      );
    };

    loadData();
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "+") {
        const id = Date.now().toString() + Math.random();

        setTableData([
          ...tableData,
          {
            id: id,
            item_id: "",
            ...defaultValues,
          },
        ]);

        setEditableRowKeys((keys) => [...keys, id]);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const columns: ProColumns<CreditNoteRowItem>[] = [
    {
      title: lang.get("inventory", ["Item Name"]),
      dataIndex: "itemName",
      width: 180,
      align: "left",
      renderFormItem: (_, { record }) => (
        <Select
          placeholder={lang.get("sales", ["Search and select an item"])}
          value={record?.item}
          onChange={(value) => handleItemSelect(record?.id, value)}
          showSearch
          onSearch={(e) =>
            CORE_FUNCTIONS.searchOptions(e, "inventory", "items", setItems, {
              warehouse: getWarehouse(),
            })
          }
          onClick={() => {
            CORE_FUNCTIONS.searchOptions("", "inventory", "items", setItems, {
              warehouse: getWarehouse(),
            });
          }}
          style={{ width: "100%" }}
        >
          {items.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: lang.get("inventory", ["Description"]),
      dataIndex: "description",
      width: 200,
      align: "left",
      renderFormItem: (_, { record, onChange }) => (
        <Input
          type="text"
          placeholder={lang.get("inventory", ["Description"])}
          value={record?.description}
          onChange={(e) => {
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id
                  ? { ...row, description: e.target.value }
                  : row
              )
            );

            setPostData({ ...postData });
          }}
        />
      ),
    },
    {
      title: lang.get("inventory", ["Lot Number"]),
      dataIndex: "lot_number",
      align: "left",
      width: 110,
      renderFormItem: (_, { record }) => (
        <Select
          value={record?.lot_number}
          onChange={(value) => {
            const lot_number = lotNumbers.find((lot) => lot.id === value);

            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id
                  ? {
                      ...row,
                      lot_number: value,
                      sale_price: lot_number?.selling_price,
                    }
                  : row
              )
            );

            setDummy((prev) => prev + 1);
            setPostData({ ...postData });
          }}
          showSearch
          onClick={() => {
            CORE_FUNCTIONS.searchOptions(
              "",
              "inventory",
              "lot_numbers",
              setLotNumbers,
              { item: record?.item_id, warehouse: getWarehouse() }
            );
          }}
          onSearch={(e) => {
            CORE_FUNCTIONS.searchOptions(
              e,
              "inventory",
              "lot_numbers",
              setLotNumbers,
              { item: record?.item_id, warehouse: getWarehouse() }
            );
          }}
          placeholder={lang.get("inventory", ["Select Lot Number"])}
        >
          {lotNumbers.map((item) => (
            <Select.Option key={item.id} value={item.id}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: lang.get("inventory", ["Quantity"]),
      dataIndex: "quantity",
      align: "left",
      width: 80,
      valueType: "digit",
      renderFormItem: (_, { record, onChange }) => (
        <Input
          type="number"
          value={record?.quantity}
          placeholder={lang.get("inventory", ["Quantity"])}
          onChange={(e) => {
            const value = Number(e.target.value);
            onChange?.(value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id ? { ...row, quantity: value } : row
              )
            );
          }}
        />
      ),
    },
    {
      title: lang.get("inventory", ["Free Qty."]),
      dataIndex: "freeQuantity",
      width: 80,
      align: "left",
      renderFormItem: (_, { record, onChange }) => (
        <Input
          type="number"
          placeholder={lang.get("inventory", ["Free Quantity"])}
          value={record?.free_quantity}
          onChange={(e) => {
            const value = Number(e.target.value);
            onChange?.(value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id ? { ...row, free_quantity: value } : row
              )
            );

            setPostData({ ...postData });
          }}
        />
      ),
    },
    {
      title: lang.get("inventory", ["Selling Price"]),
      dataIndex: "purchasePrice",
      align: "center",
      width: 100,
      valueType: "money",
      renderFormItem: (_, { record, onChange }) => (
        <Input
          type="number"
          placeholder={lang.get("inventory", ["Selling Price"])}
          defaultValue={record?.sale_price}
          onChange={(e) => {
            const value = Number(e.target.value);
            onChange?.(value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id ? { ...row, sale_price: value } : row
              )
            );

            setPostData({ ...postData });
          }}
        />
      ),
    },
    {
      title: lang.get("inventory", ["Tax"]),
      dataIndex: "tax",
      align: "left",
      width: 125,
      renderFormItem: (_, { record, onChange }) => (
        <Select
          mode="multiple"
          showSearch
          onChange={(value) => {
            let taxes: TaxOption[] = [];
            for (let i = 0; i < value.length; i++) {
              for (let j = 0; j < itemTaxes.length; j++) {
                if (value[i] == itemTaxes[j].id) {
                  taxes.push(itemTaxes[j]);
                }
              }
            }
            onChange?.(value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id ? { ...row, taxes } : row
              )
            );

            setPostData({ ...postData });
          }}
          placeholder={lang.get("inventory", ["Select Tax"])}
        >
          {itemTaxes.map((tax) => (
            <Option key={tax.id} value={tax.id}>
              {tax.name}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: lang.get("inventory", ["Discount"]),
      dataIndex: "discount",
      width: 130,
      align: "left",
      renderFormItem: (_, { record }) => (
        <Input.Group compact>
          <Input
            type="number"
            onChange={(e) => {
              const value = Number(e.target?.value);
              const discount = {
                type: record?.discount?.type ? record?.discount?.type : 0,
                value: value,
              };

              setTableData((prev) =>
                prev.map((row) =>
                  row.id === record?.id
                    ? {
                        ...row,
                        discount,
                      }
                    : row
                )
              );

              setPostData({ ...postData });
            }}
            placeholder={lang.get("inventory", ["Enter Discount"])}
            style={{ width: "70%" }}
          />
          <Select
            onChange={(value) => {
              const activeRow = tableData.find((row) => row.id === record?.id);

              const discount = {
                type: value,
                value: activeRow?.discount?.value
                  ? activeRow?.discount?.value
                  : 0,
              };

              setTableData((prev) =>
                prev.map((row) =>
                  row.id === record?.id
                    ? {
                        ...row,
                        discount,
                      }
                    : row
                )
              );

              setPostData({ ...postData });
            }}
            defaultValue={0}
            options={[
              {
                value: 0,
                label: "%",
              },
              {
                value: 1,
                label: lang.get("inventory", ["Fixed"]),
              },
            ]}
            style={{ width: "30%" }}
            dropdownStyle={{ width: "5%" }}
          />
        </Input.Group>
      ),
    },
    {
      title: lang.get("inventory", ["Total"]),
      dataIndex: "sub_total",
      align: "right",
      width: 100,
      valueType: "money",
      renderFormItem: (_, { record }) => {
        const activeRow = tableData.find((row) => row.id === record?.id);

        return (
          <p style={{ textAlign: "right" }}>
            {calculateSingleRow(activeRow ? activeRow : null)}
          </p>
        );
      },
    },
    {
      title: lang.get("inventory", ["Actions"]),
      align: "center",
      width: 100,
      valueType: "option",
    },
  ];

  return (
    <ProCard
      style={{
        border: "1px solid #d9d9d9",
        borderRadius: "8px",
        backgroundColor: "#f9f9fb",
        marginBottom: "20px",
      }}
    >
      {
        <EditableProTable
          rowKey="id"
          value={tableData}
          columns={columns}
          options={{
            fullScreen: true,
            setting: true,
            density: false,
            reload: false,
          }}
          toolbar={{
            title: lang.get("inventory", ["Add Items"]),
            tooltip: lang.get("inventory", ["Add Items"]),
          }}
          columnsState={{
            persistenceKey: "inventory-receiving-voucher-table",
            persistenceType: "localStorage",
          }}
          editable={{
            type: "multiple",
            editableKeys,
            onChange: setEditableRowKeys,
            actionRender: (row, _, defaultDoms) => [
              <Button
                key="delete"
                style={{
                  color: tableData.length === 1 ? "gray" : "red",
                  border: "none",
                  background: "none",
                  cursor: tableData.length === 1 ? "not-allowed" : "pointer",
                }}
                onClick={() => {
                  if (tableData.length > 1) {
                    setTableData((prev) =>
                      prev.filter((item) => item.id !== row.id)
                    );
                    setEditableRowKeys((prev) =>
                      prev.filter((key) => key !== row.id)
                    );
                  } else {
                    message.warning(
                      lang.get("inventory", [
                        "Cannot delete the last remaining row.",
                      ])
                    );
                  }
                }}
                disabled={tableData.length === 1}
              >
                <DeleteOutlined />
              </Button>,
            ],
          }}
          recordCreatorProps={false}
          actionRef={actionRef}
          editableFormRef={editableFormRef}
          search={false}
          scroll={{ x: 800 }}
          toolBarRender={() => []}
        />
      }
    </ProCard>
  );
};

export default MainItemsTable;
