import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Select,
  Button,
  Typography,
  Card,
  Statistic,
  Space,
  Grid,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import TimesheetTable from "./TimeTable";
import StartTimerComponent from "./Timer";

import { DBSingleProject, ProjectTimesheet } from "../../../../types/types";

import lang from "../../../../../../lang";
import TimesheetModal from "./TimesheetModal";

const { Text } = Typography;
const { useBreakpoint } = Grid;

const Timesheet = ({ project }: { project: DBSingleProject | null }) => {
  const screens = useBreakpoint();
  const [projectMembers, setProjectMembers] = useState<
    DBSingleProject["members"]
  >([]);
  const [selectedMemberId, setSelectedMemberId] = useState<string | "all">(
    "all"
  );
  const [isModalVisible, setIsModalVisible] = useState(false);

  const initial = {
    id: "",
    project: project ? project.id : "",
    tags: [],
    start_time: "",
    end_time: "",
    task: "",
    member: "",
    note: "",
  };

  const [payloadData, setPayloadData] = useState<ProjectTimesheet>(initial);

  const handleChange = (value: string | string[], field: string) => {
    setPayloadData({
      ...payloadData,
      [field]: value,
    });
  };

  const metrics = [
    {
      label: "Sessions",
      value: "0",
      percentage: "0%",
      isIncrease: true,
      comparisonText: "Increase vs Last Month",
    },
    {
      label: "New Users",
      value: "0",
      percentage: "0%",
      isIncrease: true,
      comparisonText: "Increase vs Last Month",
    },
    {
      label: "Bounce Rate",
      value: "0%",
      percentage: "0%",
      isIncrease: false,
      comparisonText: "Increase vs Last Month",
    },
    {
      label: "Avg Engagement Time",
      value: "0s",
      percentage: "0%",
      isIncrease: false,
      comparisonText: "Decrease vs Last Month",
    },
  ];

  useEffect(() => {
    if (project) {
      setProjectMembers(project.members || []);
    }
  }, []);

  useEffect(() => {
    setPayloadData(initial);
  }, []);

  return (
    <div style={{ marginTop: "20px" }}>
      {/* Header Actions */}
      <Row justify="start" style={{ marginBottom: "16px" }}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          style={{ marginRight: "8px" }}
          onClick={() => setIsModalVisible(true)}
        >
          Timesheet
        </Button>
      </Row>

      {/* Modal for Timesheet */}
      <TimesheetModal
        setModalVisible={setIsModalVisible}
        modalVisible={isModalVisible}
        project={project}
      />

      {/* Metrics Section */}
      <Row gutter={[16, 16]}>
        {metrics.map((metric, idx) => (
          <Col xs={24} sm={12} lg={6} key={idx}>
            <Card>
              <Statistic
                title={metric.label}
                value={metric.value}
                valueStyle={{ fontSize: "20px", fontWeight: "bold" }}
                prefix={
                  <Text
                    style={{
                      color: metric.isIncrease ? "#52c41a" : "#ff4d4f",
                    }}
                  >
                    {metric.isIncrease ? "▲" : "▼"} {metric.percentage}
                  </Text>
                }
                suffix={
                  screens.sm && (
                    <Text type="secondary">{metric.comparisonText}</Text>
                  )
                }
              />
            </Card>
          </Col>
        ))}
      </Row>

      {/* Filters & Actions */}
      <Row
        gutter={[16, 16]}
        align="middle"
        justify="space-between"
        style={{ marginTop: "16px", marginBottom: "16px" }}
      >
        <Col>
          <Space>
            {/* Items Per Page */}
            <Select
              style={{ width: "120px" }}
              placeholder="Items/Page"
              defaultValue="25"
              options={[
                { label: "25", value: "25" },
                { label: "50", value: "50" },
                { label: "100", value: "100" },
              ]}
            />
            {/* Filter by Member */}
            <Select
              style={{ width: "150px" }}
              placeholder="All Members"
              defaultValue="all"
              options={[
                { label: "All Members", value: "all" },
                ...projectMembers.map((member) => ({
                  label: member.name,
                  value: member.id.toString(),
                })),
              ]}
              onChange={(value) => setSelectedMemberId(value)}
            />
            <StartTimerComponent />
          </Space>
        </Col>
      </Row>

      {/* Timesheet Table */}
      <TimesheetTable project={project} />
    </div>
  );
};

export default Timesheet;
