import React from "react";
import { Breadcrumb, Typography, Button, Space, Row, Col } from "antd";
import { PlusOutlined, ExportOutlined } from "@ant-design/icons";
import lang from "../../../lang";
import ProjectTable from "../../../modules/project/project-list/components/ProjectTable";
import { useNavigate } from "react-router-dom";
import ProjectStatistic from "./components/ProjectStatistic";

const { Title } = Typography;

const ProjectList = () => {
  const navigate = useNavigate();

  const handleAddProjectClick = () => {
    navigate("/project-add");
  };

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      {/* Breadcrumb */}
      <Breadcrumb style={{ marginBottom: "16px" }} separator=">">
        <Breadcrumb.Item>{lang.get("breadcrumbs", ["Home"])}</Breadcrumb.Item>
        <Breadcrumb.Item>
          {lang.get("breadcrumbs", ["Projects"])}
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {lang.get("breadcrumbs", ["Project List"])}
        </Breadcrumb.Item>
      </Breadcrumb>

      {/* Page Title */}
      <Title level={2} style={{ marginBottom: "16px" }}>
        {lang.get("projects", ["Projects"])}
      </Title>

      <div style={{ marginBottom: "16px" }}>
        <ProjectStatistic client={null} />
      </div>

      <Row
        gutter={[16, 16]}
        style={{ marginBottom: "16px" }}
        align="middle"
        justify="space-between"
      >
        {/* Left Section: Buttons */}
        <Col xs={24} sm={24} md={12} lg={12}>
          <Space size="middle" wrap>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleAddProjectClick}
            >
              {lang.get("projects", ["Add Project"])}
            </Button>
            <Button
              variant="solid"
              color="default"
              icon={<ExportOutlined />}
              onClick={() =>
                console.log(lang.get("projects", ["Import action"]))
              }
            >
              {lang.get("projects", ["Import"])}
            </Button>
          </Space>
        </Col>
      </Row>
      <ProjectTable client={null} />
    </div>
  );
};

export default ProjectList;
