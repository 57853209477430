import React, { useState } from "react";
import {
  ProCard,
  ProForm,
  ProFormDependency,
} from "@ant-design/pro-components";
import {
  Row,
  Col,
  Typography,
  Divider,
  Button,
  Input,
  Select,
  DatePicker,
  Form,
  Space,
} from "antd";
import lang from "../../../../lang";
import OrderReturnsAddTable from "./OrderReturnAddTable";

const { Title } = Typography;
const { Option } = Select;

const OrderReturnAdd: React.FC = () => {
  const [discountType, setDiscountType] = useState<string>("%");
  const [discount, setDiscount] = useState<number>(0);
  const [adjustment] = useState<number>(0);
  const [subTotal] = useState<number>(0);
  const [tax, setTax] = useState<string[]>([]);
  const [shipping, setShipping] = useState<number>(0);
  const calculateTotal = () => {
    const discountAmount =
      discountType === "%" ? (subTotal * discount) / 100 : discount;
    return subTotal - discountAmount + adjustment;
  };

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      <Title level={2}>{lang.get("orderReturns", ["Add Order Return"])}</Title>

      <ProCard>
        <ProForm layout="vertical" submitter={false}>
          {/* First Row */}
          <Row gutter={[16, 5]}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("orderReturns", ["Purchase Order"])}
                  </span>
                }
                name="purchaseOrder"
                rules={[
                  {
                    required: true,
                    message: lang.get("orderReturns", ["Required"]),
                  },
                ]}
              >
                <Select
                  placeholder={lang.get("orderReturns", [
                    "Select Purchase Order",
                  ])}
                  size="large"
                >
                  <Option value="PO1">
                    {lang.get("orderReturns", ["PO1"])}
                  </Option>
                  <Option value="PO2">
                    {lang.get("orderReturns", ["PO2"])}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("orderReturns", ["Order Return Number"])}
                  </span>
                }
                name="orderReturnNumber"
                rules={[
                  {
                    required: true,
                    message: lang.get("orderReturns", ["Required"]),
                  },
                ]}
              >
                <Input
                  size="large"
                  value="ORDER_RETURN202501052105"
                  placeholder="ORDER_RETURN202501052105"
                  disabled
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("orderReturns", ["Order Number"])}
                  </span>
                }
                name="orderNumber"
              >
                <Input
                  placeholder={lang.get("orderReturns", ["Enter Order Number"])}
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Second Row */}
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("orderReturns", ["Vendor"])}
                  </span>
                }
                name="vendor"
                rules={[
                  {
                    required: true,
                    message: lang.get("orderReturns", ["Required"]),
                  },
                ]}
              >
                <Select
                  placeholder={lang.get("orderReturns", ["Select Vendor"])}
                  size="large"
                >
                  <Option value="Vendor1">
                    {lang.get("orderReturns", ["Vendor 1"])}
                  </Option>
                  <Option value="Vendor2">
                    {lang.get("orderReturns", ["Vendor 2"])}
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("orderReturns", ["Date Created"])}
                  </span>
                }
                name="dateCreated"
                rules={[
                  {
                    required: true,
                    message: lang.get("orderReturns", ["Required"]),
                  },
                ]}
              >
                <DatePicker style={{ width: "100%" }} size="large" showTime />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("orderReturns", ["Order Date"])}
                  </span>
                }
                name="orderDate"
                rules={[
                  {
                    required: true,
                    message: lang.get("orderReturns", ["Required"]),
                  },
                ]}
              >
                <DatePicker style={{ width: "100%" }} size="large" showTime />
              </Form.Item>
            </Col>
          </Row>

          {/* Third Row */}
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("orderReturns", ["Return Type"])}
                  </span>
                }
                name="returnType"
              >
                <Select
                  placeholder={lang.get("orderReturns", ["Select Return Type"])}
                  size="large"
                >
                  <Option value="Defective">
                    {lang.get("orderReturns", ["Defective"])}
                  </Option>
                  <Option value="Damaged">
                    {lang.get("orderReturns", ["Damaged"])}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("orderReturns", ["Email"])}
                  </span>
                }
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: lang.get("orderReturns", ["Enter valid email"]),
                  },
                ]}
              >
                <Input
                  placeholder={lang.get("orderReturns", ["Enter Email"])}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("orderReturns", ["Phone Number"])}
                  </span>
                }
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: lang.get("orderReturns", ["Required"]),
                  },
                ]}
              >
                <Input
                  placeholder={lang.get("orderReturns", ["Enter Phone Number"])}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("orderReturns", ["Currency"])}
                  </span>
                }
                name="currency"
              >
                <Input value="LKR" placeholder="LKR" disabled size="large" />
              </Form.Item>
            </Col>
          </Row>
        </ProForm>

        <Divider />

        {/* Placeholder for Table */}

        <OrderReturnsAddTable />

        <Divider />

        {/* Summary Section */}
        <Row justify="end" gutter={16} style={{ borderRadius: "8px" }}>
          <ProFormDependency name={["total", "adjustment"]}>
            {() => (
              <Space
                direction="vertical"
                style={{
                  width: "30%",
                  padding: "16px",
                }}
              >
                {/* Shipping Row */}
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: "4px" }}
                >
                  {/* Shipping Input */}
                  <Col style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        fontWeight: 400,
                        fontSize: "12px",
                        marginRight: "8px",
                      }}
                    >
                      {lang.get("sales", ["Shipping ($) :"])}
                    </div>
                    <Input
                      type="number"
                      value={shipping}
                      onChange={(e) => setShipping(Number(e.target.value))}
                      size="small"
                      style={{ width: "100px", textAlign: "center" }}
                    />
                  </Col>

                  {/* Discount Input */}
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "16px",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          fontWeight: 400,
                          fontSize: "12px",
                          marginRight: "8px",
                        }}
                      >
                        {lang.get("sales", ["Discount ($) :"])}
                      </div>
                      <Input
                        type="number"
                        value={discount}
                        onChange={(e) => setDiscount(Number(e.target.value))}
                        size="small"
                        style={{ width: "100px", textAlign: "left" }}
                        addonAfter={
                          <Select
                            value={discountType}
                            onChange={(value) => setDiscountType(value)}
                            style={{ width: "50px" }}
                            dropdownStyle={{
                              width: "150px",
                              textAlign: "center",
                            }}
                          >
                            <Option value="Fixed">
                              {lang.get("sales", ["Fixed"])}
                            </Option>
                            <Option value="%">
                              {lang.get("sales", ["%"])}
                            </Option>
                          </Select>
                        }
                      />
                    </div>
                  </Col>
                </Row>

                <Divider style={{ margin: "8px 0" }} />

                {/*Taxes Input */}

                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: "4px" }}
                >
                  <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                    {lang.get("sales", ["Taxes"])}
                  </Col>
                  <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                    <Select
                      mode="multiple"
                      value={tax}
                      onChange={(values) => setTax(values)}
                      style={{ width: "150px" }}
                      placeholder={lang.get("sales", ["Select Taxes"])}
                    >
                      <Option value="5">
                        {lang.get("sales", ["VAT (5%)"])}
                      </Option>
                      <Option value="10">
                        {lang.get("sales", ["Service Tax (10%)"])}
                      </Option>
                      <Option value="15">
                        {lang.get("sales", ["CGT (15%)"])}
                      </Option>
                    </Select>
                  </Col>
                </Row>

                {/* Shipping Total Row */}
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: "4px" }}
                >
                  <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                    {lang.get("sales", ["Shipping"])}
                  </Col>
                  <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                    {shipping ? `$${shipping.toFixed(2)}` : "-"}
                  </Col>
                </Row>

                {/* Adjustment Total Row */}
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: "4px" }}
                >
                  <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                    {lang.get("sales", ["Adjustment"])}
                  </Col>
                  <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                    {adjustment ? `$${adjustment.toFixed(2)}` : "-"}
                  </Col>
                </Row>

                {/* Discount Total Row */}
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: "4px" }}
                >
                  <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                    {lang.get("sales", ["Discount"])}
                  </Col>
                  <Col
                    style={{
                      fontWeight: 400,
                      fontSize: "12px",
                      color: "#ff4d4f",
                    }}
                  >
                    -$
                    {discountType === "%"
                      ? ((subTotal * discount) / 100).toFixed(2)
                      : discount.toFixed(2)}
                  </Col>
                </Row>

                {/* Taxes Total Row */}
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: "4px" }}
                >
                  <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                    {lang.get("sales", ["Taxes"])}
                  </Col>
                  <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                    {tax.length > 0
                      ? `$${tax
                          .reduce((acc, curr) => acc + parseFloat(curr), 0)
                          .toFixed(2)}`
                      : "-"}
                  </Col>
                </Row>

                <Divider style={{ margin: "8px 0" }} />

                {/* Total Row */}
                <Row justify="space-between" align="middle">
                  <Col style={{ fontWeight: 500, fontSize: "12px" }}>
                    {lang.get("sales", ["Total"])}
                  </Col>
                  <Col style={{ fontWeight: 500, fontSize: "12px" }}>
                    ${calculateTotal().toFixed(2)}
                  </Col>
                </Row>
              </Space>
            )}
          </ProFormDependency>
        </Row>
        {/* Action Buttons */}
        <Row justify="end" gutter={16}>
          <Button
            style={{
              backgroundColor: "white",
              borderColor: "#d9d9d9",
              color: "#000",
              marginRight: 8,
            }}
            onClick={() => console.log(lang.get("orderReturns", ["Close"]))}
          >
            {lang.get("orderReturns", ["Close"])}
          </Button>
          <Button
            type="primary"
            style={{
              backgroundColor: "#1890ff",
              borderColor: "#1890ff",
              color: "#fff",
              marginRight: 8,
            }}
            onClick={() => console.log(lang.get("orderReturns", ["Save"]))}
          >
            {lang.get("orderReturns", ["Save"])}
          </Button>
          <Button
            type="primary"
            style={{
              backgroundColor: "#1890ff",
              borderColor: "#1890ff",
              color: "#fff",
            }}
            onClick={() =>
              console.log(lang.get("orderReturns", ["Save & Send Request"]))
            }
          >
            {lang.get("orderReturns", ["Save & Send Request"])}
          </Button>
        </Row>
      </ProCard>
    </div>
  );
};

export default OrderReturnAdd;
