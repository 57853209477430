import React, { useState } from "react";
import NavbarTopHorizontal from "../navbar-horizontal/NavbarTopHorizontal";
import NavbarVertical from "../navbar-vertical/NavbarVertical";
import { Drawer, Grid } from "antd";

const AppLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const screens = Grid.useBreakpoint();

  const toggleDrawer = () => {
    setDrawerVisible((prev) => !prev);
  };

  return (
    <div style={{ display: "flex", height: "100vh", flexDirection: "column" }}>
      {/* Top Navbar */}
      <NavbarTopHorizontal toggleDrawer={toggleDrawer} />

      <div style={{ display: "flex", flex: 1 }}>
        {/* Vertical Navbar */}
        {screens.md ? (
          <NavbarVertical collapsed={collapsed} setCollapsed={setCollapsed} />
        ) : (
          <Drawer
            title="Menu"
            placement="left"
            onClose={toggleDrawer}
            open={drawerVisible}
            bodyStyle={{ padding: 0 }}
            width={250}
          >
            <NavbarVertical collapsed={false} setCollapsed={setCollapsed} />
          </Drawer>
        )}

       
        <div
          style={{
            flex: 1,
            overflowY: "auto",
           
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
