import React, { useState, useEffect } from "react";
import {
  ProForm,
  ProFormText,
  ProFormSelect,
  ProFormDependency,
  ProCard,
} from "@ant-design/pro-components";
import {
  Button,
  Modal,
  Row,
  Col,
  Divider,
  Typography,
  Space,
  Input,
  Select,
  Form,
  message,
  DatePicker,
} from "antd";
import { useNavigate } from "react-router-dom";
import DeliveryVoucherAddTable from "./DeliveryVoucherAddTable";
import select from "antd/es/select";
import { Editor } from "@tinymce/tinymce-react";
import lang from "../../../lang";

import coreFunctions from "../../../common/utils/coreFunctions";
import apiRequest from "../../../common/utils/apiRequest";

import {
  DeliveryVoucher,
  InventoryDeliveryVoucherRowItem,
  TaxOption,
  CurrencyType,
  SelectType,
} from "../types/types";

const { Title } = Typography;
const Option = select;

const initalData = {
  id: "",
  delivery_voucher_number: "",
  supplier: "",
  warehouse: "",
  date: "",
  accounting_date: "",
  voucher_date: "",
  account: "",
  items: [],
  discount: 0,
  discount_type: 0,
  shipping_cost: 0,
};

const DeliveryVoucherAddBody: React.FC = () => {
  const [allTaxes, setAllTaxes] = useState<TaxOption[]>([]);
  const [accounts, setAccounts] = useState<SelectType[]>([]);
  const [defaultCurrecyCode, setDefaultCurrecyCode] = useState("");
  const [currencies, setCurrencies] = useState<CurrencyType[]>([]);
  const [requesterList, setRequesterList] = useState<SelectType[]>([]);
  const [receiverList, setReceiverList] = useState<SelectType[]>([]);
  const [repList, setRepList] = useState<SelectType[]>([]);
  const [checkersList, setCheckersList] = useState<SelectType[]>([]);

  const [postData, setPostData] = useState<DeliveryVoucher>(initalData);

  const CORE_FUNCTIONS = new coreFunctions();
  const navigate = useNavigate();

  const [summaryData, setSummaryData] = useState({
    sub_total: 0,
    quantity: 0,
    free_quantity: 0,
    total_discount: 0,
    total_tax: 0,
    total_rows_tax: 0,
    total_rows_sub_total: 0,
    total_rows_discount: 0,
    total: 0,
  });

  const calculateTotal = (rows: InventoryDeliveryVoucherRowItem[]) => {
    let sub_total = 0;
    let quantity = 0;
    let free_quantity = 0;
    let total_discount = 0;
    let total_rows_discount = 0;
    let total_rows_sub_total = 0;
    let total_rows_tax = 0;
    let total_tax = 0;

    rows.forEach((item) => {
      let total_per_row: number =
        (item.purchase_price ? item.purchase_price : 0) * item.quantity;

      quantity = quantity + item.quantity;
      free_quantity =
        free_quantity + (item.free_quantity ? item.free_quantity : 0);

      const orderedTaxes = item.taxes.sort((a, b) => a.order - b.order);

      orderedTaxes.forEach((tax) => {
        let taxRate = tax.rate ? tax.rate : 0;
        let tax_per_row = (total_per_row * taxRate) / 100;
        total_per_row = total_per_row + tax_per_row;

        total_rows_tax = total_rows_tax + tax_per_row;
      });

      if (
        item.discount &&
        item.discount.type !== undefined &&
        item.discount.value
      ) {
        let discount_per_row = 0;
        if (item.discount.type == 1) {
          discount_per_row = item.discount.value;
        } else if (item.discount.type == 0) {
          discount_per_row = (total_per_row * item.discount.value) / 100;
        }

        total_per_row = total_per_row - discount_per_row;

        total_rows_discount = total_rows_discount + discount_per_row;
      }

      item.sub_total = total_per_row.toFixed(2);

      total_rows_sub_total = total_rows_sub_total + total_per_row;
    });

    total_discount = total_rows_discount;

    sub_total =
      total_rows_sub_total +
      CORE_FUNCTIONS.castStringToNumber(postData.shipping_cost);

    let total_bill_discount = 0;
    if (postData.discount_type == 1) {
      total_bill_discount = CORE_FUNCTIONS.castStringToNumber(
        postData.discount
      );
    } else {
      total_bill_discount =
        (CORE_FUNCTIONS.castStringToNumber(postData.discount) * sub_total) /
        100;
    }

    total_discount = total_discount + total_bill_discount;
    sub_total = sub_total - total_bill_discount;

    const taxes = (postData.taxes ? postData.taxes : []).sort(
      (a, b) => a.order - b.order
    );

    taxes.forEach((tax) => {
      let taxRate = tax.rate ? tax.rate : 0;
      let tax_per_row = (sub_total * taxRate) / 100;
      sub_total = sub_total + tax_per_row;

      total_tax = total_tax + tax_per_row;
    });

    return {
      sub_total: sub_total,
      quantity: quantity,
      free_quantity: free_quantity,
      total_discount: total_discount,
      total_rows_discount: total_rows_discount,
      total_rows_sub_total: total_rows_sub_total,
      total_rows_tax: total_rows_tax,
      total_tax: total_tax,
      total: sub_total + CORE_FUNCTIONS.castStringToNumber(postData.adjustment),
    };
  };

  const setItems = (items: InventoryDeliveryVoucherRowItem[]) => {
    setPostData({
      ...postData,
      items: items,
    });
  };

  const handleChange = (value: any, field: string) => {
    setPostData({
      ...postData,
      [field]: value,
    });
  };

  const save = () => {
    message
      .loading(lang.get("inventory", ["Adding receiving voucher"]), 1)
      .then(() => {
        const API_REQUEST = new apiRequest(
          "inventory/delivery_voucher/add",
          true
        );

        API_REQUEST.send(postData).then(
          (response: { status: string; message: string }) => {
            if (response && response.status === "success") {
              message.success(
                lang.get("inventory", ["Added successfully."]),
                2
              );
              navigate("/inventory-delivery-voucher");
            } else {
              message.error(lang.get("inventory", [response.message]), 2);
            }
          }
        );
      });
  };

  useEffect(() => {
    const API_REQUEST = new apiRequest(
      "inventory/delivery_voucher/next_number",
      true
    );

    API_REQUEST.send(
      {},
      (response: { next_number: string; status: string }) => {
        if (response.status === "success") {
          postData.delivery_voucher_number = response.next_number;
          setPostData({
            ...postData,
          });
        }
      }
    );

    const TAXES = new apiRequest("taxes/get", true);
    TAXES.send({}, (response: { data: TaxOption[]; status: string }) => {
      if (response.status === "success") {
        setAllTaxes(response.data);
      }
    });

    const CURRENCY_REQUEST = new apiRequest("currencies/get", true);
    CURRENCY_REQUEST.send(
      {},
      (data: { status: string; message: string; data: CurrencyType[] }) => {
        setCurrencies(data.data);

        data.data.forEach((element) => {
          if (element.default) {
            setPostData({
              ...postData,
              currency: element.id,
            });

            setDefaultCurrecyCode(element.code);
          }
        });
      }
    );
  }, []);

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      <Title level={2}>
        {lang.get("sales", ["Inventory Delivery voucher"])}
      </Title>
      <ProCard>
        <ProForm layout="vertical" submitter={false}>
          <Row gutter={[16, 5]}>
            {/* Delivery Voucher Number */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("deliverVoucher", ["Delivery Voucher Number"])}
                  </span>
                }
              >
                <Input
                  placeholder={lang.get("deliverVoucher", [
                    "Enter Voucher Number",
                  ])}
                  value={postData.delivery_voucher_number}
                  readOnly
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Invoice (Selection) */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("deliverVoucher", ["Invoice"])}
                  </span>
                }
              >
                <Select
                  placeholder={lang.get("deliverVoucher", ["Select Invoice"])}
                  size="large"
                  onChange={(e) => {
                    handleChange(e, "invoice");
                  }}
                >
                  <Select.Option value="INV-001">
                    {lang.get("deliverVoucher", ["Invoice 1"])}
                  </Select.Option>
                  <Select.Option value="INV-002">
                    {lang.get("deliverVoucher", ["Invoice 2"])}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Address */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("deliverVoucher", ["Address"])}
                  </span>
                }
              >
                <Input
                  placeholder={lang.get("deliverVoucher", ["Enter Address"])}
                  size="large"
                  onChange={(e) => {
                    handleChange(e.target.value, "address");
                  }}
                />
              </Form.Item>
            </Col>

            {/* Date */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("deliverVoucher", ["Date"])}
                  </span>
                }
                name="date"
              >
                <DatePicker
                  onChange={(e) => handleChange(CORE_FUNCTIONS.formatISODateTime(e.toISOString()), "date")}
                  style={{ width: "100%" }}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Accounting Date */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("deliverVoucher", ["Accounting Date"])}
                  </span>
                }
                name="accountingDate"
              >
                <DatePicker
                  onChange={(e) => handleChange(CORE_FUNCTIONS.formatISODateTime(e.toISOString()), "accounting_date")}
                  style={{ width: "100%" }}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Voucher Date */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("deliverVoucher", ["Voucher Date"])}
                  </span>
                }
                name="voucherDate"
              >
                <DatePicker
                  onChange={(e) => handleChange(CORE_FUNCTIONS.formatISODateTime(e.toISOString()), "voucher_date")}
                  style={{ width: "100%" }}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Requester (Selection) */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("deliverVoucher", ["Requester"])}
                  </span>
                }
                name="requester"
              >
                <Select
                  placeholder={lang.get("deliverVoucher", ["Select Requester"])}
                  size="large"
                  onChange={(e) => {
                    handleChange(e, "request_by");
                  }}
                  showSearch
                  onInputKeyDown={(e) =>
                    CORE_FUNCTIONS.searchOptions(
                      e.currentTarget.value,
                      "hr",
                      "records",
                      setRequesterList
                    )
                  }
                >
                  {requesterList.map((requester) => (
                    <Select.Option value={requester.id}>
                      {requester.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* Receiver (Selection) */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("deliverVoucher", ["Receiver"])}
                  </span>
                }
                name="receiver"
              >
                <Select
                  placeholder={lang.get("deliverVoucher", ["Select Receiver"])}
                  size="large"
                  showSearch
                  onChange={(e) => {
                    handleChange(e, "receive_by");
                  }}
                  onInputKeyDown={(e) =>
                    CORE_FUNCTIONS.searchOptions(
                      e.currentTarget.value,
                      "hr",
                      "records",
                      setReceiverList
                    )
                  }
                >
                  {receiverList.map((receiver) => (
                    <Select.Option value={receiver.id}>
                      {receiver.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* Sales Person (Selection) */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("deliverVoucher", ["Sales Person"])}
                  </span>
                }
                name="salesPerson"
              >
                <Select
                  placeholder={lang.get("deliverVoucher", [
                    "Select Sales Person",
                  ])}
                  size="large"
                  showSearch
                  onChange={(e) => {
                    handleChange(e, "sales_rep");
                  }}
                  onInputKeyDown={(e) =>
                    CORE_FUNCTIONS.searchOptions(
                      e.currentTarget.value,
                      "hr",
                      "records",
                      setRepList
                    )
                  }
                >
                  {repList.map((rep) => (
                    <Select.Option value={rep.id}>{rep.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* Checked by (Selection) */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("deliverVoucher", ["Checked By"])}
                  </span>
                }
                name="checkedBy"
              >
                <Select
                  placeholder={lang.get("deliverVoucher", [
                    "Select Checked By",
                  ])}
                  size="large"
                  showSearch
                  onChange={(e) => {
                    handleChange(e, "checked_by");
                  }}
                  onInputKeyDown={(e) =>
                    CORE_FUNCTIONS.searchOptions(
                      e.currentTarget.value,
                      "hr",
                      "records",
                      setCheckersList
                    )
                  }
                >
                  {checkersList.map((rep) => (
                    <Select.Option value={rep.id}>{rep.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* Shipping Carrier (Selection) */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("deliverVoucher", ["Shipping Carrier"])}
                  </span>
                }
                name="shippingCarrier"
              >
                <Select
                  placeholder={lang.get("deliverVoucher", [
                    "Select Shipping Carrier",
                  ])}
                  size="large"
                >
                  <Select.Option value="SC-001">
                    {lang.get("deliverVoucher", ["Carrier 1"])}
                  </Select.Option>
                  <Select.Option value="SC-002">
                    {lang.get("deliverVoucher", ["Carrier 2"])}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Admin Note */}
            <Col xs={24}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("deliverVoucher", ["Admin Note"])}
                  </span>
                }
                name="adminNote"
              >
                <Input.TextArea
                  placeholder={lang.get("deliverVoucher", ["Enter Admin Note"])}
                  size="large"
                  rows={4}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider />
          <DeliveryVoucherAddTable
            setItemRows={setItems}
            calculateTotal={calculateTotal}
            itemTaxes={allTaxes}
            summary={summaryData}
            setSummary={setSummaryData}
            postData={postData}
            defaultCurrecyCode={defaultCurrecyCode}
          />
          <Divider />

          {/* Summary Section */}
          <Row justify="end" gutter={16} style={{ borderRadius: "8px" }}>
            <ProFormDependency name={["total", "adjustment"]}>
              {() => (
                <Space
                  direction="vertical"
                  style={{
                    padding: "16px",
                  }}
                >
                  {/* Shipping Row */}
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px", padding: "1px" }}
                  >
                    {/* Shipping Input */}
                    <Col xs={24} sm={12} md={12} lg={12}>
                      <div
                        style={{
                          fontWeight: 400,
                          fontSize: "12px",
                          marginRight: "8px",
                        }}
                      >
                        {lang.get("inventory", ["Shipping"])}
                      </div>
                      <Input
                        type="number"
                        placeholder={lang.get("inventory", ["Shipping"])}
                        onChange={(e) => {
                          handleChange(e.target.value, "shipping_cost");
                        }}
                        size="large"
                      />
                    </Col>

                    {/* Discount Input */}
                    <Col xs={24} sm={12} md={12} lg={12}>
                      <div
                        style={{
                          fontWeight: 400,
                          fontSize: "12px",
                          marginRight: "8px",
                        }}
                      >
                        {lang.get("inventory", ["Discount"])}
                      </div>
                      <Input
                        type="number"
                        placeholder={lang.get("inventory", ["Discount"])}
                        onChange={(e) =>
                          handleChange(e.target.value, "discount")
                        }
                        size="large"
                        addonAfter={
                          <Select
                            size="large"
                            onChange={(value) =>
                              handleChange(value, "discount_type")
                            }
                          >
                            <Option value="0">
                              {lang.get("inventory", ["%"])}
                            </Option>
                            <Option value="1">
                              {lang.get("inventory", ["Fixed"])}
                            </Option>
                          </Select>
                        }
                      />
                    </Col>
                  </Row>

                  <Divider style={{ margin: "8px 0" }} />

                  {/*Taxes Input */}

                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    <Col>{lang.get("inventory", ["Taxes"])}</Col>
                    <Col>
                      <Select
                        mode="multiple"
                        size="large"
                        onChange={(values) => {
                          let taxes: TaxOption[] = [];
                          for (let i = 0; i < values.length; i++) {
                            for (let j = 0; j < allTaxes.length; j++) {
                              if (values[i] == allTaxes[j].id) {
                                taxes.push(allTaxes[j]);
                              }
                            }
                          }

                          handleChange(taxes, "taxes");
                        }}
                        style={{ width: "150px" }}
                        placeholder={lang.get("inventory", ["Select Taxes"])}
                        showSearch
                      >
                        {allTaxes.map((tax) => (
                          <Select.Option key={tax.id} value={tax.id}>
                            {tax.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                  </Row>

                  {/* Subtotal Row */}
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    <Col>{lang.get("inventory", ["Subtotal"])}</Col>
                    <Col>
                      {defaultCurrecyCode +
                        " " +
                        summaryData.sub_total.toFixed(2)}
                    </Col>
                  </Row>

                  {/*Adjustment Input */}

                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    <Col>{lang.get("inventory", ["Adjustment"])}</Col>
                    <Col>
                      <Input
                        type="number"
                        placeholder={lang.get("inventory", ["Adjustment"])}
                        onChange={(e) =>
                          handleChange(e.target.value, "adjustment")
                        }
                        size="large"
                      />
                    </Col>
                  </Row>
                  {/* Discount Total Row */}
                  <Row>
                    <Col xs={24} sm={12} md={12} lg={12}>
                      {lang.get("inventory", ["Total Discount"])}
                    </Col>
                    <Col
                      xs={24}
                      sm={12}
                      md={12}
                      lg={12}
                      style={{ textAlign: "right" }}
                    >
                      -
                      {defaultCurrecyCode +
                        " " +
                        summaryData.total_discount.toFixed(2)}
                    </Col>
                  </Row>

                  <Divider style={{ margin: "8px 0" }} />

                  {/* Total Row */}
                  <Row justify="space-between" align="middle">
                    <Col style={{ fontWeight: 500, fontSize: "12px" }}>
                      {lang.get("inventory", ["Total"])}
                    </Col>
                    <Col style={{ fontWeight: 500, fontSize: "12px" }}>
                      {" "}
                      {defaultCurrecyCode + " " + summaryData.total.toFixed(2)}
                    </Col>
                  </Row>
                </Space>
              )}
            </ProFormDependency>
          </Row>

          <Divider />

          {/* Notes Section */}
          <Row gutter={[16, 0]}>
            {/* Currency Column */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("inventory", ["Currency"])}
                  </span>
                }
              >
                <Select
                  size="large"
                  value={postData.currency}
                  placeholder={lang.get("inventory", ["Select Currency"])}
                  onChange={(value) => {
                    handleChange(value, "currency");
                    for (let i = 0; i < currencies.length; i++) {
                      if (currencies[i].id == value) {
                        setDefaultCurrecyCode(currencies[i].code);
                      }
                    }
                  }}
                  style={{ width: "100%" }}
                  showSearch
                >
                  {currencies.map((currency) => (
                    <Option key={currency.id} value={currency.id}>
                      {currency.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* Ex. Rate Column */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("inventory", ["Ex. Rate"])}
                  </span>
                }
                name="ExRate"
              >
                <Input
                  size="large"
                  type="number"
                  onChange={(e) =>
                    handleChange(e.target.value, "exchange_rate")
                  }
                  placeholder={lang.get("inventory", ["Enter Exchange Rate"])}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            {/* Account Column */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("inventory", ["Account"])}
                  </span>
                }
                name="Account"
              >
                <Select
                  size="large"
                  placeholder={lang.get("inventory", ["Select Account"])}
                  onChange={(value) => handleChange(value, "account")}
                  style={{ width: "100%" }}
                  showSearch
                  onInputKeyDown={(e) =>
                    CORE_FUNCTIONS.searchOptions(
                      e.currentTarget.value,
                      "accounting",
                      "accounts",
                      setAccounts
                    )
                  }
                >
                  {accounts.map((account) => (
                    <Option key={account.id} value={account.id}>
                      {account.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {/* Notes Section */}
          <Row gutter={[16, 5]}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("inventory", ["Client Note"])}
                  </span>
                }
                name="Note"
              >
                <Editor
                  apiKey="your-tinymce-api-key"
                  onChange={(e) => handleChange(e.target.value, "note")}
                  init={{
                    height: 200,
                    menubar: false,
                    plugins: ["lists link code"],
                    toolbar:
                      "undo redo | formatselect | bold italic underline | bullist numlist | link code",
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Terms and Conditions"])}
                  </span>
                }
                name="termsAndConditions"
              >
                <Editor
                  apiKey="your-tinymce-api-key"
                  init={{
                    height: 200,
                    menubar: false,
                    plugins: ["lists link code"],
                    toolbar:
                      "undo redo | formatselect | bold italic underline | bullist numlist | link code",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          {/* Submit Section */}
          <Row justify="end" gutter={16}>
            <Col>
              {/* Close Button */}
              <Button
                onClick={() => console.log(lang.get("sales", ["Close"]))}
                style={{
                  backgroundColor: "white",
                  borderColor: "#d9d9d9",
                  color: "#000",
                  marginRight: 8,
                }}
              >
                {lang.get("sales", ["Close"])}
              </Button>

              {/* Save & Send Request Button */}
              <Button
                type="primary"
                onClick={() =>
                  save()
                }
                style={{
                  backgroundColor: "#1890ff",
                  borderColor: "#1890ff",
                  color: "#fff",
                }}
              >
                {lang.get("sales", ["Save & Send Request to approve"])}
              </Button>
            </Col>
          </Row>
        </ProForm>
      </ProCard>
    </div>
  );
};

export default DeliveryVoucherAddBody;
