import { Button, Space, Row, Col } from "antd";
import { BellOutlined } from "@ant-design/icons";
import { DBCustomer } from "../../../types/types";
import lang from "../../../../../lang";
import ReminderTable from "./ReminderTable";

const Reminder = (props: {
  client: DBCustomer;
  preload: boolean;
  setRefresh: (e: boolean) => void;
  refresh: boolean;
}) => {
  return (
    <div style={{  padding: "16px", borderRadius: "8px" }}>
      <Row
        gutter={[16, 16]}
        style={{ marginBottom: "16px", marginTop: "16px" }}
        align="middle"
        justify="space-between"
      >
        {/* Left Section: Buttons */}
        <Col xs={24} sm={24} md={12} lg={12}>
          <Space size="middle" wrap>
            <Button type="primary" icon={<BellOutlined />}>
              {lang.get("reminder" , ["Set Reminder"])}
            </Button>
          </Space>
        </Col>
      </Row>
     

    <ReminderTable />
    </div>
  );
};

export default Reminder;
