import {
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import type { ActionType, ProColumns } from "@ant-design/pro-components";
import { ProTable } from "@ant-design/pro-components";
import { message, Button, Input, Space, InputRef, Typography } from "antd";
import { useEffect, useRef, useState } from "react";

import Export from "../../../../../../common/components/Export";
import apiRequest from "../../../../../../common/utils/apiRequest";
import {
  DBCustomer,
  DB_Currency,
  DBCustomerContact,
} from "../../../../types/types";

import lang from "../../../../../../lang";
import Avatar from "antd/es/avatar/avatar";

export const waitTimePromise = async (time: number = 100) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};

const { Text } = Typography;

export const waitTime = async (time: number = 100) => {
  await waitTimePromise(time);
};

const ContactsTable = (props: {
  addContact: (e: boolean) => void;
  customer: DBCustomer;
  refreshComponent: boolean;
  setContact: (contact: DBCustomerContact | null) => void;
  contact: DBCustomerContact | null;
}) => {
  const actionRef = useRef<ActionType>();
  const [tableData, setTableData] = useState<DBCustomerContact[]>([]);
  const [currency, setCurrency] = useState<DB_Currency[]>([]);
  const [pageSize, setPageSize] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [searchAttributes, setSearchAttributes] = useState<
    { key: string; value: string }[]
  >([]);
  const searchInput = useRef<InputRef>(null);
  const [allSearch, setAllSearch] = useState<string>("");
  const expobj = new Export("Contacts, " + new Date().toDateString());

  const handleSearch = (field: string, value: string) => {
    setAllSearch("");
    const existingAttribute = searchAttributes.find(
      (attr) => attr.key === field
    );

    if (existingAttribute) {
      setSearchAttributes(
        searchAttributes.map((attr) =>
          attr.key === field ? { ...attr, value } : attr
        )
      );
    } else {
      setSearchAttributes([
        ...searchAttributes,
        {
          key: field,
          value,
        },
      ]);
    }
  };

  useEffect(() => {
    const API_REQUEST = new apiRequest("currencies/get", true);
    API_REQUEST.send({}).then(
      (response: { status: string; data: DB_Currency[] }) => {
        if (response && response.status === "success") {
          for (let i = 0; i < response.data.length; i++) {
            setCurrency(response.data);
          }
        }
      }
    );

    actionRef.current?.reset?.();
  }, []);

  useEffect(() => {
    actionRef.current?.reset?.();
    actionRef.current?.reload();
  }, [props.refreshComponent]);

  const editContact = (contact: DBCustomerContact) => {
    props.setContact(contact);
    props.addContact(true);
  };

  const deleteContact = (contact: DBCustomerContact) => {
    message.loading(lang.get("customer", ["Deleting"]), 1).then(() => {
      if (contact.is_primary) {
        message.error(
          lang.get("customer", ["Cannot delete primary contact"]),
          2
        );
        return;
      }

      const API_REQUEST = new apiRequest("client/contact/delete", true);
      API_REQUEST.send(
        { contact: contact.id },
        (response: { status: string; message: string }) => {
          if (response.status === "success") {
            message.success(
              lang.get("customer", ["Customer contact deleted successfully."]),
              2
            );
            actionRef.current?.reset?.();
            actionRef.current?.reload();
          } else {
            message.error(response.message, 2);
          }
        }
      );
    });
  };

  const searchCols = (field: string, label: string) => {
    return {
      filterDropdown: (props: any) => {
        const { close } = props;

        setTimeout(() => {
          if (searchInput.current) {
            searchInput.current.focus();
          }
        }, 100);

        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={`Search ${label}`}
              onChange={(e) => handleSearch(field, e.target.value)}
              value={
                searchAttributes.filter((item) => item.key === field)[0]?.value
              }
              style={{ marginBottom: 8, display: "block" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  close();
                  if (actionRef.current) {
                    actionRef.current?.reset?.();
                    actionRef.current.reload();
                  }
                }
              }}
            />
            <Space>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="small"
                onClick={() => {
                  actionRef.current?.reset?.();
                  actionRef.current?.reload();
                }}
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button
                onClick={() => close()}
                size="small"
                style={{ width: 90 }}
              >
                Close
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
    };
  };

  const columns: ProColumns<DBCustomerContact>[] = [
    {
      title: lang.get("customer", ["ID"]),
      dataIndex: "id",
      key: "id",
      render: (_text, _record, index) => <Text>{index + 1}</Text>,
    },
    {
      title: lang.get("customer", ["Profile Image"]),
      dataIndex: "profile_img",
      key: "profile_img",
      render: (_text, record) =>
        record.profile_image ? (
          <Avatar src={record.profile_image} size="large" />
        ) : (
          lang.get("customer", ["No Image"])
        ),
    },
    {
      title: lang.get("customer", ["First Name"]),
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: lang.get("customer", ["Last Name"]),
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: lang.get("customer", ["Email"]),
      dataIndex: "email",
      key: "email",
    },
    {
      title: lang.get("customer", ["Phone"]),
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: lang.get("customer", ["Action"]),
      dataIndex: "action",
      key: "action",
      render: (_text, record) => (
        <Space>
          <Button
            type="link"
            onClick={() => editContact(record)}
            color="primary"
          >
            <EditOutlined />
          </Button>
          {record.is_primary ? null : (
            <Button
              type="link"
              color="danger"
              onClick={() => deleteContact(record)}
            >
              <DeleteOutlined />
            </Button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <ProTable
      headerTitle={lang.get("customer", ["contacts"])}
      columns={columns}
      actionRef={actionRef}
      cardBordered
      request={async (params, sort, filter) => {
        await waitTime(10);

        const processedFilters = Object.entries(filter || {}).reduce(
          (acc, [key, value]) => {
            if (value && value.length > 0) {
              acc[key] = value;
            }
            return acc;
          },
          {} as Record<string, (string | number)[]>
        );

        const payload = {
          client: props.customer.id,
          params: params,
          sorts: sort,
          filters: processedFilters,
          searches: searchAttributes,
          all_search: allSearch,
        };

        const API_REQUEST = new apiRequest("client/contacts/get", true);
        const response = await API_REQUEST.send(payload);

        if (response && response.status === "success") {
          const fetchedData = response.data || [];
          setTableData(fetchedData);
          setTotalRecords(response.total ? response.total : fetchedData.length);

          return {
            data: fetchedData,
            total: totalRecords,
            success: true,
          };
        }

        return {
          data: [],
          total: 0,
          success: false,
        };
      }}
      columnsState={{
        persistenceKey: "customer-contacts-table",
        persistenceType: "localStorage",
      }}
      pagination={{
        defaultCurrent: 1,
        pageSize: pageSize,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50, 100, totalRecords],
        showQuickJumper: true,
        showTotal: (total, range) =>
          `Showing ${range[0]}-${range[1]} of ${total} items`,
        onShowSizeChange: (current, size) => {
          setPageSize(size);
        },
      }}
      rowKey="id"
      search={false}
      scroll={{ x: 800 }}
      toolBarRender={() => [
        <Input
          style={{ width: "auto" }}
          key="all_search"
          allowClear
          placeholder={lang.get("customer", "Global Search")}
          value={allSearch}
          onChange={(e) => {
            setAllSearch(e.target.value);
            setSearchAttributes([]);
            actionRef?.current?.reload();
          }}
        />,
        <Button
          key="csv"
          onClick={() => {
            const csvData = tableData.map((row) => ({
              "First Name": row.first_name,
              "Last Name": row.last_name,
              Email: row.email,
              Phone: row.phone,
            }));

            expobj.exportToCSV(csvData);
          }}
        >
          Export CSV
        </Button>,
        <Button
          key="excel"
          onClick={() => {
            const data = tableData.map((row) => ({
              "First Name": row.first_name,
              "Last Name": row.last_name,
              Email: row.email,
              Phone: row.phone,
            }));

            expobj.exportToExcel(data);
          }}
        >
          Export Excel
        </Button>,
        <Button
          key="pdf"
          onClick={() => {
            expobj.exportToPDF({
              head: [
                [
                  lang.get("customer", ["First Name"]),
                  lang.get("customer", ["Last Name"]),
                  lang.get("customer", ["Email"]),
                  lang.get("customer", ["Phone"]),
                ],
              ],
              body: tableData.map((row) => [
                row.first_name,
                row.last_name,
                row.email,
                row.phone,
              ]),
            });
          }}
        >
          Export PDF
        </Button>,
        <Button
          type="primary"
          onClick={() => {
            props.setContact(null);
            props.addContact(true);
          }}
        >
          {lang.get("customer", ["+ Add Contact"])}
        </Button>,
      ]}
    />
  );
};

export default ContactsTable;
