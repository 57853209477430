import React from "react";
import {
  Avatar,
  Tag,
  Card,
  Dropdown,
  Menu,
  Input,
  Button,
  Typography,
  Space,
  Divider,
} from "antd";
import {
  UserOutlined,
  LogoutOutlined,
  SettingOutlined,
  LockOutlined,
  PieChartOutlined,
  UserAddOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import team30 from "../assets/img/team/30.webp";

const { Text } = Typography;

const ProfileDropdownMenu: React.FC = () => {
  const navigate = useNavigate();

  const navItems = [
    { label: "Profile", icon: <UserOutlined /> },
    { label: "Dashboard", icon: <PieChartOutlined /> },
    { label: "Posts & Activity", icon: <LockOutlined /> },
    { label: "Settings & Privacy", icon: <SettingOutlined /> },
    { label: "Help Center", icon: <QuestionCircleOutlined /> },
  ];

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  const menu = (
    <Card
      bordered={false}
      style={{
        width: "300px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
      }}
    >
      {/* User Info Section */}
      <Space
        direction="vertical"
        align="center"
        style={{ width: "100%" }}
        size={2}
      >
        <Avatar size={40} src={team30} />
        <motion.div animate={{ scale: [0, 1] }} className="tag-container">
          <Tag color="blue">
            {`Hello ${localStorage.getItem("role")?.toUpperCase() || "USER"}!`}
          </Tag>
        </motion.div>
        <motion.div animate={{ scale: [0, 1] }} transition={{ delay: 0.3 }}>
          <Text type="secondary">
            PL:{" "}
            {localStorage.getItem("last_logined") !== "null"
              ? localStorage.getItem("last_logined")
              : "N/A"}
          </Text>
        </motion.div>
        <Input
          placeholder="Update your status"
          size="small"
          style={{
            borderRadius: "4px",
            width: "100%",
          }}
        />
      </Space>

      <Divider style={{ margin: "8px 0" }} />

      <Menu style={{ border: "none", boxShadow: "none" }}>
        {navItems.map((item) => (
          <Menu.Item key={item.label} icon={item.icon}>
            {item.label}
          </Menu.Item>
        ))}

        <Divider style={{ margin: "8px 0" }} />
        <Menu.Item key="add-account" icon={<UserAddOutlined />}>
          Add another account
        </Menu.Item>
        <Menu.Item
          key="logout"
          icon={<LogoutOutlined />}
          onClick={handleLogout}
          style={{ padding: "8px 16px" }}
        >
          Sign out
        </Menu.Item>
      </Menu>

      <Divider style={{ margin: "8px 0" }} />

      {/* Footer Links */}
      <Space
        direction="horizontal"
        size="small"
        style={{ justifyContent: "center", width: "100%" }}
      >
        <Text type="secondary">
          <a href="#!" style={{ color: "#8c8c8c" }}>
            Privacy policy
          </a>
        </Text>
        <Text type="secondary">•</Text>
        <Text type="secondary">
          <a href="#!" style={{ color: "#8c8c8c" }}>
            Terms
          </a>
        </Text>
        <Text type="secondary">•</Text>
        <Text type="secondary">
          <a href="#!" style={{ color: "#8c8c8c" }}>
            Cookies
          </a>
        </Text>
      </Space>
    </Card>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      placement="bottomRight"
      overlayStyle={{ padding: 0 }}
    >
      <Button
        type="text"
        icon={<Avatar size="large" src={team30} />}
        style={{ border: "none", boxShadow: "none" }}
      />
    </Dropdown>
  );
};

export default ProfileDropdownMenu;
