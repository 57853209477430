import { useState, ChangeEvent, useEffect } from "react";
import {
  Card,
  Steps,
  Row,
  Col,
  Input,
  Select,
  Form,
  Button,
  Divider,
  ColorPicker,
  Space,
  message,
} from "antd";

import lang from "../../../../lang";
import { ProFormUploadDragger } from "@ant-design/pro-components";
import { SelectType } from "../../../../common/types";

import apiRequest from "../../../../common/utils/apiRequest";
import coreFunctions from "../../../../common/utils/coreFunctions";
import { RcFile } from "antd/es/upload";
import UploadFile from "../../../../common/utils/upload";

import { DB_ITEM } from "../../types/types";

const { Option } = Select;
const { Step } = Steps;
const { TextArea } = Input;

const ItemAddBody = (props: {
  modelClose: () => void;
  setRefreshTable: (refreshTable: boolean) => void;
  refreshTable: boolean;
}) => {
  const initalData: DB_ITEM = {
    id: "",
    type: "",
    name: "",
    code: "",
    barcode: "",
    description: "",
    tags: [],
    unit: "",
    suppliers: [],
    commodity_group: "",
    commodity_sub_group: "",
    manufacturer: "",
    minimum_stock: 0,
    brands: [],
    maximum_stock: 0,
    image: "",
    addtional_files: [],
    item_brochure: "",
    colors: [],
    sizes: [],
    styles: "",
    models: [],
    warehouses: [],
    rack_name: "",
    status: "",
    rack_number: "",
    purchase_price: 0,
    purchase_account: "",
    purchase_description: "",
    purchase_taxes: [],
    sales_taxes: [],
    selling_price: 0,
    sales_account: "",
    sales_description: "",
    default_profit_rate: 0,
    inventory_account: "",
  };

  const [postData, setPostData] = useState<DB_ITEM>(initalData);

  const [currentStep, setCurrentStep] = useState(0);
  const [itemTypes, setItemTypes] = useState<SelectType[]>([]);
  const [tags, setTags] = useState<SelectType[]>([]);
  const [units, setUnits] = useState<SelectType[]>([]);
  const [models, setModels] = useState<SelectType[]>([]);
  const [sizes, setSizes] = useState<SelectType[]>([]);
  const [brands, setBrands] = useState<SelectType[]>([]);
  const [incomeAccounts, setIncomeAccounts] = useState<SelectType[]>([]);
  const [purchaseTaxes, setPurchaseTaxes] = useState<SelectType[]>([]);
  const [expensesAccounts, setExpensesAccounts] = useState<SelectType[]>([]);
  const [inventoryAccounts, setInventoryAccounts] = useState<SelectType[]>([]);
  const [salesTaxes, setSalesTaxes] = useState<SelectType[]>([]);
  const [categories, setCategories] = useState<SelectType[]>([]);
  const [subCategories, setSubCategories] = useState<SelectType[]>([]);
  const [suppliers, setSuppliers] = useState<SelectType[]>([]);
  const [colors, setColors] = useState<SelectType[]>([]);
  const [defaultCurrency, setDefaultCurrency] = useState<SelectType | null>(
    null
  );
  const [itemImage, setItemImage] = useState<File | null>(null);
  const [itemBrochure, setItemBrochure] = useState<File | null>(null);
  const [additionalFiles, setAdditionalFiles] = useState<File[]>([]);

  const [saving, setSaving] = useState(false);

  const CORE_FUNCTIONS = new coreFunctions();

  const handleChange = (value: string | string[], field: string) => {
    setPostData({
      ...postData,
      [field]: value,
    });
  };

  const saveItem = async () => {
    setSaving(true);
    const FILE_INSTANCE = new UploadFile();

    if (additionalFiles.length > 0 && postData.addtional_files.length < 1) {
      for (let i = 0; i < additionalFiles.length; i++) {
        await FILE_INSTANCE.initUpload(additionalFiles[i]);

        const file_name = FILE_INSTANCE.getFileName();
        if (typeof file_name === "string") {
          postData.addtional_files.push(file_name);
        }
      }
    }

    if (itemImage) {
      await FILE_INSTANCE.initUpload(itemImage);

      const file_name = FILE_INSTANCE.getFileName();
      if (typeof file_name === "string") {
        postData.image = file_name;
      }
    }

    if (itemBrochure) {
      await FILE_INSTANCE.initUpload(itemBrochure);

      const file_name = FILE_INSTANCE.getFileName();
      if (typeof file_name === "string") {
        postData.item_brochure = file_name;
      }
    }

    message.loading(lang.get("project", ["Adding item"]), 1).then(() => {
      const API_REQUEST = new apiRequest("inventory/item/add", true, true);
      API_REQUEST.send(
        postData,
        (data: { status: string; message: string }) => {
          if (data.status === "success") {
            message.success(
              lang.get("project", ["Item added successfully."]),
              2
            );

            props.setRefreshTable(!props.refreshTable);
            props.modelClose();

            setPostData(initalData);
          } else {
            message.error(lang.get("project", [data.message]), 2);
          }
          setSaving(false);
        }
      );
    });

    setPostData(postData);
  };

  useEffect(() => {
    const API_REQUEST = new apiRequest("currency/default", true);

    API_REQUEST.send({}, (response: { status: string; data: SelectType }) => {
      if (response.status === "success") {
        setDefaultCurrency(response.data);
      }
    });
  }, []);

  const steps = [
    {
      title: lang.get("inventory", ["General Information"]),
      content: (
        <Row gutter={[16, 4]}>
          <Col xs={24} lg={12}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("inventory", ["Inventory Type"])}
                </span>
              }
              name="item_type"
            >
              <Select
                onChange={(value) => handleChange(value, "type")}
                size="large"
                showSearch
                placeholder={lang.get("inventory", ["Select Inventory Type"])}
                onInputKeyDown={(e) =>
                  CORE_FUNCTIONS.searchOptions(
                    e.currentTarget.value,
                    "inventory",
                    "item_types",
                    setItemTypes
                  )
                }
              >
                {itemTypes.map((type) => (
                  <Option key={type.id} value={type.id}>
                    {type.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("inventory", ["Item Name"])}
                </span>
              }
              name="item_name"
            >
              <Input
                id="item_name"
                placeholder={lang.get("inventory", ["Item Name"])}
                onChange={(e) => handleChange(e.target.value, "name")}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("inventory", ["Item Code"])}
                </span>
              }
              name="item_code"
            >
              <Input
                id="item_code"
                placeholder={lang.get("inventory", ["Item Code"])}
                onChange={(e) => handleChange(e.target.value, "code")}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("inventory", ["Item Barcode"])}
                </span>
              }
              name="item_barcode"
            >
              <Input
                id="item_barcode"
                placeholder={lang.get("inventory", ["Item Barcode"])}
                onChange={(e) => handleChange(e.target.value, "barcode")}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("inventory", ["Description"])}
                </span>
              }
              name="description"
            >
              <TextArea
                id="description"
                placeholder={lang.get("inventory", ["Description"])}
                onChange={(e) => handleChange(e.target.value, "description")}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("inventory", ["Tags"])}
                </span>
              }
              name="tags"
            >
              <Select
                mode="tags"
                placeholder={lang.get("inventory", ["Add Tags"])}
                onChange={(value) => handleChange(value, "tags")}
                size="large"
                showSearch
                onInputKeyDown={(e) =>
                  CORE_FUNCTIONS.searchOptions(
                    e.currentTarget.value,
                    "global",
                    "tags",
                    setTags
                  )
                }
              >
                {tags.map((type) => (
                  <Option key={type.id} value={type.id}>
                    {type.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("inventory", ["Units"])}
                </span>
              }
              name="unit"
            >
              <Select
                onChange={(value) => handleChange(value, "unit")}
                size="large"
                placeholder={lang.get("inventory", ["Select Unit"])}
                showSearch
                onInputKeyDown={(e) =>
                  CORE_FUNCTIONS.searchOptions(
                    e.currentTarget.value,
                    "inventory",
                    "units",
                    setUnits
                  )
                }
              >
                {units.map((type) => (
                  <Option key={type.id} value={type.id}>
                    {type.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("inventory", ["Supplier"])}
                </span>
              }
              name="suppliers"
            >
              <Select
                mode="multiple"
                onChange={(value) => handleChange(value, "suppliers")}
                size="large"
                allowClear
                placeholder={lang.get("inventory", ["Select Suppliers"])}
                showSearch
                onInputKeyDown={(e) =>
                  CORE_FUNCTIONS.searchOptions(
                    e.currentTarget.value,
                    "purchase",
                    "vendors",
                    setSuppliers
                  )
                }
              >
                {suppliers.map((type) => (
                  <Option key={type.id} value={type.id}>
                    {type.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("inventory", ["Category"])}
                </span>
              }
              name="category"
            >
              <Select
                onChange={(value) => handleChange(value, "commodity_group")}
                size="large"
                placeholder={lang.get("inventory", ["Select Category"])}
                showSearch
                onInputKeyDown={(e) =>
                  CORE_FUNCTIONS.searchOptions(
                    e.currentTarget.value,
                    "inventory",
                    "commodity_groups",
                    setCategories
                  )
                }
              >
                {categories.map((type) => (
                  <Option key={type.id} value={type.id}>
                    {type.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("inventory", ["Manufacturer"])}
                </span>
              }
              name="manufacturer"
            >
              <Input
                id="manufacturer"
                placeholder={lang.get("inventory", ["Manufacturer"])}
                onChange={(e) => handleChange(e.target.value, "manufacturer")}
                size="large"
              />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("inventory", ["Sub Category"])}
                </span>
              }
              name="commodity_sub_group"
            >
              <Select
                onChange={(value) => handleChange(value, "commodity_sub_group")}
                placeholder={lang.get("inventory", ["Select Sub Category"])}
                size="large"
                showSearch
                onInputKeyDown={(e) =>
                  CORE_FUNCTIONS.searchOptions(
                    e.currentTarget.value,
                    "inventory",
                    "commodity_sub_groups",
                    setSubCategories,
                    {
                      commodity_group: postData.commodity_group,
                    }
                  )
                }
              >
                {subCategories.map((type) => (
                  <Option key={type.id} value={type.id}>
                    {type.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("inventory", ["Minimum Stock"])}
                </span>
              }
              name="minimum_stock"
            >
              <Input
                id="minimum_stock"
                type="number"
                placeholder={lang.get("inventory", ["Minimum Stock"])}
                onChange={(e) => handleChange(e.target.value, "minimum_stock")}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("inventory", ["Brand"])}
                </span>
              }
              name="brand"
            >
              <Select
                mode="tags"
                placeholder={lang.get("inventory", ["Add Brand"])}
                onChange={(value) => handleChange(value, "brands")}
                size="large"
                showSearch
                onInputKeyDown={(e) =>
                  CORE_FUNCTIONS.searchOptions(
                    e.currentTarget.value,
                    "inventory",
                    "brands",
                    setBrands
                  )
                }
              >
                {brands.map((type) => (
                  <Option key={type.id} value={type.id}>
                    {type.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("inventory", ["Maximum Stock"])}
                </span>
              }
              name="maximum_stock"
            >
              <Input
                id="maximum_stock"
                type="number"
                placeholder={lang.get("inventory", ["Maximum Stock"])}
                onChange={(e) => handleChange(e.target.value, "maximum_stock")}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <ProFormUploadDragger
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("inventory", ["Item Image"])}
                </span>
              }
              name="item_image"
              max={1}
              fieldProps={{
                name: "file",
                listType: "picture-card",
              }}
              onChange={(info) => {
                setItemImage(
                  info.file.originFileObj ? info.file.originFileObj : null
                );
              }}
              title={lang.get("project", [
                "Click or drag files into this area to upload",
              ])}
              description={lang.get("project", [
                "Support single or batch upload",
              ])}
              accept="image/*"
            />
          </Col>
          <Col xs={24} lg={12}>
            <ProFormUploadDragger
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("inventory", ["Additional Images"])}
                </span>
              }
              name="additional_images"
              max={2}
              fieldProps={{
                name: "file",
                listType: "picture-card",
              }}
              onChange={(info) => {
                const { fileList } = info;
                if (fileList && fileList.length > 0) {
                  const up_files = fileList
                    .map((file) => file.originFileObj)
                    .filter((file): file is RcFile => !!file);

                  setAdditionalFiles(up_files);
                }
              }}
              title={lang.get("project", [
                "Click or drag files into this area to upload",
              ])}
              description={lang.get("project", [
                "Support single or batch upload",
              ])}
              accept="image/*"
            />
          </Col>
          <Col xs={24} lg={24}>
            <ProFormUploadDragger
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("inventory", ["Product Brochure"])}
                </span>
              }
              name="product_brochure"
              max={1}
              fieldProps={{
                name: "file",
                listType: "picture-card",
              }}
              onChange={(info) => {
                setItemBrochure(
                  info.file.originFileObj ? info.file.originFileObj : null
                );
              }}
              title={lang.get("project", [
                "Click or drag files into this area to upload",
              ])}
              description={lang.get("project", [
                "Support single or batch upload",
              ])}
              accept="image/*"
            />
          </Col>
        </Row>
      ),
    },
    {
      title: lang.get("inventory", ["Properties"]),
      content: (
        <Row gutter={[16, 4]}>
          <Col xs={24} lg={8}>
            <Form.Item label={lang.get("inventory", ["Color"])} name="colors">
              <Select
                mode="tags"
                placeholder={lang.get("inventory", ["Add Colors"])}
                onChange={(value) => handleChange(value, "colors")}
                size="large"
                showSearch
                onInputKeyDown={(e) =>
                  CORE_FUNCTIONS.searchOptions(
                    e.currentTarget.value,
                    "inventory",
                    "colors",
                    setColors
                  )
                }
              >
                {colors.map((color) => (
                  <Option key={color.id} value={color.id}>
                    {color.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("inventory", ["Models"])}
                </span>
              }
              name="models"
            >
              <Select
                mode="tags"
                placeholder={lang.get("inventory", ["Add Models"])}
                onChange={(value) => handleChange(value, "models")}
                size="large"
                showSearch
                onInputKeyDown={(e) =>
                  CORE_FUNCTIONS.searchOptions(
                    e.currentTarget.value,
                    "inventory",
                    "models",
                    setModels
                  )
                }
              >
                {models.map((model) => (
                  <Option key={model.id} value={model.id}>
                    {model.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("inventory", ["Sizes"])}
                </span>
              }
              name="sizes"
            >
              <Select
                mode="multiple"
                placeholder={lang.get("inventory", ["Add Sizes"])}
                onChange={(value) => handleChange(value, "sizes")}
                size="large"
                showSearch
                onInputKeyDown={(e) =>
                  CORE_FUNCTIONS.searchOptions(
                    e.currentTarget.value,
                    "inventory",
                    "sizes",
                    setSizes
                  )
                }
              >
                {sizes.map((size) => (
                  <Option key={size.id} value={size.id}>
                    {size.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("inventory", ["Styles"])}
                </span>
              }
              name="styles"
            >
              <Input
                id="styles"
                placeholder={lang.get("inventory", ["Styles"])}
                onChange={(e) => handleChange(e.target.value, "styles")}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("inventory", ["Rack Name"])}
                </span>
              }
              name="rackName"
            >
              <Input
                id="rackName"
                placeholder={lang.get("inventory", ["Enter Rack Name"])}
                onChange={(e) => handleChange(e.target.value, "rack_name")}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("inventory", ["Rack Number"])}
                </span>
              }
              name="rackNumber"
            >
              <Input
                id="rackNumber"
                placeholder={lang.get("inventory", ["Enter Rack Number"])}
                onChange={(e) => handleChange(e.target.value, "rack_number")}
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
    {
      title: lang.get("inventory", ["Pricing Structure"]),
      content: (
        <Row gutter={[16, 4]}>
          {/* Purchase Information */}
          <Col xs={24} lg={12}>
            <Card
              title={
                <span style={{ fontSize: "14px" }}>Purchase Information</span>
              }
              bordered
            >
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("inventory", ["Purchase Price"])}
                  </span>
                }
                name="purchasePrice"
              >
                <Input
                  id="purchasePrice"
                  type="number"
                  addonBefore={defaultCurrency?.name}
                  placeholder={lang.get("inventory", ["Purchase Price"])}
                  onChange={(e) =>
                    handleChange(e.target.value, "purchase_price")
                  }
                  size="large"
                />
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("inventory", ["Expenses Account"])}
                  </span>
                }
                name="purchaseAccount"
              >
                <Select
                  onChange={(value) => handleChange(value, "purchase_account")}
                  size="large"
                  placeholder={lang.get("inventory", ["Select Account"])}
                  showSearch
                  onInputKeyDown={(e) =>
                    CORE_FUNCTIONS.searchOptions(
                      e.currentTarget.value,
                      "accounting",
                      "accounts",
                      setExpensesAccounts
                    )
                  }
                >
                  {expensesAccounts.map((account) => (
                    <Option key={account.id} value={account.id}>
                      {account.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("inventory", ["Description"])}
                  </span>
                }
                name="purchaseDescription"
              >
                <TextArea
                  id="purchaseDescription"
                  placeholder={lang.get("inventory", ["Description"])}
                  onChange={(e) =>
                    handleChange(e.target.value, "purchase_description")
                  }
                  size="large"
                />
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("inventory", ["Tax"])}
                  </span>
                }
                name="purchaseTax"
              >
                <Select
                  mode="tags"
                  onChange={(value) => handleChange(value, "purchase_taxes")}
                  size="large"
                  placeholder={lang.get("inventory", ["Select a Tax"])}
                  showSearch
                  onInputKeyDown={(e) =>
                    CORE_FUNCTIONS.searchOptions(
                      e.currentTarget.value,
                      "global",
                      "taxes",
                      setPurchaseTaxes
                    )
                  }
                >
                  {purchaseTaxes.map((tax) => (
                    <Option key={tax.id} value={tax.id}>
                      {tax.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Card>
          </Col>
          {/* Sales Information */}
          <Col xs={24} lg={12}>
            <Card
              title={
                <span style={{ fontSize: "14px" }}>Selling Information</span>
              }
              bordered
            >
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("inventory", ["Selling Price"])}
                  </span>
                }
                name="sellingPrice"
              >
                <Input
                  id="sellingPrice"
                  type="number"
                  addonBefore={defaultCurrency?.name}
                  placeholder={lang.get("inventory", ["Selling Price"])}
                  onChange={(e) =>
                    handleChange(e.target.value, "selling_price")
                  }
                  size="large"
                />
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("inventory", ["Income Account"])}
                  </span>
                }
                name="salesAccount"
              >
                <Select
                  onChange={(value) => handleChange(value, "sales_account")}
                  size="large"
                  placeholder={lang.get("inventory", ["Select Account"])}
                  showSearch
                  onInputKeyDown={(e) =>
                    CORE_FUNCTIONS.searchOptions(
                      e.currentTarget.value,
                      "accounting",
                      "accounts",
                      setIncomeAccounts
                    )
                  }
                >
                  {incomeAccounts.map((account) => (
                    <Option key={account.id} value={account.id}>
                      {account.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("inventory", ["Description"])}
                  </span>
                }
                name="salesDescription"
              >
                <TextArea
                  id="salesDescription"
                  placeholder={lang.get("inventory", ["Description"])}
                  onChange={(e) =>
                    handleChange(e.target.value, "sales_description")
                  }
                  size="large"
                />
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("inventory", ["Tax"])}
                  </span>
                }
                name="salesTax"
              >
                <Select
                  mode="tags"
                  onChange={(value) => handleChange(value, "sales_taxes")}
                  size="large"
                  placeholder={lang.get("inventory", ["Select a Tax"])}
                  showSearch
                  onInputKeyDown={(e) =>
                    CORE_FUNCTIONS.searchOptions(
                      e.currentTarget.value,
                      "global",
                      "taxes",
                      setSalesTaxes
                    )
                  }
                >
                  {salesTaxes.map((tax) => (
                    <Option key={tax.id} value={tax.id}>
                      {tax.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Card>
          </Col>

          <Divider />
          <Col xs={24} lg={12}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("inventory", ["Default Profit Rate (%)"])}
                </span>
              }
              name="discountRate"
            >
              <Input
                id="discountRate"
                type="number"
                placeholder={lang.get("inventory", ["Default Profit Rate (%)"])}
                onChange={(e) =>
                  handleChange(e.target.value, "default_profit_rate")
                }
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("inventory", ["Inventory Account"])}
                </span>
              }
              name="inventoryAccount"
              rules={[
                {
                  required: true,
                  message: lang.get("inventory", ["Account is required"]),
                },
              ]}
            >
              <Select
                size="large"
                placeholder={lang.get("inventory", [
                  "Select Inventory Account",
                ])}
                onChange={(value) => handleChange(value, "inventory_account")}
                showSearch
                onInputKeyDown={(e) =>
                  CORE_FUNCTIONS.searchOptions(
                    e.currentTarget.value,
                    "accounting",
                    "accounts",
                    setInventoryAccounts
                  )
                }
              >
                {inventoryAccounts.map((account) => (
                  <Option key={account.id} value={account.id}>
                    {account.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <Card>
      <Steps
        current={currentStep}
        onChange={(step) => setCurrentStep(step)}
        className="mb-4"
      >
        {steps.map((step, index) => (
          <Step key={index} title={step.title} />
        ))}
      </Steps>
      <Form layout="vertical" style={{ fontSize: "13px" }}>
        {steps[currentStep].content}
      </Form>
      <div className="steps-action mt-4">
        <Row justify="end" gutter={[8, 0]}>
          {currentStep > 0 && (
            <Col>
              <Button onClick={() => setCurrentStep(currentStep - 1)}>
                {lang.get("inventory", ["Previous"])}
              </Button>
            </Col>
          )}
          {currentStep < steps.length - 1 && (
            <Col>
              <Button
                type="primary"
                onClick={() => setCurrentStep(currentStep + 1)}
              >
                {lang.get("inventory", ["Next"])}
              </Button>
            </Col>
          )}
          {currentStep === steps.length - 1 && (
            <Col>
              <Button
                type="primary"
                onClick={() => {
                  saveItem();
                }}
              >
                {lang.get("inventory", ["Save Item"])}
              </Button>
            </Col>
          )}
        </Row>
      </div>
    </Card>
  );
};

export default ItemAddBody;
