import React, { useEffect, useState } from "react";
import {
  ProForm,
  ProFormDependency,
  ProCard,
} from "@ant-design/pro-components";
import {
  Button,
  Row,
  Col,
  Divider,
  Typography,
  Space,
  Input,
  Select,
  Form,
  DatePicker,
  message,
} from "antd";
import ReceivingVoucherAddTable from "./ReceivingVoucherAddTable";
import select from "antd/es/select";
import { Editor } from "@tinymce/tinymce-react";
import lang from "../../../lang";

import coreFunctions from "../../../common/utils/coreFunctions";
import apiRequest from "../../../common/utils/apiRequest";
import {
  ReceivingVoucher,
  SelectType,
  InventoryReceivingVoucherRowItem,
  TaxOption,
  CurrencyType,
} from "../types/types";

import { useNavigate } from "react-router-dom";

const { Title } = Typography;
const Option = select;

const ReceivingVoucherAddBody = () => {
  const [suppliers, setSuppliers] = useState<SelectType[]>([]);
  const [projects, setProjects] = useState<SelectType[]>([]);
  const [departments, setDepartments] = useState<SelectType[]>([]);
  const [warehouses, setWarehouses] = useState<SelectType[]>([]);
  const [accounts, setAccounts] = useState<SelectType[]>([]);
  const [allTaxes, setAllTaxes] = useState<TaxOption[]>([]);
  const [currencies, setCurrencies] = useState<CurrencyType[]>([]);
  const [receivingVoucherTypes, setReceivingVoucherTypes] = useState<
    SelectType[]
  >([]);
  const [defaultCurrecyCode, setDefaultCurrecyCode] = useState("");

  const [summaryData, setSummaryData] = useState({
    sub_total: 0,
    quantity: 0,
    free_quantity: 0,
    total_discount: 0,
    total_tax: 0,
    total_rows_tax: 0,
    total_rows_sub_total: 0,
    total_rows_discount: 0,
    total: 0,
  });

  const initalData = {
    id: "",
    receving_number: "",
    supplier: "",
    warehouse: "",
    date: "",
    accounting_date: "",
    voucher_date: "",
    account: "",
    items: [],
    discount: 0,
    discount_type: 0,
    shipping_cost: 0,
  };

  const [postData, setPostData] = useState<ReceivingVoucher>(initalData);

  const CORE_FUNCTIONS = new coreFunctions();
  const navigate = useNavigate();

  const calculateTotal = (rows: InventoryReceivingVoucherRowItem[]) => {
    let sub_total = 0;
    let quantity = 0;
    let free_quantity = 0;
    let total_discount = 0;
    let total_rows_discount = 0;
    let total_rows_sub_total = 0;
    let total_rows_tax = 0;
    let total_tax = 0;

    rows.forEach((item) => {
      let total_per_row: number =
        (item.purchase_price ? item.purchase_price : 0) * item.quantity;

      quantity = quantity + item.quantity;
      free_quantity =
        free_quantity + (item.free_quantity ? item.free_quantity : 0);

      const orderedTaxes = item.taxes.sort((a, b) => a.order - b.order);

      orderedTaxes.forEach((tax) => {
        let taxRate = tax.rate ? tax.rate : 0;
        let tax_per_row = (total_per_row * taxRate) / 100;
        total_per_row = total_per_row + tax_per_row;

        total_rows_tax = total_rows_tax + tax_per_row;
      });

      if (
        item.discount &&
        item.discount.type !== undefined &&
        item.discount.value
      ) {
        let discount_per_row = 0;
        if (item.discount.type == 1) {
          discount_per_row = item.discount.value;
        } else if (item.discount.type == 0) {
          discount_per_row = (total_per_row * item.discount.value) / 100;
        }

        total_per_row = total_per_row - discount_per_row;

        total_rows_discount = total_rows_discount + discount_per_row;
      }

      item.sub_total = total_per_row.toFixed(2);

      total_rows_sub_total = total_rows_sub_total + total_per_row;
    });

    total_discount = total_rows_discount;

    sub_total =
      total_rows_sub_total +
      CORE_FUNCTIONS.castStringToNumber(postData.shipping_cost);

    let total_bill_discount = 0;
    if (postData.discount_type == 1) {
      total_bill_discount = CORE_FUNCTIONS.castStringToNumber(
        postData.discount
      );
    } else {
      total_bill_discount =
        (CORE_FUNCTIONS.castStringToNumber(postData.discount) * sub_total) /
        100;
    }

    total_discount = total_discount + total_bill_discount;
    sub_total = sub_total - total_bill_discount;

    const taxes = (postData.taxes ? postData.taxes : []).sort(
      (a, b) => a.order - b.order
    );

    taxes.forEach((tax) => {
      let taxRate = tax.rate ? tax.rate : 0;
      let tax_per_row = (sub_total * taxRate) / 100;
      sub_total = sub_total + tax_per_row;

      total_tax = total_tax + tax_per_row;
    });

    return {
      sub_total: sub_total,
      quantity: quantity,
      free_quantity: free_quantity,
      total_discount: total_discount,
      total_rows_discount: total_rows_discount,
      total_rows_sub_total: total_rows_sub_total,
      total_rows_tax: total_rows_tax,
      total_tax: total_tax,
      total: sub_total + CORE_FUNCTIONS.castStringToNumber(postData.adjustment),
    };
  };

  const handleChange = (value: any, field: string) => {
    setPostData({
      ...postData,
      [field]: value,
    });
  };

  const setItems = (items: InventoryReceivingVoucherRowItem[]) => {
    setPostData({
      ...postData,
      items: items,
    });
  };

  const save = () => {
    message
      .loading(lang.get("inventory", ["Adding receiving voucher"]), 1)
      .then(() => {
        const API_REQUEST = new apiRequest(
          "inventory/receiving_voucher/add",
          true
        );

        API_REQUEST.send(postData).then(
          (response: { status: string; message: string }) => {
            if (response && response.status === "success") {
              message.success(
                lang.get("inventory", ["Added successfully."]),
                2
              );
              // navigate("/inventory-receiving-voucher");
            } else {
              message.error(lang.get("inventory", [response.message]), 2);
            }
          }
        );
      });
  };

  useEffect(() => {
    const API_REQUEST = new apiRequest(
      "inventory/receiving_voucher/next_number",
      true
    );

    API_REQUEST.send(
      {},
      (response: { next_number: string; status: string }) => {
        if (response.status === "success") {
          postData.receving_number = response.next_number;
          setPostData({
            ...postData,
          });
        }
      }
    );

    const TAXES = new apiRequest("taxes/get", true);
    TAXES.send({}, (response: { data: TaxOption[]; status: string }) => {
      if (response.status === "success") {
        setAllTaxes(response.data);
      }
    });

    const CURRENCY_REQUEST = new apiRequest("currencies/get", true);
    CURRENCY_REQUEST.send(
      {},
      (data: { status: string; message: string; data: CurrencyType[] }) => {
        setCurrencies(data.data);

        data.data.forEach((element) => {
          if (element.default) {
            setPostData({
              ...postData,
              currency: element.id,
            });

            setDefaultCurrecyCode(element.code);
          }
        });
      }
    );
  }, []);

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      <Title level={2}>
        {lang.get("inventory", ["Inventory receiving voucher"])}
      </Title>
      <ProCard style={{ paddingTop: "10px" }}>
        <ProForm layout="vertical" submitter={false}>
          <Row gutter={[16, 1]}>
            {/* Customer */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("inventory", ["Inventory Receiving Number"])}
                  </span>
                }
              >
                <Input
                  placeholder="00000X"
                  value={postData.receving_number}
                  size="large"
                  readOnly
                />
              </Form.Item>
            </Col>

            {/* Tags */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("inventory", ["Purchase Order"])}
                  </span>
                }
              >
                <Select
                  placeholder={lang.get("inventory", ["Select Order"])}
                  onChange={(value) => handleChange(value, "purchase_order")}
                  size="large"
                ></Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Supplier"])}
                  </span>
                }
              >
                <Select
                  onChange={(value) => handleChange(value, "supplier")}
                  size="large"
                  allowClear
                  placeholder={lang.get("inventory", ["Select Suppliers"])}
                  showSearch
                  onInputKeyDown={(e) =>
                    CORE_FUNCTIONS.searchOptions(
                      e.currentTarget.value,
                      "purchase",
                      "vendors",
                      setSuppliers
                    )
                  }
                >
                  {suppliers.map((type) => (
                    <Option key={type.id} value={type.id}>
                      {type.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* Date */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Date"])}
                  </span>
                }
              >
                <DatePicker
                  onChange={(e) =>
                    handleChange(
                      CORE_FUNCTIONS.formatISODateTime(e.toISOString()),
                      "date"
                    )
                  }
                  style={{ width: "100%" }}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Accounting Date */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Accounting Date"])}
                  </span>
                }
              >
                <DatePicker
                  onChange={(e) =>
                    handleChange(
                      CORE_FUNCTIONS.formatISODateTime(e.toISOString()),
                      "accounting_date"
                    )
                  }
                  style={{ width: "100%" }}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Voucher Date */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Voucher Date"])}
                  </span>
                }
              >
                <DatePicker
                  onChange={(e) =>
                    handleChange(
                      CORE_FUNCTIONS.formatISODateTime(e.toISOString()),
                      "voucher_date"
                    )
                  }
                  style={{ width: "100%" }}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Address */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Address"])}
                  </span>
                }
              >
                <Input
                  placeholder={lang.get("receivingVoucher", ["Enter Address"])}
                  onChange={(e) => handleChange(e.target.value, "address")}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Delivery Location */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Delivery Location"])}
                  </span>
                }
              >
                <Input
                  placeholder={lang.get("receivingVoucher", [
                    "Enter Delivery Location",
                  ])}
                  onChange={(e) =>
                    handleChange(e.target.value, "delivery_location")
                  }
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Ship No */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Ship To"])}
                  </span>
                }
              >
                <Input
                  onChange={(e) => handleChange(e.target.value, "ship_to")}
                  placeholder={lang.get("receivingVoucher", ["Enter Ship No"])}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Order By */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Order By"])}
                  </span>
                }
              >
                <Input
                  onChange={(e) => handleChange(e.target.value, "order_by")}
                  placeholder={lang.get("receivingVoucher", ["Enter Order By"])}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Request By */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Request By"])}
                  </span>
                }
              >
                <Input
                  onChange={(e) => handleChange(e.target.value, "request_by")}
                  placeholder={lang.get("receivingVoucher", [
                    "Enter Request By",
                  ])}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Checked By */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Checked By"])}
                  </span>
                }
              >
                <Input
                  onChange={(e) => handleChange(e.target.value, "checked_by")}
                  placeholder={lang.get("receivingVoucher", [
                    "Enter Checked By",
                  ])}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Rep Name */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Rep Name"])}
                  </span>
                }
              >
                <Input
                  onChange={(e) => handleChange(e.target.value, "rep_name")}
                  placeholder={lang.get("receivingVoucher", ["Enter Rep Name"])}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Shipping Service */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Shipping Service"])}
                  </span>
                }
              >
                <Input
                  onChange={(e) =>
                    handleChange(e.target.value, "shipping_service")
                  }
                  placeholder={lang.get("receivingVoucher", [
                    "Enter Shipping Service",
                  ])}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Reference Number */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Reference Number"])}
                  </span>
                }
              >
                <Input
                  onChange={(e) =>
                    handleChange(e.target.value, "reference_number")
                  }
                  placeholder={lang.get("receivingVoucher", [
                    "Enter Reference Number",
                  ])}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Dispatched Number */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Dispatched Number"])}
                  </span>
                }
              >
                <Input
                  onChange={(e) =>
                    handleChange(e.target.value, "dispatched_number")
                  }
                  placeholder={lang.get("receivingVoucher", [
                    "Enter Dispatched Number",
                  ])}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Project */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Project"])}
                  </span>
                }
              >
                <Select
                  placeholder={lang.get("receivingVoucher", ["Select Project"])}
                  size="large"
                  showSearch
                  onChange={(value) => handleChange(value, "project")}
                  onInputKeyDown={(e) =>
                    CORE_FUNCTIONS.searchOptions(
                      e.currentTarget.value,
                      "projects",
                      "projects",
                      setProjects
                    )
                  }
                >
                  {projects.map((project) => (
                    <Select.Option key={project.id} value={project.id}>
                      {project.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* Department */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Department"])}
                  </span>
                }
              >
                <Select
                  placeholder={lang.get("receivingVoucher", [
                    "Select Department",
                  ])}
                  size="large"
                  onChange={(value) => handleChange(value, "department")}
                  showSearch
                  onInputKeyDown={(e) =>
                    CORE_FUNCTIONS.searchOptions(
                      e.currentTarget.value,
                      "global",
                      "departments",
                      setDepartments
                    )
                  }
                >
                  {departments.map((department) => (
                    <Select.Option key={department.id} value={department.id}>
                      {department.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Warehouse"])}
                  </span>
                }
              >
                <Select
                  onChange={(value) => handleChange(value, "warehouse")}
                  placeholder={lang.get("receivingVoucher", [
                    "Select Warehouse",
                  ])}
                  size="large"
                  showSearch
                  onInputKeyDown={(e) =>
                    CORE_FUNCTIONS.searchOptions(
                      e.currentTarget.value,
                      "inventory",
                      "warehouses",
                      setWarehouses
                    )
                  }
                >
                  {warehouses.map((warehouse) => (
                    <Select.Option key={warehouse.id} value={warehouse.id}>
                      {warehouse.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* Type */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Type"])}
                  </span>
                }
              >
                <Select
                  placeholder={lang.get("receivingVoucher", ["Select Type"])}
                  size="large"
                  onChange={(value) => handleChange(value, "type")}
                  showSearch
                  onInputKeyDown={(e) =>
                    CORE_FUNCTIONS.searchOptions(
                      e.currentTarget.value,
                      "inventory",
                      "receiving_voucher_types",
                      setReceivingVoucherTypes
                    )
                  }
                >
                  {receivingVoucherTypes.map((type) => (
                    <Select.Option key={type.id} value={type.id}>
                      {type.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Divider />
          <ReceivingVoucherAddTable
            setItemRows={setItems}
            calculateTotal={calculateTotal}
            itemTaxes={allTaxes}
            summary={summaryData}
            setSummary={setSummaryData}
            postData={postData}
            defaultCurrecyCode={defaultCurrecyCode}
          />
          <Divider />

          {/* Summary Section */}
          <Row justify="end" gutter={16} style={{ borderRadius: "8px" }}>
            <ProFormDependency name={["total", "adjustment"]}>
              {() => (
                <Space
                  direction="vertical"
                  style={{
                    padding: "16px",
                  }}
                >
                  {/* Shipping Row */}
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px", padding: "1px" }}
                  >
                    {/* Shipping Input */}
                    <Col xs={24} sm={12} md={12} lg={12}>
                      <div
                        style={{
                          fontWeight: 400,
                          fontSize: "12px",
                          marginRight: "8px",
                        }}
                      >
                        {lang.get("inventory", ["Shipping"])}
                      </div>
                      <Input
                        type="number"
                        placeholder={lang.get("inventory", ["Shipping"])}
                        onChange={(e) => {
                          handleChange(e.target.value, "shipping_cost");
                        }}
                        size="large"
                      />
                    </Col>

                    {/* Discount Input */}
                    <Col xs={24} sm={12} md={12} lg={12}>
                      <div
                        style={{
                          fontWeight: 400,
                          fontSize: "12px",
                          marginRight: "8px",
                        }}
                      >
                        {lang.get("inventory", ["Discount"])}
                      </div>
                      <Input
                        type="number"
                        placeholder={lang.get("inventory", ["Discount"])}
                        onChange={(e) =>
                          handleChange(e.target.value, "discount")
                        }
                        size="large"
                        addonAfter={
                          <Select
                            size="large"
                            onChange={(value) =>
                              handleChange(value, "discount_type")
                            }
                          >
                            <Option value="0">
                              {lang.get("inventory", ["%"])}
                            </Option>
                            <Option value="1">
                              {lang.get("inventory", ["Fixed"])}
                            </Option>
                          </Select>
                        }
                      />
                    </Col>
                  </Row>

                  <Divider style={{ margin: "8px 0" }} />

                  {/*Taxes Input */}

                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    <Col>{lang.get("inventory", ["Taxes"])}</Col>
                    <Col>
                      <Select
                        mode="multiple"
                        size="large"
                        onChange={(values) => {
                          let taxes: TaxOption[] = [];
                          for (let i = 0; i < values.length; i++) {
                            for (let j = 0; j < allTaxes.length; j++) {
                              if (values[i] == allTaxes[j].id) {
                                taxes.push(allTaxes[j]);
                              }
                            }
                          }

                          handleChange(taxes, "taxes");
                        }}
                        style={{ width: "150px" }}
                        placeholder={lang.get("inventory", ["Select Taxes"])}
                        showSearch
                      >
                        {allTaxes.map((tax) => (
                          <Select.Option key={tax.id} value={tax.id}>
                            {tax.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                  </Row>

                  {/* Subtotal Row */}
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    <Col>{lang.get("inventory", ["Subtotal"])}</Col>
                    <Col>
                      {defaultCurrecyCode +
                        " " +
                        summaryData.sub_total.toFixed(2)}
                    </Col>
                  </Row>

                  {/*Adjustment Input */}

                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    <Col>{lang.get("inventory", ["Adjustment"])}</Col>
                    <Col>
                      <Input
                        type="number"
                        placeholder={lang.get("inventory", ["Adjustment"])}
                        onChange={(e) =>
                          handleChange(e.target.value, "adjustment")
                        }
                        size="large"
                      />
                    </Col>
                  </Row>
                  {/* Discount Total Row */}
                  <Row>
                    <Col xs={24} sm={12} md={12} lg={12}>
                      {lang.get("inventory", ["Total Discount"])}
                    </Col>
                    <Col
                      xs={24}
                      sm={12}
                      md={12}
                      lg={12}
                      style={{ textAlign: "right" }}
                    >
                      -
                      {defaultCurrecyCode +
                        " " +
                        summaryData.total_discount.toFixed(2)}
                    </Col>
                  </Row>

                  <Divider style={{ margin: "8px 0" }} />

                  {/* Total Row */}
                  <Row justify="space-between" align="middle">
                    <Col style={{ fontWeight: 500, fontSize: "12px" }}>
                      {lang.get("inventory", ["Total"])}
                    </Col>
                    <Col style={{ fontWeight: 500, fontSize: "12px" }}>
                      {" "}
                      {defaultCurrecyCode + " " + summaryData.total.toFixed(2)}
                    </Col>
                  </Row>
                </Space>
              )}
            </ProFormDependency>
          </Row>

          <Divider />

          {/* Notes Section */}
          <Row gutter={[16, 0]}>
            {/* Currency Column */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("inventory", ["Currency"])}
                  </span>
                }
              >
                <Select
                  size="large"
                  value={postData.currency}
                  placeholder={lang.get("inventory", ["Select Currency"])}
                  onChange={(value) => {
                    handleChange(value, "currency");
                    for (let i = 0; i < currencies.length; i++) {
                      if (currencies[i].id == value) {
                        setDefaultCurrecyCode(currencies[i].code);
                      }
                    }
                  }}
                  style={{ width: "100%" }}
                  showSearch
                >
                  {currencies.map((currency) => (
                    <Option key={currency.id} value={currency.id}>
                      {currency.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* Ex. Rate Column */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("inventory", ["Ex. Rate"])}
                  </span>
                }
                name="ExRate"
              >
                <Input
                  size="large"
                  type="number"
                  onChange={(e) =>
                    handleChange(e.target.value, "exchange_rate")
                  }
                  placeholder={lang.get("inventory", ["Enter Exchange Rate"])}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            {/* Account Column */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("inventory", ["Account"])}
                  </span>
                }
                name="Account"
              >
                <Select
                  size="large"
                  placeholder={lang.get("inventory", ["Select Account"])}
                  onChange={(value) => handleChange(value, "account")}
                  style={{ width: "100%" }}
                  showSearch
                  onInputKeyDown={(e) =>
                    CORE_FUNCTIONS.searchOptions(
                      e.currentTarget.value,
                      "accounting",
                      "accounts",
                      setAccounts
                    )
                  }
                >
                  {accounts.map((account) => (
                    <Option key={account.id} value={account.id}>
                      {account.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {/* Notes Section */}
          <Row gutter={[16, 5]}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("inventory", ["Client Note"])}
                  </span>
                }
                name="Note"
              >
                <Editor
                  apiKey="your-tinymce-api-key"
                  onChange={(e) => handleChange(e.target.value, "note")}
                  init={{
                    height: 200,
                    menubar: false,
                    plugins: ["lists link code"],
                    toolbar:
                      "undo redo | formatselect | bold italic underline | bullist numlist | link code",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider />
          <Row justify="end" gutter={16}>
            <Col>
              {/* Close Button */}
              <Button
                onClick={() => {
                  navigate("inventory-receiving-voucher");
                }}
                style={{
                  backgroundColor: "white",
                  borderColor: "#d9d9d9",
                  color: "#000",
                  marginRight: 8,
                }}
              >
                {lang.get("inventory", ["Close"])}
              </Button>

              {/* Save & Send Request Button */}
              <Button
                type="primary"
                onClick={() => save()}
                style={{
                  backgroundColor: "#1890ff",
                  borderColor: "#1890ff",
                  color: "#fff",
                }}
              >
                {lang.get("inventory", ["Save & Send Request"])}
              </Button>
            </Col>
          </Row>
        </ProForm>
      </ProCard>
    </div>
  );
};

export default ReceivingVoucherAddBody;
