import { Button, Space, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { DBCustomer } from "../../../types/types";
import ProjectStatistic from "./ProjectStatistics";
import ProjectTable from "../../../../project/project-list/components/ProjectTable";
import lang from "../../../../../lang";
const Projects = (props: {
  client: DBCustomer;
  preload: boolean;
  setRefresh: (e: boolean) => void;
  refresh: boolean;
}) => {
  return (
    <div style={{ padding: "16px", borderRadius: "8px" }}>
      <Row
        gutter={[16, 16]}
        style={{ marginBottom: "16px", marginTop: "16px" }}
        align="middle"
        justify="space-between"
      >
        {/* Left Section: Buttons */}
        <Col xs={24} sm={24} md={12} lg={12}>
          <Space size="middle" wrap>
            <Button type="primary" icon={<PlusOutlined />}>
              {lang.get("project", ["New Project"])}
            </Button>
          </Space>
        </Col>
      </Row>

      <div style={{ marginBottom: "16px" }}>
        <ProjectStatistic client={props.client} />
      </div>

      <ProjectTable client={props.client} />
    </div>
  );
};

export default Projects;
