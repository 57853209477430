import React, { useState, useEffect, useRef } from "react";
import {
  ProForm,
  ProFormText,
  ProFormSelect,
  ProFormDependency,
  ProCard,
} from "@ant-design/pro-components";
import {
  Button,
  Modal,
  Row,
  Col,
  Divider,
  Typography,
  Space,
  Input,
  Select,
  Form,
  DatePicker,
  Skeleton,
  Tabs,
  Card,
  Alert,
  message,
  InputRef,
} from "antd";
import { EditOutlined, SendOutlined } from "@ant-design/icons";

import lang from "../../../../../lang";
import TinyMCEEditor from "../../../../../common/base/Editor";
import apiRequest from "../../../../../common/utils/apiRequest";
import coreFunctions from "../../../../../common/utils/coreFunctions";

import MainItemsTable from "./MainItemsTable";
import OptionalItemsTable from "./OptionalItemsTable";

import {
  TaxOption,
  Invoice,
  SelectType,
  CurrencyType,
  InvoiceType,
  InvoiceRowItemSection,
  ClientNote,
  TermAndCondition,
  Address,
} from "../../../types/types";
import { useNavigate } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";

const { Title } = Typography;
const Option = Select;

const initalData = {
  id: "",
  invoice_number: "",
  warehouse: "",
  invoice_date: "",
  customer: "",
  due_date: "",
  invoice_type: "",
  account: "",
  reference: "",
  payment_modes: [],
  recurring_invoice: "",
  items: [
    {
      id: "M0",
      name: "Section 1",
      items: [],
    },
  ],
  optional_items: [
    {
      id: "O0",
      name: "Section 1",
      items: [],
    },
  ],
  discount: 0,
  discount_type: 0,
  shipping_cost: 0,
};

const AddInvoice = () => {
  const [postDataItems, setPostDataItems] = useState<Invoice>(initalData);
  const [postData, setPostData] = useState<Invoice>(initalData);

  const [invoiceTypes, setInvoiceTypes] = useState<InvoiceType[]>([]);
  const [invoicePrefix, setInvoicePrefix] = useState("INV-");
  const [invoiceNumber, setInvoiceNumber] = useState("");

  const [clients, setClients] = useState<SelectType[]>([]);
  const [paymentModes, setPaymentModes] = useState<SelectType[]>([]);
  const [accountList, setAccountList] = useState<SelectType[]>([]);
  const [warehouseList, setWarehouseList] = useState<SelectType[]>([]);
  const [salesRepList, setSalesRepList] = useState<SelectType[]>([]);
  const [assingersList, setAssingersList] = useState<SelectType[]>([]);
  const [orderedByList, setOrderedByList] = useState<SelectType[]>([]);
  const [checkedByList, setCheckedByList] = useState<ClientNote[]>([]);
  const [shippingCarrierList, setShippingCarrierList] = useState<SelectType[]>(
    []
  );
  const [countryList, setCountryList] = useState<SelectType[]>([]);
  const [noteList, setNoteList] = useState<SelectType[]>([]);
  const [termAndConditionList, setTermAndConditionList] = useState<
    TermAndCondition[]
  >([]);

  const [isBillToModalVisible, setBillToModalVisible] = useState(false);
  const [isShipToModalVisible, setShipToModalVisible] = useState(false);

  const [allTaxes, setAllTaxes] = useState<TaxOption[]>([]);
  const [defaultCurrecyCode, setDefaultCurrecyCode] = useState("");
  const [currencies, setCurrencies] = useState<CurrencyType[]>([]);

  const [activeKey, setActiveKey] = useState("M0");
  const [optionalItemsActiveKey, setOptionalItemsActiveKey] = useState("O0");
  const [projectList, setProjectList] = useState<SelectType[]>([]);

  const [billingAddressText, setBillingAddressText] = useState("");
  const [shippingAddressText, setShippingAddressText] = useState("");

  const newTabIndex = useRef(1);
  const newOptionalTabIndex = useRef(1);

  const [loading, setLoading] = useState(true);

  const CORE_FUNCTIONS = new coreFunctions();

  const navigate = useNavigate();

  const warehouseRef = useRef("");

  const getWarehouse = () => {
    return warehouseRef.current;
  };

  useEffect(() => {
    const loadData = async () => {
      const TAXES = new apiRequest("taxes/get", true);
      await TAXES.send(
        {},
        (response: { data: TaxOption[]; status: string }) => {
          if (response.status == "success") {
            setAllTaxes(response.data);
          }
        }
      );

      const COUNTRIES = new apiRequest("countries/get", true);
      await COUNTRIES.send(
        {},
        (response: { data: SelectType[]; status: string }) => {
          if (response.status == "success") {
            setCountryList(response.data);
          }
        }
      );

      const CURRENCY_REQUEST = new apiRequest("currencies/get", true);
      await CURRENCY_REQUEST.send(
        {},
        (data: { status: string; message: string; data: CurrencyType[] }) => {
          setCurrencies(data.data);

          data.data.forEach((element) => {
            if (element.default) {
              setPostData({
                ...postData,
                currency: element.id,
              });

              setDefaultCurrecyCode(element.code);
            }
          });
        }
      );

      const INVOICE_TYPE_REQUEST = new apiRequest(
        "sales/invoices/types/get",
        true
      );
      await INVOICE_TYPE_REQUEST.send(
        {},
        (data: { status: string; message: string; data: InvoiceType[] }) => {
          setInvoiceTypes(data.data);
        }
      );

      setLoading(false);
    };

    loadData();
  }, []);

  const [summaryData, setSummaryData] = useState({
    sub_total: 0,
    quantity: 0,
    free_quantity: 0,
    total_discount: 0,
    total_tax: 0,
    total_rows_tax: 0,
    total_rows_sub_total: 0,
    total_rows_discount: 0,
    total: 0,
  });

  const calculateTotal = () => {
    const sections: InvoiceRowItemSection[] = postDataItems.items;

    let sub_total = 0;
    let quantity = 0;
    let free_quantity = 0;
    let total_discount = 0;
    let total_rows_discount = 0;
    let total_rows_sub_total = 0;
    let total_rows_tax = 0;
    let total_tax = 0;

    sections.forEach((section) => {
      section.items.forEach((item) => {
        let total_per_row: number =
          (item.sale_price ? item.sale_price : 0) * item.quantity;

        quantity = quantity + item.quantity;
        free_quantity =
          free_quantity + (item.free_quantity ? item.free_quantity : 0);

        const orderedTaxes = item.taxes.sort((a, b) => a.order - b.order);

        orderedTaxes.forEach((tax) => {
          let taxRate = tax.rate ? tax.rate : 0;
          let tax_per_row = (total_per_row * taxRate) / 100;
          total_per_row = total_per_row + tax_per_row;

          total_rows_tax = total_rows_tax + tax_per_row;
        });

        if (
          item.discount &&
          item.discount.type !== undefined &&
          item.discount.value
        ) {
          let discount_per_row = 0;
          if (item.discount.type == 1) {
            discount_per_row = item.discount.value;
          } else if (item.discount.type == 0) {
            discount_per_row = (total_per_row * item.discount.value) / 100;
          }

          total_per_row = total_per_row - discount_per_row;

          total_rows_discount = total_rows_discount + discount_per_row;
        }

        item.sub_total = total_per_row.toFixed(2);

        total_rows_sub_total = total_rows_sub_total + total_per_row;
      });

      total_discount = total_rows_discount;

      sub_total =
        total_rows_sub_total +
        CORE_FUNCTIONS.castStringToNumber(postData.shipping_cost);

      let total_bill_discount = 0;
      if (postData.discount_type == 1) {
        total_bill_discount = CORE_FUNCTIONS.castStringToNumber(
          postData.discount
        );
      } else {
        total_bill_discount =
          (CORE_FUNCTIONS.castStringToNumber(postData.discount) * sub_total) /
          100;
      }

      total_discount = total_discount + total_bill_discount;
      sub_total = sub_total - total_bill_discount;

      const taxes = (postData.taxes ? postData.taxes : []).sort(
        (a, b) => a.order - b.order
      );

      taxes.forEach((tax) => {
        let taxRate = tax.rate ? tax.rate : 0;
        let tax_per_row = (sub_total * taxRate) / 100;
        sub_total = sub_total + tax_per_row;

        total_tax = total_tax + tax_per_row;
      });
    });

    setSummaryData({
      sub_total: sub_total,
      quantity: quantity,
      free_quantity: free_quantity,
      total_discount: total_discount,
      total_rows_discount: total_rows_discount,
      total_rows_sub_total: total_rows_sub_total,
      total_rows_tax: total_rows_tax,
      total_tax: total_tax,
      total: sub_total + CORE_FUNCTIONS.castStringToNumber(postData.adjustment),
    });
  };

  const initialItems = [
    {
      label: (
        <Input
          placeholder={lang.get("sales", ["Section Name"])}
          variant="borderless"
          onClick={() => setActiveKey("M0")}
          onChange={(e) => {
            var currentItems: InvoiceRowItemSection[] = postDataItems.items;

            for (let i = 0; i < currentItems.length; i++) {
              if (currentItems[i].id === "M0") {
                currentItems[i].name = e.target.value;
                break;
              }
            }

            setPostDataItems({ ...postDataItems, items: currentItems });
          }}
        />
      ),
      children: (
        <MainItemsTable
          key={activeKey}
          activeKey={activeKey}
          setPostData={setPostDataItems}
          postData={postDataItems}
          getWarehouse={getWarehouse}
        />
      ),
      key: "M0",
      closable: false,
    },
  ];

  const initialOptionalItems = [
    {
      label: (
        <Input
          placeholder={lang.get("sales", ["Section Name"])}
          variant="borderless"
          onClick={() => setOptionalItemsActiveKey("O0")}
          onChange={(e) => {
            var currentItems: InvoiceRowItemSection[] =
              postDataItems.optional_items;

            for (let i = 0; i < currentItems.length; i++) {
              if (currentItems[i].id === "O0") {
                currentItems[i].name = e.target.value;
                break;
              }
            }

            setPostDataItems({
              ...postDataItems,
              optional_items: currentItems,
            });
          }}
        />
      ),
      children: (
        <OptionalItemsTable
          key={optionalItemsActiveKey}
          activeKey={optionalItemsActiveKey}
          setPostData={setPostDataItems}
          postData={postDataItems}
          getWarehouse={getWarehouse}
        />
      ),
      key: "O0",
      closable: false,
    },
  ];

  const [items, setItems] = useState(initialItems);
  const [optionaltems, setOptionalItems] = useState(initialOptionalItems);

  const add = (type: string) => {
    if (type === "main") {
      const newActiveKey = `M${newTabIndex.current++}`;
      const newPanes = [...items];
      newPanes.push({
        label: (
          <Input
            placeholder={lang.get("sales", ["Section Name"])}
            variant="borderless"
            onClick={() => setActiveKey(newActiveKey)}
            onChange={(e) => {
              var currentItems: InvoiceRowItemSection[] = postDataItems.items;

              for (let i = 0; i < currentItems.length; i++) {
                if (currentItems[i].id === newActiveKey) {
                  currentItems[i].name = e.target.value;
                  break;
                }
              }

              setPostDataItems({ ...postDataItems, items: currentItems });
            }}
          />
        ),
        children: (
          <MainItemsTable
            key={newActiveKey}
            getWarehouse={getWarehouse}
            activeKey={newActiveKey}
            setPostData={setPostDataItems}
            postData={postDataItems}
          />
        ),
        key: newActiveKey,
        closable: true,
      });

      setItems(newPanes);
      setActiveKey(newActiveKey);

      const newSection = {
        id: newActiveKey,
        name: "Section " + newTabIndex.current,
        items: [],
      };

      postDataItems.items.push(newSection);
      setPostData({
        ...postDataItems,
      });
    } else {
      const newActiveKey = `O${newOptionalTabIndex.current++}`;
      const newPanes = [...optionaltems];
      newPanes.push({
        label: (
          <Input
            placeholder={lang.get("sales", ["Section Name"])}
            variant="borderless"
            onClick={() => setOptionalItemsActiveKey(newActiveKey)}
            onChange={(e) => {
              var currentItems: InvoiceRowItemSection[] =
                postDataItems.optional_items;

              for (let i = 0; i < currentItems.length; i++) {
                if (currentItems[i].id === newActiveKey) {
                  currentItems[i].name = e.target.value;
                  break;
                }
              }

              setPostDataItems({
                ...postDataItems,
                optional_items: currentItems,
              });
            }}
          />
        ),
        children: (
          <OptionalItemsTable
            getWarehouse={getWarehouse}
            key={newActiveKey}
            activeKey={newActiveKey}
            setPostData={setPostDataItems}
            postData={postDataItems}
          />
        ),
        key: newActiveKey,
        closable: true,
      });

      setOptionalItems(newPanes);
      setOptionalItemsActiveKey(newActiveKey);

      const newSection = {
        id: newActiveKey,
        name: "Section " + newOptionalTabIndex.current,
        items: [],
      };

      postDataItems.optional_items.push(newSection);
      setPostData({
        ...postDataItems,
      });
    }
  };

  const remove = (targetKey: string, type: string) => {
    if (type === "main") {
      let newActiveKey = activeKey;

      let lastIndex = -1;
      items.forEach((item, i) => {
        if (item.key === targetKey) {
          lastIndex = i - 1;
        }
      });
      const newPanes = items.filter((item) => item.key !== targetKey);
      if (newPanes.length && newActiveKey === targetKey) {
        if (lastIndex >= 0) {
          newActiveKey = newPanes[lastIndex].key;
        } else {
          newActiveKey = newPanes[0].key;
        }
      }
      setItems(newPanes);
      setActiveKey(newActiveKey);
    } else {
      let newActiveKey = optionalItemsActiveKey;
      let lastIndex = -1;
      optionaltems.forEach((item, i) => {
        if (item.key === targetKey) {
          lastIndex = i - 1;
        }
      });
      const newPanes = optionaltems.filter((item) => item.key !== targetKey);
      if (newPanes.length && newActiveKey === targetKey) {
        if (lastIndex >= 0) {
          newActiveKey = newPanes[lastIndex].key;
        } else {
          newActiveKey = newPanes[0].key;
        }
      }
      setOptionalItems(newPanes);
      setOptionalItemsActiveKey(newActiveKey);
    }
  };

  const onEdit = (
    targetKey: string,
    action: "add" | "remove" | undefined,
    type: string
  ) => {
    if (action === "add") {
      add(type);
    } else {
      remove(targetKey, type);
    }
  };

  const onChange = (key: string, value: any) => {
    setPostData({
      ...postData,
      [key]: value,
    });
  };

  const addInvoice = (save_type: string = "draft") => {
    const payloadData = {
      ...postData,
      items: postDataItems.items,
      optional_items: postDataItems.optional_items,
      save_type: save_type,
    };

    message.loading(lang.get("project", ["Saving"]), 1).then(() => {
      const API_REQUEST = new apiRequest("sales/invoice/add", true);

      API_REQUEST.send(
        payloadData,
        (response: { status: string; message: string }) => {
          if (response.status == "success") {
            message.success(response.message);

            navigate("/invoices");
          } else {
            message.error(response.message);
          }
        }
      );
    });
  };

  const generateAddress = (address: Address | undefined) => {
    let addressString = "";

    if (!address) {
      return addressString;
    }

    if (address.city) {
      addressString += address.city + ", ";
    }

    if (address.state) {
      addressString += address.state + ", ";
    }

    if (address.zip_code) {
      addressString += address.zip_code + ", ";
    }

    if (address.country) {
      addressString += address.country;
    }

    return addressString;
  };

  useEffect(() => {
    calculateTotal();
  }, [postDataItems, postData]);

  const rowStyles = {
    marginBottom: "-8px",
  }

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
      }}
    >
      <Title level={2}>{lang.get("sales", ["Create New Invoice"])}</Title>
      <ProCard>
        <ProForm layout="vertical" submitter={false}>
          <Row gutter={[16, 0]}>
            {/* Invoice Number */}
            <Col style={rowStyles} xs={24} sm={6} md={6}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Invoice Number"])}
                  </span>
                }
                name="invoice_number"
                rules={[
                  {
                    required: true,
                    message: lang.get("sales", ["Invoice Number is required!"]),
                  },
                ]}
              >
                <Input
                  type="text"
                  value={invoiceNumber || ""}
                  addonBefore={invoicePrefix}
                  onChange={(e) => {
                    onChange("invoice_number", e.currentTarget.value);
                  }}
                  placeholder={invoiceNumber}
                  size="large"
                  readOnly
                />
              </Form.Item>
            </Col>

            {/* Invoice Date */}
            <Col style={rowStyles} xs={24} sm={6} md={6}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Invoice Date"])}
                  </span>
                }
                name="invoice_date"
                rules={[
                  {
                    required: true,
                    message: lang.get("sales", ["Please select a date!"]),
                  },
                ]}
              >
                <DatePicker
                  onChange={(e) => {
                    onChange("invoice_date", e?.format("YYYY-MM-DD"));
                  }}
                  style={{ width: "100%" }}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Due Date */}
            <Col style={rowStyles} xs={24} sm={6} md={6}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Due Date"])}
                  </span>
                }
                name="dueDate"
                rules={[
                  {
                    required: true,
                    message: lang.get("sales", ["Please select a due date!"]),
                  },
                ]}
              >
                <DatePicker
                  onChange={(e) =>
                    onChange("due_date", e?.format("YYYY-MM-DD"))
                  }
                  style={{ width: "100%" }}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Invoice Type */}
            <Col style={rowStyles} xs={24} sm={6} md={6}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Invoice Type"])}
                  </span>
                }
                name="invoiceType"
                rules={[
                  {
                    required: true,
                    message: lang.get("sales", [
                      "Please select a invoice type!",
                    ]),
                  },
                ]}
              >
                <Select
                  onChange={(e) => {
                    onChange("invoice_type", e);
                    invoiceTypes.forEach((item) => {
                      if (item.id == e) {
                        setInvoicePrefix(item.prefix ? item.prefix : "");
                        setInvoiceNumber(
                          item.starting_number ? item.starting_number : "DRAFT"
                        );
                      }
                    });
                  }}
                  placeholder="Select Invoice Type"
                  size="large"
                  options={invoiceTypes.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                />
              </Form.Item>
            </Col>

            {/* Customer */}
            <Col style={rowStyles} xs={24} sm={6} md={6}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {" "}
                    {lang.get("sales", ["Customer"])}
                  </span>
                }
                name="customer"
                rules={[
                  {
                    required: true,
                    message: "Please select a customer!",
                  },
                ]}
              >
                <Select
                  showSearch
                  onChange={(e) => onChange("customer", e)}
                  placeholder="Select Customer"
                  size="large"
                  onSearch={(e) => {
                    CORE_FUNCTIONS.searchOptions(
                      e,
                      "clients",
                      "clients",
                      setClients
                    );
                  }}
                  onClick={() => {
                    CORE_FUNCTIONS.searchOptions(
                      "",
                      "clients",
                      "clients",
                      setClients
                    );
                  }}
                >
                  {clients.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* Project */}
            <Col style={rowStyles} xs={24} sm={6} md={6}>
              <Form.Item label="Project" name="project">
                <Select
                  showSearch
                  placeholder="Add Project"
                  onChange={(value) => onChange("project", value)}
                  size="large"
                  filterOption={false}
                  onSearch={(e) => {
                    CORE_FUNCTIONS.searchOptions(
                      e,
                      "projects",
                      "projects",
                      setProjectList,
                      { customer: postData.customer }
                    );
                  }}
                  onClick={() => {
                    CORE_FUNCTIONS.searchOptions(
                      "",
                      "projects",
                      "projects",
                      setProjectList,
                      { customer: postData.customer }
                    );
                  }}
                  options={projectList.map((project) => ({
                    label: project.name,
                    value: project.id,
                  }))}
                />
              </Form.Item>
            </Col>

            {/* Billing Address */}
            <Col style={rowStyles} xs={24} sm={6} md={6}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {" "}
                    {lang.get("sales", ["Billing Address"])}
                  </span>
                }
                name="billingAddress"
              >
                <Input
                  placeholder={
                    billingAddressText
                      ? billingAddressText
                      : "Enter Billing Address"
                  }
                  suffix={
                    <EditOutlined onClick={() => setBillToModalVisible(true)} />
                  }
                  size="large"
                  readOnly
                />
              </Form.Item>
            </Col>

            {/* Shipping Address */}
            <Col style={rowStyles} xs={24} sm={6} md={6}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {" "}
                    {lang.get("sales", ["Shipping Address"])}
                  </span>
                }
                name="shippingAddress"
              >
                <Input
                  placeholder={
                    shippingAddressText
                      ? shippingAddressText
                      : lang.get("sales", ["Enter Shipping Address"])
                  }
                  suffix={
                    <EditOutlined onClick={() => setShipToModalVisible(true)} />
                  }
                  size="large"
                  readOnly
                />
              </Form.Item>
            </Col>

            {/* VAT Number */}
            <Col style={rowStyles} xs={24} sm={6} md={6}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["VAT Number"])}
                  </span>
                }
                name="vatNo"
              >
                <Input
                  onChange={(e) => onChange("vat_no", e.target.value)}
                  placeholder={lang.get("sales", ["Enter VAT Number"])}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Currency */}
            <Col style={rowStyles} xs={24} sm={6} md={6}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Currency"])}
                  </span>
                }
                name="currency"
              >
                <Select
                  showSearch
                  onChange={(e) => {
                    onChange("currency", e);

                    for (let i = 0; i < currencies.length; i++) {
                      if (currencies[i].id == e) {
                        setDefaultCurrecyCode(currencies[i].code);
                      }
                    }
                  }}
                  placeholder="Select Currency"
                  size="large"
                >
                  {currencies.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* Exchange Rate */}
            <Col style={rowStyles} xs={24} sm={6} md={6}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Exchange Rate"])}
                  </span>
                }
                name="exchangeRate"
              >
                <Input
                  onChange={(e) => onChange("exchange_rate", e.target.value)}
                  placeholder={lang.get("sales", ["Exchange Rate"])}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Reference */}
            <Col style={rowStyles} xs={24} sm={6} md={6}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Reference"])}
                  </span>
                }
                name="reference"
              >
                <Input
                  onChange={(e) => onChange("reference", e.target.value)}
                  placeholder={lang.get("sales", ["Reference"])}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Allowed Payment Modes */}
            <Col style={rowStyles} xs={24} sm={6} md={6}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Allowed Payment Modes"])}
                  </span>
                }
                name="paymentModes"
              >
                <Select
                  showSearch
                  mode="multiple"
                  placeholder="Select Payment Mode"
                  size="large"
                  onChange={(e) => onChange("payment_modes", e)}
                  onClick={() => {
                    CORE_FUNCTIONS.searchOptions(
                      "",
                      "global",
                      "payment_modes",
                      setPaymentModes
                    );
                  }}
                >
                  {paymentModes.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* Account */}
            <Col style={rowStyles} xs={24} sm={6} md={6}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Account"])}
                  </span>
                }
                name="account"
                rules={[
                  {
                    required: true,
                    message: lang.get("sales", ["Please select an account"]),
                  },
                ]}
              >
                <Select
                  showSearch
                  onChange={(e) => onChange("account", e)}
                  placeholder="Select Account"
                  size="large"
                  onSearch={(e) => {
                    CORE_FUNCTIONS.searchOptions(
                      e,
                      "accounting",
                      "accounts",
                      setAccountList
                    );
                  }}
                  onClick={() => {
                    CORE_FUNCTIONS.searchOptions(
                      "",
                      "accounting",
                      "accounts",
                      setAccountList
                    );
                  }}
                >
                  {accountList.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* Warehouse */}
            <Col style={rowStyles} xs={24} sm={6} md={6}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Warehouse"])}
                  </span>
                }
                name="warehouse"
              >
                <Select
                  showSearch
                  onChange={(e) => {
                    onChange("warehouse", e);
                    warehouseRef.current = e;
                  }}
                  placeholder="Select Warehouse"
                  size="large"
                  onClick={() => {
                    CORE_FUNCTIONS.searchOptions(
                      "",
                      "inventory",
                      "warehouses",
                      setWarehouseList
                    );
                  }}
                >
                  {warehouseList.map((warehouse) => (
                    <Option key={warehouse.id} value={warehouse.id}>
                      {warehouse.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* Sales Rep */}
            <Col style={rowStyles} xs={24} sm={6} md={6}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Sales Rep"])}
                  </span>
                }
                name="salesRep"
              >
                <Select
                  showSearch
                  onChange={(e) => onChange("sales_rep", e)}
                  placeholder={lang.get("sales", ["Enter Sales Rep"])}
                  size="large"
                  onSearch={(e) => {
                    CORE_FUNCTIONS.searchOptions(
                      e,
                      "hr",
                      "records",
                      setSalesRepList
                    );
                  }}
                  onClick={() => {
                    CORE_FUNCTIONS.searchOptions(
                      "",
                      "hr",
                      "records",
                      setSalesRepList
                    );
                  }}
                >
                  {salesRepList.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* Assigned */}
            <Col style={rowStyles} xs={24} sm={6} md={6}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Assigned"])}
                  </span>
                }
                name="assigned"
              >
                <Select
                  showSearch
                  onChange={(e) => onChange("assigned", e)}
                  placeholder={lang.get("sales", ["Select Assigned Person"])}
                  size="large"
                  onSearch={(e) => {
                    CORE_FUNCTIONS.searchOptions(
                      e,
                      "hr",
                      "records",
                      setAssingersList
                    );
                  }}
                  onClick={() => {
                    CORE_FUNCTIONS.searchOptions(
                      "",
                      "hr",
                      "records",
                      setAssingersList
                    );
                  }}
                >
                  {assingersList.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* Order by */}
            <Col style={rowStyles} xs={24} sm={6} md={6}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Order By"])}
                  </span>
                }
                name="orderBy"
              >
                <Select
                  showSearch
                  onChange={(e) => onChange("order_by", e)}
                  placeholder="Select Order By"
                  size="large"
                  onSearch={(e) => {
                    CORE_FUNCTIONS.searchOptions(
                      e,
                      "hr",
                      "records",
                      setOrderedByList
                    );
                  }}
                  onClick={() => {
                    CORE_FUNCTIONS.searchOptions(
                      "",
                      "hr",
                      "records",
                      setOrderedByList
                    );
                  }}
                >
                  {orderedByList.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* Checked By */}
            <Col style={rowStyles} xs={24} sm={6} md={6}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Checked By"])}
                  </span>
                }
                name="checkedBy"
              >
                <Select
                  showSearch
                  onChange={(e) => onChange("checked_by", e)}
                  placeholder="Select Checked By"
                  size="large"
                  onSearch={(e) => {
                    CORE_FUNCTIONS.searchOptions(
                      e,
                      "hr",
                      "records",
                      setCheckedByList
                    );
                  }}
                  onClick={() => {
                    CORE_FUNCTIONS.searchOptions(
                      "",
                      "hr",
                      "records",
                      setCheckedByList
                    );
                  }}
                >
                  {checkedByList.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* Shipping Carrier */}
            <Col style={rowStyles} xs={24} sm={6} md={6}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Shipping Carrier"])}
                  </span>
                }
                name="shippingCarrier"
              >
                <Select
                  showSearch
                  onChange={(e) => onChange("shipping_carrier", e)}
                  placeholder={lang.get("sales", ["Select Shipping Carrier"])}
                  size="large"
                  onSearch={(e) => {
                    CORE_FUNCTIONS.searchOptions(
                      e,
                      "sales",
                      "shipping_carriers",
                      setShippingCarrierList
                    );
                  }}
                  onClick={() => {
                    CORE_FUNCTIONS.searchOptions(
                      "",
                      "sales",
                      "shipping_carriers",
                      setShippingCarrierList
                    );
                  }}
                >
                  {shippingCarrierList.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* Recurring Invoice */}
            <Col style={rowStyles} xs={24} sm={6} md={6}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Recurring Invoice?"])}
                  </span>
                }
                name="recurringInvoice"
                rules={[
                  {
                    required: true,
                    message: lang.get("sales", [
                      "Please Select Recurring Invoice",
                    ]),
                  },
                ]}
              >
                <Select
                  onChange={(e) => onChange("recurring_invoice", e)}
                  placeholder={lang.get("sales", ["Select Recurring Invoice"])}
                  size="large"
                >
                  <Option value="1">Yes</Option>
                  <Option value="0">No</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Admin Note */}
            <Col style={rowStyles} xs={24}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Admin Note"])}
                  </span>
                }
                name="adminNote"
              >
                <TextArea
                  rows={4}
                  onChange={(e) => onChange("admin_note", e.target.value)}
                  placeholder={lang.get("sales", ["Enter Admin Note"])}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider />
          {loading ? (
            <>
              <Skeleton active />
            </>
          ) : warehouseRef.current === "" ? (
            <>
              <Card size="default" className="w-100 text-center">
                <h6 className="animate__animated animate__fadeIn">
                  {lang.get("sales", ["Please Select Warehouse"])}
                </h6>
              </Card>
            </>
          ) : (
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24}>
                <Tabs
                  defaultActiveKey="1"
                  tabPosition="top"
                  items={[
                    {
                      label: lang.get("sales", ["Main Items"]),
                      key: "1",
                      children: (
                        <Tabs
                          type="editable-card"
                          onChange={(newActiveKey) => {
                            setActiveKey(newActiveKey);
                          }}
                          activeKey={activeKey}
                          onEdit={(e, action) => {
                            if (e && (action == "add" || action == "remove")) {
                              onEdit(e.toString(), action, "main");
                            }
                          }}
                          items={items}
                        />
                      ),
                    },
                    {
                      label: lang.get("sales", ["Optional Items"]),
                      key: "2",
                      children: (
                        <Tabs
                          type="editable-card"
                          onChange={(newActiveKey) => {
                            setOptionalItemsActiveKey(newActiveKey);
                          }}
                          activeKey={optionalItemsActiveKey}
                          onEdit={(e, action) => {
                            if (e && (action == "add" || action == "remove")) {
                              onEdit(e.toString(), action, "optional");
                            }
                          }}
                          items={optionaltems}
                        />
                      ),
                    },
                  ]}
                />
              </Col>
            </Row>
          )}
          <Divider />

          {/* Summary Section */}
          <Row
            justify="end"
            gutter={16}
            style={{ borderRadius: "8px", position: "relative" }}
          >
            <ProFormDependency name={["total", "adjustment"]}>
              {() => (
                <Space
                  direction="vertical"
                  style={{
                    padding: "16px",
                  }}
                >
                  {/* Shipping Row */}
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px", padding: "1px" }}
                  >
                    {/* Shipping Input */}
                    <Col xs={24} sm={12} md={12} lg={12}>
                      <div
                        style={{
                          fontWeight: 400,
                          fontSize: "12px",
                          marginRight: "8px",
                        }}
                      >
                        {lang.get("inventory", ["Shipping"])}
                      </div>
                      <Input
                        type="number"
                        placeholder={lang.get("inventory", ["Shipping"])}
                        onChange={(e) => {
                          const value = Number(e.target.value);

                          postData.shipping_cost = value;

                          setPostData({ ...postData });
                        }}
                        size="large"
                      />
                    </Col>

                    {/* Discount Input */}
                    <Col xs={24} sm={12} md={12} lg={12}>
                      <div
                        style={{
                          fontWeight: 400,
                          fontSize: "12px",
                          marginRight: "8px",
                        }}
                      >
                        {lang.get("inventory", ["Discount"])}
                      </div>
                      <Input
                        type="number"
                        placeholder={lang.get("inventory", ["Discount"])}
                        onChange={(e) => {
                          const value = Number(e.target.value);

                          setPostData((prevState) => ({
                            ...prevState,
                            discount: value,
                          }));
                        }}
                        size="large"
                        addonAfter={
                          <Select
                            size="large"
                            onChange={(value) => {
                              setPostData({
                                ...postData,
                                discount_type: value,
                              });
                            }}
                          >
                            <Option value="0">
                              {lang.get("inventory", ["%"])}
                            </Option>
                            <Option value="1">
                              {lang.get("inventory", ["Fixed"])}
                            </Option>
                          </Select>
                        }
                      />
                    </Col>
                  </Row>

                  <Divider style={{ margin: "8px 0" }} />

                  {/*Taxes Input */}

                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    <Col>{lang.get("inventory", ["Taxes"])}</Col>
                    <Col>
                      <Select
                        mode="multiple"
                        size="large"
                        onChange={(values) => {
                          let taxes: TaxOption[] = [];
                          for (let i = 0; i < values.length; i++) {
                            for (let j = 0; j < allTaxes.length; j++) {
                              if (values[i] == allTaxes[j].id) {
                                taxes.push(allTaxes[j]);
                              }
                            }
                          }

                          setPostData((prevState) => ({
                            ...prevState,
                            taxes,
                          }));
                        }}
                        style={{ width: "150px" }}
                        placeholder={lang.get("inventory", ["Select Taxes"])}
                        showSearch
                      >
                        {allTaxes.map((tax) => (
                          <Select.Option key={tax.id} value={tax.id}>
                            {tax.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                  </Row>

                  {/* Subtotal Row */}
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    <Col>{lang.get("inventory", ["Subtotal"])}</Col>
                    <Col>
                      {defaultCurrecyCode +
                        " " +
                        summaryData.sub_total.toFixed(2)}
                    </Col>
                  </Row>

                  {/*Adjustment Input */}

                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    <Col>{lang.get("inventory", ["Adjustment"])}</Col>
                    <Col>
                      <Input
                        type="number"
                        placeholder={lang.get("inventory", ["Adjustment"])}
                        onChange={(e) => {
                          const value = Number(e.target.value);
                          setPostData((prevState) => ({
                            ...prevState,
                            adjustment: value,
                          }));
                        }}
                        size="large"
                      />
                    </Col>
                  </Row>
                  {/* Discount Total Row */}
                  <Row>
                    <Col xs={24} sm={12} md={12} lg={12}>
                      {lang.get("inventory", ["Total Discount"])}
                    </Col>
                    <Col
                      xs={24}
                      sm={12}
                      md={12}
                      lg={12}
                      style={{ textAlign: "right" }}
                    >
                      -
                      {defaultCurrecyCode +
                        " " +
                        summaryData.total_discount.toFixed(2)}
                    </Col>
                  </Row>

                  <Divider style={{ margin: "8px 0" }} />

                  {/* Total Row */}
                  <Row justify="space-between" align="middle">
                    <Col style={{ fontWeight: 500, fontSize: "12px" }}>
                      {lang.get("inventory", ["Total"])}
                    </Col>
                    <Col style={{ fontWeight: 500, fontSize: "12px" }}>
                      {" "}
                      {defaultCurrecyCode + " " + summaryData.total.toFixed(2)}
                    </Col>
                  </Row>
                </Space>
              )}
            </ProFormDependency>
          </Row>

          <Divider />

          {/* Notes Section */}
          <Divider />
          <Row gutter={[16, 5]}>
            {/* Select Client Note */}
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Select Client Note"])}
                  </span>
                }
                name="selectClientNote"
              >
                <Select
                  onChange={(e) => {
                    onChange("client_note_type", e);
                  }}
                  placeholder={lang.get("sales", ["Select a Client Note"])}
                  size="large"
                  onClick={() => {
                    CORE_FUNCTIONS.searchOptions(
                      "",
                      "sales",
                      "client_notes",
                      setNoteList
                    );
                  }}
                >
                  {noteList.map((note) => (
                    <Select.Option key={note.id} value={note.id}>
                      {note.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Client Note"])}
                  </span>
                }
                name="Note"
              >
                <TinyMCEEditor
                  onEditorChange={() => (e: any) => {
                    onChange("client_note", e);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Select Terms & Conditions"])}
                  </span>
                }
                name="selectTerms"
              >
                <Select
                  onChange={(e) => {
                    onChange("terms_and_conditions_type", e);
                  }}
                  placeholder={lang.get("sales", ["Select Terms & Conditions"])}
                  size="large"
                  onClick={() => {
                    CORE_FUNCTIONS.searchOptions(
                      "",
                      "sales",
                      "terms_and_conditions",
                      setTermAndConditionList
                    );
                  }}
                >
                  {termAndConditionList.map((note) => (
                    <Select.Option key={note.id} value={note.id}>
                      {note.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Terms & Conditions"])}
                  </span>
                }
                name="Terms"
              >
                <TinyMCEEditor
                  onEditorChange={() => (e: any) => {
                    onChange("terms_and_conditions", e);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />

          <Row justify="end" gutter={16}>
            <Button
              color="primary"
              variant="outlined"
              icon={<SendOutlined />}
              style={{ marginBottom: "30px", marginTop: "30px" }}
              onClick={() => addInvoice()}
            >
              {lang.get("sales", ["Send the created invoice  For approval"])}
            </Button>
          </Row>
          <Row justify="end" gutter={16}>
            <Col>
              {/* Close Button */}
              <Button
                onClick={() => console.log(lang.get("sales", ["Close"]))}
                style={{
                  backgroundColor: "white",
                  borderColor: "#d9d9d9",
                  color: "#000",
                  marginRight: 8,
                }}
              >
                {lang.get("sales", ["Close"])}
              </Button>

              {/* Save Button */}
              <Button
                type="primary"
                onClick={() => addInvoice()}
                style={{
                  backgroundColor: "#1890ff",
                  borderColor: "#1890ff",
                  color: "#fff",
                  marginRight: 8,
                }}
              >
                {lang.get("sales", ["Save as Draft"])}
              </Button>

              {/* Save & Send Request Button */}
              <Button
                type="primary"
                onClick={() => addInvoice()}
                style={{
                  backgroundColor: "#1890ff",
                  borderColor: "#1890ff",
                  color: "#fff",
                }}
              >
                {lang.get("sales", ["Save & Send To Customer"])}
              </Button>
            </Col>
          </Row>
        </ProForm>
      </ProCard>

      {/* Modals */}
      <Modal
        title={lang.get("sales", ["Edit Bill To"])}
        open={isBillToModalVisible}
        onCancel={() => setBillToModalVisible(false)}
        footer={null}
      >
        <hr />
        <Form.Item name="bill_to_street">
          <Input
            type="text"
            defaultValue={postData.billing_address?.street}
            placeholder={lang.get("sales", ["Enter street"])}
            onChange={(e) => {
              const billing_address = {
                ...postData.billing_address,
                street: e.target.value,
              };

              setPostData({ ...postData, billing_address });
            }}
            size="large"
          />
        </Form.Item>
        <Form.Item name="bill_to_state">
          <Input
            type="text"
            defaultValue={postData.billing_address?.state}
            placeholder={lang.get("sales", ["Enter state"])}
            onChange={(e) => {
              const billing_address = {
                ...postData.billing_address,
                state: e.target.value,
              };

              setPostData({ ...postData, billing_address });
            }}
            size="large"
          />
        </Form.Item>
        <Form.Item name="bill_to_city">
          <Input
            type="text"
            defaultValue={postData.billing_address?.city}
            placeholder={lang.get("sales", ["Enter city"])}
            onChange={(e) => {
              const billing_address = {
                ...postData.billing_address,
                city: e.target.value,
              };

              setPostData({ ...postData, billing_address });
            }}
            size="large"
          />
        </Form.Item>
        <Form.Item name="bill_to_zip">
          <Input
            type="text"
            placeholder={lang.get("sales", ["Enter zip code"])}
            onChange={(e) => {}}
            size="large"
          />
        </Form.Item>
        <Form.Item name="Bill_to_country">
          <Select
            showSearch
            defaultValue={postData.billing_address?.country}
            onChange={(e) => {
              const billing_address = {
                ...postData.billing_address,
                country: e,
              };

              setPostData({ ...postData, billing_address });
            }}
            placeholder={lang.get("sales", ["Enter country"])}
            size="large"
          >
            {countryList.map((country) => (
              <Select.Option key={country.id} value={country.name}>
                {country.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Row justify="end" gutter={16}>
          <Col>
            <Button
              type="primary"
              onClick={() => {
                setBillToModalVisible(false);
                setBillingAddressText(
                  generateAddress(postData.billing_address)
                );
              }}
              style={{
                backgroundColor: "#1890ff",
                borderColor: "#1890ff",
                color: "#fff",
              }}
            >
              {lang.get("sales", ["Save"])}
            </Button>
          </Col>
        </Row>
      </Modal>

      <Modal
        title={lang.get("sales", ["Edit Ship To"])}
        open={isShipToModalVisible}
        onCancel={() => setShipToModalVisible(false)}
        footer={null}
      >
        <hr />
        <Form.Item name="ship_to_street">
          <Input
            type="text"
            defaultValue={postData.shipping_address?.street}
            placeholder={lang.get("sales", ["Enter street"])}
            onChange={(e) => {
              const shipping_address = {
                ...postData.shipping_address,
                street: e.target.value,
              };

              setPostData({ ...postData, shipping_address });
            }}
            size="large"
          />
        </Form.Item>
        <Form.Item name="ship_to_state">
          <Input
            type="text"
            defaultValue={postData.shipping_address?.state}
            placeholder={lang.get("sales", ["Enter state"])}
            onChange={(e) => {
              const shipping_address = {
                ...postData.shipping_address,
                state: e.target.value,
              };

              setPostData({ ...postData, shipping_address });
            }}
            size="large"
          />
        </Form.Item>
        <Form.Item name="ship_to_city">
          <Input
            type="text"
            defaultValue={postData.shipping_address?.city}
            placeholder={lang.get("sales", ["Enter city"])}
            onChange={(e) => {
              const shipping_address = {
                ...postData.shipping_address,
                city: e.target.value,
              };

              setPostData({ ...postData, shipping_address });
            }}
            size="large"
          />
        </Form.Item>
        <Form.Item name="ship_to_zip">
          <Input
            type="text"
            defaultValue={postData.shipping_address?.zip_code}
            placeholder={lang.get("sales", ["Enter zip code"])}
            onChange={(e) => {
              const shipping_address = {
                ...postData.shipping_address,
                zip_code: e.target.value,
              };

              setPostData({ ...postData, shipping_address });
            }}
            size="large"
          />
        </Form.Item>
        <Form.Item name="ship_to_country">
          <Select
            showSearch
            defaultValue={postData.shipping_address?.country}
            onChange={(e) => {
              const shipping_address = {
                ...postData.shipping_address,
                country: e,
              };

              setPostData({ ...postData, shipping_address });
            }}
            placeholder={lang.get("sales", ["Enter country"])}
            size="large"
          >
            {countryList.map((country) => (
              <Select.Option key={country.id} value={country.name}>
                {country.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Row justify="end" gutter={16}>
          <Col>
            <Button
              type="primary"
              onClick={() => {
                setShipToModalVisible(false);
                setShippingAddressText(
                  generateAddress(postData.shipping_address)
                );
              }}
              style={{
                backgroundColor: "#1890ff",
                borderColor: "#1890ff",
                color: "#fff",
              }}
            >
              {lang.get("sales", ["Save"])}
            </Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default AddInvoice;
