import { useState } from "react";
import { Modal } from "antd";

import { DBCustomer, DBCustomerContact } from "../../../types/types";
import ContactModel from "./contact-model/ContactModel";
import ContactsTable from "./contacts/ContactsTable";

import lang from "../../../../../lang";

const ContactTab = (props: {
  client: DBCustomer;
  preload: boolean;
  setRefresh: (e: boolean) => void;
  refresh: boolean;
  currentKey: string;
}) => {
  const [refreshComponent, setRefreshComponent] = useState(false);
  const [modelRefresh, setModelRefresh] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [contact, setContact] = useState<DBCustomerContact | null>(null);

  return (
    <div>
      {/* Contacts Table */}
      <ContactsTable
        refreshComponent={refreshComponent}
        addContact={setContactModalOpen}
        customer={props.client}
        setContact={setContact}
        contact={contact}
      />

      <Modal
        title={lang.get("contact", [contact ? "Edit Contact" : "Add Contact"])}
        open={contactModalOpen}
        onCancel={() => setContactModalOpen(false)}
        footer={null}
        width={800}
        destroyOnClose
        maskClosable={false}
        loading={modelRefresh}
      >
        <ContactModel
          client={props.client.id}
          contact={contact}
          refreshComponent={refreshComponent}
          setRefreshComponent={setRefreshComponent}
          setContactModelOpen={setContactModalOpen}
          setModelRefresh={setModelRefresh}
        />
      </Modal>
    </div>
  );
};

export default ContactTab;
