import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Tabs,
  Form,
  Input,
  Button,
  Select,
  Typography,
  Divider,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import lang from "../../../../lang"; // Adjust the import path as needed
import TinyMCEEditor from "../../../../common/base/Editor";

const { TabPane } = Tabs;
const { Title } = Typography;

const VendorAddBody: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("vendorDetails");

  const handleSave = () => {
    console.log("Vendor data saved.");
  };

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      <Title level={2} className="mb-4">
        {lang.get("purchase", ["Add Vendor"])}
      </Title>
      <Card>
        <Tabs
          activeKey={activeTab}
          onChange={(key) => setActiveTab(key)}
          tabBarStyle={{ marginBottom: 24, backgroundColor: "#f5f7fa" }}
        >
          {/* Vendor Details Tab */}
          <TabPane
            tab={
              <div>
                <InfoCircleOutlined />{" "}
                {lang.get("purchase", ["Vendor Details"])}
              </div>
            }
            key="vendorDetails"
          >
            <Form layout="vertical">
              <Row gutter={[16, 5]}>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Vendor Code"])}
                      </span>
                    }
                    name="vendorCode"
                    rules={[
                      {
                        required: true,
                        message: lang.get("purchase", ["Required"]),
                      },
                    ]}
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter Vendor Code"])}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Company"])}
                      </span>
                    }
                    name="company"
                    rules={[
                      {
                        required: true,
                        message: lang.get("purchase", ["Required"]),
                      },
                    ]}
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter Company Name"])}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["VAT Number"])}
                      </span>
                    }
                    name="vatNumber"
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter VAT Number"])}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Phone"])}
                      </span>
                    }
                    name="phone"
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter Phone Number"])}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Website"])}
                      </span>
                    }
                    name="website"
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter Website URL"])}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Vendor Category"])}
                      </span>
                    }
                    name="vendorCategory"
                  >
                    <Select
                      placeholder={lang.get("purchase", [
                        "Select Vendor Category",
                      ])}
                      size="large"
                    >
                      <Select.Option value="category1">
                        {lang.get("purchase", ["Category 1"])}
                      </Select.Option>
                      <Select.Option value="category2">
                        {lang.get("purchase", ["Category 2"])}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Currency"])}
                      </span>
                    }
                    name="currency"
                  >
                    <Select
                      placeholder={lang.get("purchase", ["System Default"])}
                      size="large"
                    >
                      <Select.Option value="LKR">
                        {lang.get("purchase", ["LKR"])}
                      </Select.Option>
                      <Select.Option value="USD">
                        {lang.get("purchase", ["USD"])}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Default Language"])}
                      </span>
                    }
                    name="defaultLanguage"
                  >
                    <Select
                      placeholder={lang.get("purchase", ["System Default"])}
                      size="large"
                    >
                      <Select.Option value="en">
                        {lang.get("purchase", ["English"])}
                      </Select.Option>
                      <Select.Option value="si">
                        {lang.get("purchase", ["Sinhala"])}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Country"])}
                      </span>
                    }
                    name="country"
                  >
                    <Select
                      placeholder={lang.get("purchase", ["Select Country"])}
                      size="large"
                    >
                      <Select.Option value="Sri Lanka">
                        {lang.get("purchase", ["Sri Lanka"])}
                      </Select.Option>
                      <Select.Option value="USA">
                        {lang.get("purchase", ["USA"])}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Address"])}
                      </span>
                    }
                    name="address"
                  >
                    <Input.TextArea
                      rows={3}
                      placeholder={lang.get("purchase", ["Enter Address"])}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Billing Address"])}
                      </span>
                    }
                    name="billingAddress"
                  >
                    <Input.TextArea
                      rows={3}
                      placeholder={lang.get("purchase", [
                        "Enter Billing Address",
                      ])}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Shipping Address"])}
                      </span>
                    }
                    name="shippingAddress"
                  >
                    <Input.TextArea
                      rows={3}
                      placeholder={lang.get("purchase", [
                        "Enter Shipping Address",
                      ])}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </TabPane>

          {/* Billing & Shipping Tab */}
          <TabPane
            tab={lang.get("purchase", ["Billing & Shipping"])}
            key="billingShipping"
          >
            <Form layout="vertical">
              <Row gutter={[16, 5]}>
                <Col xs={24} sm={12}>
                  <Divider>{lang.get("purchase", ["Billing Address"])}</Divider>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Street"])}
                      </span>
                    }
                    name="billingStreet"
                  >
                    <Input.TextArea
                      rows={3}
                      placeholder={lang.get("purchase", ["Enter Street"])}
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["City"])}
                      </span>
                    }
                    name="billingCity"
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter City"])}
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["State"])}
                      </span>
                    }
                    name="billingState"
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter State"])}
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Zip Code"])}
                      </span>
                    }
                    name="billingZipCode"
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter Zip Code"])}
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Country"])}
                      </span>
                    }
                    name="billingCountry"
                  >
                    <Select
                      placeholder={lang.get("purchase", ["Select Country"])}
                      size="large"
                    >
                      <Select.Option value="Sri Lanka">
                        {lang.get("purchase", ["Sri Lanka"])}
                      </Select.Option>
                      <Select.Option value="USA">
                        {lang.get("purchase", ["USA"])}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                  <Divider>
                    {lang.get("purchase", ["Shipping Address"])}
                  </Divider>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Street"])}
                      </span>
                    }
                    name="shippingStreet"
                  >
                    <Input.TextArea
                      rows={3}
                      placeholder={lang.get("purchase", ["Enter Street"])}
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["City"])}
                      </span>
                    }
                    name="shippingCity"
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter City"])}
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["State"])}
                      </span>
                    }
                    name="shippingState"
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter State"])}
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Zip Code"])}
                      </span>
                    }
                    name="shippingZipCode"
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter Zip Code"])}
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Country"])}
                      </span>
                    }
                    name="shippingCountry"
                  >
                    <Select
                      placeholder={lang.get("purchase", ["Select Country"])}
                      size="large"
                    >
                      <Select.Option value="Sri Lanka">
                        {lang.get("purchase", ["Sri Lanka"])}
                      </Select.Option>
                      <Select.Option value="USA">
                        {lang.get("purchase", ["USA"])}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </TabPane>

          {/* Bank Details Tab */}
          <TabPane
            tab={lang.get("purchase", ["Bank Details"])}
            key="bankDetails"
          >
            <Form layout="vertical">
              {/* First Row */}
              <Row gutter={[16, 5]}>
                <Col xs={24} sm={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Print Name On Cheque"])}
                      </span>
                    }
                    name="printNameOnCheque"
                  >
                    <Input
                      placeholder={lang.get("purchase", [
                        "Enter Print Name On Cheque",
                      ])}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Account Name"])}
                      </span>
                    }
                    name="accountName"
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter Account Name"])}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Account Number"])}
                      </span>
                    }
                    name="accountNumber"
                  >
                    <Input
                      placeholder={lang.get("purchase", [
                        "Enter Account Number",
                      ])}
                      size="large"
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* Second Row */}
              <Row gutter={[16, 5]}>
                <Col xs={24} sm={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Bank Name"])}
                      </span>
                    }
                    name="bankName"
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter Bank Name"])}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Branch"])}
                      </span>
                    }
                    name="branch"
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter Branch"])}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Swift Code"])}
                      </span>
                    }
                    name="swiftCode"
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter Swift Code"])}
                      size="large"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </TabPane>

          {/* Additional Information Tab */}
          <TabPane
            tab={lang.get("purchase", ["Additional Information"])}
            key="additionalInformation"
          >
            <Form layout="vertical">
              {/* First Row */}
              <Row gutter={[16, 5]}>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["VAT No"])}
                      </span>
                    }
                    name="vatNo"
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter VAT No"])}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["SVAT No"])}
                      </span>
                    }
                    name="svatNo"
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter SVAT No"])}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Credit Limit"])}
                      </span>
                    }
                    name="creditLimit"
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter Credit Limit"])}
                      size="large"
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* Second Row */}
              <Row gutter={[16, 5]}>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Credit Period"])}
                      </span>
                    }
                    name="creditPeriod"
                  >
                    <Input
                      placeholder={lang.get("purchase", [
                        "Enter Credit Period",
                      ])}
                      size="large"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("purchase", ["Terms & Conditions"])}
                  </span>
                }
                name="termsAndConditions"
              >
                <TinyMCEEditor />
              </Form.Item>
            </Form>
          </TabPane>

          {/* Return Policies Tab */}
          <TabPane
            tab={lang.get("purchase", ["Return Policies"])}
            key="returnPolicies"
          >
            <Form layout="vertical">
              <Row gutter={[16, 5]}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Return Request Days"])}
                      </span>
                    }
                    name="returnRequestDays"
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter Days"])}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Fee for Return Order"])}
                      </span>
                    }
                    name="returnFee"
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter Fee"])}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Return Policies Information"])}
                      </span>
                    }
                    name="returnPoliciesInfo"
                  >
                    <Input.TextArea
                      rows={3}
                      placeholder={lang.get("purchase", [
                        "Enter Return Policies",
                      ])}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </TabPane>
        </Tabs>

        <Row justify="end" gutter={16} className="mt-4">
          <Col>
            <Button type="primary" onClick={handleSave}>
              {lang.get("purchase", ["Save"])}
            </Button>
          </Col>
          <Col>
            <Button type="default">{lang.get("purchase", ["Cancel"])}</Button>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default VendorAddBody;
