import { Breadcrumb, Typography, Button, Space, Row, Col, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import ItemListTable from "./item-components/itemTable";
import lang from "../../../lang";
import { useState } from "react";
import ItemAddBody from "./item-components/ItemAddBody";

const { Title } = Typography;

const ItemList = () => {
  const [modelOpen, setModelOpen] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      {/* Breadcrumb */}
      <Breadcrumb style={{ marginBottom: "16px" }} separator=">">
        <Breadcrumb.Item>{lang.get("breadcrumbs", ["Home"])}</Breadcrumb.Item>
        <Breadcrumb.Item>
          {lang.get("breadcrumbs", ["Inventory"])}
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {lang.get("breadcrumbs", ["Item List"])}
        </Breadcrumb.Item>
      </Breadcrumb>

      {/* Page Title */}
      <Title level={2} style={{ marginBottom: "16px" }}>
        {lang.get("items", ["Item List"])}
      </Title>

      {/* Filter and Actions */}
      <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Space size="middle" wrap>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setModelOpen(true)}
            >
              {lang.get("items", ["Add Item"])}
            </Button>
          </Space>
        </Col>
      </Row>

      {/* Table */}
      <ItemListTable
        setRefreshTable={setRefreshTable}
        refreshTable={refreshTable}
      />

      {/* Add Item Modal */}
      <Modal
        title={lang.get("items", ["Add Item"])}
        open={modelOpen}
        centered
        maskClosable={false}
        footer={null}
        onCancel={() => setModelOpen(false)}
        destroyOnClose
        width={1250}
      >
        <ItemAddBody
          modelClose={() => setModelOpen(false)}
          setRefreshTable={setRefreshTable}
          refreshTable={refreshTable}
        />
      </Modal>
    </div>
  );
};

export default ItemList;
