import { SearchOutlined } from "@ant-design/icons";
import type { ActionType, ProColumns } from "@ant-design/pro-components";
import { ProTable } from "@ant-design/pro-components";
import { Badge, Button, Input, Space, InputRef } from "antd";
import { useEffect, useRef, useState } from "react";
import Export from "../../../../common/components/Export";
import apiRequest from "../../../../common/utils/apiRequest";
import coreFunctions from "../../../../common/utils/coreFunctions";

import { Link } from "react-router-dom";

import lang from "../../../../lang";

import { DBCustomer, DB_Currency } from "../../types/types";

const CustomerTable = (props: { updateTable: boolean }) => {
  const actionRef = useRef<ActionType>();
  const [tableData, setTableData] = useState<DBCustomer[]>([]);
  const [currency, setCurrency] = useState<DB_Currency[]>([]);
  const [pageSize, setPageSize] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [searchAttributes, setSearchAttributes] = useState<
    { key: string; value: string }[]
  >([]);
  const searchInput = useRef<InputRef>(null);
  const [allSearch, setAllSearch] = useState<string>("");
  const expobj = new Export("customers, " + new Date().toDateString());

  const handleSearch = (field: string, value: string) => {
    setAllSearch("");
    const existingAttribute = searchAttributes.find(
      (attr) => attr.key === field
    );

    if (existingAttribute) {
      setSearchAttributes(
        searchAttributes.map((attr) =>
          attr.key === field ? { ...attr, value } : attr
        )
      );
    } else {
      setSearchAttributes([
        ...searchAttributes,
        {
          key: field,
          value,
        },
      ]);
    }
  };

  useEffect(() => {
    const API_REQUEST = new apiRequest("currencies/get", true);
    API_REQUEST.send({}).then(
      (response: { status: string; data: DB_Currency[] }) => {
        if (response && response.status === "success") {
          for (let i = 0; i < response.data.length; i++) {
            setCurrency(response.data);
          }
        }
      }
    );

    actionRef.current?.reset?.();
  }, []);

  useEffect(() => {
    actionRef.current?.reset?.();
    actionRef.current?.reload();
  }, [props.updateTable]);

  const searchCols = (field: string, label: string) => {
    return {
      filterDropdown: (props: any) => {
        const { close } = props;

        setTimeout(() => {
          if (searchInput.current) {
            searchInput.current.focus();
          }
        }, 100);

        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={`Search ${label}`}
              onChange={(e) => handleSearch(field, e.target.value)}
              value={
                searchAttributes.filter((item) => item.key === field)[0]?.value
              }
              style={{ marginBottom: 8, display: "block" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  close();
                  if (actionRef.current) {
                    actionRef.current?.reset?.();
                    actionRef.current.reload();
                  }
                }
              }}
            />
            <Space>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="small"
                onClick={() => {
                  actionRef.current?.reset?.();
                  actionRef.current?.reload();
                }}
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button
                onClick={() => close()}
                size="small"
                style={{ width: 90 }}
              >
                Close
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
    };
  };

  const columns: ProColumns<DBCustomer>[] = [
    {
      title: "#",
      width: 50,
      sorter: false,
      search: false,
      render: (_, record, index) => <p style={{ margin: 0 }}>{index + 1}</p>,
    },
    {
      title: lang.get("customer", ["Company Name"]),
      dataIndex: "company_name",
      sorter: true,
      ellipsis: true,
      search: false,
      ...searchCols("company_name", "Company Name"),
      render: (_, record) => (
        <Link
          to={`/customer-profile/` + record.id}
          style={{
            color: "inherit",
            textDecoration: "none",
          }}
        >
          <p style={{ margin: 0, fontWeight: "bold" }}>{record.company_name}</p>
        </Link>
      ),
    },
    {
      title: lang.get("customer", ["Primary Contact"]),
      dataIndex: "primary_contact",
      sorter: true,
      ...searchCols("primary_contact", "Primary Contact"),
    },
    {
      title: lang.get("customer", ["Primary Email"]),
      dataIndex: "primary_email",
      sorter: true,
      ...searchCols("primary_email", "Primary Email"),
    },
    {
      title: lang.get("customer", ["Phone"]),
      key: "phone",
      dataIndex: "phone",
      sorter: true,
      ellipsis: true,
      ...searchCols("phone", "Phone"),
    },
    {
      title: lang.get("customer", ["Active"]),
      key: "status",
      dataIndex: "status",
      sorter: true,
      ...searchCols("status", "Active"),
      filters: [
        {
          text: "Active",
          value: "1",
        },
        {
          text: "Inactive",
          value: "0",
        },
      ],
      render: (_, record) => {
        return record.status === 1 ? (
          <Badge status="success" text="Active" />
        ) : (
          <Badge status="error" text="Inactive" />
        );
      },
    },
    {
      title: lang.get("customer", ["Created at"]),
      key: "created_at",
      dataIndex: "created_at",
      sorter: true,
      ...searchCols("created_at", "Created at"),
      render: (_, record) => {
        return new Date(record.created_at).toLocaleString("en-US");
      },
    },
    {
      title: lang.get("customer", ["Default Currency"]),
      dataIndex: "currency",
      sorter: true,
      ...searchCols("currency", "Default Currency"),
      filters: currency.map((item) => ({
        text: item.name,
        value: item.name.toLowerCase(),
      })),
      render: (_, record) => {
        return record.currency.toUpperCase();
      },
    },
  ];

  return (
    <ProTable
      headerTitle={lang.get("customer", ["Customer"])}
      columns={columns}
      actionRef={actionRef}
      cardBordered
      request={async (params, sort, filter) => {
        await new coreFunctions().waitRequest(10);

        const processedFilters = Object.entries(filter || {}).reduce(
          (acc, [key, value]) => {
            if (value && value.length > 0) {
              acc[key] = value;
            }
            return acc;
          },
          {} as Record<string, (string | number)[]>
        );

        const payload = {
          params: params,
          sorts: sort,
          filters: processedFilters,
          searches: searchAttributes,
          all_search: allSearch,
        };

        const API_REQUEST = new apiRequest("clients/get", true);
        const response = await API_REQUEST.send(payload);

        if (response && response.status === "success") {
          const fetchedData = response.data || [];
          setTableData(fetchedData);
          setTotalRecords(response.total);

          return {
            data: fetchedData,
            total: totalRecords,
            success: true,
          };
        }

        return {
          data: [],
          total: 0,
          success: false,
        };
      }}
      columnsState={{
        persistenceKey: "customer-table",
        persistenceType: "localStorage",
      }}
      pagination={{
        defaultCurrent: 1,
        pageSize: pageSize,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50, 100, totalRecords],
        showQuickJumper: true,
        showTotal: (total, range) =>
          `Showing ${range[0]}-${range[1]} of ${total} items`,
        onShowSizeChange: (current, size) => {
          setPageSize(size);
        },
      }}
      rowKey="id"
      search={false}
      scroll={{ x: 800 }}
      toolBarRender={() => [
        <Input
          style={{ width: "auto" }}
          key="all_search"
          allowClear
          placeholder={lang.get("customer", "Search")}
          value={allSearch}
          onChange={(e) => {
            setAllSearch(e.target.value);
            setSearchAttributes([]);
            actionRef?.current?.reload();
          }}
        />,
        <Button
          key="csv"
          onClick={() => {
            const csvData = tableData.map((row) => ({
              Company: row.company_name,
              "Primary Contact": row.primary_contact,
              "Primary Email": row.primary_email,
              Phone: row.phone,
              Currency: row.currency,
            }));

            expobj.exportToCSV(csvData);
          }}
        >
          Export CSV
        </Button>,
        <Button
          key="excel"
          onClick={() => {
            const data = tableData.map((row) => ({
              Company: row.company_name,
              "Primary Contact": row.primary_contact,
              "Primary Email": row.primary_email,
              Phone: row.phone,
              Currency: row.currency,
            }));

            expobj.exportToExcel(data);
          }}
        >
          Export Excel
        </Button>,
        <Button
          key="pdf"
          onClick={() => {
            expobj.exportToPDF({
              head: [
                [
                  lang.get("customer", ["Company"]),
                  lang.get("customer", ["Primary Contact"]),
                  lang.get("customer", ["Primary Email"]),
                  lang.get("customer", ["Phone"]),
                  lang.get("customer", ["Currency"]),
                ],
              ],
              body: tableData.map((row) => [
                row.company_name,
                row.primary_contact,
                row.primary_email,
                row.phone,
                row.currency,
              ]),
            });
          }}
        >
          Export PDF
        </Button>,
      ]}
    />
  );
};

export default CustomerTable;
