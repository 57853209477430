import { Row, Col, Form, Button, Input, Select } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";

import lang from "../../../lang";

import {
  ClientBillingAddress,
  ClientShippingAddress,
  SelectType,
} from "../types/types";

const { Option } = Select;

const ClientBillingAndShipping = (props: {
  setClientBillingAddress: (
    clientBillingAddress: ClientBillingAddress[]
  ) => void;
  setClientShippingAddress: (
    clientShippingAddress: ClientShippingAddress[]
  ) => void;
  clientBillingAddress: ClientBillingAddress[];
  clientShippingAddress: ClientShippingAddress[];
  countryList: SelectType[];
}) => {
  const removeShippingAddress = (index: number) => {
    const updatedAddresses = props.clientShippingAddress.filter(
      (_, i) => i !== index
    );
    props.setClientShippingAddress(updatedAddresses);
  };

  const removeBillingAddress = (index: number) => {
    const updatedAddresses = props.clientBillingAddress.filter(
      (_, i) => i !== index
    );
    props.setClientBillingAddress(updatedAddresses);
  };

  return (
    <Row gutter={[16, 16]}>
      {/* Billing Address */}
      <Col xs={24} lg={12}>
        <Row align="middle" gutter={[16, 8]}>
          <Col>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                props.setClientBillingAddress([
                  ...props.clientBillingAddress,
                  {
                    address: "",
                    city: "",
                    state: "",
                    zip_code: "",
                    country: "",
                  },
                ]);
              }}
            >
              {lang.get("address", ["Add"])}
            </Button>
          </Col>
          <Col>
            <h5 className="text-body-highlight" style={{ fontSize: "14px" }}>
              {lang.get("address", ["Billing Address"])}
            </h5>
          </Col>
        </Row>
        {props.clientBillingAddress.map((item, index) => (
          <Row
            key={"b" + index}
            gutter={[16, 4]}
            style={{
              borderBottom: "1px dotted #000",
              paddingBottom: "10px",
              marginBlockStart: "10px",
            }}
          >
            <Col span={24}>
              <Form.Item>
                <Input
                  onChange={(e) => {
                    item.address = e.target.value;
                    props.setClientBillingAddress([
                      ...props.clientBillingAddress,
                    ]);
                  }}
                  value={item.address}
                  placeholder={lang.get("address", ["Address"])}
                  size="large"
                  style={{ fontSize: "14px" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Input
                  onChange={(e) => {
                    item.city = e.target.value;
                    props.setClientBillingAddress([
                      ...props.clientBillingAddress,
                    ]);
                  }}
                  value={item.city}
                  placeholder={lang.get("address", ["City"])}
                  size="large"
                  style={{ fontSize: "14px" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Input
                  onChange={(e) => {
                    item.state = e.target.value;
                    props.setClientBillingAddress([
                      ...props.clientBillingAddress,
                    ]);
                  }}
                  value={item.state}
                  placeholder={lang.get("address", ["State"])}
                  size="large"
                  style={{ fontSize: "14px" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Input
                  onChange={(e) => {
                    item.zip_code = e.target.value;
                    props.setClientBillingAddress([
                      ...props.clientBillingAddress,
                    ]);
                  }}
                  value={item.zip_code}
                  placeholder={lang.get("address", ["Zip Code"])}
                  size="large"
                  style={{ fontSize: "14px" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Select
                  placeholder={lang.get("customer", ["Country"])}
                  showSearch
                  onChange={(value) => {
                    item.country = value;
                    props.setClientBillingAddress([
                      ...props.clientBillingAddress,
                    ]);
                  }}
                  value={item.country}
                  size="large"
                >
                  {props.countryList.map((country) => (
                    <Option value={country.name}>{country.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Button
                type="primary"
                danger
                icon={<DeleteOutlined />}
                onClick={() => removeBillingAddress(index)}
              >
                {lang.get("address", ["Remove"])}
              </Button>
            </Col>
          </Row>
        ))}
      </Col>

      {/* Shipping Address */}
      <Col xs={24} lg={12}>
        <Row align="middle" gutter={[16, 8]}>
          <Col>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() =>
                props.setClientShippingAddress([
                  ...props.clientShippingAddress,
                  {
                    address: "",
                    city: "",
                    state: "",
                    zip_code: "",
                    country: "",
                  },
                ])
              }
            >
              {lang.get("address", ["Add"])}
            </Button>
          </Col>
          <Col>
            <h5 className="text-body-highlight" style={{ fontSize: "14px" }}>
              {lang.get("address", ["Shipping Address"])}
            </h5>
          </Col>
        </Row>
        {props.clientShippingAddress.map((item, index) => (
          <Row
            key={"s" + index}
            gutter={[16, 4]}
            style={{
              borderBottom: "1px dotted #000",
              paddingBottom: "10px",
              marginBlockStart: "10px",
            }}
          >
            <Col span={24}>
              <Form.Item>
                <Input
                  onChange={(e) => {
                    item.address = e.target.value;
                    props.setClientShippingAddress([
                      ...props.clientShippingAddress,
                    ]);
                  }}
                  value={item.address}
                  placeholder={lang.get("address", ["Address"])}
                  size="large"
                  style={{ fontSize: "14px" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Input
                  onChange={(e) => {
                    item.city = e.target.value;
                    props.setClientShippingAddress([
                      ...props.clientShippingAddress,
                    ]);
                  }}
                  value={item.city}
                  placeholder={lang.get("address", ["City"])}
                  size="large"
                  style={{ fontSize: "14px" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Input
                  onChange={(e) => {
                    item.state = e.target.value;
                    props.setClientShippingAddress([
                      ...props.clientShippingAddress,
                    ]);
                  }}
                  value={item.state}
                  placeholder={lang.get("address", ["State"])}
                  size="large"
                  style={{ fontSize: "14px" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Input
                  onChange={(e) => {
                    item.zip_code = e.target.value;
                    props.setClientShippingAddress([
                      ...props.clientShippingAddress,
                    ]);
                  }}
                  value={item.zip_code}
                  placeholder={lang.get("address", ["Zip Code"])}
                  size="large"
                  style={{ fontSize: "14px" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Select
                  placeholder={lang.get("customer", ["Country"])}
                  showSearch
                  onChange={(value) => {
                    item.country = value;
                    props.setClientShippingAddress([
                      ...props.clientShippingAddress,
                    ]);
                  }}
                  size="large"
                  value={item.country}
                >
                  {props.countryList.map((country) => (
                    <Option value={country.name}>{country.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Button
                type="primary"
                danger
                icon={<DeleteOutlined />}
                onClick={() => removeShippingAddress(index)}
              >
                {lang.get("address", ["Remove"])}
              </Button>
            </Col>
          </Row>
        ))}
      </Col>
    </Row>
  );
};

export default ClientBillingAndShipping;
