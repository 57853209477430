import { StatisticCard, StatisticProps } from "@ant-design/pro-components";
import RcResizeObserver from "rc-resize-observer";
import { useState } from "react";
import Draft from "../../../../../../../assets/img/statistics/draft.svg";
import Sent from "../../../../../../../assets/img/statistics/sent.svg";
import Accept from "../../../../../../../assets/img/statistics/accept.svg";
import Expired from "../../../../../../../assets/img/statistics/expired.svg";
import Declined from "../../../../../../../assets/img/statistics/declined.svg";
import Paid from "../../../../../../../assets/img/statistics/paid.svg";
import Due from "../../../../../../../assets/img/statistics/due.svg";
import Outstanding from "../../../../../../../assets/img/statistics/outstanding.svg";

import CountUp from "react-countup";
const { Divider } = StatisticCard;

const imgStyle = {
  display: "block",
  width: 60,
  height: 55,
};

const formatter: StatisticProps["formatter"] = (value) => {
  return (
    <CountUp end={value as number} separator="," prefix="$" decimals={2} />
  );
};

const formatPercentageView = (value: number) => {
  return <span style={{ color: "gray" }}>{`${value}%`}</span>;
};

const InvoiceStatistics = () => {
  const [responsive, setResponsive] = useState(false);

  return (
    <RcResizeObserver
      key="resize-observer"
      onResize={(offset) => {
        setResponsive(offset.width < 596);
      }}
    >
      <StatisticCard.Group
        direction={responsive ? "row" : "row"}
        style={{
          paddingTop: "16px",
          paddingBottom: "16px",
          marginBottom: "16px",
          height: responsive ? "100px" : "auto",
          overflowX: "auto",
          overflowY: "hidden",
        }}
      >
        <StatisticCard
          statistic={{
            formatter,
            title: "Outstanding Invoices",
            value: 0.0,
            icon: <img style={imgStyle} src={Outstanding} alt="icon" />,
          }}
        />
        <Divider type={responsive ? "horizontal" : "vertical"} />
        <StatisticCard
          statistic={{
            formatter,
            title: "Past Due Invoices",
            value: 0.0,
            icon: <img style={imgStyle} src={Due} alt="icon" />,
          }}
        />
        <Divider type={responsive ? "horizontal" : "vertical"} />
        <StatisticCard
          statistic={{
            formatter,
            title: "Paid Invoices",
            value: 0.0,
            icon: <img style={imgStyle} src={Paid} alt="icon" />,
          }}
        />
    
      </StatisticCard.Group>

      <StatisticCard.Group
        direction={responsive ? "row" : "row"}
        style={{
          paddingTop: "16px",
          paddingBottom: "16px",
          height: responsive ? "100px" : "auto",
          overflowX: "auto",
          overflowY: "hidden",
        }}
      >
        <StatisticCard
          statistic={{
            title: "Unpaid",
            value: "0/0 View",
            description: formatPercentageView(0),
            icon: <img style={imgStyle} src={Declined} alt="Draft icon" />,
          }}
        />
        <Divider type={responsive ? "horizontal" : "vertical"} />
        <StatisticCard
          statistic={{
            title: "Paid",
            value: "0/0 View",
            description: formatPercentageView(0),
            icon: <img style={imgStyle} src={Sent} alt="Sent icon" />,
          }}
        />
        <Divider type={responsive ? "horizontal" : "vertical"} />
        <StatisticCard
          statistic={{
            title: "Partially Paid",
            value: "0/0 View",
            description: formatPercentageView(0),
            icon: <img style={imgStyle} src={Accept} alt="Expired icon" />,
          }}
        />
        <Divider type={responsive ? "horizontal" : "vertical"} />
        <StatisticCard
          statistic={{
            title: "Overdue",
            value: "0/0 View",
            description: formatPercentageView(0),
            icon: <img style={imgStyle} src={Expired} alt="Declined icon" />,
          }}
        />
        <Divider type={responsive ? "horizontal" : "vertical"} />
        <StatisticCard
          statistic={{
            title: "Draft",
            value: "0/0 View",
            description: formatPercentageView(0),
            icon: <img style={imgStyle} src={Draft} alt="Accepted icon" />,
          }}
        />
      </StatisticCard.Group>
    </RcResizeObserver>
  );
};

export default InvoiceStatistics;
