import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Switch,
  Button,
  Select,
  Divider,
  message,
} from "antd";
import lang from "../../../../../../lang";
import { DBCustomerContact, SelectType } from "../../../../types/types";

import { ProFormUploadDragger } from "@ant-design/pro-components";

import UploadFile from "../../../../../../common/utils/upload";
import coreFunctions from "../../../../../../common/utils/coreFunctions";
import apiRequest from "../../../../../../common/utils/apiRequest";

const { Option } = Select;

const ContactModel = ({
  contact,
  setContactModelOpen,
  refreshComponent,
  setRefreshComponent,
  client,
  setModelRefresh,
}: {
  contact?: DBCustomerContact | null;
  client: string;
  refreshComponent: boolean;
  setRefreshComponent: (e: boolean) => void;
  setContactModelOpen: (e: boolean) => void;
  setModelRefresh: (e: boolean) => void;
}) => {
  const [title, setTitle] = useState(contact?.title);
  const [firstName, setFirstName] = useState(contact?.first_name);
  const [lastName, setLastName] = useState(contact?.last_name);
  const [email, setEmail] = useState(contact?.email);
  const [position, setPosition] = useState(contact?.position);
  const [phone, setPhone] = useState(contact?.phone);
  const [password, setPassword] = useState("");
  const [primary, setPrimary] = useState(contact ? contact.is_primary : 0);
  const [doNotSendWelcomeEmail, setDoNotSendWelcomeEmail] = useState(
    contact ? contact.do_not_send_welcome_email : 0
  );
  const [sendSetPassword, setSendSetPassword] = useState(
    contact ? contact.send_set_password : 0
  );
  const [profileImage, setProfileImage] = useState<File | null>(null);
  const [profileImageHash, setProfileImageHash] = useState<string | null>(null);
  const [permissionsList, setPermissionsList] = useState<SelectType[]>([]);
  const [emailTemplateList, setEmailTemplateList] = useState<SelectType[]>([]);
  const [saving, setSaving] = useState(false);

  const saveContact = async () => {
    setSaving(true);
    if (profileImage) {
      const FILE_INSTANCE = new UploadFile();

      await FILE_INSTANCE.initUpload(profileImage);

      setProfileImageHash(FILE_INSTANCE.getFileName());
    }

    message
      .loading(lang.get("customer", ["Adding customer contact"]), 1)
      .then(() => {
        const API_REQUEST = new apiRequest("client/contact/add", true);
        API_REQUEST.send(
          {
            title,
            client: client,
            first_name: firstName,
            last_name: lastName,
            email,
            position,
            phone,
            password,
            primary,
            do_not_send_welcome_email: doNotSendWelcomeEmail,
            send_set_password: sendSetPassword,
            profile_image: profileImageHash,
            permissions: permissionsList.map((permission) => permission.id),
            email_notifications: emailTemplateList.map(
              (emailTemplate) => emailTemplate.id
            ),
          },
          (response: { status: string; message: string }) => {
            if (response.status === "success") {
              message.success(
                lang.get("customer", ["Customer contact added successfully."]),
                2
              );

              setProfileImage(null);
              setProfileImageHash(null);
              setTitle("");
              setFirstName("");
              setLastName("");
              setEmail("");
              setPosition("");
              setPhone("");
              setPassword("");
              setPrimary(1);
              setDoNotSendWelcomeEmail(0);
              setSendSetPassword(0);

              setRefreshComponent(!refreshComponent);
              setContactModelOpen(false);
              setSaving(false);
            } else {
              message.error(lang.get("customer", [response.message]), 2);
              setProfileImage(null);
            }
            setSaving(false);
          }
        );
      });
  };

  useEffect(() => {
    setModelRefresh(true);

    if (contact) {
      setTitle(contact.title);
      setFirstName(contact.first_name);
      setLastName(contact.last_name);
      setEmail(contact.email);
      setPosition(contact.position);
      setPhone(contact.phone);
      setPrimary(contact.is_primary);
      setDoNotSendWelcomeEmail(contact.do_not_send_welcome_email);
      setSendSetPassword(contact.send_set_password);
      setProfileImageHash(contact.profile_image);
    }

    const loadData = async () => {
      await new coreFunctions().waitRequest();

      const PERMISSIONS_REQUEST = new apiRequest("permissions/get", true);
      PERMISSIONS_REQUEST.send(
        {},
        (data: { status: string; message: string; data: SelectType[] }) => {
          setPermissionsList(data.data);
        }
      );

      const EMAIL_NOTIFICATION_REQUEST = new apiRequest(
        "notifications/email_templates/get",
        true
      );
      EMAIL_NOTIFICATION_REQUEST.send(
        {},
        (data: { status: string; message: string; data: SelectType[] }) => {
          setEmailTemplateList(data.data);
        }
      );
    };

    loadData();

    setModelRefresh(false);
  }, []);

  return (
    <Form layout="vertical">
      <hr />
      {/* General Information */}
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Form.Item label={lang.get("customer", ["Title"])}>
            <Input
              placeholder={lang.get("customer", ["Enter Title"])}
              value={title}
              size="large"
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item label={lang.get("customer", ["Position"])}>
            <Input
              placeholder={lang.get("customer", ["Enter Position"])}
              value={position}
              size="large"
              onChange={(e) => setPosition(e.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Form.Item label={lang.get("customer", ["First Name"])}>
            <Input
              placeholder={lang.get("customer", ["Enter First Name"])}
              value={firstName}
              size="large"
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item label={lang.get("customer", ["Last Name"])}>
            <Input
              placeholder={lang.get("customer", ["Enter Last Name"])}
              value={lastName}
              size="large"
              onChange={(e) => setLastName(e.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>

      {/* Contact Information */}
      <Row gutter={[16, 16]}>
        <Col xs={24} md={8}>
          <Form.Item label={lang.get("customer", ["Email"])}>
            <Input
              placeholder={lang.get("customer", ["Enter Email"])}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              size="large"
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item label={lang.get("customer", ["Phone"])}>
            <Input
              placeholder={lang.get("customer", ["Enter Phone"])}
              value={phone}
              size="large"
              onChange={(e) => setPhone(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item label={lang.get("customer", ["Password"])}>
            <Input.Password
              size="large"
              placeholder={lang.get("customer", ["Enter Password"])}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>

      {/* Switches Row */}
      <Row gutter={[16, 16]}>
        <Col xs={24} md={8}>
          <Form.Item label={lang.get("customer", ["Primary Contact"])}>
            <Switch
              checked={primary === 1}
              onChange={(checked) => setPrimary(checked ? 1 : 0)}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            label={lang.get("customer", ["Do Not Send Welcome Email"])}
          >
            <Switch
              value={doNotSendWelcomeEmail === 1}
              onChange={(checked) => setDoNotSendWelcomeEmail(checked ? 1 : 0)}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item label={lang.get("customer", ["Send Set Password Email"])}>
            <Switch
              value={sendSetPassword === 1}
              onChange={(checked) => setSendSetPassword(checked ? 1 : 0)}
            />
          </Form.Item>
        </Col>
      </Row>

      {/* Permissions */}
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Form.Item label={lang.get("customer", ["Permissions"])}>
            <Select
              size="large"
              mode="multiple"
              placeholder={lang.get("customer", ["Select permissions"])}
            >
              {permissionsList.map((permission) => (
                <Option key={permission.id} value={permission.id}>
                  {permission.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item label={lang.get("customer", ["Email Notifications"])}>
            <Select
              size="large"
              mode="multiple"
              placeholder={lang.get("customer", ["Select email notifications"])}
            >
              {emailTemplateList.map((emailTemplate) => (
                <Option key={emailTemplate.id} value={emailTemplate.id}>
                  {emailTemplate.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col xs={24} md={24}>
          <Form.Item label={lang.get("customer", ["Profile Image"])}>
            <ProFormUploadDragger
              max={1}
              onChange={(info) => {
                if (info.file.originFileObj) {
                  setProfileImage(info.file.originFileObj);
                }
              }}
              title={lang.get("customer", [
                "Click or drag files into this area to upload",
              ])}
              description={lang.get("customer", [
                "Support single file type: jpeg, jpg, png, gif",
              ])}
              accept="image/*"
              name="drag-pic"
            />
          </Form.Item>
        </Col>
      </Row>

      {/* Actions */}
      <Divider />
      <Row justify="end" gutter={[16, 16]}>
        <Col>
          <Button onClick={() => setContactModelOpen(false)}>
            {lang.get("customer", ["Cancel"])}
          </Button>
        </Col>
        <Col>
          <Button onClick={saveContact} loading={saving} type="primary">
            {lang.get("customer", ["Save"])}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ContactModel;
